<div class="tfi-modal">
  <span class="tfi-modal__title">
    Total a pagar: ${{ totalPayment | number : '1.2-2' }}
  </span>
  <span class="tfi-modal__label">Ingrese la información de su tarjeta:</span>

  <div class="tfi-modal-body" [formGroup]="form">
    <gc-label
      label="Número de la tarjeta"
      [control]="creditCardNumberFormControl"
    >
      <gc-input
        id="creditCardNumberBilling"
        type="cardNumber"
        formControlName="creditCardNumber"
        placeHolder="Número de la tarjeta"
      ></gc-input>
    </gc-label>
    <gc-label label="Fecha de expiración" [control]="expirationDateFormControl">
      <gc-input
        id="expirationDateBilling"
        type="expirationCard"
        formControlName="expirationDate"
        placeHolder="Fecha de expiración"
      ></gc-input>
    </gc-label>
    <gc-label label="Código de seguridad" [control]="cvvFormControl">
      <gc-input
        id="cvvFormControlBilling"
        type="text"
        formControlName="cvv"
        [mask]="'000'"
        placeHolder="Código de seguridad"
      ></gc-input>
    </gc-label>
  </div>
  <span class="tfi-modal__disclaimer"
    >El pago que está a punto de realizar será procesado por nuestra empresa
    subcontratada, Wompi. Como resultado, no almacenamos ni conservamos ninguna
    información de pago proporcionada durante este proceso. Su transacción será
    manejada de manera segura y conforme a las políticas de privacidad y
    seguridad de Wompi.
  </span>
  <div class="tfi-modal-buttons-container">
    <div class="tfi-modal-buttons-container__button">
      <gc-button
        text="Cancelar"
        type="full"
        mode="outline"
        color="secondary"
        (click)="cancel()"
      ></gc-button>
    </div>
    <div class="tfi-modal-buttons-container__button">
      <gc-button text="Acepto" type="full" (click)="submit()"></gc-button>
    </div>
  </div>
</div>
