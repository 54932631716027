import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISelectOption } from '../../interfaces/option-interface';

@Component({
  selector: 'gc-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  @Input() totalElements = 0;
  @Input() currentPage = 0;
  @Input() limitPerPage = 0;
  @Output() pageChanged = new EventEmitter<number>();
  @Output() limitPerPageChanged = new EventEmitter<number>();

  limits: ISelectOption[] = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  get totalPages(): number {
    return Math.ceil(this.totalElements / this.limitPerPage);
  }

  get firstElemenet(): number {
    return (this.currentPage - 1) * this.limitPerPage + 1;
  }

  get lastElement(): number {
    return Math.min(this.currentPage * this.limitPerPage, this.totalElements);
  }

  get lastPage(): number {
    return Math.min(this.totalElements / this.limitPerPage);
  }

  nextPage(): void {
    if (this.currentPage === this.lastPage) return;
    this.currentPage++;
    this.pageChanged.emit(this.currentPage);
  }

  previousPage(): void {
    if (this.currentPage === 1) return;
    this.currentPage--;
    this.pageChanged.emit(this.currentPage);
  }

  limitChanged(optionSelected: ISelectOption): void {
    this.limitPerPage = optionSelected.value;
    this.limitPerPageChanged.emit(optionSelected.value);
  }

  goToLastPage() {
    if (this.currentPage === this.lastPage) return;
    this.pageChanged.emit(Math.ceil(this.lastPage));
  }

  goToFirstPage() {
    if (this.currentPage === 1) return;
    this.pageChanged.emit(1);
  }
}
