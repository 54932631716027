import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ITableColumn,
  TableColumnTypeEnum,
  TableEntityEnum,
} from '@gc-tech/components';
import {
  IAdminInvoice,
  InvoiceStatusEnum,
  PaymentTypeEnum,
  UserPermissionsEnum,
} from '@tfi-workspace-web/tfi-shared-utils';
import { BillingPaymentSelectionDialogComponent } from './billing-payment-selection-dialog/billing-payment-selection-dialog.component';
import { WirePaymentDialogComponent } from './wire-payment-dialog/wire-payment-dialog.component';
import { BillingViewService } from '../../services/billing/view/billing-view.service';
import * as moment from 'moment';
import { EMPTY, catchError, take } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PaymentViewService } from '../../services/payment/view/payment-view.service';
import { InternalPaymentMethodEnum } from '../../enums/internal-payment-method.enum';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';

@Component({
  selector: 'tfi-feat-bm-billing-management',
  templateUrl: './billing-management.component.html',
  styleUrls: ['./billing-management.component.scss'],
})
export class BillingManagementComponent implements OnInit {
  selectedInvoices: IAdminInvoice[] = [];
  @ViewChild('statusModal') statusModal!: TemplateRef<any>;
  startDateFilter?: string;
  endDateFilter?: string;
  invoices: IAdminInvoice[] = [];
  isLoading = false;
  canExecutePayment = false;
  userPermissionsEnum = UserPermissionsEnum;

  columns: ITableColumn[] = [
    {
      field: 'invoiceNumber',
      header: 'No. Factura',
      sort: false,
    },
    {
      field: 'issueDateTime',
      header: 'Fecha de emisión',
      sort: false,
      fieldType: TableColumnTypeEnum.FULL_DATE,
    },
    {
      field: 'dueDate',
      header: 'Última fecha de pago',
      sort: false,
      fieldType: TableColumnTypeEnum.FULL_DATE,
    },
    {
      field: 'status',
      header: 'Estado',
      sort: false,
      fieldType: TableColumnTypeEnum.TAG,
      entity: TableEntityEnum.PAYMENT,
    },
    {
      field: 'totalBeforeTax',
      header: 'Total antes de impuestos',
      sort: false,
      fieldType: TableColumnTypeEnum.CURRENCY,
    },
    {
      field: 'ivaAmount',
      header: 'IVA',
      sort: false,
      fieldType: TableColumnTypeEnum.CURRENCY,
    },
    {
      field: 'total',
      header: 'Total',
      sort: false,
      fieldType: TableColumnTypeEnum.CURRENCY,
    },
  ];

  constructor(
    private dialog: MatDialog,
    private billingViewService: BillingViewService,
    private toastr: ToastrService,
    private paymentViewService: PaymentViewService,
    private auth: AuthenticationViewService
  ) {
    this.inicializeDatesFilters();
  }

  ngOnInit(): void {
    this.getInvoices(this.startDateFilter || '', this.endDateFilter || '');
    this.canExecutePayment = this.auth.hasPermissionStrict([
      this.userPermissionsEnum.USR_APT_CRT,
    ]);
  }

  inicializeDatesFilters() {
    const currentYear = moment().year();
    this.startDateFilter = moment()
      .year(currentYear)
      .startOf('year')
      .format('YYYY-MM-DD');
    this.endDateFilter = moment()
      .year(currentYear)
      .endOf('year')
      .format('YYYY-MM-DD');
  }

  getInvoices(startDate: string, endDate: string) {
    this.billingViewService
      .getInvoices(startDate, endDate)
      .pipe(
        take(1),
        catchError(() => {
          this.toastr.error(
            'No se pudo completar la solicitud de las facturas.'
          );
          return EMPTY;
        })
      )
      .subscribe((invoiceResponse) => {
        this.invoices = invoiceResponse.data.invoices.map((inv) => {
          const dateMomentObject = moment(inv.dueDate, 'DD/MM/YYYY');
          const dateObject = dateMomentObject.toDate();
          return Object.assign(inv, { dueDate: dateObject });
        });
      });
  }

  setSelectedInvoices(invoices: IAdminInvoice[]): void {
    this.selectedInvoices = [];
    this.selectedInvoices = invoices;
  }

  openPaymentDialog() {
    if (this.selectedInvoices.length === 0) return;
    const isValidSelection = this.selectedInvoices.some(
      (invoice) =>
        invoice.status === InvoiceStatusEnum.PENDING ||
        invoice.status === InvoiceStatusEnum.PAID
    );
    if (isValidSelection) {
      this.toastr.error(
        'las facturas seleccionas no son validas para realizar un pago'
      );
      return;
    }
    const dialogRef = this.dialog.open(BillingPaymentSelectionDialogComponent, {
      width: '90%',
      maxWidth: '500px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.selectedInvoices = this.selectedInvoices.map((inv) => {
        const dateMomentObject = moment(inv.dueDate).format('DD/MM/YYYY');
        const dateObject = dateMomentObject.toString();
        return Object.assign(inv, { dueDate: dateObject });
      });
      if (res && res === PaymentTypeEnum.WIRE) {
        this.dialog.open(WirePaymentDialogComponent, {
          data: this.selectedInvoices,
          width: '90%',
          maxWidth: '500px',
          disableClose: true,
        });
      }
      if (res && res === PaymentTypeEnum.CREDIT_CARD) {
        this.submitCardPayment();
      }
    });
  }

  submitCardPayment() {
    if (this.isLoading) return;
    this.isLoading = true;
    const modal = this.dialog.open(this.statusModal, {
      disableClose: true,
    });
    const payload = {
      invoicesToPay: this.selectedInvoices,
      paymentMethod: InternalPaymentMethodEnum.WOMPI,
    };
    this.paymentViewService
      .submitPayment(payload)
      .pipe(
        take(1),
        catchError(() => {
          modal.close();
          this.toastr.error('Lo sentimos, no pudimos procesar la solicitud');
          this.isLoading = false;
          this.getInvoices(
            this.startDateFilter || '',
            this.endDateFilter || ''
          );
          return EMPTY;
        })
      )
      .subscribe((resp) => {
        modal.close();
        this.isLoading = false;
        const url = resp.data.urlEnlace;
        window.location.href = url;
      });
  }
}
