<div class="tfi-category" [@slideInFromRight]>
  <div class="tfi-category-controls">
    <div class="tfi-category-controls__input">
      <gc-input
        id="searchCategory"
        #searchTerm
        placeHolder="Buscar una categoría"
        type="text"
        icon="search"
        (input)="search(searchTerm.value)"
      ></gc-input>
    </div>
    <div class="tfi-category-controls__button">
      <gc-button
        *PermissionControl="[userPermissions.USR_CAT_CRT]"
        text="Agregar una categoría"
        icon="plus"
        type="full"
        (click)="openCreateCategoryDialog()"
      ></gc-button>
    </div>
  </div>
  <div class="tfi-category-cards">
    <gc-card
      *ngFor="let category of categories; let i = index"
      [cardImage]="category.categoryImg"
      title="{{ category.categoryName }}"
      (click)="onCardSelected(i)"
    ></gc-card>
  </div>
</div>
