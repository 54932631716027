<div class="branch-edit-dialog-container">
  <div class="title-container">
    <h2 class="title-container__title">{{ formTitle || '' }}</h2>
    <gc-icon
      class="title-container__icon"
      icon="x"
      [dimension]="22"
      color="grey-800"
      mat-dialog-close
    ></gc-icon>
  </div>
  <div class="form-container" [formGroup]="form">
    <div class="form-wrapper">
      <div class="form-section-left-desktop">
        <div class="input-container">
          <gc-label
            label="Código de sucursal"
            [control]="branchCodeFormControl"
          >
            <gc-input
              id="branchCode"
              class="input-container__input"
              type="text"
              formControlName="branchCode"
              placeHolder="Código de sucursal"
            ></gc-input>
          </gc-label>
        </div>
        <div class="input-container">
          <gc-label
            label="Nombre de la sucursal"
            [control]="branchNameFormControl"
          >
            <gc-input
              id="branchName"
              class="input-container__input"
              type="text"
              formControlName="branchName"
              placeHolder="Nombre de la sucursal"
            ></gc-input>
          </gc-label>
        </div>
        <div class="input-container">
          <gc-label label="Correo Electrónico" [control]="emailFormControl">
            <gc-input
              id="branchEmail"
              class="input-container__input"
              type="text"
              formControlName="email"
              placeHolder="Correo Electrónico"
            ></gc-input>
          </gc-label>
        </div>
        <div class="controls-wrapper">
          <div class="input-container">
            <gc-label label="Teléfono" [control]="phoneNumberFormControl">
              <gc-input
                id="branchPhone"
                class="input-container__input"
                type="text"
                formControlName="phoneNumber"
                [mask]="'0000-0000'"
                placeHolder="Teléfono"
              ></gc-input>
            </gc-label>
          </div>
          <div class="input-container">
            <gc-label
              label="Tipo de sucursal"
              [control]="branchTypeFormControl"
            >
              <gc-select
                id="branchType"
                formControlName="branchType"
                [data]="branchType"
                placeholder="Tipo de sucursal"
              ></gc-select>
            </gc-label>
          </div>
        </div>
      </div>
      <div class="form-section-right-desktop">
        <div class="input-container">
          <gc-label label="Dirección" [control]="addressLineFormControl">
            <gc-input
              id="addressLineBranch"
              class="input-container__input"
              type="text"
              formControlName="addressLine"
              placeHolder="Dirección"
            ></gc-input>
          </gc-label>
        </div>
        <div class="controls-wrapper">
          <div class="input-container">
            <gc-label label="Departamento" [control]="stateFormControl">
              <gc-select
                id="branchState"
                formControlName="state"
                [data]="addressStates"
                placeholder="Departamento"
              ></gc-select>
            </gc-label>
          </div>
          <div class="input-container">
            <gc-label label="Municipio" [control]="cityFormControl">
              <gc-select
                id="branchCity"
                formControlName="city"
                [data]="addressCities"
                placeholder="Municipio"
              ></gc-select>
            </gc-label>
          </div>
        </div>
        <div class="controls-wrapper">
          <div class="input-container">
            <gc-label
              label="Distrito (Opcional)"
              [control]="districtFormControl"
            >
              <gc-select
                id="branchDistrict"
                formControlName="district"
                [data]="addressDistrics"
                placeholder="Distrito"
              ></gc-select>
            </gc-label>
          </div>
          <div class="input-container"></div>
        </div>
        <div class="controls-wrapper">
          <div class="input-container">
            <gc-label
              label="Identificador de sucursal"
              [control]="mhCodeFormControl"
            >
              <gc-input
                id="mhCode"
                class="input-container__input"
                type="text"
                [mask]="'AAAA'"
                formControlName="mhCode"
                placeHolder="Identificador de sucursal"
              ></gc-input>
            </gc-label>
          </div>
          <div class="input-container">
            <gc-label
              label="Identificador de POS"
              [control]="posNumberFormControl"
            >
              <gc-input
                id="posNumber"
                class="input-container__input"
                type="text"
                [mask]="'000'"
                formControlName="posNumber"
                placeHolder="Identificador de POS"
              ></gc-input>
            </gc-label>
          </div>
        </div>
      </div>
    </div>
    <button class="add-schedule-button" (click)="addSchedule()">
      Agregar horario +
    </button>
    <div
      class="schedule-container"
      formArrayName="schedules"
      *ngFor="let schedule of schedulesFormControl.controls; let i = index"
    >
      <div class="schedule-item" [formGroupName]="i">
        <div class="schedule-input-container">
          <gc-label label="Día" [control]="getControl(schedule, 'day')">
            <gc-select
              id="dayOfWeek"
              formControlName="day"
              [data]="dayOfWeek"
              placeholder="Día"
            ></gc-select>
          </gc-label>
        </div>
        <div class="input-container">
          <gc-label label="Descripción (Opcional)">
            <gc-input
              id="dayDescriptionBranch"
              class="input-container__input"
              type="text"
              formControlName="description"
              placeHolder="Descripción"
            ></gc-input>
          </gc-label>
        </div>
        <div class="controls-wrapper">
          <div class="schedule-input-container">
            <gc-label
              label="Apertura"
              [control]="getControl(schedule, 'startTime')"
            >
              <gc-select
                id="branchStartTime"
                formControlName="startTime"
                [data]="operationTime"
                placeholder="Hora de apertura"
              ></gc-select>
            </gc-label>
          </div>
          <div class="schedule-input-container">
            <gc-label
              label="Cierre"
              [control]="getControl(schedule, 'endTime')"
            >
              <gc-select
                id="branchEndTime"
                formControlName="endTime"
                [data]="operationTime"
                placeholder="Hora de cierre"
              ></gc-select>
            </gc-label>
          </div>
        </div>
        <button
          class="schedule-item__delete-button"
          (click)="deleteSchedule(i)"
          [disabled]="i === 0"
        >
          <i-feather
            class="schedule-item__delete-button--icon"
            name="trash"
          ></i-feather>
        </button>
      </div>
    </div>
    <div class="error-alert-container">
      <gc-alert
        *ngIf="errorMsg"
        icon="alert-triangle"
        [title]="errorMsg"
        primaryColor="red-500"
        borderColor="red-600"
        backgroundColor="red-50"
        [closable]="false"
      ></gc-alert>
    </div>
    <div class="buttons-wrapper">
      <div class="button-container">
        <gc-button
          class="button-container__button"
          text="Cancelar"
          (click)="cancel()"
          type="full"
          color="secondary"
          mode="ghost"
        ></gc-button>
      </div>
      <div class="button-container">
        <gc-button
          class="button-container__button"
          text="Aceptar"
          (click)="submit()"
          type="full"
          [isLoading]="isLoading"
        ></gc-button>
      </div>
    </div>
  </div>
</div>
