<div class="gc-calendar-container">
  <p-calendar
    [id]="id"
    class="gc-calendar-container__calendar"
    [readonlyInput]="readOnly"
    [selectionMode]="type"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [ngClass]="{
      'gc-calendar-container__calendar--disabled': disabled,
      'gc-calendar-container__calendar--left': textAlign === 'left',
      'gc-calendar-container__calendar--center': textAlign === 'center',
      'gc-calendar-container__calendar--right': textAlign === 'right',
    }"
    [minDate]="minDate"
    [maxDate]="maxDate"
    (onBlur)="onBlur()"
    (onInput)="onInput()"
    (onSelect)="onSelect()"
  ></p-calendar>
</div>
