import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'gc-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent implements OnInit, OnChanges {
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() text?: string;
  @Input() color = 'primary-500';

  class?: string;
  fontSize?: string;

  ngOnInit(): void {
    this.setValues();
  }

  ngOnChanges(): void {
    this.setValues();
  }

  setValues() {
    this.class = `[ gc-link-container__link--${this.color} ]`;
    switch (this.size) {
      case 'sm':
        this.fontSize = '14px';
        break;
      case 'md':
        this.fontSize = '16px';
        break;
      case 'lg':
        this.fontSize = '18px';
        break;
      default:
        this.fontSize = '16px';
        break;
    }
  }
}
