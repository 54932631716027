import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';
import { authMethods, environment } from '@tfi-workspace-web/tfi-shared-utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  noSecureApis = [
    environment.api_host + authMethods.signIn,
    environment.api_host + authMethods.resetPassword,
  ];
  constructor(private authView: AuthenticationViewService) {}
  private isPrivateRoute(currentUrl: string): boolean {
    return !this.noSecureApis.find((api) => currentUrl === api);
  }
  private authorizationIsNotInjectedIntoHeader(req: HttpRequest<any>): boolean {
    return !req.headers.get('Authorization');
  }
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.authorizationIsNotInjectedIntoHeader(request)) {
      if (this.isPrivateRoute(request.url)) {
        const copiedReq = this.handlingTokenInjection(request);
        return next
          .handle(copiedReq)
          .pipe(catchError((err) => this.handleError(err)));
      }
    }
    return next
      .handle(request)
      .pipe(catchError((err) => this.handleError(err)));
  }

  handlingTokenInjection(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      body: req.body,
      headers: req.headers.append(
        'Authorization',
        'Bearer ' + this.authView.token
      ),
    });
  }

  handleError(err: any) {
    if ([401, 403].includes(err.status)) {
      this.authView.logout();
    }
    console.error(err);
    return throwError(() => err);
  }
}
