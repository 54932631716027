import { ShippingStatusEnum } from '../interfaces/shipping.interface';

export const ShippingStatusOptions = [
  { label: 'Creado', value: ShippingStatusEnum.CREATED },
  { label: 'En Proceso', value: ShippingStatusEnum.IN_PROCESS },
  { label: 'Envio', value: ShippingStatusEnum.IN_TRANSIT },
  { label: 'Entregado', value: ShippingStatusEnum.DELIVERED },
  { label: 'No Retirado', value: ShippingStatusEnum.NO_DELIVERED },
  { label: 'Deposito', value: ShippingStatusEnum.PAID },
];
