import { IBaseEntity } from './base-entity.interface';
import { DeliverOptionEnum } from './ticket.interface';

export interface IKitchenOrder extends IBaseEntity {
  tableNumber: string;
  deliverOption: DeliverOptionEnum;
  orderNumber: string;
  customerName?: string;
  orderStatus: orderStatusEnum | string;
  orderDateTime: string;
  inProgressDateTime?: Date;
  readyDateTime?: Date;
  orderDetail: IKitchenOrderDetail[];
}

export enum orderStatusEnum {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  READY = 'READY',
}

export interface IKitchenOrderDetail {
  itemDescription: string;
  itemCategory: string;
  itemQuantity: number;
  itemNote?: string;
  itemStatus: orderStatusEnum;
  inProgressDate?: Date;
  readyDate?: Date;
}

export interface IKitchenResponse {
  status: string;
  results: number;
  data: {
    kitchenOrders: IKitchenOrder[];
  };
}

export enum kitchenOrderStatusTranslationEnum {
  TO_DO = 'Por hacer',
  IN_PROGRESS = 'En progreso',
  READY = 'Lista',
}
