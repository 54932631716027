import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  environment,
  IUser,
  IUserAssignRoleResponse,
  IUserResponse,
  IUsersResponse,
  userMethods,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BusinessManagementDataService {
  private readonly userUrl = environment.api_host + userMethods.user;
  constructor(private http: HttpClient) {}

  getUsers(): Observable<IUsersResponse> {
    return this.http.get<IUsersResponse>(this.userUrl);
  }

  getUser(id: string): Observable<IUserResponse> {
    return this.http.get<IUserResponse>(`${this.userUrl}/${id}`);
  }

  createUser(user: IUser): Observable<IUserResponse> {
    return this.http.post<IUserResponse>(this.userUrl, user);
  }

  updateUser(userId: string, user: IUser): Observable<IUserResponse> {
    return this.http.put<IUserResponse>(`${this.userUrl}/${userId}`, user);
  }

  patchUser(userId: string, user: any): Observable<IUserResponse> {
    return this.http.patch<IUserResponse>(`${this.userUrl}/${userId}`, user);
  }

  assignUserRole(
    payload: any,
    id: string
  ): Observable<IUserAssignRoleResponse> {
    return this.http.put<IUserAssignRoleResponse>(
      `${this.userUrl}/${id}/assign-role`,
      payload
    );
  }
}
