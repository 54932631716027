import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormErrorMessageEnum } from '@tfi-workspace-web/tfi-shared-utils';
import { ShippingViewService } from 'libs/tfi-feat-shipping-management/src/lib/services/shipping/view/shipping-view.service';
import { catchError, EMPTY, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'gc-create-driver-dialog',
  templateUrl: './create-driver-dialog.component.html',
  styleUrls: ['./create-driver-dialog.component.scss'],
})
export class CreateDriverDialogComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  isLoading = false;
  errorMsg?: string;
  widthBreakpoint = 1000;
  screenWidth?: number;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenWidth = window.innerWidth;
    this.validateScreenWidth();
  }

  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  get nameFormControl() {
    return this.form.get('name') as FormControl;
  }

  constructor(
    public dialogRef: MatDialogRef<CreateDriverDialogComponent>,
    private view: ShippingViewService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.errorMsg = ''));
  }

  validateScreenWidth() {
    if (this.screenWidth && this.screenWidth > this.widthBreakpoint) {
      this.dialogRef.close();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    this.errorMsg = '';
    if (this.form.invalid) {
      this.errorMsg = 'Verifique el campo requerido';
      this.form.markAllAsTouched();
      return;
    }
    if (!this.isLoading) {
      this.isLoading = true;
      const formData = {
        name: this.nameFormControl.value,
      };
      this.view
        .createShippingCompany(formData)
        .pipe(
          take(1),
          catchError(() => {
            this.errorMsg =
              FormErrorMessageEnum.OCURRIO_UN_ERROR_INTENTE_MAS_TARDE;
            this.isLoading = false;
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.isLoading = false;
          this.dialogRef.close(this.nameFormControl.value);
        });
    }
  }
}
