import { Component, Input } from '@angular/core';

@Component({
  selector: 'gc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() mode: 'solid' | 'outline' | 'ghost' = 'solid';
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() text?: string;
  @Input() color: 'primary' | 'secondary' = 'primary';
  @Input() icon?: string;
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() type?: 'full';
  @Input() disabled = false;
  @Input() isLoading = false;
  @Input() skeleton = false;
}
