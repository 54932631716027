import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DownloadUploadDialogComponent } from 'libs/gc-components-lib/src/lib/components/download-upload-dialog/download-upload-dialog.component';
import { ProductViewService } from '@tfi-workspace-web/tfi-feat-product-management';
import { catchError, EMPTY, forkJoin, of, take } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CompanyViewService } from '../../../services/company/view/company-view.service';
import { WebCatalogViewService } from '../../../services/web-catalog/view/web-catalog-view.service';
import {
  FormErrorMessageEnum,
  ICompany,
  ICompanyWebCatalog,
  UserPermissionsEnum,
} from '@tfi-workspace-web/tfi-shared-utils';

@Component({
  selector: 'tfi-feat-bm-catalog-config',
  templateUrl: './catalog-config.component.html',
  styleUrls: ['./catalog-config.component.scss'],
})
export class CatalogConfigComponent {
  @Output() updateCompanyData = new EventEmitter<void>();
  @Input() company!: ICompany;
  @Input() companyWebCatalog?: ICompanyWebCatalog;
  isEditMode = false;
  isLoading = false;
  selectedLogo?: string;
  currentLogoImage?: string;
  userPermissionsEnum = UserPermissionsEnum;

  form = new FormGroup({
    mainLogo: new FormControl(''),
    slogan: new FormControl(''),
    whatsapp: new FormControl(''),
    instagram: new FormControl(''),
    facebook: new FormControl(''),
    phoneNumber: new FormControl(''),
    tiktok: new FormControl(''),
    youtube: new FormControl(''),
    twitter: new FormControl(''),
    domain: new FormControl('', [
      Validators.min(0),
      Validators.max(100),
      Validators.pattern(/^(?!-)[a-zA-Z0-9-]{1,25}(?<!-)$/),
    ]),
  });

  get mainLogoFormControl() {
    return this.form.get('mainLogo') as FormControl;
  }
  get sloganFormControl() {
    return this.form.get('slogan') as FormControl;
  }
  get whatsappFormControl() {
    return this.form.get('whatsapp') as FormControl;
  }
  get instagramFormControl() {
    return this.form.get('instagram') as FormControl;
  }
  get facebookFormControl() {
    return this.form.get('facebook') as FormControl;
  }
  get phoneNumberFormControl() {
    return this.form.get('phoneNumber') as FormControl;
  }
  get tiktokFormControl() {
    return this.form.get('tiktok') as FormControl;
  }
  get youtubeFormControl() {
    return this.form.get('youtube') as FormControl;
  }
  get twitterFormControl() {
    return this.form.get('twitter') as FormControl;
  }
  get domainFormControl() {
    return this.form.get('domain') as FormControl;
  }

  constructor(
    private dialog: MatDialog,
    private companyViewService: CompanyViewService,
    private productViewService: ProductViewService,
    private webCatalogViewService: WebCatalogViewService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  onEditCatalog() {
    this.patchEditForm();
    this.isEditMode = true;
    this.currentLogoImage = this.company.mainLogo;
  }

  patchEditForm() {
    const formData = {
      mainLogo: this.company.mainLogo,
      domain: this.companyWebCatalog?.domain,
      slogan: this.company.slogan,
      whatsapp: this.company.socialMedia?.whatsapp,
      instagram: this.company.socialMedia?.instagram,
      facebook: this.company.socialMedia?.facebook,
      phoneNumber: this.company.socialMedia?.phoneNumber,
      tiktok: this.company.socialMedia?.tiktok,
      youtube: this.company.socialMedia?.youtube,
      twitter: this.company.socialMedia?.twitter,
    };
    this.form.patchValue(formData);
  }

  onImageClicked() {
    const dialogRef = this.dialog.open(DownloadUploadDialogComponent, {
      width: '90%',
      maxWidth: '700px',
      data: {
        title: 'Actualizar logotipo',
        subtitle:
          'Selecciona una imagen desde tu ordenador para reemplazar la imagen actual',
        primaryButtonLabel: 'Actualizar',
        secondaryButtonLabel: 'Cancelar',
        hideDownloadButton: true,
      },
    });
    dialogRef.componentInstance.fileLoadedEmitter.subscribe((file) => {
      this.uploadImage(file);
    });
    dialogRef.componentInstance.secondaryButtonClicked.subscribe(() => {
      this.mainLogoFormControl.setValue('');
      this.dialog.closeAll();
    });
    dialogRef.componentInstance.fileDeletedEmitter.subscribe(() => {
      this.mainLogoFormControl.setValue('');
    });
    dialogRef.componentInstance.primaryButtonClicked.subscribe(() => {
      this.cdr.detectChanges();
      this.currentLogoImage = this.selectedLogo;
      this.mainLogoFormControl.setValue(this.selectedLogo);
      this.dialog.closeAll();
    });
  }

  deleteLogo() {
    (this.currentLogoImage = ''), this.mainLogoFormControl.setValue('');
  }

  uploadImage(file: File) {
    const formData = new FormData();
    formData.append('image', file);
    this.productViewService
      .uploadImage(formData)
      .pipe(
        take(1),
        catchError(() => {
          this.toastr.error(
            'Lo sentimos, no pudimos procesar la imagen que intentaste agregar. Asegúrate que la imagen tenga formato JPG, JPEG o PNG'
          );
          this.dialog.closeAll();
          return EMPTY;
        })
      )
      .subscribe((response) => {
        this.selectedLogo = response.data.file.url;
      });
  }

  submitCatalogForm() {
    if (this.isLoading) return;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading = true;

    const payload = {
      mainLogo: this.mainLogoFormControl.value,
      slogan: this.sloganFormControl.value,
      socialMedia: {
        whatsapp: this.whatsappFormControl.value,
        instagram: this.instagramFormControl.value,
        facebook: this.facebookFormControl.value,
        phoneNumber: this.phoneNumberFormControl.value,
        tiktok: this.tiktokFormControl.value,
        youtube: this.youtubeFormControl.value,
        twitter: this.twitterFormControl.value,
      },
    };

    const updateCompany$ = this.companyViewService.updateCompany(
      payload as Partial<ICompany>
    );

    const updateDomain$ = this.domainFormControl.dirty
      ? this.webCatalogViewService.updateCompanyWebCatalog({
          domain: this.domainFormControl.value,
        })
      : of(null);

    forkJoin([updateCompany$, updateDomain$])
      .pipe(
        catchError(() => {
          this.toastr.error(
            FormErrorMessageEnum.OCURRIO_UN_ERROR_INTENTE_MAS_TARDE
          );
          this.isLoading = false;
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.isLoading = false;
        this.isEditMode = false;
        this.toastr.success('La solicitud se realizó con éxito');
        this.updateCompanyData.emit();
        if (this.domainFormControl.dirty) {
          this.webCatalogViewService.getCompanyWebCatalog();
          this.domainFormControl.markAsPristine();
        }
      });
  }
}
