import { Component, Input, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputComponent } from '../input/input.component';
import { noop } from 'rxjs';

@Component({
  selector: 'gc-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent),
    },
  ],
})
export class TextAreaComponent {
  @Input() disabled = false;
  @Input() cols: number = 30;
  @Input() rows: number = 5;
  @Input() placeholder: string = '';
  @Input() maxLength = 999;
  @Input() resize = true;

  formControl: FormControl = new FormControl<string>('');
  value = '';

  onTouch: () => void = noop;

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    this.formControl.setValue(value, { emitEvent: false });
  }
}
