import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CrudActionsEnum,
  EntitiesDescription,
  entityCodeEnum,
  IPermisionHandler,
  IPermission,
  IPermissionEntity,
  IRolesPostResponse,
  Modaldata,
  TOAST_MESSAGES,
} from '@tfi-workspace-web/tfi-shared-utils';
import { RoleViewService } from 'libs/tfi-feat-business-management/src/lib/services/role/view/role-view.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, EMPTY, Observable, take } from 'rxjs';

@Component({
  selector: 'tfi-feat-bm-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss'],
})
export class RoleFormComponent implements OnInit {
  formTitle = '';
  allEnabled = false;
  isLoading = false;
  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl(''),
  });
  mappedPermissions: any[] = [];
  updatedPermissionsList: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Modaldata,
    private rolService: RoleViewService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<RoleFormComponent>
  ) {}

  get nameFormControl() {
    return this.form.get('name') as FormControl;
  }
  get descriptionFormControl() {
    return this.form.get('description') as FormControl;
  }

  ngOnInit(): void {
    this.setForm();
    this.mapPermissions(this.data.modalData.permissions);
    this.checkIfAllEnabled();
  }

  setForm() {
    if (this.data.crudAction === CrudActionsEnum.UPDATE) {
      this.formTitle = 'Edita este rol';
      this.updatedPermissionsList =
        this.data.modalData.selectedRol.permissions.map((per: any) => per._id);
      this.form.patchValue({
        name: this.data?.modalData?.selectedRol?.name,
        description: this.data?.modalData?.selectedRol?.description || '',
      });
    } else {
      this.formTitle = 'Crea un nuevo rol';
    }
  }

  mapPermissions(perArr: IPermission[]): void {
    let aux = perArr[0].entity;
    let pivotObj = {
      entity: this.translateEntityLabel(aux as entityCodeEnum),
      permissions: [] as any,
    };
    perArr.forEach((element: IPermission, index) => {
      if (aux !== element.entity || perArr.length === index + 1) {
        aux = element.entity;
        if (perArr.length === index + 1) {
          pivotObj.permissions.push({
            id: element._id,
            code: element.code,
            isSelected: this.updatedPermissionsList.includes(
              element._id as string
            ),
            label: this.translateLabel(element.code),
          });
        }
        this.mappedPermissions.push(pivotObj);
        pivotObj = {
          entity: this.translateEntityLabel(aux as entityCodeEnum),
          permissions: [],
        };
      }
      pivotObj.permissions.push({
        code: element.code,
        id: element._id,
        isSelected: this.updatedPermissionsList.includes(element._id as string),
        label: this.translateLabel(element.code),
      });
    });
  }

  translateLabel(value: string): string {
    if (value.includes('LST')) return 'Listar';
    if (value.includes('CRT')) return 'Crear';
    if (value.includes('EDT')) return 'Editar';
    if (value.includes('DLT')) return 'Borrar';
    return '';
  }

  translateEntityLabel(value: entityCodeEnum): string {
    return EntitiesDescription[value] || '';
  }

  toggleSw(permissionToggled: any, permissionObject: any): void {
    this.populatePermissions(permissionToggled, permissionObject);
    Object.assign(permissionToggled, {
      ...permissionToggled,
      isSelected: !permissionToggled.isSelected,
    });
    if (permissionToggled.isSelected) {
      this.updatedPermissionsList.push(permissionToggled.id);
    } else {
      const index = this.updatedPermissionsList.findIndex(
        (per) => per === permissionToggled.id
      );
      if (index >= 0) {
        this.updatedPermissionsList.splice(index, 1);
      }
    }
    this.checkIfAllEnabled();
  }

  populatePermissions(
    permission: IPermisionHandler,
    permissionObject: IPermissionEntity
  ) {
    if (
      (permission.code.includes('CRT') || permission.code.includes('EDT')) &&
      !permission.isSelected
    ) {
      permissionObject.permissions.forEach((permission) => {
        if (permission.code.includes('LST')) permission.isSelected = true;
      });
    }
    if (permission.code.includes('LST') && !!permission.isSelected) {
      permissionObject.permissions.forEach((permission) => {
        if (permission.code.includes('LST')) return;
        permission.isSelected = false;
      });
    }
  }

  updateAll(): void {
    this.updatedPermissionsList = [];
    this.allEnabled = !this.allEnabled;
    this.mappedPermissions.forEach((entity) => {
      entity.permissions.forEach((permission: any) => {
        permission.isSelected = this.allEnabled;
        if (this.allEnabled) {
          this.updatedPermissionsList.push(permission.id);
        }
      });
    });
    this.checkIfAllEnabled();
  }

  checkIfAllEnabled(): void {
    this.allEnabled =
      this.updatedPermissionsList.length >=
      this.data.modalData.permissions.length;
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  submitAction(): void {
    if (this.isLoading) return;
    this.isLoading = true;
    let sendAction: Observable<IRolesPostResponse>;
    const payload = {
      name: this.nameFormControl.value,
      description: this.descriptionFormControl.value || '',
      permissions: this.updatedPermissionsList,
    };
    if (this.data.crudAction === CrudActionsEnum.CREATE) {
      sendAction = this.rolService.createRole(payload);
    } else {
      sendAction = this.rolService.updateRole(
        payload,
        this.data.modalData.selectedRol._id
      );
    }
    sendAction
      .pipe(
        take(1),
        catchError(() => {
          this.isLoading = false;
          this.toastr.error(TOAST_MESSAGES.ERROR);
          return EMPTY;
        })
      )
      .subscribe((res) => {
        if (res) {
          this.toastr.success(TOAST_MESSAGES.SUCCESS);
          this.dialogRef.close(true);
        }
      });
  }
}
