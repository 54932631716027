import { DayOfWeekEnum } from '../enums/day-of-week.enum';

export const DaysOfWeekConstant = [
  { label: 'Lunes', value: DayOfWeekEnum.MONDAY },
  { label: 'Martes', value: DayOfWeekEnum.TUESDAY },
  { label: 'Miércoles', value: DayOfWeekEnum.WEDNESDAY },
  { label: 'Jueves', value: DayOfWeekEnum.THURSDAY },
  { label: 'Viernes', value: DayOfWeekEnum.FRIDAY },
  { label: 'Sábado', value: DayOfWeekEnum.SATURDAY },
  { label: 'Domingo', value: DayOfWeekEnum.SUNDAY },
];
