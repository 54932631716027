<div class="tfi-empty-state">
  <div class="tfi-empty-state-content">
    <img class="tfi-empty-state-content__image" [src]="imageUrl" />
    <div>
      <h2 class="tfi-empty-state__title">{{ title }}</h2>
      <p class="tfi-empty-state__subtitle">
        {{ subtitle }}
      </p>
      <div class="tfi-empty-state-buttons">
        <gc-button
          *ngIf="primaryButton"
          class="tfi-empty-state-buttons__button"
          type="full"
          [text]="primaryButton"
          (click)="onClickPrimaryButton()"
        ></gc-button>
        <gc-button
          *ngIf="secondaryButton"
          class="tfi-empty-state-buttons__button"
          color="secondary"
          type="full"
          [text]="secondaryButton"
          (click)="onClickSecondaryButton()"
        ></gc-button>
      </div>
    </div>
  </div>
</div>
