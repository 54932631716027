import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { BrandsDataService } from '../../data/brands-data/brands-data.service';
import { IBrand } from '@tfi-workspace-web/tfi-shared-utils';

@Injectable({
  providedIn: 'root',
})
export class BrandsViewService {
  private brandsSubject = new BehaviorSubject<IBrand[]>([]);

  brands$ = this.brandsSubject.asObservable();

  constructor(private data: BrandsDataService) {}

  getBrands() {
    return this.data
      .getBrands()
      .pipe(take(1))
      .subscribe((response) => {
        this.brandsSubject.next(response.data.brands);
      });
  }

  createBrand(brand: IBrand) {
    return this.data.createBrand(brand);
  }

  updateBrand(brandId: string, brand: IBrand) {
    return this.data.updateBrand(brandId, brand);
  }
}
