export interface IStateResponse {
  status: string;
  results: number;
  data: {
    states: IState[];
  };
}

export interface IState {
  stateCode: string;
  stateDescription: string;
}

export interface ICityResponse {
  status: string;
  results: number;
  data: {
    cities: ICity[];
  };
}

export interface ICity {
  cityCode: string;
  cityDescription: string;
  stateCode: string;
}

export interface IDistrictResponse {
  status: string;
  results: number;
  data: {
    districts: IDistrict[];
  };
}

export interface IDistrict {
  districtCode: string;
  districtDescription: string;
  cityCode: string;
  cityDescription: string;
  stateCode: string;
  stateDescription: string;
}
