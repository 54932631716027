import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IMenuOptions } from '@gc-tech/components';
import {
  IProduct,
  TableMenuActionsEnum,
  UserPermissionsEnum,
} from '@tfi-workspace-web/tfi-shared-utils';
import { DownloadUploadDialogComponent } from 'libs/gc-components-lib/src/lib/components/download-upload-dialog/download-upload-dialog.component';
import { ProductViewService } from 'libs/tfi-feat-product-management/src/lib/services/view/product-view/product-view.service';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { take, catchError, EMPTY } from 'rxjs';

@Component({
  selector: 'tfi-feat-product-table',
  templateUrl: './products-table.component.html',
  styleUrls: ['./products-table.component.scss'],
})
export class ProductsTableComponent implements OnChanges {
  @ViewChild('table') table?: Table;
  @Input() data: IProduct[] = [];
  @Input() rows = 10;
  @Input() showPaginator = true;
  @Input() rowsPerPageOptions: number[] = [10, 20, 30];
  @Input() showCurrentPageReport = true;
  @Input() rowHover = true;
  @Input() skeleton = false;
  @Input() showFilters = false;
  @Input() first = 0;
  @Input() selectedRows: IProduct[] = [];
  @Input() totalTableElements?: number;
  @Input() limitPerPage?: number;
  @Input() currentPage?: number;
  @Output() selectedRowsChange = new EventEmitter<IProduct[]>();
  @Output() productSelected = new EventEmitter();
  @Output() updateImageProduct = new EventEmitter<IProduct>();
  @Output() pageChangedEmitter = new EventEmitter<number>();
  @Output() limitPerPageChangedEmitter = new EventEmitter<number>();
  userPermissionsEnum = UserPermissionsEnum;
  selectedImageUrl?: string;
  isLoadingImage = false;
  options: IMenuOptions[] = [
    {
      id: TableMenuActionsEnum.EDIT,
      label: 'Editar',
      icon: TableMenuActionsEnum.EDIT,
    },
  ];

  constructor(
    private dialog: MatDialog,
    private productViewService: ProductViewService,
    private toastr: ToastrService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.table?.resetScrollTop();
    }
  }

  setAction(action: string, data: any, index: number) {
    const detail = {
      data,
      index,
      action,
    };
    this.productSelected.emit(detail);
  }

  updateSelection(): void {
    this.selectedRowsChange.emit(this.selectedRows);
  }

  onImageClicked(product: IProduct) {
    const dialogRef = this.dialog.open(DownloadUploadDialogComponent, {
      width: '90%',
      maxWidth: '700px',
      data: {
        title: 'Actualizar image de producto',
        subtitle:
          'Selecciona una imagen desde tu ordenador para reemplazar la imagen actual',
        primaryButtonLabel: 'Actualizar',
        secondaryButtonLabel: 'Cancelar',
        hideDownloadButton: true,
      },
    });
    dialogRef.componentInstance.fileLoadedEmitter.subscribe((file) => {
      this.uploadImage(file);
    });
    dialogRef.componentInstance.secondaryButtonClicked.subscribe(() => {
      this.selectedImageUrl = '';
      this.dialog.closeAll();
    });
    dialogRef.componentInstance.fileDeletedEmitter.subscribe(() => {
      this.selectedImageUrl = '';
    });
    dialogRef.componentInstance.primaryButtonClicked.subscribe(() => {
      product = {
        ...product,
        mainImageUrl: this.selectedImageUrl,
        category: product.category._id,
        brand: product.brand._id,
      };
      this.updateImageProduct.emit(product);
    });
  }

  uploadImage(file: File) {
    if (this.isLoadingImage) return;
    this.isLoadingImage = true;
    const formData = new FormData();
    formData.append('image', file);
    this.productViewService
      .uploadImage(formData)
      .pipe(
        take(1),
        catchError(() => {
          this.toastr.error(
            'Lo sentimos, no pudimos procesar la imagen que intentaste agregar. Asegúrate que la imagen tenga formato JPG, JPEG o PNG'
          );
          this.isLoadingImage = false;
          this.dialog.closeAll();
          return EMPTY;
        })
      )
      .subscribe((response) => {
        this.isLoadingImage = false;
        this.selectedImageUrl = response.data.file.url;
      });
  }

  pageChanged(page: number) {
    this.pageChangedEmitter.emit(page);
  }

  limitPerPageChanged(limitPerpage: number) {
    this.limitPerPageChangedEmitter.emit(limitPerpage);
  }
}
