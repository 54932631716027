<div class="product-container" [@slideInFromRight]>
  <div class="controls-container">
    <div class="search-container">
      <div class="input-container">
        <form [formGroup]="formSearch">
          <gc-input
            id="searchProduct"
            #searchTerm
            type="text"
            formControlName="searchFilter"
            icon="search"
            (input)="search(searchTerm.value)"
            placeHolder="Buscar por nombre de producto o SKU interno"
          ></gc-input>
        </form>
      </div>
      <div *ngIf="batchUpdateProducts.length > 0">
        <gc-button
          text="Actualizar producto"
          mode="outline"
          type="full"
          (click)="openUpdateProductModal()"
        ></gc-button>
      </div>
    </div>
    <gc-button
      class="controls-container__filter-btn"
      icon="filter"
      mode="outline"
      pTooltip="Filtros"
      tooltipPosition="top"
      (click)="toogleFilters()"
    ></gc-button>
    <gc-button
      *PermissionControl="[userPermissionsEnum.USR_PRD_CRT]"
      class="product-upload"
      text="Importar productos"
      type="full"
      mode="outline"
      icon="upload"
      (click)="openUploadModal()"
    ></gc-button>
    <gc-button
      *PermissionControl="[
        userPermissionsEnum.USR_PRD_CRT,
        userPermissionsEnum.USR_BRD_LST,
        userPermissionsEnum.USR_CAT_LST
      ]"
      class="product-add-btn"
      text="Crear producto"
      icon="plus"
      type="full"
      (click)="createNewProduct()"
    ></gc-button>
  </div>

  <form [formGroup]="form" *ngIf="showFilters" class="filters-container">
    <gc-select
      class="filters-container__select-item"
      formControlName="productStateFilter"
      [data]="productStatOpt"
      placeholder="Filtrar por estado"
      (optionSelectedEmitter)="onFilterByStatus($event)"
    ></gc-select>

    <gc-select
      class="filters-container__select-item"
      formControlName="productBrandFilter"
      [data]="brandInputOptions"
      placeholder="Filtrar por marca"
      (optionSelectedEmitter)="onFilterByBrand($event)"
    ></gc-select>

    <gc-select
      class="filters-container__select-item"
      formControlName="productCategoryFilter"
      [data]="categoryInputOptions"
      placeholder="Filtrar por categoria"
      (optionSelectedEmitter)="onFilterByCateogry($event)"
    ></gc-select>

    <gc-button
      text="Limpiar Filtros"
      mode="ghost"
      color="secondary"
      type="full"
      (click)="clearFilters()"
    ></gc-button>
  </form>

  <div class="table-container">
    <tfi-feat-product-table
      [data]="products"
      [skeleton]="isSearching"
      [totalTableElements]="totalTableElements"
      [showFilters]="showFilters"
      [showPaginator]="false"
      (productSelected)="tableMenuSelected($event)"
      (updateImageProduct)="updateImageProduct($event)"
      [(selectedRows)]="batchUpdateProducts"
    ></tfi-feat-product-table>
  </div>
  <div class="card-container-mobile" #mobileCardsContainer>
    <gc-small-info-card
      *ngFor="let product of products"
      [imageUrl]="product.mainImageUrl || '/assets/images/no-image.jpg'"
      [primaryLabel]="product.internalSKU"
      [secondaryLabel]="product.productName"
      [price]="product.price"
      (click)="openEditMobileSidebar(product)"
    >
    </gc-small-info-card>
  </div>
  <div *ngIf="totalTableElements && filters.page && filters.limit">
    <gc-paginator
      [totalElements]="totalTableElements"
      [currentPage]="filters.page"
      [limitPerPage]="filters.limit"
      (pageChanged)="pageChanged($event)"
      (limitPerPageChanged)="limitPerPageChange($event)"
    ></gc-paginator>
  </div>
  <gc-sidebar-product-basic-form
    *ngIf="mobileProductSelected && editSidebarVisibility"
    [isProductSidebarVisible]="editSidebarVisibility"
    [selectedProduct]="mobileProductSelected"
    (hideSidebar)="onHideProductSidebar()"
  ></gc-sidebar-product-basic-form>

  <ng-template #statusModal let-data>
    <div class="tfi-update-modal" *ngIf="!showCatalogModal && !showStatusModal">
      <div class="tfi-update-modal-header">
        <div class="tfi-update-modal-header-icon">
          <gc-icon icon="edit" [dimension]="22"></gc-icon>
        </div>
        <gc-icon
          class="tfi-update-modal-header__icon"
          icon="x"
          color="grey-700"
          [dimension]="22"
          (click)="closeAllModals()"
        ></gc-icon>
      </div>
      <div class="tfi-update-modal-body">
        <span class="tfi-update-modal-body__label">
          ¿Desea actualizar la disponibilidad en el catálogo o los estados?
        </span>
        <div class="tfi-update-modal-body-buttons">
          <div class="tfi-update-modal-body-buttons__button">
            <gc-button
              mode="outline"
              type="full"
              text="Disponibilidad en catálogo"
              (click)="showCatalogModal = true"
            ></gc-button>
          </div>
          <div class="tfi-update-modal-body-buttons__button">
            <gc-button
              type="full"
              text="Actualizar Estados"
              (click)="showStatusModal = true"
            ></gc-button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showCatalogModal" class="tfi-update-modal">
      <div class="tfi-update-modal-header">
        <div class="tfi-update-modal-header-icon">
          <gc-icon icon="edit" [dimension]="22"></gc-icon>
        </div>
        <gc-icon
          class="tfi-update-modal-header__icon"
          icon="x"
          color="grey-700"
          [dimension]="22"
          (click)="closeAllModals()"
        ></gc-icon>
      </div>
      <div class="tfi-update-modal-body">
        <span class="tfi-update-modal-body__label">
          ¿Deseas actualizar la disponibilidad en el catálogo de
          {{ batchUpdateProducts.length }}
          {{ batchUpdateProducts.length > 1 ? 'productos' : 'producto' }}?
        </span>
        <form
          [formGroup]="showCatalogModalForm"
          class="tfi-update-modal-body-form"
        >
          <gc-radio-card
            class="tfi-update-modal-body-form__radio-card"
            title="Activar"
            description="Los productos seleccionados estarán disponibles en tu catálogo en linea."
            [disabled]="isUpdatingStatus"
            [value]="prodStatusEnum.ACTIVATE"
            [checked]="
              showCatalogModalForm.controls.showCatalog.value ===
              prodStatusEnum.ACTIVATE
            "
            formControlName="showCatalog"
          ></gc-radio-card>
          <gc-radio-card
            class="tfi-update-modal-body-form__radio-card"
            title="Desactivar"
            description="Los productos seleccionados no aparecerán en tu catálogo en linea."
            [disabled]="isUpdatingStatus"
            [value]="prodStatusEnum.DEACTIVATE"
            [checked]="
              showCatalogModalForm.controls.showCatalog.value ===
              prodStatusEnum.DEACTIVATE
            "
            formControlName="showCatalog"
          ></gc-radio-card>
        </form>
        <div class="tfi-update-modal-body-buttons">
          <div class="tfi-update-modal-body-buttons__button">
            <gc-button
              mode="outline"
              type="full"
              text="Cancelar"
              [disabled]="isUpdatingStatus"
              (click)="closeAllModals()"
            ></gc-button>
          </div>
          <div class="tfi-update-modal-body-buttons__button">
            <gc-button
              type="full"
              text="Actualizar"
              [disabled]="!showCatalogModalForm.valid"
              [isLoading]="isUpdatingStatus"
              (click)="onBulkShowCatalog()"
            ></gc-button>
          </div>
        </div>
      </div>
    </div>
    <form
      *ngIf="showStatusModal"
      [formGroup]="statusModalForm"
      class="tfi-status-modal-container"
    >
      <span class="tfi-status-modal-container__title">
        ¿Deseas actualizar el estado de {{ batchUpdateProducts.length }}
        {{ batchUpdateProducts.length > 1 ? 'productos' : 'producto' }}?</span
      >
      <gc-radio-card
        class="tfi-status-modal-container__radio-card"
        title="Activar"
        description="Activar los productos seleccionados."
        [disabled]="isUpdatingStatus"
        [value]="prodStatusEnum.ACTIVATE"
        [checked]="
          statusModalForm.controls.modalSwCard.value === prodStatusEnum.ACTIVATE
        "
        formControlName="modalSwCard"
      ></gc-radio-card>
      <gc-radio-card
        class="tfi-status-modal-container__radio-card"
        title="Desactivar"
        description="Desactivar productos seleccionados (Inventario asociado tambien sera desactivado.) ."
        [disabled]="isUpdatingStatus"
        [value]="prodStatusEnum.DEACTIVATE"
        [checked]="
          statusModalForm.controls.modalSwCard.value ===
          prodStatusEnum.DEACTIVATE
        "
        formControlName="modalSwCard"
      ></gc-radio-card>
      <div class="tfi-modal-btn-container">
        <div [mat-dialog-close]="false">
          <gc-button
            [disabled]="isUpdatingStatus"
            text="Cancelar"
            mode="ghost"
            type="full"
            (click)="closeAllModals()"
          ></gc-button>
        </div>
        <gc-button
          (click)="executeBatchUpdate()"
          [isLoading]="isUpdatingStatus"
          text="Actualizar"
          type="full"
          [disabled]="!statusModalForm.valid"
        ></gc-button>
      </div>
    </form>
  </ng-template>
</div>

<ng-template #confirmationModal>
  <div class="tfi-modal-message-container">
    <ng-container *ngIf="isUpdatingStatus; else message">
      <div class="tfi-sub-container">
        <span class="tfi-sub-container__message"
          >Estamos importando los productos</span
        >
        <mat-spinner [diameter]="75"></mat-spinner>
        <span class="tfi-sub-container__message"
          >Por favor, espere un momento mientras procesamos la
          información...</span
        >
        <span>No cierre esta ventana hasta que el proceso termine.</span>
      </div>
    </ng-container>

    <ng-template #message>
      <div class="tfi-sub-container">
        <span class="tfi-sub-container__message"
          >{{ batchResultMessage }}
        </span>
        <div class="tfi-sub-container__btn">
          <gc-button
            type="full"
            text="Aceptar"
            size="md"
            (click)="closeAllModals()"
          ></gc-button>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>
