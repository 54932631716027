import { IBaseAddress } from './base-address.interface';
import { IBaseEntity } from './base-entity.interface';
import { IBranch } from './branch.interface';
import { ICompany } from './company.interface';
import { ICustomer } from './customer.interface';

export interface IShippingForm {
  shippingNumber: number;
  customer: string;
  company: any;
  branch: any;
  driverName?: string;
  deliveryDay: string;
  shippingAddress?: {
    addressLine: string;
    state: string;
    city: string;
    country: string;
  };
  deliveryHour: string;
  realDeliveryHour: string;
  realDeliveryDateTime: Date;
  cost: number;
  price: number;
  shippingPrice: number;
  customerPhoneNumber: string;
  shippingStatus: ShippingStatusEnum;
  notes?: string;
  location?: string;
  requestPayment: boolean;
  email?: string;
  ticketId?: any;
}

export interface IShippingSend extends IBaseEntity {
  customer: string;
  customerPhoneNumber?: string;
  branch: string;
  driverName: string;
  deliveryDay: string;
  collectionDate: string;
  deliveryHour: string;
  shippingStatus?: string;
  cost: number;
  price: number;
  shippingPrice: number;
  notes?: string;
  shippingAddress: {
    addressLine: string;
    state: string;
    stateCode: string;
    city: string;
    cityCode: string;
    district: string;
    districtCode: string;
    country: string;
  };
  requestPayment: boolean;
}

export interface IShipping extends IBaseEntity {
  shippingNumber: number;
  customer: ICustomer;
  company: ICompany;
  branch: IBranch;
  driverName?: string;
  shippingAddress?: {
    addressLine: string;
    state?: string;
    stateCode?: string;
    city?: string;
    cityCode?: string;
    district?: string;
    districtCode?: string;
    country: string;
  };
  deliveryDay: Date;
  collectionDate: Date;
  deliveryHour: string;
  realDeliveryHour: string;
  realDeliveryDateTime: Date;
  cost: number;
  price: number;
  shippingPrice: number;
  customerPhoneNumber: string;
  shippingStatus: ShippingStatusEnum;
  notes?: string;
  location?: string;
  requestPayment: boolean;
  email?: string;
  ticketId?: string;
  packingUser?: string;
}

export enum ShippingStatusEnum {
  CREATED = 'CREATED',
  IN_PROCESS = 'IN_PROCESS',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
  NO_DELIVERED = 'NO_DELIVERED',
  PAID = 'PAID',
  RE_DELIVERED = 'RE_DELIVERED',
}

export interface IShippingsResponse {
  status: string;
  results: number;
  page: number;
  result: number;
  total: number;
  totalPages: number;
  data: {
    shippings: IShipping[];
  };
}

export interface IShippingResponse {
  status: string;
  results: number;
  data: {
    shipping: IShipping;
  };
}
