import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { IMenuOptions } from '../../interfaces/menu-options.interface';

@Component({
  selector: 'gc-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() options?: IMenuOptions[];
  @Input() position: 'left' | 'right' | 'top' = 'right';
  @Output() selectedOption = new EventEmitter<string>();
  showOptions = false;

  @HostListener('document:click')
  clickedOut() {
    this.showOptions = false;
  }

  openOptions(e: any) {
    e.stopPropagation();
    this.showOptions = true;
  }

  selectOption(id: string) {
    this.selectedOption.emit(id);
  }
}
