import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  companyWebCatalogMethods,
  environment,
  ICompanyWebCatalog,
  ICompanyWebCatalogResponse,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebCatalogDataService {
  private readonly companyWebCatalogUrl =
    environment.api_host + companyWebCatalogMethods.webCatalog;

  constructor(private http: HttpClient) {}

  getCompanyWebCatalog(): Observable<ICompanyWebCatalogResponse> {
    return this.http.get<ICompanyWebCatalogResponse>(this.companyWebCatalogUrl);
  }

  updateCompanyWebCatalog(
    webCatalog: Partial<ICompanyWebCatalog>
  ): Observable<ICompanyWebCatalogResponse> {
    return this.http.put<ICompanyWebCatalogResponse>(
      this.companyWebCatalogUrl,
      webCatalog
    );
  }
}
