import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gc-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() cardImage?: string;
  @Input() title?: string;
  @Input() description?: string;
  @Input() isSelected = false;
  @Input() skeleton = false;
  @Output() cardSelected = new EventEmitter<void>();

  onCardSelected() {
    if (!this.skeleton) {
      this.cardSelected.emit();
    }
  }
}
