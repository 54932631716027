<div class="user-container" [@slideInFromRight]>
  <div class="controls-container">
    <div class="input-container">
      <gc-input
        id="search"
        #searchTerm
        class="input-container__input"
        type="text"
        icon="search"
        (input)="search(searchTerm.value)"
        placeHolder="Buscar usuario"
      ></gc-input>
    </div>
    <div class="button-container">
      <gc-button
        *PermissionControl="[
          userPermissionsEnum.USR_USR_CRT,
          userPermissionsEnum.USR_BRN_LST,
          userPermissionsEnum.USR_ROL_LST
        ]"
        text="Agregar usuario"
        icon="plus"
        type="full"
        (click)="createUser()"
      ></gc-button>
    </div>
  </div>
  <div class="table-container">
    <gc-table
      (selectedAction)="tableMenuSelected($event)"
      [columns]="columns"
      [data]="users"
      [options]="tableOptions"
      [skeleton]="isSearching"
    ></gc-table>
  </div>
</div>
