<div class="tfi-modal">
  <div class="tfi-modal-title">
    <h3 class="tfi-modal-title__title">Agrega un nuevo encomendista</h3>
    <gc-icon
      class="tfi-modal-title__icon"
      icon="x"
      color="grey-800"
      [dimension]="22"
      (click)="cancel()"
    ></gc-icon>
  </div>
  <form class="tfi-modal-form" [formGroup]="form">
    <gc-label label="Nombre del encomendista" [control]="nameFormControl">
      <gc-input
        id="shippingCompanyName"
        type="text"
        formControlName="name"
        placeHolder="Nombre del encomendista"
      ></gc-input>
    </gc-label>
    <gc-alert
      class="tfi-modal-alert"
      *ngIf="errorMsg"
      icon="alert-triangle"
      [title]="errorMsg"
      primaryColor="red-500"
      borderColor="red-600"
      backgroundColor="red-50"
      [closable]="false"
    ></gc-alert>
    <div class="tfi-modal-buttons">
      <gc-button
        class="tfi-modal-buttons__button"
        text="Cancelar"
        type="full"
        color="secondary"
        mode="ghost"
        (click)="cancel()"
      ></gc-button>
      <gc-button
        class="tfi-modal-buttons__button"
        text="Aceptar"
        type="full"
        [isLoading]="isLoading"
        (click)="submit()"
      ></gc-button>
    </div>
  </form>
</div>
