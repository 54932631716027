<button
  class="gc-button"
  #button
  [ngClass]="{
    solid: mode === 'solid',
    outline: mode === 'outline',
    ghost: mode === 'ghost',
    small: size === 'sm',
    medium: size === 'md',
    large: size === 'lg',
    full: type === 'full',
    skeleton: skeleton,
    secondary: color === 'secondary',
    'secondary-ghost': color === 'secondary' && mode === 'ghost',
    disabled: disabled,
    'outline-disabled': disabled && mode === 'outline',
    'secondary-disabled': disabled && color === 'secondary',
    enabled: !disabled
  }"
  [disabled]="disabled"
>
  <div
    class="gc-button__spinner"
    *ngIf="isLoading"
    [ngClass]="{
      'gc-button__spinner--ghost': mode === 'ghost' || mode === 'outline'
    }"
  ></div>
  <gc-icon
    class="gc-button__icon"
    [icon]="icon"
    *ngIf="icon && iconPosition !== 'right'"
    [dimension]="20"
  ></gc-icon>
  {{ text }}
  <gc-icon
    class="gc-button__icon"
    [icon]="icon"
    *ngIf="icon && iconPosition === 'right'"
    [dimension]="20"
  ></gc-icon>
</button>
