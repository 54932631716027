<div class="company-container" [@slideInFromRight]>
  <h2 class="company-container__sub-title">Informacion de negocio</h2>
  <div class="company-details-container">
    <div class="company-sub-container">
      <div class="company-item">
        <span class="company-item__key">Nombre:</span>
        <span class="company-item__value">{{ company?.displayName }}</span>
      </div>
      <div class="company-item">
        <span class="company-item__key">Nombre Legal:</span>
        <span class="company-item__value">{{ company?.legalName }}</span>
      </div>
    </div>

    <div class="company-sub-container">
      <div class="company-item">
        <span class="company-item__key">Industria:</span>
        <span class="company-item__value">{{
          companyIndustry[company?.industry || 'OTHER']
        }}</span>
      </div>
      <div class="company-item">
        <span class="company-item__key">Moneda por defecto:</span>
        <span class="company-item__value">{{ company?.defaultCurrency }}</span>
      </div>
    </div>
  </div>

  <h2 class="company-container__sub-title company-container__sub-title--inner">
    Contacto
  </h2>
  <div class="company-details-container">
    <div class="company-sub-container" [formGroup]="contactForm">
      <div class="company-item">
        <gc-label
          label="Correo Electrónico"
          [control]="emailCodeFormControl"
          *ngIf="contactEditMode"
        >
          <gc-input
            id="email"
            type="text"
            formControlName="email"
            placeHolder="Correo Electrónico"
          ></gc-input>
        </gc-label>

        <span *ngIf="!contactEditMode" class="company-item__key"
          >Correo Electrónico:</span
        >
        <span *ngIf="!contactEditMode" class="company-item__value">{{
          company?.email
        }}</span>
      </div>
      <div class="company-item">
        <gc-label
          label="Numero de telefono"
          [control]="phoneNumberhCodeFormControl"
          *ngIf="contactEditMode"
        >
          <gc-input
            id="phoneNumber"
            type="text"
            [mask]="'0000-0000'"
            formControlName="phoneNumber"
            placeHolder="Teléfono"
          ></gc-input>
        </gc-label>

        <span *ngIf="!contactEditMode" class="company-item__key"
          >Numero de telefono:</span
        >
        <span *ngIf="!contactEditMode" class="company-item__value">{{
          company?.phoneNumber
        }}</span>
      </div>
    </div>
    <div *ngIf="errorMsgContact" class="error-alert-container">
      <gc-alert
        icon="alert-triangle"
        [title]="errorMsgContact"
        primaryColor="red-500"
        borderColor="red-600"
        backgroundColor="red-50"
        [closable]="false"
      ></gc-alert>
    </div>
    <div class="buttons-container">
      <gc-button
        *ngIf="contactEditMode"
        class="buttons-container__btn"
        text="Cancelar"
        type="full"
        (click)="cancelContactEdit()"
        color="secondary"
        mode="ghost"
        [disabled]="isLoading"
      ></gc-button>
      <gc-button
        *ngIf="contactEditMode"
        class="buttons-container__btn"
        text="Guardar"
        type="full"
        (click)="editContactInfo(contactEditMode)"
        [isLoading]="isLoading"
        [disabled]="
          (!contactForm.valid && contactEditMode) ||
          (!contactForm.dirty && contactEditMode)
        "
      ></gc-button>
      <ng-container *PermissionControl="[userPermissionsEnum.USR_CMP_EDT]">
        <gc-link
          size="lg"
          *ngIf="!contactEditMode"
          (click)="editContactInfo(contactEditMode)"
          text="Editar"
        ></gc-link>
      </ng-container>
    </div>
  </div>
  <div *ngIf="company && company?._id">
    <tfi-feat-bm-catalog-config
      [company]="company"
      [companyWebCatalog]="companyWebCatalog"
      (updateCompanyData)="updateCompanyData()"
    ></tfi-feat-bm-catalog-config>
  </div>

  <!-- <h2 class="company-container__sub-title company-container__sub-title--inner">
    Configuracion de impuestos
  </h2>
  <div class="company-details-container">
    <div class="company-sub-container">
      <div class="company-item">
        <span class="company-item__key"
          >Número de Identificación Tributaria (NIT):</span
        >
        <span class="company-item__value">{{ company?.taxINumber }}</span>
      </div>
    </div>
  </div>
  <h3 class="company-container__sub-title company-container__sub-title--small">
    Detalle de impuestos
  </h3>
  <div [formGroup]="taxDetailForm">
    <div
      formArrayName="taxDetail"
      *ngFor="let tax of taxDetailFormControl.controls; let i = index"
    >
      <div [formGroupName]="i" class="tax-container">
        <div class="tax-container__item">
          <gc-label
            label="Tipo de impuesto"
            [control]="getControl(tax, 'taxType')"
          >
            <gc-input
              type="text"
              [disabled]="!taxEditMode"
              formControlName="taxType"
              placeHolder=""
            ></gc-input>
          </gc-label>
        </div>
        <div class="tax-container__item">
          <div>
            <gc-label
              label="Porcentaje"
              [control]="getControl(tax, 'taxPercentage')"
              [messages]="messagePatter"
            >
              <gc-input
                [disabled]="!taxEditMode"
                type="text"
                formControlName="taxPercentage"
                placeHolder=""
                min="1"
                max="100"
                iconPosition="right"
                icon="percent"
                [maxLength]="5"
              ></gc-input>
            </gc-label>
          </div>
        </div>
        <div class="tax-container__item tax-container__item--large">
          <div>
            <gc-label
              label="Descripción (opcional)"
              [control]="getControl(tax, 'description')"
            >
              <gc-input
                type="text"
                [disabled]="!taxEditMode"
                formControlName="description"
                placeHolder=""
              ></gc-input>
            </gc-label>
          </div>
        </div>

        <button
          class="delete-button"
          (click)="deleteTax(i)"
          [disabled]="i === 0 || !taxEditMode"
        >
          <i-feather class="delete-button__icon" name="trash"></i-feather>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="errorMsgTax" class="error-alert-container">
    <gc-alert
      *ngIf="errorMsgTax"
      icon="alert-triangle"
      [title]="errorMsgTax"
      primaryColor="red-500"
      borderColor="red-600"
      backgroundColor="red-50"
      [closable]="false"
    ></gc-alert>
  </div>

  <div class="options-container">
    <button
      class="options-container__add-tax-button"
      (click)="addTax()"
      [disabled]="!taxEditMode"
      [ngClass]="{
        'options-container__add-tax-button--disabled': !taxEditMode
      }"
    >
      Agregar detalle de impuestos +
    </button>
    <div class="buttons-container">
      <gc-button
        *ngIf="taxEditMode"
        class="buttons-container__btn"
        text="Cancelar"
        type="full"
        (click)="cancelTaxEdit()"
        color="secondary"
        mode="ghost"
        [disabled]="isLoading"
      ></gc-button>
      <gc-button
        *ngIf="taxEditMode"
        class="buttons-container__btn"
        text="Guardar"
        type="full"
        (click)="editTaxInfo(taxEditMode)"
        [isLoading]="isLoading"
        [disabled]="
          (!taxDetailForm.valid && taxEditMode) ||
          (!taxDetailForm.dirty && taxEditMode)
        "
      ></gc-button>
      <ng-container *PermissionControl="[userPermissionsEnum.USR_CMP_EDT]">
        <gc-link
          size="lg"
          *ngIf="!taxEditMode"
          (click)="editTaxInfo(taxEditMode)"
          text="Editar"
        ></gc-link>
      </ng-container>
    </div>
  </div> -->
  <div *ngIf="company && company?._id">
    <tfi-feat-bm-payment-gateways-config
      [company]="company"
      (updateCompanyData)="updateCompanyData()"
    ></tfi-feat-bm-payment-gateways-config>
  </div>
  <div>
    <h2
      class="company-container__sub-title company-container__sub-title--inner"
    >
      Parametros de negocio
    </h2>
    <div class="company-details-container">
      <div
        class="company-sub-container"
        *ngIf="company?.industry === companyIndustryEnum.FOOD"
      >
        <div class="tfi-sw-card">
          <gc-switch-card
            title="Habilitar pantalla de cocina"
            description="Permite visualizar las ordenes en tiempo real por sucursal"
            icon="monitor"
            [checked]="company?.businessParams?.enableKDS?.value || false"
            [disabled]="isSavingParams"
            (click)="toggleKitchenParam()"
          ></gc-switch-card>
        </div>
      </div>
      <div class="company-sub-container">
        <div class="tfi-sw-card" [ngClass]="{ 'no-click': canNotEditCompany }">
          <gc-switch-card
            title="Habilitar impresion por Zebra"
            description="Permite imprimir etiquetas por protocolo de zebra"
            icon="printer"
            [checked]="
              company?.businessParams?.enableZebraPrinter?.value || false
            "
            [disabled]="isSavingParams || canNotEditCompany"
            (click)="toggleZebraPrinter()"
          ></gc-switch-card>
        </div>
      </div>
      <div class="company-sub-container">
        <div class="tfi-sw-card" [ngClass]="{ 'no-click': canNotEditCompany }">
          <gc-switch-card
            title="Ocultar productos con stock cero en POS"
            description=""
            icon="layers"
            [checked]="
              company?.businessParams?.enableHideStockZero?.value || false
            "
            [disabled]="isSavingParams || canNotEditCompany"
            (click)="toggleHideStockZero()"
          ></gc-switch-card>
        </div>
      </div>
    </div>
  </div>
</div>
