import { PaymentProviderEnum } from './company.interface';

export interface IPaymentGateway {
  comission: number;
  paymentProvider: PaymentProviderEnum;
  paymentProviderName: string;
}

export interface IPaymentProviderResponse {
  status: string;
  data: IPaymentGateway[];
}

export interface IPaymentLinkPayload {
  provider: PaymentProviderEnum;
  ticketId: string;
}
