import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ProductViewService } from '../../../services/view/product-view/product-view.service';
import {
  CrudActionsEnum,
  FormErrorMessageEnum,
  IAttributeOption,
  IBrand,
  ICategory,
  IProduct,
  IProductAttribute,
  IProductResponse,
  ISelectableItem,
  ISkuVerificationResponse,
  ProductTypeOptions,
  SaleTypeOptions,
  TOAST_MESSAGES,
  UserPermissionsEnum,
} from '@tfi-workspace-web/tfi-shared-utils';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { CategoryViewService } from '../../../services/view/category-view/category-view.service';
import { ISelectOption } from '@gc-tech/components';
import {
  EMPTY,
  Observable,
  Subject,
  Subscription,
  catchError,
  map,
  mergeMap,
  of,
  take,
  takeUntil,
} from 'rxjs';
import { BrandsViewService } from '../../../services/view/brands-view/brands-view.service';
import { ProductModalTitlesEnum } from '../../../enums/product-modal-titles.enum';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ManageCategoryDialogComponent } from '../../category/manage-category-dialog/manage-category-dialog.component';
import { ManageBrandsDialogComponent } from '../../brands/manage-brands-dialog/manage-brands-dialog.component';
import { InputComponent } from 'libs/gc-components-lib/src/lib/components/input/input.component';
import { SwitchComponent } from 'libs/gc-components-lib/src/lib/components/switch/switch.component';

@Component({
  selector: 'tfi-feat-product-management-form',
  templateUrl: './product-management-form.html',
  styleUrls: ['./product-management-form.scss'],
  animations: [
    trigger('menuAnimation', [
      state('void', style({ height: '0', opacity: '0' })),
      state('*', style({ height: '*', opacity: '1' })),
      transition('void <=> *', animate('150ms ease')),
    ]),
  ],
})
export class ProductManagementFormComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenWidth = window.innerWidth;
  }
  screenWidth = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();
  private categoriesSubscription?: Subscription;
  private brandsSubscription?: Subscription;
  isLoading = false;
  isDragZoneVisible = false;
  formTitle?: string;
  errorMsg?: string;
  productTypeOptions = ProductTypeOptions;
  saleTypeOptions = SaleTypeOptions;
  categoriesInputOptions: ISelectOption[] = [];
  brandsInputOptions: ISelectOption[] = [];
  productImages: ISelectableItem[] = [];
  imagesUrl: string[] = [];
  prodId = '';
  currentProduct?: IProduct;
  userPermissionsEnum = UserPermissionsEnum;
  @ViewChild('myInput', { static: false }) inputComponent!: InputComponent;

  form = new FormGroup({
    productName: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.maxLength(1000)]),
    cost: new FormControl(0),
    unitVariableCost: new FormControl(0),
    margin: new FormControl(0),
    showInCatalog: new FormControl(false),
    marginPerc: new FormControl(0),
    price: new FormControl(0, [Validators.required, Validators.min(0.1)]),
    category: new FormControl('', [Validators.required]),
    mainImageUrl: new FormControl(''),
    attributes: new FormArray([]),
    brand: new FormControl('', [Validators.required]),
    productType: new FormControl('', [Validators.required]),
    sellWithoutStock: new FormControl(false),
    productCode: new FormControl(''),
    internalSKU: new FormControl('', [Validators.maxLength(25)]),
    vendorSKU: new FormControl(''),
    isEnabled: new FormControl(true),
    saleType: new FormControl('', [Validators.required]),
  });

  constructor(
    private productViewService: ProductViewService,
    private categoryViewService: CategoryViewService,
    private brandsViewService: BrandsViewService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {}

  get productNameFormControl() {
    return this.form.get('productName') as FormControl;
  }
  get showInCatalogFormControl() {
    return this.form.get('showInCatalog') as FormControl;
  }
  get descriptionFormControl() {
    return this.form.get('description') as FormControl;
  }
  get costFormControl() {
    return this.form.get('cost') as FormControl;
  }
  get unitVariableCostFormControl() {
    return this.form.get('unitVariableCost') as FormControl;
  }
  get marginPercFormControl() {
    return this.form.get('marginPerc') as FormControl;
  }
  get marginFormControl() {
    return this.form.get('margin') as FormControl;
  }
  get priceFormControl() {
    return this.form.get('price') as FormControl;
  }
  get productTypeFormControl() {
    return this.form.get('productType') as FormControl;
  }
  get categoryFormControl() {
    return this.form.get('category') as FormControl;
  }
  get brandFormControl() {
    return this.form.get('brand') as FormControl;
  }
  get attributesFormControl() {
    return this.form.get('attributes') as FormArray;
  }
  get mainImageUrlFormControl() {
    return this.form.get('mainImageUrl') as FormControl;
  }
  get productCodeFormControl() {
    return this.form.get('productCode') as FormControl;
  }
  get internalSKUFormControl() {
    return this.form.get('internalSKU') as FormControl;
  }
  get vendorSKUFormControl() {
    return this.form.get('vendorSKU') as FormControl;
  }
  get sellWithoutStockControl() {
    return this.form.get('sellWithoutStock') as FormControl;
  }
  get productIsEnabled() {
    return this.form.get('isEnabled') as FormControl;
  }
  get saleTypeFormControl() {
    return this.form.get('saleType') as FormControl;
  }

  get totalCost() {
    return (
      Number(this.costFormControl.value) +
      Number(this.unitVariableCostFormControl.value)
    );
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.setupSKUValidators();
    this.getCategories();
    this.getBrands();
    this.activatedRoute.params
      .pipe(
        takeUntil(this.destroy$),
        mergeMap((params) => {
          this.prodId = params['id'];
          if (this.prodId !== 'new') {
            return this.productViewService.getProduct(this.prodId);
          } else {
            this.setForm();
            return of({} as IProductResponse);
          }
        }),
        catchError(() => {
          this.toastr.error(TOAST_MESSAGES.DATA_FETCH_ERROR);
          return EMPTY;
        })
      )
      .subscribe((res: IProductResponse) => {
        if (res?.data?.product) {
          this.currentProduct = res.data.product;
          this.setForm();
          this.patchForm();
        }
        this.form.markAsPristine();
      });
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.errorMsg = ''));
  }

  ngAfterViewInit(): void {
    this.inputComponent.focusInput();
  }

  ngOnDestroy(): void {
    if (this.categoriesSubscription) this.categoriesSubscription.unsubscribe();
    if (this.brandsSubscription) this.brandsSubscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setupSKUValidators() {
    this.internalSKUFormControl.setAsyncValidators(this.validateInternalSKU());
    this.vendorSKUFormControl.setAsyncValidators(this.validateVendorSKU());
  }

  validateInternalSKU(): AsyncValidatorFn {
    return (control): Observable<ValidationErrors | null> => {
      const value = control.value;
      if (
        !value ||
        (this.currentProduct && this.currentProduct?.internalSKU === value)
      ) {
        return of(null);
      }

      return this.productViewService.validateSKU('internal', value).pipe(
        takeUntil(this.destroy$),
        map((res: ISkuVerificationResponse) => {
          return res.exists ? { exists: true } : null;
        }),
        catchError(() => {
          this.toastr.error(TOAST_MESSAGES.ERROR);
          return EMPTY;
        })
      );
    };
  }

  validateVendorSKU(): AsyncValidatorFn {
    return (control): Observable<ValidationErrors | null> => {
      const value = control.value;
      if (
        !value ||
        (this.currentProduct && this.currentProduct?.vendorSKU === value)
      ) {
        return of(null);
      }

      return this.productViewService.validateSKU('vendor', value).pipe(
        takeUntil(this.destroy$),
        map((res: ISkuVerificationResponse) => {
          return res.exists ? { exists: true } : null;
        }),
        catchError(() => {
          this.toastr.error(TOAST_MESSAGES.ERROR);
          return EMPTY;
        })
      );
    };
  }

  patchForm() {
    if (this.currentProduct?._id) {
      const formData = {
        productName: this.currentProduct.productName,
        description: this.currentProduct.description,
        cost: this.currentProduct.cost,
        unitVariableCost: this.currentProduct.unitVariableCost,
        margin: this.currentProduct.margin,
        marginPerc: this.currentProduct.marginPerc * 100,
        price: this.currentProduct.price,
        category: this.currentProduct?.category?._id,
        mainImageUrl: this.currentProduct.mainImageUrl,
        brand: this.currentProduct?.brand?._id,
        productType: this.currentProduct.productType,
        sellWithoutStock: this.currentProduct.sellWithoutStock,
        showInCatalog: this.currentProduct.showInCatalog ?? false,
        productCode: this.currentProduct.productCode,
        internalSKU: this.currentProduct.internalSKU,
        vendorSKU: this.currentProduct.vendorSKU,
        isEnabled: this.currentProduct.isEnabled,
        saleType: this.currentProduct.saleType,
      };
      this.form.patchValue(formData);

      const attributesArray = this.form.get('attributes') as FormArray;
      this.currentProduct?.attributes?.forEach(
        (attribute: IProductAttribute) => {
          const attributeFormGroup = new FormGroup({
            attributeName: new FormControl(attribute.attributeName, [
              Validators.required,
            ]),
            attributeOptions: new FormArray([]),
          });

          const attributeOptionsArray = attributeFormGroup.get(
            'attributeOptions'
          ) as FormArray;
          attribute.attributeOptions.forEach((option: IAttributeOption) => {
            const optionFormGroup = new FormGroup({
              optionName: new FormControl(option.optionName, [
                Validators.required,
              ]),
              priceModifier: new FormControl(option.priceModifier),
            });
            attributeOptionsArray.push(optionFormGroup);
          });
          attributesArray.push(attributeFormGroup);
        }
      );
      if (formData.mainImageUrl) {
        this.isDragZoneVisible = true;
        this.productImages.push({
          url: formData.mainImageUrl,
          isSelected: true,
        });
      }
      this.currentProduct?.images?.forEach((element: string) => {
        this.productImages.push({
          url: element,
          isSelected: false,
        });
      });
    }
  }

  setForm() {
    if (this.currentProduct?._id) {
      this.formTitle = ProductModalTitlesEnum.EDITA_UN_PRODUCTO_EXISTENTE;
    } else {
      this.formTitle = ProductModalTitlesEnum.CREA_UN_NUEVO_PRODUCTO;
    }
  }

  getCategories(idCategorySelected?: string) {
    this.categoriesSubscription =
      this.categoryViewService.categories$.subscribe((categories) => {
        this.updateCategoriesInputOptions(categories);
        if (this.currentProduct?._id) {
          this.cdr.detectChanges();
          this.categoryFormControl.setValue(this.currentProduct?.category?._id);
        }
        if (idCategorySelected) {
          this.cdr.detectChanges();
          this.categoryFormControl.setValue(idCategorySelected);
        }
      });
    this.categoryViewService.getCategories();
  }

  updateCategoriesInputOptions(categories: ICategory[]) {
    categories.forEach((category) => {
      if (!this.categoriesInputOptions.some((c) => c.value === category._id)) {
        this.categoriesInputOptions = [
          ...this.categoriesInputOptions,
          {
            label: category.categoryName,
            value: category._id,
          },
        ];
      }
    });
  }

  getBrands(idBrandSelected?: string) {
    this.brandsSubscription = this.brandsViewService.brands$.subscribe(
      (brands) => {
        this.updateBrandsInputOptions(brands);
        if (this.currentProduct?._id) {
          this.cdr.detectChanges();
          this.brandFormControl.setValue(this.currentProduct?.brand?._id);
        }
        if (idBrandSelected) {
          this.cdr.detectChanges();
          this.brandFormControl.setValue(idBrandSelected);
        }
      }
    );
    this.brandsViewService.getBrands();
  }

  updateBrandsInputOptions(brands: IBrand[]) {
    brands.forEach((brand) => {
      if (!this.brandsInputOptions.some((c) => c.value === brand._id)) {
        this.brandsInputOptions = [
          ...this.brandsInputOptions,
          {
            label: brand.brandName,
            value: brand._id,
          },
        ];
      }
    });
  }

  addProductAttribute() {
    const newAttribute = new FormGroup({
      attributeName: new FormControl('', [Validators.required]),
      attributeOptions: new FormArray([this.createFirstAttribueOption()]),
    });
    this.attributesFormControl.push(newAttribute);
  }

  createFirstAttribueOption(): FormGroup {
    return new FormGroup({
      optionName: new FormControl('', [Validators.required]),
      priceModifier: new FormControl(0),
    });
  }

  addAttribueOption(index: number) {
    const attribute = this.attributesFormControl;
    const option = attribute.at(index).get('attributeOptions') as FormArray;
    option.push(this.createFirstAttribueOption());
  }

  getFormGroup(formGroup: AbstractControl): FormGroup {
    return formGroup as FormGroup;
  }

  getAttributeOptions(attributeItem: AbstractControl): AbstractControl[] {
    const attributeOption = this.getFormGroup(attributeItem).get(
      'attributeOptions'
    ) as FormArray;
    return attributeOption.controls;
  }

  getControl(control: AbstractControl, controlName: string): FormControl {
    return control.get(controlName) as FormControl;
  }

  deleteProductAttribute(index: number) {
    const attribute = this.attributesFormControl;
    attribute.removeAt(index);
  }
  deleteOption(mainIndex: number, nestedIndex: number) {
    const attribute = this.attributesFormControl;
    const option = attribute.at(mainIndex).get('attributeOptions') as FormArray;
    option.removeAt(nestedIndex);
  }

  onImageSelected(event: Event) {
    this.errorMsg = '';
    const imageFile = event.target as HTMLInputElement;
    if (imageFile.files) {
      const file = imageFile.files[0];
      this.uploadImage(file);
    }
  }

  uploadImage(file: File) {
    const formData = new FormData();
    formData.append('image', file);
    this.productViewService
      .uploadImage(formData)
      .pipe(
        take(1),
        catchError(() => {
          this.errorMsg =
            'Lo sentimos, no pudimos procesar la imagen que intentaste agregar. Asegúrate que la imagen tenga formato JPG, JPEG o PNG';
          return EMPTY;
        })
      )
      .subscribe((response) => {
        this.form.markAsDirty();
        this.productImages.push({
          url: response.data.file.url,
          isSelected: false,
        });
      });
  }

  removeImage(index: number) {
    this.productImages.splice(index, 1);
    this.form.markAsDirty();
  }

  onRadioImageSelected(index: number) {
    this.errorMsg = '';
    if (this.productImages.length > 0) {
      this.productImages.forEach((image) => {
        image.isSelected = false;
      });
      this.productImages[index].isSelected = true;
      this.form.markAsDirty();
    }
  }

  openCategoryDialog() {
    const dialogRef = this.dialog.open(ManageCategoryDialogComponent, {
      data: {
        crudAction: CrudActionsEnum.CREATE,
        modalData: {},
      },
      minWidth: '360px',
      width: '85%',
      maxWidth: '800px',
      maxHeight: '90vh',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.data)
        this.getCategories(response.data.category._id);
    });
  }

  openBrandDialog() {
    const dialogRef = this.dialog.open(ManageBrandsDialogComponent, {
      data: {
        crudAction: CrudActionsEnum.CREATE,
        modalData: {},
      },
      minWidth: '360px',
      width: '85%',
      maxWidth: '800px',
      maxHeight: '90vh',
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.data) this.getBrands(response.data.brand._id);
    });
  }

  cancel() {
    this.router.navigate(['/admin/product-management/product']);
  }

  submit() {
    this.errorMsg = '';
    if (this.form.invalid) {
      this.errorMsg =
        FormErrorMessageEnum.LOS_DATOS_SON_INVALIDOS_INTENTE_DE_NUEVO;
      this.form.markAllAsTouched();
      return;
    }
    if (this.productImages.length > 0) {
      const mainImage = this.productImages.find((image) => image.isSelected);
      if (!mainImage) {
        this.errorMsg = 'Por favor, seleccion una imagen como principal';
        return;
      }
      this.mainImageUrlFormControl.setValue(mainImage.url);
      const images = this.productImages.filter((image) => image !== mainImage);
      images.forEach((image) => {
        if (!this.imagesUrl.includes(image.url)) {
          this.imagesUrl.push(image.url);
        }
      });
    } else {
      this.mainImageUrlFormControl.setValue('');
      this.imagesUrl = [];
    }

    const formData = {
      productName: this.form.controls['productName'].value,
      description: this.form.controls['description'].value,
      cost: Number(this.form.controls['cost'].value),
      unitVariableCost: Number(this.form.controls['unitVariableCost'].value),
      margin: this.form.controls['margin'].value,
      marginPerc: this.form.controls['marginPerc'].value,
      price: Number(this.form.controls['price'].value),
      productType: this.form.controls['productType'].value,
      showInCatalog: this.form.controls['showInCatalog'].value,
      mainImageUrl: this.form.controls['mainImageUrl'].value,
      images: this.imagesUrl,
      sellWithoutStock: this.form.controls['sellWithoutStock'].value,
      category: this.form.controls['category'].value,
      brand: this.form.controls['brand'].value,
      attributes: this.form.controls['attributes'].value,
      vendorSKU: this.form.controls['vendorSKU'].value,
      isEnabled: this.form.controls['isEnabled'].value,
      saleType: this.form.controls['saleType'].value,
    } as IProduct;

    if (!this.isLoading) {
      this.isLoading = true;
      if (!this.currentProduct?._id) {
        this.productViewService
          .createProduct(formData)
          .pipe(
            take(1),
            catchError(() => {
              this.errorMsg =
                FormErrorMessageEnum.OCURRIO_UN_ERROR_INTENTE_MAS_TARDE;
              this.isLoading = false;
              this.toastr.error(TOAST_MESSAGES.ERROR);
              return EMPTY;
            })
          )
          .subscribe(() => {
            this.isLoading = false;
            this.errorMsg = '';
            this.toastr.success(TOAST_MESSAGES.SUCCESS);
            this.router.navigate(['/admin/product-management/product']);
          });
      }
      if (this.currentProduct?._id) {
        this.productViewService
          .updateProduct(this.currentProduct._id, formData)
          .pipe(
            take(1),
            catchError(() => {
              this.errorMsg =
                FormErrorMessageEnum.OCURRIO_UN_ERROR_INTENTE_MAS_TARDE;
              this.isLoading = false;
              this.toastr.error(TOAST_MESSAGES.ERROR);
              return EMPTY;
            })
          )
          .subscribe(() => {
            this.isLoading = false;
            this.errorMsg = '';
            this.router.navigate(['/admin/product-management/product']);
            this.toastr.success(TOAST_MESSAGES.SUCCESS);
          });
      }
    }
  }

  onCostChange(): void {
    if (this.totalCost <= 0) {
      this.marginPercFormControl.setValue(null);
      this.marginFormControl.setValue(null);
    }
    if (this.totalCost && this.priceFormControl.value) {
      this.calculateMargin();
      this.calculateMarginPercWithCost();
      return;
    }
  }

  onMarginPercChange(): void {
    if (!this.marginPercFormControl.value) {
      this.marginFormControl.setValue(null);
      this.priceFormControl.setValue(null);
    }
    if (this.totalCost && this.marginPercFormControl.value) {
      this.calculatePrice();
      this.calculateMargin();
    }
  }

  onMarginChange() {
    if (!this.marginFormControl.value) {
      this.marginPercFormControl.setValue(null);
      this.priceFormControl.setValue(null);
    }
    if (this.totalCost && this.marginFormControl.value) {
      this.calculatePriceWithMargin();
      this.calculateMarginPerc();
    }
  }

  onPriceChange() {
    if (!this.priceFormControl.value) {
      this.marginFormControl.setValue(null);
      this.marginPercFormControl.setValue(null);
    }
    if (this.totalCost) {
      this.marginFormControl.setValue(null);
      this.marginPercFormControl.setValue(null);
    }
    if (this.costFormControl.value && this.priceFormControl.value) {
      this.calculateMarginPercWithPrice();
      this.calculateMargin();
    }
  }

  calculatePrice(): void {
    const cost = this.totalCost;
    const marginPerc = Number(this.marginPercFormControl.value);
    const price = cost + cost * (marginPerc / 100);
    this.priceFormControl.setValue(price.toFixed(2));
  }

  calculatePriceWithMargin(): void {
    const cost = this.totalCost;
    const margin = Number(this.marginFormControl.value);
    const price = cost + margin;
    this.priceFormControl.setValue(price.toFixed(2));
  }

  calculateMargin(): void {
    const cost = this.totalCost;
    const price = Number(this.priceFormControl.value);
    const margin = (Math.round((price - cost) * 100) / 100) * 1;
    this.marginFormControl.setValue(margin.toFixed(2));
  }

  calculateMarginPerc(): void {
    const cost = this.totalCost;
    const margin = Number(this.marginFormControl.value);
    const price = cost + margin;
    const marginPerc = Math.round((price / cost - 1) * 100 * 100) / 100;
    this.marginPercFormControl.setValue(marginPerc.toFixed(2));
  }

  calculateMarginPercWithPrice(): void {
    const cost = this.totalCost;
    const price = Number(this.priceFormControl.value);
    const marginPerc = Math.round((price / cost - 1) * 100 * 100) / 100;
    this.marginPercFormControl.setValue(marginPerc.toFixed(2));
  }

  calculateMarginPercWithCost(): void {
    const cost = this.totalCost;
    const margin = Number(this.priceFormControl.value) - this.totalCost;
    const marginPerc = (margin / cost) * 100;
    this.marginPercFormControl.setValue(marginPerc.toFixed(2));
  }
}
