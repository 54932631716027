import {
  IAdminInvoice,
  IBaseEntity,
  PaymentMethodEnum,
} from '@tfi-workspace-web/tfi-shared-utils';

export interface ITransactionsResponse {
  status: string;
  results: number;
  data: {
    adminPaymentTransactions: IAdminPaymentTransaction[];
  };
}

export interface IRefreshTransactionsResponce {
  status: string;
  data: {
    adminPaymentTransactions?: IAdminPaymentTransaction[];
    message?: string;
  };
}

export interface IAdminPaymentTransaction extends IBaseEntity {
  amount: number;
  paymentMethod: PaymentMethodEnum;
  paymentMethodName: string;
  status: AdminPaymentTransactionsStatusEnum;
  invoices: IAdminInvoice[];
  paymentReferenceId?: string;
  paymentReceiptAttachments: string[];
  transactionDate: Date;
  companyId?: string;
}

export enum AdminPaymentTransactionsStatusEnum {
  SUCCEEDED = 'SUCCEEDED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}
