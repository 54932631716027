import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CrudActionsEnum,
  FormErrorMessageEnum,
  IBrand,
  Modaldata,
  TOAST_MESSAGES,
} from '@tfi-workspace-web/tfi-shared-utils';
import { EMPTY, catchError, take } from 'rxjs';
import { BrandsViewService } from '../../../services/view/brands-view/brands-view.service';
import { BrandModalTitlesEnum } from '../../../enums/brand-modal-titles.enum';
import { ToastrService } from 'ngx-toastr';
import { ProductViewService } from '../../../services/view/product-view/product-view.service';

@Component({
  selector: 'tfi-feat-product-manage-brands-dialog',
  templateUrl: './manage-brands-dialog.component.html',
  styleUrls: ['./manage-brands-dialog.component.scss'],
})
export class ManageBrandsDialogComponent implements OnInit {
  isLoading = false;
  formTitle?: string;
  errorMsg?: string;
  imageItem?: string;
  isImageLoading = false;

  form = new FormGroup({
    brandName: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    vendors: new FormArray([]),
  });

  constructor(
    public dialogRef: MatDialogRef<ManageBrandsDialogComponent>,
    private brandViewService: BrandsViewService,
    @Inject(MAT_DIALOG_DATA) public data: Modaldata,
    private toastr: ToastrService,
    private productViewService: ProductViewService
  ) {}

  get brandNameFormControl() {
    return this.form.get('brandName') as FormControl;
  }
  get descriptionFormControl() {
    return this.form.get('description') as FormControl;
  }
  get vendorsFormControl() {
    return this.form.get('vendors') as FormArray;
  }

  get isFormValid() {
    return this.form.valid;
  }

  ngOnInit(): void {
    this.patchForm();
    this.setTitleForm();
  }

  patchForm() {
    if (this.data.crudAction === CrudActionsEnum.UPDATE) {
      this.form.patchValue(this.data.modalData);
      const vendorsArray: string[] = this.data.modalData.vendors;
      vendorsArray.forEach((vendor) => {
        this.vendorsFormControl.push(
          new FormControl(vendor, [Validators.required])
        );
      });
      this.imageItem = this.data.modalData.brandImg;
    }
  }

  setTitleForm() {
    if (this.data.crudAction === CrudActionsEnum.UPDATE) {
      this.formTitle = BrandModalTitlesEnum.EDITA_UNA_MARCA_EXISTENTE;
    } else {
      this.formTitle = BrandModalTitlesEnum.CREA_UNA_NUEVA_MARCA;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  addVendor() {
    this.vendorsFormControl.push(new FormControl('', [Validators.required]));
  }

  removeVendor(index: number) {
    this.vendorsFormControl.removeAt(index);
  }

  onImageSelected(event: Event) {
    this.errorMsg = '';
    const imageFile = event.target as HTMLInputElement;
    if (imageFile.files) {
      const file = imageFile.files[0];
      this.uploadImage(file);
    }
  }

  uploadImage(file: File) {
    if (!this.isImageLoading) {
      this.isImageLoading = true;
      const formData = new FormData();
      formData.append('image', file);
      this.productViewService
        .uploadImage(formData)
        .pipe(
          take(1),
          catchError(() => {
            this.errorMsg =
              'Lo sentimos, no pudimos procesar la imagen que intentaste agregar. Asegúrate que la imagen tenga formato JPG, JPEG o PNG';
            this.isImageLoading = false;
            return EMPTY;
          })
        )
        .subscribe((response) => {
          this.isImageLoading = false;
          this.imageItem = response.data.file.url;
        });
    }
  }

  removeImage() {
    this.imageItem = '';
  }

  saveAndContinue() {
    this.errorMsg = '';
    if (this.form.invalid) {
      this.errorMsg =
        FormErrorMessageEnum.LOS_DATOS_SON_INVALIDOS_INTENTE_DE_NUEVO;
      this.form.markAllAsTouched();
      return;
    }
    const formData = {
      brandName: this.form.controls['brandName'].value ?? '',
      description: this.form.controls['description'].value,
      vendors: this.form.controls['vendors'].value,
      brandImg: this.imageItem,
    } as IBrand;

    if (!this.isLoading) {
      this.isLoading = true;
      if (this.data.crudAction === CrudActionsEnum.CREATE) {
        this.brandViewService
          .createBrand(formData)
          .pipe(
            take(1),
            catchError((err: any) => {
              if (err.error.status === 400) {
                this.errorMsg = FormErrorMessageEnum.LOS_DATOS_SON_INALIDOS;
              } else if (err.error.status === 409) {
                this.errorMsg = FormErrorMessageEnum.EL_REGISTRO_YA_EXISTE;
              } else if (err.error.status === 500) {
                this.errorMsg =
                  FormErrorMessageEnum.NO_SE_PUDO_COMPLETAR_EL_REGISTRO;
              } else {
                this.errorMsg =
                  FormErrorMessageEnum.OCURRIO_UN_ERROR_INTENTE_MAS_TARDE;
              }
              this.isLoading = false;
              this.toastr.error(TOAST_MESSAGES.ERROR);
              return EMPTY;
            })
          )
          .subscribe((response) => {
            this.isLoading = false;
            this.errorMsg = '';
            this.toastr.success(TOAST_MESSAGES.SUCCESS);
            this.dialogRef.close(response);
          });
      }
      if (this.data.crudAction === CrudActionsEnum.UPDATE) {
        this.brandViewService
          .updateBrand(this.data.modalData._id, formData)
          .pipe(
            take(1),
            catchError((err: any) => {
              if (err.error.status === 400) {
                this.errorMsg = FormErrorMessageEnum.LOS_DATOS_SON_INALIDOS;
              } else if (err.error.status === 500) {
                this.errorMsg =
                  FormErrorMessageEnum.NO_SE_PUDO_COMPLETAR_EL_REGISTRO;
              } else {
                this.errorMsg =
                  FormErrorMessageEnum.OCURRIO_UN_ERROR_INTENTE_MAS_TARDE;
              }
              this.isLoading = false;
              this.toastr.error(TOAST_MESSAGES.ERROR);
              return EMPTY;
            })
          )
          .subscribe(() => {
            this.isLoading = false;
            this.errorMsg = '';
            this.toastr.success(TOAST_MESSAGES.SUCCESS);
            this.dialogRef.close();
          });
      }
    }
  }
}
