import { Injectable } from '@angular/core';
import { BillingDataService } from '../data/billing-data.service';

@Injectable({
  providedIn: 'root',
})
export class BillingViewService {
  constructor(private data: BillingDataService) {}

  getInvoices(startDate: string, endDate: string) {
    return this.data.getInvoices(startDate, endDate);
  }
}
