import { ISelectOption } from '../interfaces/input-select.interface';

export const DocumentOptions: ISelectOption[] = [
  { value: 'DUI', label: 'DUI' },
  { value: 'NIT', label: 'NIT' },
  { value: 'PASAPORTE', label: 'Pasaporte' },
  { value: 'CARNET_RESIDENTE', label: 'Carnet de residente' },
  { value: 'OTRO', label: 'Otros' },
];

export enum documentTypeValueEnum {
  DUI = 'DUI',
  NIT = 'NIT',
  PASAPORTE = 'PASAPORTE',
  CARNET_RESIDENTE = 'CARNET_RESIDENTE',
  OTRO = 'OTRO',
}

export enum contributorSizeEnum {
  GRANDE = 'GRANDE',
  MEDIANO = 'MEDIANO',
  OTROS = 'OTROS',
}

export const ContributorSizeOptions: ISelectOption[] = [
  { value: contributorSizeEnum.GRANDE, label: 'Grande' },
  { value: contributorSizeEnum.MEDIANO, label: 'Mediano' },
  { value: contributorSizeEnum.OTROS, label: 'Otros' },
];

export enum contributorTyoe {
  NATURAL = 'NATURAL',
  JURIDICA = 'JURIDICA',
}

export const ContributorTypeOptions: ISelectOption[] = [
  { value: contributorTyoe.NATURAL, label: 'Natural' },
  { value: contributorTyoe.JURIDICA, label: 'Jurídico' },
];
