import { IBaseEntity } from './base-entity.interface';
import { IPermission } from './permission.interface';

export interface IRole extends IBaseEntity {
  company: string;
  name: string;
  description?: string;
  permissions: IPermission[];
  isDefaultRole: boolean;
}

export interface IRolesResponse {
  status: string;
  results: number;
  page: number;
  result: number;
  total: number;
  totalPages: number;
  data: {
    roles: IRole[];
  };
}

export interface IRolesPostResponse {
  status: string;
  data: {
    userRole: IRole;
  };
}
