import { CitiesEnum, DistricEnum, StatesEnum } from '../enums/address.enum';
import { ISelectOption } from '../interfaces/input-select.interface';

export const States: ISelectOption[] = [
  {
    value: StatesEnum.SAN_SALVADOR,
    label: 'San Salvador',
  },
  {
    value: StatesEnum.LA_LIBERTAD,
    label: 'La Libertad',
  },
];

export const Cities = {
  [StatesEnum.SAN_SALVADOR]: [
    {
      value: CitiesEnum.SAN_SALVADOR_CENTRO,
      label: 'San Salvador Centro',
    },
    {
      value: CitiesEnum.SAN_SALVADOR_NORTE,
      label: 'San Salvador Norte',
    },
    {
      value: CitiesEnum.SAN_SALVADOR_SUR,
      label: 'San Salvador Sur',
    },
    {
      value: CitiesEnum.SAN_SALVADOR_OESTE,
      label: 'San Salvador Oeste',
    },
    {
      value: CitiesEnum.SAN_SALVADOR_ESTE,
      label: 'San Salvador Este',
    },
  ],

  [StatesEnum.LA_LIBERTAD]: [
    {
      value: CitiesEnum.LA_LIBERTAD_CENTRO,
      label: 'La Libertad Centro',
    },
    {
      value: CitiesEnum.LA_LIBERTAD_NORTE,
      label: 'La Libertad Norte',
    },
    {
      value: CitiesEnum.LA_LIBERTAD_SUR,
      label: 'La Libertad Sur',
    },
    {
      value: CitiesEnum.LA_LIBERTAD_OESTE,
      label: 'La Libertad Oeste',
    },
    {
      value: CitiesEnum.LA_LIBERTAD_ESTE,
      label: 'La Libertad Este',
    },
    {
      value: CitiesEnum.LA_LIBERTAD_COSTA,
      label: 'La Libertad Costa',
    },
  ],
};

export const Districts = {
  [CitiesEnum.SAN_SALVADOR_NORTE]: [
    {
      value: DistricEnum.AGUILARES,
      label: 'Aguilares',
    },
    {
      value: DistricEnum.EL_PAISNAL,
      label: 'El Paisnal',
    },
    {
      value: DistricEnum.GUAZAPA,
      label: 'Guazapa',
    },
  ],
  [CitiesEnum.SAN_SALVADOR_OESTE]: [
    {
      value: DistricEnum.APOPA,
      label: 'Apopa',
    },
    {
      value: DistricEnum.NEJAPA,
      label: 'Nejapa',
    },
  ],
  [CitiesEnum.SAN_SALVADOR_ESTE]: [
    {
      value: DistricEnum.ILOPANGO,
      label: 'Ilopango',
    },
    {
      value: DistricEnum.SAN_MARTIN,
      label: 'San Martin',
    },
    {
      value: DistricEnum.SOYAPANGO,
      label: 'Soyapango',
    },
  ],
  [CitiesEnum.SAN_SALVADOR_CENTRO]: [
    {
      value: DistricEnum.AYUTUXTEPEQUE,
      label: 'Ayutuxtepeque',
    },
    {
      value: DistricEnum.MEJICANOS,
      label: 'Mejicanos',
    },
    {
      value: DistricEnum.CUSCATANCINGO,
      label: 'Cuscatancingo',
    },
    {
      value: DistricEnum.CIUDAD_DELGADO,
      label: 'Ciudad Delgado',
    },
    {
      value: DistricEnum.SAN_SALVADOR,
      label: 'San Salvador',
    },
  ],
  [CitiesEnum.SAN_SALVADOR_SUR]: [
    {
      value: DistricEnum.SAN_MARCOS,
      label: 'San Marcos',
    },
    {
      value: DistricEnum.SANTO_TOMAS,
      label: 'Santo Tomas',
    },
    {
      value: DistricEnum.SANTIAGO_TEXACUANGOS,
      label: 'Santiago Texacuangos',
    },
    {
      value: DistricEnum.PANCHIMALCO,
      label: 'Panchimalco',
    },
    {
      value: DistricEnum.ROSARIO_DE_MORA,
      label: 'Rosario de Mora',
    },
  ],

  [CitiesEnum.LA_LIBERTAD_CENTRO]: [
    {
      value: DistricEnum.SAN_JUAN_OPICO,
      label: 'San Juan Opico',
    },
    {
      value: DistricEnum.CIUDAD_ARCE,
      label: 'Ciudad Arce',
    },
  ],
  [CitiesEnum.LA_LIBERTAD_NORTE]: [
    {
      value: DistricEnum.QUEZALTEPEQUE,
      label: 'Quezaltepeque',
    },
    {
      value: DistricEnum.SAN_MATIAS,
      label: 'San Matías',
    },
    {
      value: DistricEnum.SAN_PABLO_TACACHICO,
      label: 'San Pablo Tacachico',
    },
  ],
  [CitiesEnum.LA_LIBERTAD_OESTE]: [
    {
      value: DistricEnum.COLON,
      label: 'Colón',
    },
    {
      value: DistricEnum.JAYAQUE,
      label: 'Jayaque',
    },
    {
      value: DistricEnum.SACACOYO,
      label: 'Sacacoyo',
    },
    {
      value: DistricEnum.TEPECOYO,
      label: 'Tepecoyo',
    },
    {
      value: DistricEnum.TALNIQUE,
      label: 'Talnique',
    },
  ],
  [CitiesEnum.LA_LIBERTAD_ESTE]: [
    {
      value: DistricEnum.ANTIGUO_CUSCATLAN,
      label: 'Antiguo Cuscatlán',
    },
    {
      value: DistricEnum.HUIZUCAR,
      label: 'Huizúcar',
    },
    {
      value: DistricEnum.NUEVO_CUSCATLAN,
      label: 'Nuevo Cuscatlán',
    },
    {
      value: DistricEnum.SAN_JOSE_VILLANUEVA,
      label: 'San José Villanueva',
    },
    {
      value: DistricEnum.ZARAGOZA,
      label: 'Zaragoza',
    },
  ],
  [CitiesEnum.LA_LIBERTAD_COSTA]: [
    {
      value: DistricEnum.CHILTIUPAN,
      label: 'Chiltiupán',
    },
    {
      value: DistricEnum.JICALAPA,
      label: 'Jicalapa',
    },
    {
      value: DistricEnum.LA_LIBERTAD,
      label: 'La Libertad',
    },
    {
      value: DistricEnum.TAMANIQUE,
      label: 'Tamanique',
    },
    {
      value: DistricEnum.TEOTEPEQUE,
      label: 'Teotepeque',
    },
  ],
  [CitiesEnum.LA_LIBERTAD_SUR]: [
    {
      value: DistricEnum.SANTA_TECLA,
      label: 'Santa Tecla',
    },
    {
      value: DistricEnum.COMASAGUA,
      label: 'Comasagua',
    },
  ],
};
