// eslint-disable-next-line @nx/enforce-module-boundaries
import { ProductViewService } from './../../../../../tfi-feat-product-management/src/lib/services/view/product-view/product-view.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, EMPTY, take } from 'rxjs';
import { IProduct } from '../../interfaces/product.interface';
import { UserPermissionsEnum } from '@tfi-workspace-web/tfi-shared-utils';

@Component({
  selector: 'gc-sidebar-product-basic-form',
  templateUrl: './sidebar-product-basic-form.component.html',
  styleUrls: ['./sidebar-product-basic-form.component.scss'],
})
export class SidebarProductBasicFormComponent implements OnInit {
  @Output() hideSidebar = new EventEmitter<void>();
  @Input() isProductSidebarVisible = false;
  @Input() selectedProduct?: IProduct;
  isLoading = false;
  userPermissionsEnum = UserPermissionsEnum;

  form = new FormGroup({
    productName: new FormControl('', [Validators.required]),
    cost: new FormControl(0),
    price: new FormControl(0, [Validators.required, Validators.min(0.1)]),
    mainImageUrl: new FormControl(''),
    isEnabled: new FormControl(false),
  });

  get productNameFormControl() {
    return this.form.get('productName') as FormControl;
  }
  get costFormControl() {
    return this.form.get('cost') as FormControl;
  }
  get priceFormControl() {
    return this.form.get('price') as FormControl;
  }
  get mainImageUrlFormControl() {
    return this.form.get('mainImageUrl') as FormControl;
  }
  get statusFormControl() {
    return this.form.get('isEnabled') as FormControl;
  }

  constructor(
    private router: Router,
    private productViewService: ProductViewService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.patchForm();
  }

  patchForm() {
    if (this.selectedProduct) {
      const { productName, mainImageUrl, price, cost, isEnabled } =
        this.selectedProduct;
      const formData = { productName, mainImageUrl, price, cost, isEnabled };
      this.form.patchValue(formData);
    }
  }

  onHide() {
    this.hideSidebar.emit();
  }

  navigateToEditProduct() {
    this.router.navigate([
      `/admin/product-management/product/${this.selectedProduct?._id}`,
    ]);
  }

  onNewImageSelected(event: Event) {
    const imageFile = event.target as HTMLInputElement;
    if (imageFile.files) {
      const file = imageFile.files[0];
      this.uploadImage(file);
    }
  }

  uploadImage(file: File) {
    if (this.isLoading) return;
    this.isLoading = true;
    const formData = new FormData();
    formData.append('image', file);
    this.productViewService
      .uploadImage(formData)
      .pipe(
        take(1),
        catchError(() => {
          this.toastr.error(
            'Lo sentimos, no pudimos procesar la imagen que intentaste agregar. Asegúrate que la imagen tenga formato JPG, JPEG o PNG'
          );
          this.isLoading = false;
          return EMPTY;
        })
      )
      .subscribe((response) => {
        if (this.selectedProduct) {
          this.selectedProduct = {
            ...this.selectedProduct,
            mainImageUrl: response.data.file.url,
          };
        }
        this.isLoading = false;
      });
  }

  onEditFormSubmitted() {
    if (this.isLoading) return;
    this.isLoading = true;
    // If the selected product has a 'category' property that includes an object with an '_id' property,
    // we replace the 'category' object with just its '_id' value.
    if (this.selectedProduct?.category._id) {
      const categoryId = this.selectedProduct?.category._id;
      this.selectedProduct.category = categoryId;
    }
    // If the selected product has a 'brand' property that includes an object with an '_id' property,
    // we replace the 'brand' object with just its '_id' value.
    if (this.selectedProduct?.brand._id) {
      const brandId = this.selectedProduct?.brand._id;
      this.selectedProduct.brand = brandId;
    }
    if (this.selectedProduct && this.selectedProduct?._id) {
      this.selectedProduct.productName = this.productNameFormControl.value;
      this.selectedProduct.cost = this.costFormControl.value;
      this.selectedProduct.price = this.priceFormControl.value;
      this.selectedProduct.isEnabled = this.statusFormControl.value;
      this.selectedProduct.saleType;

      this.productViewService
        .updateProduct(this.selectedProduct?._id, this.selectedProduct)
        .pipe(
          take(1),
          catchError(() => {
            this.toastr.error('Error procesando la solicitud');
            this.isLoading = false;
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.toastr.success('La solicitud se completó exitosamente');
          this.isLoading = false;
          this.onHide();
        });
    }
  }
}
