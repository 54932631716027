import { Component, EventEmitter, Input, Output } from '@angular/core';
import { emptyStateImage } from '../../constants/empty-state-image.constant';

@Component({
  selector: 'gc-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent {
  @Input() imageUrl: string = emptyStateImage;
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() primaryButton?: string;
  @Input() secondaryButton?: string;
  @Output() primaryButtonClicked = new EventEmitter<void>();
  @Output() secondaryButtonClicked = new EventEmitter<void>();

  onClickPrimaryButton() {
    this.primaryButtonClicked.emit();
  }
  onClickSecondaryButton() {
    this.secondaryButtonClicked.emit();
  }
}
