import { ProductUpdateStatus } from '@tfi-workspace-web/tfi-feat-product-management';
import { ProductTypeEnum } from '../enums/product-type.enum';
import { IBaseEntity } from './base-entity.interface';

export interface IProduct extends IBaseEntity {
  productName: string;
  description?: string;
  cost?: number;
  unitVariableCost?: number;
  margin: any;
  marginPerc: number;
  price: number;
  category: any;
  showInCatalog: boolean;
  mainImageUrl?: string;
  images?: string[];
  attributes?: IProductAttribute[];
  brand?: any;
  productType: ProductTypeEnum;
  sellWithoutStock: boolean;
  vendorSKU?: string;
  internalSKU?: string;
  productCode?: any;
  isEnabled?: boolean;
  saleType: SaleTypeEnum;
}

export interface IProductBatchUpdate {
  products: string[];
  action: ProductUpdateStatus | null;
}

export interface IProductBatchUpdateResponce {
  status: string;
}
export interface IProductAttribute {
  attributeName: string;
  attributeOptions: IAttributeOption[];
}

export interface IAttributeOption {
  optionName: string;
  priceModifier: number;
}

export interface IProductResponse {
  status: string;
  data: {
    product: IProduct;
  };
}

export interface IProductBulkResponse {
  success: boolean;
  message: string;
  file?: string;
}

export interface IProductsResponse {
  status: string;
  results: number;
  total: number;
  totalPages: number;
  page: number;
  data: {
    products: IProduct[];
  };
}

export interface IShowInCatalogPayload {
  showInCatalog: boolean;
  productsId: string[];
}

export enum SaleTypeEnum {
  NON_SUBJECT = 'NO_SUJETA',
  TAXED = 'GRAVADA',
  EXEMPT = 'EXENTA',
}
