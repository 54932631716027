import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { noop } from 'rxjs';
import { ISelectOption } from '../../interfaces/option-interface';
import { KeyboardValueEnum } from '../../enums/keyboard-values.enum';

@Component({
  selector: 'gc-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent),
    },
  ],
})
export class SelectComponent implements ControlValueAccessor {
  @Input() id?: string;
  private _data: ISelectOption[] = [];
  @Input() set data(value: ISelectOption[]) {
    this._data = value;
  }
  get data() {
    return this._data;
  }
  @Input() type = 'text';
  @Input() placeholder = 'Buscar';
  @Input() maxLength = 999;
  @Input() disabled = false;
  @Input() enableSearch = false;
  @Input() enableEmptyState = false;
  @Input() topPosition = false;
  @Output() optionSelectedEmitter = new EventEmitter<ISelectOption>();

  @ViewChild('optionList', { static: false }) optionList!: ElementRef;

  filteredOptions: ISelectOption[] = [];
  searchTerm = '';
  currentHoverIndex = 0;

  formControl: FormControl = new FormControl<string>('');

  constructor(private elRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.filteredOptions = [];
    }
  }

  filterOptions(event: KeyboardEvent) {
    if (
      event.key === KeyboardValueEnum.ENTER ||
      event.key === KeyboardValueEnum.ARROW_UP ||
      event.key === KeyboardValueEnum.ARROW_DOWN ||
      event.key === KeyboardValueEnum.TAB
    )
      return;
    this.filteredOptions = this.data.filter((option) =>
      option.label.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  selectOption(option: ISelectOption) {
    this.searchTerm = option.label;
    this.filteredOptions = [];
    this.onChange(option.value);
    this.optionSelectedEmitter.emit(option);
  }

  onInputFocus() {
    this.showPanel();
  }

  onFocusOut() {
    setTimeout(() => {
      this.hidePanel();
    }, 200);
  }

  onChange: (value: string) => void = noop;
  onTouch: () => void = noop;

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    if (value === '' || value === null) {
      this.searchTerm = '';
      return;
    }
    this.formControl.setValue(value, { emitEvent: false });
    this.setSearchTerm(value);
  }

  setSearchTerm(value: string): void {
    const checkInitialValue = this.data?.find((item) => item.value === value);
    this.searchTerm = checkInitialValue?.label || value;
  }

  showPanel() {
    if (this.disabled) return;
    if (this.searchTerm === '') {
      this.data?.forEach((item) => {
        if (!this.filteredOptions.some((option) => option === item)) {
          this.filteredOptions.push(item);
        }
      });
      return;
    }
    this.filteredOptions = this.data;
  }

  hidePanel() {
    this.filteredOptions = [];
  }

  emptySelection() {
    if (this.disabled) return;
    this.searchTerm = '';
    const emptyOption = {
      label: '',
      value: '',
    };
    this.selectOption(emptyOption);
  }

  onKeydown(event: KeyboardEvent) {
    if (this.filteredOptions.length > 0) {
      if (event.key === KeyboardValueEnum.ARROW_DOWN) {
        this.currentHoverIndex =
          (this.currentHoverIndex + 1) % this.filteredOptions.length;
        this.scrollToView();
        event.preventDefault();
        return;
      } else if (event.key === KeyboardValueEnum.ARROW_UP) {
        this.currentHoverIndex =
          (this.currentHoverIndex - 1 + this.filteredOptions.length) %
          this.filteredOptions.length;
        this.scrollToView();
        event.preventDefault();
        return;
      } else if (
        event.key === KeyboardValueEnum.ENTER &&
        this.currentHoverIndex > -1
      ) {
        this.selectOption(this.filteredOptions[this.currentHoverIndex]);
        event.preventDefault();
      }
    }
  }

  scrollToView() {
    if (this.optionList && this.optionList.nativeElement) {
      const optionListElement = this.optionList.nativeElement;
      const hoveredOption = optionListElement.children[this.currentHoverIndex];
      if (hoveredOption) {
        hoveredOption.scrollIntoView({ block: 'nearest' });
      }
    }
  }
}
