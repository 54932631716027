import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  environment,
  IPermissionResponse,
  permissionMethods,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionDataService {
  private readonly permissionUrl =
    environment.api_host + permissionMethods.roles;
  constructor(private http: HttpClient) {}

  getPermissions(): Observable<IPermissionResponse> {
    return this.http.get<IPermissionResponse>(this.permissionUrl);
  }
}
