<div
  class="radio-card"
  [ngClass]="{
    'radio-card--disabled': disabled,
    'radio-card--active': checked,
    'radio-card--small': size === 'sm'
  }"
  (click)="onContainerSelected()"
>
  <div class="radio-card-icon" *ngIf="icon">
    <gc-icon [icon]="icon" [dimension]="16"></gc-icon>
  </div>
  <div class="radio-card-content">
    <span class="radio-card-content__title">{{ title }}</span>
    <span class="radio-card-content__description">{{ description }}</span>
  </div>
  <input
    [id]="id"
    type="radio"
    class="radio-card__input"
    [disabled]="disabled"
    [checked]="checked"
    (change)="select()"
  />
</div>
