import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Modaldata } from '@tfi-workspace-web/tfi-shared-utils';
import { CategoryViewService } from '../../view/category-view/category-view.service';
import { ManageCategoryDialogComponent } from '../../../components/category/manage-category-dialog/manage-category-dialog.component';
import { CategoryDetailsDialogComponent } from '../../../components/category/category-details-dialog/category-details-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class CategoryDialogService {
  constructor(
    private dialog: MatDialog,
    private categoryViewService: CategoryViewService
  ) {}

  openManageCategoryDialog(modalData: Modaldata) {
    const dialogRef = this.dialog.open(ManageCategoryDialogComponent, {
      data: modalData,
      minWidth: '360px',
      width: '85%',
      maxWidth: '800px',
      maxHeight: '90vh',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.categoryViewService.getCategories();
    });
  }

  openDetailsCategoryDialog(modalData: Modaldata) {
    const dialogRef = this.dialog.open(CategoryDetailsDialogComponent, {
      data: modalData,
      minWidth: '360px',
    });
  }
}
