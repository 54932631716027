import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IFormFieldMessages } from '../../interfaces/iform-field-messages';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'gc-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnInit, OnDestroy {
  @Input() label = '';
  @Input() showTooltip = false;
  @Input() tooltip?: string;
  @Input() control?: FormControl;
  @Input() messages?: IFormFieldMessages;
  currentMessage?: string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnInit(): void {
    if (this.messages?.default) this.currentMessage = this.messages.default;
    this.control?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (
        this.control?.invalid &&
        (this.control?.touched || this.control?.dirty)
      ) {
        if (this.control.hasError('required'))
          this.currentMessage =
            this.messages?.required ??
            (this.label ? this.label + ' es requerido' : 'Requerido');
        if (this.control.hasError('maxlength'))
          this.currentMessage =
            this.messages?.maxlength ?? 'Error en longitud maxima';
        if (this.control.hasError('minlength'))
          this.currentMessage =
            this.messages?.minlength ?? 'Error en longitud minima';
        if (this.control.hasError('max'))
          this.currentMessage = this.messages?.max ?? 'Error en valor maximo';
        if (this.control.hasError('min'))
          this.currentMessage = this.messages?.min ?? 'Error en valor minimo';
        if (this.control.hasError('email'))
          this.currentMessage = this.messages?.email ?? 'Email invalido';
        if (this.control.hasError('pattern'))
          this.currentMessage = this.messages?.pattern ?? 'Patron invalido';
      } else {
        this.currentMessage = undefined;
      }
    });

    this.control?.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.control?.hasError('exists'))
        this.currentMessage = this.messages?.exists ?? 'El código ya existe';
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get touchedOnly() {
    return this.control?.touched && !this.control.dirty && this.control.invalid;
  }

  get defaultMessage() {
    return this.label + ' es invalido';
  }
}
