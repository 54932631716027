/* eslint-disable @angular-eslint/directive-selector */
import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';

@Directive({
  selector: '[PermissionControl]',
})
export class PermissionControlDirective implements OnInit {
  @Input('PermissionControl') permissions?: string[];
  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<any>,
    private authService: AuthenticationViewService
  ) {}

  ngOnInit(): void {
    this.checkAccess();
  }

  checkAccess() {
    if (this.authService.hasPermissionStrict(this.permissions ?? [])) {
      this.view.createEmbeddedView(this.template);
    } else {
      this.view.clear();
    }
  }
}
