<div class="role-edit-dialog-container">
  <div class="title-container">
    <h2 class="title-container__title">{{ formTitle || '' }}</h2>
    <gc-icon
      class="title-container__icon"
      icon="x"
      [dimension]="22"
      color="grey-800"
      mat-dialog-close
    ></gc-icon>
  </div>
  <div class="form-container" [formGroup]="form">
    <div class="input-container">
      <gc-label label="Nombre de el rol" [control]="nameFormControl">
        <gc-input
          id="name"
          class="input-container__input"
          type="text"
          formControlName="name"
          placeHolder="Nombre de el rol"
        ></gc-input>
      </gc-label>
    </div>
    <div class="input-container">
      <gc-label label="Descripción" [control]="descriptionFormControl">
        <gc-input
          id="description"
          class="input-container__input"
          type="text"
          formControlName="description"
          placeHolder="Descripción"
        ></gc-input>
      </gc-label>
    </div>
    <span class="form-container__sub-title">Asignación de permisos :</span>
    <cdk-virtual-scroll-viewport itemSize="50" class="viewport-container">
      <div class="main-toggle-container">
        <div class="toggle-inner-container">
          Agregar todos :
          <div class="toggle-inner-container__element">
            <gc-switch
              size="md"
              (toggleChanged)="updateAll()"
              [checked]="allEnabled"
            ></gc-switch>
            {{ allEnabled ? 'Desactivar' : 'Activar' }}
          </div>
        </div>
        <div
          class="toggle-sub-container"
          *ngFor="let perObj of mappedPermissions"
        >
          {{ perObj.entity }}
          <div class="permissions-container">
            <div
              class="permissions-container__element"
              *ngFor="let per of perObj.permissions"
            >
              <gc-switch
                size="md"
                (toggleChanged)="toggleSw(per, perObj)"
                [checked]="per.isSelected"
              ></gc-switch>
              {{ per.label }}
            </div>
          </div>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <div class="buttons-wrapper">
    <div class="button-container">
      <gc-button
        class="button-container__button"
        text="Cancelar"
        type="full"
        (click)="cancel()"
        color="secondary"
        mode="ghost"
      ></gc-button>
    </div>
    <div class="button-container">
      <gc-button
        class="button-container__button"
        text="Aceptar"
        (click)="submitAction()"
        type="full"
        [isLoading]="isLoading"
        [disabled]="!form.valid || updatedPermissionsList.length === 0"
      ></gc-button>
    </div>
  </div>
</div>
