import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadPdfService {
  downloadFileExcel(base64: string, fileName: string) {
    // Decode the base64 string
    const byteCharacters = atob(base64);
    const byteNumbers = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Create a Blob from the byte array
    const blob = new Blob([byteNumbers], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Create a URL for the Blob and trigger a download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.xlsx`; // Specify the filename
    document.body.appendChild(link); // Append link to the body
    link.click(); // Trigger the download
    document.body.removeChild(link); // Remove link from the body
    URL.revokeObjectURL(link.href); // Clean up URL.createObjectURL
  }
}
