import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IBranch,
  IBranchResponse,
  IBranchesResponse,
  branchMethods,
  environment,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BranchDataService {
  private readonly branchUrl = environment.api_host + branchMethods.branch;
  constructor(private http: HttpClient) {}

  getBranches(): Observable<IBranchesResponse> {
    return this.http.get<IBranchesResponse>(this.branchUrl);
  }

  createBranch(branch: IBranch): Observable<IBranchResponse> {
    return this.http.post<IBranchResponse>(this.branchUrl, branch);
  }

  updateBranch(branchId: string, branch: IBranch): Observable<IBranchResponse> {
    return this.http.put<IBranchResponse>(
      `${this.branchUrl}/${branchId}`,
      branch
    );
  }
}
