<div class="gateways">
  <h2 class="gateways__title">Pasarelas de pago</h2>
  <div class="gateways-wompi">
    <div class="gateways-wompi__subtitle">Wompi</div>
    <div class="gateways-wompi-view" *ngIf="!isEditMode; else editRef">
      <div class="gateways-wompi-view-wrapper">
        <div class="gateways-wompi-view-wrapper-item">
          <span class="gateways-wompi-view-wrapper-item__key"
            >Wompi ClientID:</span
          >
          <span class="gateways-wompi-view-wrapper-item__value">{{
            company.paymentGateways?.wompi?.clientId ?? '-'
          }}</span>
        </div>
        <div class="gateways-wompi-view-wrapper-item">
          <span class="gateways-wompi-view-wrapper-item__key"
            >Wompi SecredID:</span
          >
          <span class="gateways-wompi-view-wrapper-item__value">{{
            company.paymentGateways?.wompi?.secretKey ?? '-'
          }}</span>
        </div>
      </div>

      <div class="gateways-wompi-view-wrapper">
        <div class="gateways-wompi-view-wrapper-item">
          <span class="gateways-wompi-view-wrapper-item__key"
            >Nombre del producto:</span
          >
          <span class="gateways-wompi-view-wrapper-item__value">{{
            company.paymentGateways?.wompi?.productName ?? '-'
          }}</span>
        </div>
        <div class="gateways-wompi-view-wrapper-item">
          <span class="gateways-wompi-view-wrapper-item__key">Comisión:</span>
          <span class="gateways-wompi-view-wrapper-item__value"
            >{{
              (company.paymentGateways?.wompi?.commission ?? 0) * 100
                | number : '1.0-2'
            }}%</span
          >
        </div>
      </div>

      <div class="gateways-wompi-view-wrapper">
        <div class="gateways-wompi-view-wrapper-item">
          <span class="gateways-wompi-view-wrapper-item__key"
            >Correo electrónico de notificación:</span
          >
          <span class="gateways-wompi-view-wrapper-item__value">{{
            company.paymentGateways?.wompi?.emailNotification ?? '-'
          }}</span>
        </div>
        <div class="gateways-wompi-view-wrapper-item">
          <span class="gateways-wompi-view-wrapper-item__key"
            >Notificaciones:</span
          >
          <gc-switch
            [disabled]="true"
            [checked]="
              company.paymentGateways?.wompi?.notifyClient ? true : false
            "
          ></gc-switch>
        </div>
      </div>

      <div
        *PermissionControl="[userPermissionsEnum.USR_CMP_EDT]"
        class="gateways-wompi-view__button"
      >
        <gc-link size="lg" text="Editar" (click)="onEditConfig()"></gc-link>
      </div>
    </div>
    <ng-template #editRef>
      <div class="gateways-wompi-edit">
        <form class="gateways-wompi-edit-form" [formGroup]="form">
          <div class="gateways-wompi-edit-form-wrapper">
            <div class="gateways-wompi-edit-form-wrapper-item">
              <gc-label label="Wompi ClientID" [control]="clientIdFormControl">
                <gc-input
                  id="clientId"
                  type="text"
                  formControlName="clientId"
                  placeHolder="Wompi ClientID"
                ></gc-input>
              </gc-label>
            </div>
            <div class="gateways-wompi-edit-form-wrapper-item">
              <gc-label label="Wompi SecredID" [control]="secretKeyFormControl">
                <gc-input
                  id="secretKey"
                  type="text"
                  formControlName="secretKey"
                  placeHolder="Wompi SecredID"
                ></gc-input>
              </gc-label>
            </div>
          </div>

          <div class="gateways-wompi-edit-form-wrapper">
            <div class="gateways-wompi-edit-form-wrapper-item">
              <gc-label
                label="Nombre del producto"
                [control]="productNameFormControl"
              >
                <gc-input
                  id="productName"
                  type="text"
                  formControlName="productName"
                  placeHolder="Nombre del producto"
                ></gc-input>
              </gc-label>
            </div>
            <div class="gateways-wompi-edit-form-wrapper-item">
              <gc-label
                label="Porcentaje de Comisión"
                [control]="commissionFormControl"
              >
                <gc-input
                  id="comission"
                  type="percent"
                  formControlName="commission"
                  placeHolder=""
                ></gc-input>
              </gc-label>
            </div>
          </div>

          <div class="gateways-wompi-edit-form-wrapper">
            <div class="gateways-wompi-edit-form-wrapper-item">
              <gc-label
                label="Correo electrónico de notificación"
                [control]="emailNotificationFormControl"
              >
                <gc-input
                  id="emailNotification"
                  type="text"
                  formControlName="emailNotification"
                  placeHolder="Correo electrónico de notificación"
                ></gc-input>
              </gc-label>
            </div>
            <div class="gateways-wompi-edit-form-wrapper-item">
              <gc-label
                label="¿Desea recibir notificaciones?"
                [control]="notifyClientFormControl"
              >
                <gc-switch formControlName="notifyClient"></gc-switch>
              </gc-label>
            </div>
          </div>

          <div class="gateways-wompi-edit-form-buttons">
            <gc-button
              class="gateways-wompi-edit-form-buttons__button"
              text="Cancelar"
              type="full"
              (click)="isEditMode = false"
              color="secondary"
              mode="ghost"
              [disabled]="isLoading"
            ></gc-button>
            <gc-button
              class="gateways-wompi-edit-form-buttons__button"
              text="Guardar"
              type="full"
              [isLoading]="isLoading"
              [disabled]="isLoading"
              (click)="submitForm()"
            ></gc-button>
          </div>
        </form>
      </div>
    </ng-template>
  </div>
</div>
