import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ISelectOption } from '@tfi-workspace-web/tfi-shared-utils';
import { noop } from 'rxjs';

@Component({
  selector: 'gc-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SearchSelectComponent),
    },
  ],
})
export class SearchSelectComponent implements ControlValueAccessor {
  @Input() id?: string;
  @Input() placeholder = 'Buscar';
  @Input() disabled = false;
  private _listOptions: ISelectOption[] = [];
  @Input() set listOptions(value: ISelectOption[]) {
    this._listOptions = value;
    if (value.length > 0 && this.searchTerm) {
      this.setSearchTerm(this.searchTerm);
    }
  }
  get listOptions() {
    return this._listOptions;
  }
  @Output() keyPressEmitter = new EventEmitter<string>();
  @Output() optionSelectedEmitter = new EventEmitter<ISelectOption>();

  searchTerm = '';
  isPanelOptionsVisible = false;

  formControl: FormControl = new FormControl<string>('');

  get isInputEmpty() {
    return this.searchTerm === '' || this.searchTerm === null;
  }

  constructor(private elRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.isPanelOptionsVisible = false;
    }
  }

  onChange: (value: string) => void = noop;
  onTouch: () => void = noop;
  writeValue(value: any): void {
    if (value === '' || value === null) {
      this.searchTerm = '';
      return;
    }
    this.formControl.setValue(value, { emitEvent: false });
    this.setSearchTerm(value);
  }

  setSearchTerm(value: string): void {
    if (this.listOptions?.length > 0) {
      const checkInitialValue = this.listOptions?.find(
        (item) => item.value === value
      );
      this.searchTerm = checkInitialValue?.label || value;
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  onInput() {
    this.isPanelOptionsVisible = true;
    this.keyPressEmitter.emit(this.searchTerm);
  }

  optionSelected(option: ISelectOption) {
    this.searchTerm = option.label;
    this.isPanelOptionsVisible = false;
    this.onChange(option.value as string);
    this.optionSelectedEmitter.emit(option);
  }

  emptySelection(event: Event) {
    if (event) event.stopPropagation();
    if (this.disabled) return;
    this.searchTerm = '';
    const emptyOption: any = {
      label: '',
      value: null,
    };
    this.optionSelected(emptyOption);
  }

  openPanel(event: Event) {
    if (this.disabled) return;
    if (this.isPanelOptionsVisible) return;
    if (event) event.stopPropagation();
    this.isPanelOptionsVisible = true;
  }

  closePanel(event: Event) {
    if (event) event.stopPropagation();
    this.isPanelOptionsVisible = false;
  }
}
