export enum EntityEnum {
  BRANCH = 'BRANCH',
  BRAND = 'BRAND',
  CATEGORY = 'CATEGORY',
  COMPANY = 'COMPANY',
  CUSTOMER = 'CUSTOMER',
  INVENTORY = 'INVENTORY',
  KITCHEN_ORDER = 'KITCHEN_ORDER',
  PRODUCT = 'PRODUCT',
  SHIPPING = 'SHIPPING',
  SHIPPING_COMPANY = 'SHIPPING_COMPANY',
  TICKET = 'TICKET',
  USER = 'USER',
  VENDOR = 'VENDOR',
}
