import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WirePaymentDialogComponent } from '../wire-payment-dialog/wire-payment-dialog.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'tfi-feat-bm-credit-card-payment-dialog',
  templateUrl: './credit-card-payment-dialog.component.html',
  styleUrls: ['./credit-card-payment-dialog.component.scss'],
})
export class CreditCardPaymentDialogComponent implements OnInit {
  totalPayment = 0;

  form = new FormGroup({
    creditCardNumber: new FormControl('', [Validators.required]),
    expirationDate: new FormControl('', [Validators.required]),
    cvv: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(4),
    ]),
  });

  get creditCardNumberFormControl() {
    return this.form.get('creditCardNumber') as FormControl;
  }
  get expirationDateFormControl() {
    return this.form.get('expirationDate') as FormControl;
  }
  get cvvFormControl() {
    return this.form.get('cvv') as FormControl;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any[],
    public dialogRef: MatDialogRef<WirePaymentDialogComponent>
  ) {}
  ngOnInit(): void {
    this.getTotalPayment();
  }

  getTotalPayment() {
    this.totalPayment = this.data
      .map((invoice) => invoice.total)
      .reduce((a, b) => a + b, 0);
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const cardNumber = Number(
      this.creditCardNumberFormControl.value.replace(/-/g, '')
    );
    const expirationMonth = Number(
      this.expirationDateFormControl.value.substring(0, 2)
    );
    const exprationYear = Number(
      this.expirationDateFormControl.value.slice(-2)
    );

    const formData = {
      creditCardNumber: cardNumber,
      expirationMonth,
      exprationYear,
      cvv: Number(this.cvvFormControl.value),
    };
    // this.dialogRef.close();
  }
}
