import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, debounceTime, take, takeUntil } from 'rxjs';
import { CategoryViewService } from '../../services/view/category-view/category-view.service';
import {
  CrudActionsEnum,
  ICategory,
  UserPermissionsEnum,
} from '@tfi-workspace-web/tfi-shared-utils';
import { CategoryDialogService } from '../../services/dialog/category-dialog/category-dialog.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';

@Component({
  selector: 'tfi-feat-product-category-wrapper',
  templateUrl: './category-wrapper.component.html',
  styleUrls: ['./category-wrapper.component.scss'],
  animations: [
    trigger('slideInFromRight', [
      state('void', style({ transform: 'translateX(100%)' })),
      state('*', style({ transform: 'translateX(0)' })),
      transition('void => *', animate('300ms ease-out')),
    ]),
  ],
})
export class CategoryWrapperComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  categories: ICategory[] = [];
  userPermissions = UserPermissionsEnum;
  constructor(
    private dialogService: CategoryDialogService,
    private categoryViewService: CategoryViewService,
    private auth: AuthenticationViewService
  ) {}

  ngOnInit(): void {
    this.categoryViewService.categories$
      .pipe(takeUntil(this.destroy$))
      .subscribe((categories) => {
        this.categories = categories;
      });
    this.categoryViewService.getCategories();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  openCreateCategoryDialog() {
    this.dialogService.openManageCategoryDialog({
      crudAction: CrudActionsEnum.CREATE,
      modalData: {},
    });
  }

  onCardSelected(index: number) {
    if (this.auth.hasPermissionStrict([UserPermissionsEnum.USR_CAT_EDT])) {
      this.dialogService.openManageCategoryDialog({
        crudAction: CrudActionsEnum.UPDATE,
        modalData: this.categories[index],
      });
    }
  }

  search(search: string) {
    const searchTerm = search.toLowerCase();

    this.categoryViewService.categories$
      .pipe(debounceTime(700), take(1))
      .subscribe((category) => {
        this.categories = category.filter((category) =>
          category.categoryName.toLowerCase().includes(searchTerm)
        );
      });
  }
}
