<div class="gc-radio-button-container">
  <input
    [id]="id"
    type="radio"
    class="gc-radio-button-container__input"
    [ngClass]="{ 'gc-radio-button-container__input--enabled': !disabled }"
    [disabled]="disabled"
    [checked]="checked"
    (change)="select()"
    [name]="groupName"
  />
  <span
    class="gc-radio-button-container__label"
    [ngClass]="{ 'gc-radio-button-container__label--disabled': disabled }"
    >{{ label }}</span
  >
</div>
