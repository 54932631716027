<div
  class="gc-badge-container"
  [ngClass]="[backgrounfColorClass, skeleton ? 'skeleton' : '', skeleton ? 'skeleton--no-animation' : '']"
  [ngStyle]="{ 'background-color': modeBackground }"
>
  <div class="size-wrapper" [ngClass]="sizeClass">
    <gc-icon
      [color]="skeleton ? 'transparent' : textColor"
      [icon]="icon"
      [dimension]="14"
      *ngIf="icon && iconRight !== 'right' && !iconLeft"
    ></gc-icon>
    <gc-icon
      [color]="skeleton ? 'transparent' : textColor"
      [icon]="iconLeft"
      [dimension]="14"
      *ngIf="iconLeft && !icon && !iconRight"
    ></gc-icon>
    <span
      class="size-wrapper__label"
      [ngClass]="[textColorClass, skeleton ? 'skeleton' : '']"
      >{{ text }}</span
    >
    <gc-icon
      [color]="skeleton ? 'transparent' : textColor"
      [icon]="iconRight"
      [dimension]="14"
      *ngIf="iconRight && !icon && !iconLeft"
    ></gc-icon>
  </div>
</div>
