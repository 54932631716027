import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductViewService } from '@tfi-workspace-web/tfi-feat-product-management';
import { IAdminInvoice } from '@tfi-workspace-web/tfi-shared-utils';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, catchError, take } from 'rxjs';
import { InternalPaymentMethodEnum } from '../../../enums/internal-payment-method.enum';
import { PaymentViewService } from '../../../services/payment/view/payment-view.service';
import { Router } from '@angular/router';

@Component({
  selector: 'tfi-feat-bm-wire-payment-dialog',
  templateUrl: './wire-payment-dialog.component.html',
  styleUrls: ['./wire-payment-dialog.component.scss'],
})
export class WirePaymentDialogComponent implements OnInit {
  totalPayment = 0;
  filesUploaded: string[] = [];
  isLoading = false;
  errorMsg?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IAdminInvoice[],
    public dialogRef: MatDialogRef<WirePaymentDialogComponent>,
    private productViewService: ProductViewService,
    private toastr: ToastrService,
    private paymentViewService: PaymentViewService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.getTotalPayment();
  }

  getTotalPayment() {
    this.totalPayment = this.data
      .map((invoice) => invoice.total)
      .reduce((a, b) => a + b, 0);
  }

  copyOnClipboard(value: string) {
    navigator.clipboard.writeText(value);
  }

  fileLoaded(image: File) {
    this.errorMsg = '';
    this.uploadImage(image);
  }

  fileDeleted() {
    this.filesUploaded = [];
  }

  uploadImage(file: File) {
    if (this.isLoading) return;
    this.isLoading = true;
    const formData = new FormData();
    formData.append('image', file);
    this.productViewService
      .uploadImage(formData)
      .pipe(
        take(1),
        catchError(() => {
          this.toastr.error(
            'Lo sentimos, no pudimos procesar la imagen que intentaste agregar. Asegúrate que la imagen tenga formato JPG, JPEG o PNG'
          );
          this.isLoading = false;
          return EMPTY;
        })
      )
      .subscribe((response) => {
        this.isLoading = false;
        this.filesUploaded?.push(response.data.file.url);
      });
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    if (this.filesUploaded.length === 0) {
      this.errorMsg =
        'Necesitas adjuntar un comprobante de pago para continuar';
      return;
    }
    if (this.isLoading) return;
    this.isLoading = true;
    const payload = {
      invoicesToPay: this.data,
      paymentMethod: InternalPaymentMethodEnum.TRANSFER,
      attachments: this.filesUploaded,
    };
    this.paymentViewService
      .submitPayment(payload)
      .pipe(
        take(1),
        catchError(() => {
          this.toastr.error('Lo sentimos, no pudimos procesar la solicitud');
          this.isLoading = false;
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.isLoading = false;
        this.router.navigateByUrl('admin/business-management/transactions');
        this.dialogRef.close();
      });
  }
}
