import { Injectable } from '@angular/core';
import { CompanyDataService } from '../data/company-data.service';
import {
  CompanyIndustryEnum,
  CurrencyEnum,
  ICompany,
} from '@tfi-workspace-web/tfi-shared-utils';
import { BehaviorSubject, take } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CompanyViewService {
  private companySubject = new BehaviorSubject<ICompany>({
    displayName: '',
    email: '',
    phoneNumber: '',
    location: {
      addressLine: '',
      street: '',
      city: '',
      state: '',
      primary: false,
    },
    industry: CompanyIndustryEnum.OTHER,
    defaultCurrency: CurrencyEnum.USD,
    authorizedUsers: [],
  });

  company$ = this.companySubject.asObservable();

  constructor(private data: CompanyDataService) {}

  getCompany() {
    return this.data
      .getCompany()
      .pipe(take(1))
      .subscribe((response) => {
        this.companySubject.next(response.data.company);
      });
  }

  updateCompany(company: Partial<ICompany>) {
    return this.data.updateCompany(company);
  }

  getFeSetting() {
    return this.data.getFeSetting();
  }
}
