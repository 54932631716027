<nav>
  <div class="tfi-paginator">
    <span class="tfi-paginator__label"
      >Mostrando {{ firstElemenet }} hasta {{ lastElement }} de
      {{ totalElements }} registros</span
    >
    <div class="tfi-paginator-pages">
      <gc-icon
        class="tfi-paginator-pages-previous"
        [ngClass]="{
          'tfi-paginator-pages-previous--disabled': currentPage === 1
        }"
        icon="chevrons-left"
        color="grey-400"
        [dimension]="17"
        (click)="goToFirstPage()"
      ></gc-icon>
      <gc-icon
        class="tfi-paginator-pages-previous"
        [ngClass]="{
          'tfi-paginator-pages-previous--disabled': currentPage === 1
        }"
        icon="chevron-left"
        color="grey-400"
        [dimension]="17"
        (click)="previousPage()"
      ></gc-icon>
      <div class="tfi-paginator-pages__page-number">
        {{ currentPage }}
      </div>
      <gc-icon
        class="tfi-paginator-pages-next"
        icon="chevron-right"
        color="grey-400"
        [dimension]="17"
        [ngClass]="{
          'tfi-paginator-pages-next--disabled': currentPage >= lastPage
        }"
        (click)="nextPage()"
      ></gc-icon>
      <gc-icon
        class="tfi-paginator-pages-next"
        icon="chevrons-right"
        color="grey-400"
        [dimension]="17"
        [ngClass]="{
          'tfi-paginator-pages-next--disabled': currentPage >= lastPage
        }"
        (click)="goToLastPage()"
      ></gc-icon>
    </div>
    <gc-select
      class="tfi-paginator__limit-selector"
      [data]="limits"
      [(ngModel)]="limitPerPage"
      [topPosition]="true"
      (optionSelectedEmitter)="limitChanged($event)"
    ></gc-select>
  </div>
</nav>
