import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { noop } from 'rxjs';

@Component({
  selector: 'gc-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent),
    },
  ],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input() id?: string;
  @Input() icon?: string;
  @Input() type = 'text';
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() placeHolder?: string;
  @Input() min: string | null = null;
  @Input() max: string | null = null;
  @Input() maxLength = 999;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() mask?: string;
  @Input() decimalLimit = 2;
  @ViewChild('inputElement', { static: false }) inputElement!: ElementRef;

  formControl: FormControl = new FormControl<string>('');
  iconPassword = '';
  value = '';

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.type === 'password') {
      this.iconPassword = 'eye-off';
      this.icon = '';
    }
    this.cdr.detectChanges();
  }

  onChange: (value: string) => void = noop;
  onTouch: () => void = noop;

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    this.value = value;
    this.cdr.detectChanges();
  }

  onIconPasswordClick() {
    if (this.type === 'password') {
      this.iconPassword = 'eye';
      this.type = 'text';
    } else if (this.type === 'text') {
      this.iconPassword = 'eye-off';
      this.type = 'password';
    }
  }

  focusInput() {
    if (this.inputElement) {
      this.inputElement.nativeElement.focus();
    }
  }
}
