import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[NumbersOnlyDirective]',
})
export class NumbersOnlyDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    value = value.replace(/[^0-9.]/g, '');

    const decimalCount = value.split('.').length - 1;
    if (decimalCount > 1) {
      value = value.slice(0, value.lastIndexOf('.'));
    }

    input.value = value;
  }
}
