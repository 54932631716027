export enum OperationTimeEnum {
  Hora0500 = '05:00',
  Hora0530 = '05:30',
  Hora0600 = '06:00',
  Hora0630 = '06:30',
  Hora0700 = '07:00',
  Hora0730 = '07:30',
  Hora0800 = '08:00',
  Hora0830 = '08:30',
  Hora0900 = '09:00',
  Hora0930 = '09:30',
  Hora1000 = '10:00',
  Hora1030 = '10:30',
  Hora1100 = '11:00',
  Hora1130 = '11:30',
  Hora1200 = '12:00',
  Hora1230 = '12:30',
  Hora1300 = '13:00',
  Hora1330 = '13:30',
  Hora1400 = '14:00',
  Hora1430 = '14:30',
  Hora1500 = '15:00',
  Hora1530 = '15:30',
  Hora1600 = '16:00',
  Hora1630 = '16:30',
  Hora1700 = '17:00',
  Hora1730 = '17:30',
  Hora1800 = '18:00',
  Hora1830 = '18:30',
  Hora1900 = '19:00',
  Hora1930 = '19:30',
  Hora2000 = '20:00',
  Hora2030 = '20:30',
  Hora2100 = '21:00',
  Hora2130 = '21:30',
  Hora2200 = '22:00',
  Hora2230 = '22:30',
  Hora2300 = '23:00',
  Hora2330 = '23:30',
  Hora2400 = '24:00',
  Hora0100 = '01:00',
  Hora0130 = '01:30',
  Hora0200 = '02:00',
  Hora0230 = '02:30',
  Hora0300 = '03:00',
  Hora0330 = '03:30',
  Hora0400 = '04:00',
  Hora0430 = '04:30',
}
