<div class="ecommerce">
  <div class="ecommerce-carousel">
    <div class="ecommerce-carousel__previous-button swiper-button-prev"></div>
    <swiper-container class="ecommerce-carousel-swiper" #swiperRef init="false">
      <swiper-slide
        class="ecommerce-carousel-swiper-card"
        *ngFor="let categoryCard of categories"
        (click)="filterProductsByCategory(categoryCard)"
        [ngClass]="{
          'ecommerce-carousel-swiper-card--isSelected': categoryCard.isSelected
        }"
      >
        <span class="ecommerce-carousel-swiper-card__label">{{
          categoryCard.categoryName
        }}</span>
      </swiper-slide>
    </swiper-container>
    <div class="ecommerce-carousel__next-button swiper-button-next"></div>
  </div>
  <div class="ecommerce-body">
    <div class="ecommerce-body-sidebar">
      <div class="ecommerce-body-sidebar-wrapper">
        <p class="ecommerce-body-sidebar-wrapper__title">Categorías</p>
        <ul class="ecommerce-body-sidebar-wrapper-list">
          <li
            class="ecommerce-body-sidebar-wrapper-list__category"
            *ngFor="let categoryItem of categories"
            (click)="filterProductsByCategory(categoryItem)"
          >
            <gc-icon
              *ngIf="categoryItem.isSelected"
              icon="chevron-right"
              [dimension]="14"
              color="grey-500"
            ></gc-icon>
            <span>{{ categoryItem.categoryName }}</span>
          </li>
        </ul>
      </div>
      <div class="ecommerce-body-sidebar-wrapper">
        <p class="ecommerce-body-sidebar-wrapper__title">Contactos</p>
        <ul class="ecommerce-body-sidebar-wrapper-list">
          <li
            *ngIf="company.socialMedia?.whatsapp"
            class="ecommerce-body-sidebar-wrapper-list__company"
            (click)="openWhatsapp(company.socialMedia?.whatsapp)"
          >
            <img
              class="icon-container"
              src="/assets/icons/whatsapp-grey.svg"
              alt="whatsapp"
            />
            <span>{{ company.socialMedia?.whatsapp }}</span>
          </li>
          <li
            *ngIf="company.socialMedia?.phoneNumber"
            class="ecommerce-body-sidebar-wrapper-list__company"
            (click)="makeCall(company.socialMedia?.phoneNumber)"
          >
            <gc-icon icon="phone" [dimension]="14" color="grey-500"></gc-icon>
            <span>{{ company.socialMedia?.phoneNumber }}</span>
          </li>
          <li *ngIf="company.socialMedia?.instagram">
            <a
              href="https://www.instagram.com/{{
                company.socialMedia?.instagram
              }}"
              class="ecommerce-body-sidebar-wrapper-list__company"
              target="_blank"
            >
              <gc-icon
                icon="instagram"
                [dimension]="14"
                color="grey-500"
              ></gc-icon>
              <span>{{ company.socialMedia?.instagram }}</span>
            </a>
          </li>
          <li *ngIf="company.socialMedia?.facebook">
            <a
              href="https://www.facebook.com/{{
                company.socialMedia?.facebook
              }}"
              class="ecommerce-body-sidebar-wrapper-list__company"
              target="_blank"
            >
              <gc-icon
                icon="facebook"
                [dimension]="14"
                color="grey-500"
              ></gc-icon>
              <span>{{ company.socialMedia?.facebook }}</span>
            </a>
          </li>
          <li *ngIf="company.socialMedia?.youtube">
            <a
              href="https://www.youtube.com/{{ company.socialMedia?.youtube }}"
              class="ecommerce-body-sidebar-wrapper-list__company"
              target="_blank"
            >
              <gc-icon
                icon="youtube"
                [dimension]="14"
                color="grey-500"
              ></gc-icon>
              <span>{{ company.socialMedia?.youtube }}</span>
            </a>
          </li>
          <li *ngIf="company.socialMedia?.twitter">
            <a
              href="https://www.x.com/{{ company.socialMedia?.twitter }}"
              class="ecommerce-body-sidebar-wrapper-list__company"
              target="_blank"
            >
              <img
                class="icon-container"
                src="/assets/icons/x-grey.svg"
                alt="X"
              />
              <span>{{ company.socialMedia?.twitter }}</span>
            </a>
          </li>
          <li *ngIf="company.socialMedia?.tiktok">
            <a
              href="https://www.tiktok.com/{{ company.socialMedia?.tiktok }}"
              class="ecommerce-body-sidebar-wrapper-list__company"
              target="_blank"
            >
              <img
                class="icon-container"
                src="/assets/icons/tiktok-grey.svg"
                alt="tiktok"
              />
              <span>{{ company.socialMedia?.tiktok }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="ecommerce-body-right-section">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
