import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyViewService } from '@tfi-workspace-web/tfi-feat-business-management';
import {
  FormErrorMessageEnum,
  ICompany,
  PaymentProviderEnum,
  UserPermissionsEnum,
} from '@tfi-workspace-web/tfi-shared-utils';
import { ToastrService } from 'ngx-toastr';
import { catchError, EMPTY, take } from 'rxjs';

@Component({
  selector: 'tfi-feat-bm-payment-gateways-config',
  templateUrl: './payment-gateways-config.component.html',
  styleUrls: ['./payment-gateways-config.component.scss'],
})
export class PaymentGatewaysConfigComponent {
  @Output() updateCompanyData = new EventEmitter<void>();
  @Input() company!: ICompany;
  userPermissionsEnum = UserPermissionsEnum;
  isEditMode = false;
  isLoading = false;

  form = new FormGroup({
    clientId: new FormControl('', [
      Validators.required,
      Validators.pattern(/^(?!.*\*{3,}).*$/),
    ]),
    secretKey: new FormControl('', [
      Validators.required,
      Validators.pattern(/^(?!.*\*{3,}).*$/),
    ]),
    productName: new FormControl('', [Validators.required]),
    commission: new FormControl('', [Validators.min(0), Validators.max(100)]),
    notifyClient: new FormControl(false),
    emailNotification: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
  });

  get clientIdFormControl() {
    return this.form.get('clientId') as FormControl;
  }
  get secretKeyFormControl() {
    return this.form.get('secretKey') as FormControl;
  }
  get productNameFormControl() {
    return this.form.get('productName') as FormControl;
  }
  get commissionFormControl() {
    return this.form.get('commission') as FormControl;
  }
  get notifyClientFormControl() {
    return this.form.get('notifyClient') as FormControl;
  }
  get emailNotificationFormControl() {
    return this.form.get('emailNotification') as FormControl;
  }

  constructor(
    private companyViewService: CompanyViewService,
    private toastr: ToastrService
  ) {}

  onEditConfig() {
    this.form.reset();
    this.patchForm();
    this.form.markAsUntouched();
    this.isEditMode = true;
  }

  patchForm() {
    this.productNameFormControl.patchValue(
      this.company.paymentGateways?.wompi?.productName
    );
    this.commissionFormControl.patchValue(
      this.company.paymentGateways?.wompi?.commission
        ? parseFloat(
            (this.company.paymentGateways?.wompi?.commission * 100).toFixed(2)
          )
        : 0
    );
    this.notifyClientFormControl.patchValue(
      this.company.paymentGateways?.wompi?.notifyClient ?? false
    ),
      this.emailNotificationFormControl.patchValue(
        this.company.paymentGateways?.wompi?.emailNotification
      );
  }

  submitForm() {
    if (this.isLoading) return;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading = true;

    const payload = {
      paymentGateways: {
        wompi: {
          clientId: this.clientIdFormControl.value,
          secretKey: this.secretKeyFormControl.value,
          productName: this.productNameFormControl.value,
          commission: parseFloat(
            (this.commissionFormControl.value / 100).toFixed(4)
          ),
          notifyClient: this.notifyClientFormControl.value,
          emailNotification: this.emailNotificationFormControl.value,
          providerKey: PaymentProviderEnum.WOMPI,
        },
      },
    };

    this.companyViewService
      .updateCompany(payload as Partial<ICompany>)
      .pipe(
        take(1),
        catchError(() => {
          this.toastr.error(
            FormErrorMessageEnum.OCURRIO_UN_ERROR_INTENTE_MAS_TARDE
          );
          this.isLoading = false;
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.updateCompanyData.emit();
        this.isLoading = false;
        this.isEditMode = false;
        this.toastr.success('La solicitud se realizo con éxito');
      });
  }
}
