import { ICustomer } from './customer.interface';
import { ISelectOption } from './input-select.interface';

export interface ICustomerForm {
  customer?: ICustomer;
  states: ISelectOption[];
  cities: ISelectOption[];
  districts: ISelectOption[];
  economicActivities: ISelectOption[];
}
