import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';
import { ClaimsEnum } from '@tfi-workspace-web/tfi-shared-utils';
import { Subject } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[AccessControlDirective]',
})
export class AccessControlDirective implements OnInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('AccessControlDirective') roles?: ClaimsEnum[];
  private destroy$ = new Subject();
  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<any>,
    private authViewService: AuthenticationViewService
  ) {}

  ngOnInit() {
    this.checkAccess();
  }
  checkAccess() {
    const userRoles = this.authViewService.userProfile.claims;
    const index = this.roles?.findIndex((claim: string) =>
      userRoles?.includes(claim as ClaimsEnum)
    );

    if (index !== -1) {
      this.view.createEmbeddedView(this.template);
    } else {
      this.view.clear();
    }
  }

  ngOnDestroy() {
    this.destroy$.complete();
  }
}
