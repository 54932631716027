import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BranchViewService } from '../../../services/branch/view/branch-view.service';
import {
  BranchTypeOptions,
  Cities,
  CitiesEnum,
  CrudActionsEnum,
  DaysOfWeekConstant,
  Districts,
  FormErrorMessageEnum,
  IBranch,
  ISchedule,
  Modaldata,
  OperationTimeOptions,
  States,
  StatesEnum,
  TOAST_MESSAGES,
} from '@tfi-workspace-web/tfi-shared-utils';
import { EMPTY, Subject, catchError, take, takeUntil } from 'rxjs';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  AbstractControl,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tfi-feat-bm-branch-edit-dialog',
  templateUrl: './branch-edit-dialog.component.html',
  styleUrls: ['./branch-edit-dialog.component.scss'],
})
export class BranchEditDialogComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  isLoading = false;
  branchType = BranchTypeOptions;
  operationTime = OperationTimeOptions;
  dayOfWeek = DaysOfWeekConstant;
  addressStates = States;
  addressCities = Cities[StatesEnum.SAN_SALVADOR];
  addressDistrics = Districts[CitiesEnum.SAN_SALVADOR_NORTE];
  formTitle?: string;
  errorMsg = '';
  prevState = '';
  prevCity = '';

  form = new FormGroup({
    branchCode: new FormControl('', [Validators.required]),
    branchName: new FormControl('', [Validators.required]),
    branchType: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phoneNumber: new FormControl('', [Validators.required]),
    addressLine: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    district: new FormControl(''),
    schedules: new FormArray([]),
    mhCode: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(4),
    ]),
    posNumber: new FormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(999),
    ]),
  });

  constructor(
    public dialogRef: MatDialogRef<BranchEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modaldata,
    private view: BranchViewService,
    private toastr: ToastrService
  ) {}

  get branchCodeFormControl() {
    return this.form.get('branchCode') as FormControl;
  }
  get branchNameFormControl() {
    return this.form.get('branchName') as FormControl;
  }
  get branchTypeFormControl() {
    return this.form.get('branchType') as FormControl;
  }
  get emailFormControl() {
    return this.form.get('email') as FormControl;
  }
  get phoneNumberFormControl() {
    return this.form.get('phoneNumber') as FormControl;
  }
  get addressLineFormControl() {
    return this.form.get('addressLine') as FormControl;
  }
  get stateFormControl() {
    return this.form.get('state') as FormControl;
  }
  get cityFormControl() {
    return this.form.get('city') as FormControl;
  }
  get districtFormControl() {
    return this.form.get('district') as FormControl;
  }
  get schedulesFormControl() {
    return this.form.get('schedules') as FormArray;
  }
  get mhCodeFormControl() {
    return this.form.get('mhCode') as FormControl;
  }
  get posNumberFormControl() {
    return this.form.get('posNumber') as FormControl;
  }

  get isFormValid() {
    return this.form.valid;
  }

  ngOnInit(): void {
    this.setForm();
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.errorMsg = ''));

    this.form.controls['state'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.addressCities = Cities[data as StatesEnum];
          if (this.prevState !== data) {
            this.prevState = data;
            this.form.controls['city'].setValue('');
            this.form.controls['district'].setValue('');
          }
        } else {
          this.addressCities = [];
          this.form.controls['city'].setValue('');
          this.addressDistrics = [];
          this.form.controls['district'].setValue('');
        }
      });

    this.form.controls['city'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.addressDistrics = Districts[data as CitiesEnum];
          if (this.prevCity !== data) {
            this.prevCity = data;
            this.form.controls['district'].setValue('');
          }
        } else {
          this.addressDistrics = [];
          this.form.controls['district'].setValue('');
        }
      });
    this.patchForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setForm() {
    if (this.data.crudAction === CrudActionsEnum.UPDATE) {
      this.formTitle = 'Edita esta sucursal';
    } else {
      this.formTitle = 'Crea una nueva sucursal';
    }
  }

  patchForm() {
    if (this.data.crudAction === CrudActionsEnum.UPDATE) {
      const branch = this.data.modalData;
      const formData = {
        branchCode: branch.branchCode,
        branchName: branch.branchName,
        branchType: branch.branchType,
        email: branch.email,
        phoneNumber: branch.phoneNumber,
        addressLine: branch.address?.addressLine || '',
        state: branch.address?.state || '',
        city: branch.address?.city || '',
        district: branch.address?.district || '',
        mhCode: branch.mhCode,
        posNumber: branch.posNumber,
      };
      this.form.patchValue(formData);

      branch.schedules.forEach((schedule: ISchedule) => {
        const scheduleFormGroup = new FormGroup({
          day: new FormControl(schedule.day, [Validators.required]),
          description: new FormControl(schedule.description),
          startTime: new FormControl(schedule.startTime, [Validators.required]),
          endTime: new FormControl(schedule.endTime, [Validators.required]),
        });
        this.schedulesFormControl.push(scheduleFormGroup);
      });
    } else {
      this.addressCities = [];
      this.addressDistrics = [];
      this.addSchedule();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  addSchedule() {
    const newSchedule = new FormGroup({
      day: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      startTime: new FormControl('', [Validators.required]),
      endTime: new FormControl('', [Validators.required]),
    });
    this.schedulesFormControl.push(newSchedule);
  }

  getControl(control: AbstractControl, controlName: string): FormControl {
    return control.get(controlName) as FormControl;
  }

  deleteSchedule(index: number) {
    const scheduele = this.schedulesFormControl;
    scheduele.removeAt(index);
  }

  submit() {
    this.errorMsg = '';
    if (this.form.invalid) {
      this.errorMsg =
        FormErrorMessageEnum.LOS_DATOS_SON_INVALIDOS_INTENTE_DE_NUEVO;
      this.form.markAllAsTouched();
      return;
    }

    const formData = {
      branchCode: this.form.controls['branchCode'].value,
      branchName: this.form.controls['branchName'].value,
      branchType: this.form.controls['branchType'].value,
      email: this.form.controls['email'].value,
      phoneNumber: this.form.controls['phoneNumber'].value,
      address: {
        addressLine: this.form.controls['addressLine']?.value || '',
        street: ' ',
        city: this.form.controls['city']?.value || '',
        state: this.form.controls['state']?.value || '',
        primary: true,
        district: this.form.controls['district']?.value || '',
      },
      schedules: this.form.controls['schedules'].value,
      mhCode: this.form.controls['mhCode'].value,
      posNumber: this.form.controls['posNumber'].value,
    } as IBranch;

    if (!this.isLoading) {
      this.isLoading = true;
      if (this.data.crudAction === CrudActionsEnum.CREATE) {
        this.view
          .createBranch(formData)
          .pipe(
            take(1),
            catchError((err: any) => {
              this.errorMsg =
                FormErrorMessageEnum.OCURRIO_UN_ERROR_INTENTE_MAS_TARDE;
              this.isLoading = false;
              this.toastr.error(TOAST_MESSAGES.ERROR);
              return EMPTY;
            })
          )
          .subscribe(() => {
            this.isLoading = false;
            this.errorMsg = '';
            this.toastr.success(TOAST_MESSAGES.SUCCESS);
            this.dialogRef.close();
          });
      }
      if (this.data.crudAction === CrudActionsEnum.UPDATE) {
        this.view
          .updateBranch(this.data.modalData._id, formData)
          .pipe(
            take(1),
            catchError((err: any) => {
              this.errorMsg =
                FormErrorMessageEnum.OCURRIO_UN_ERROR_INTENTE_MAS_TARDE;
              this.isLoading = false;
              this.toastr.error(TOAST_MESSAGES.ERROR);
              return EMPTY;
            })
          )
          .subscribe(() => {
            this.isLoading = false;
            this.errorMsg = '';
            this.toastr.success(TOAST_MESSAGES.SUCCESS);
            this.dialogRef.close();
          });
      }
    }
  }
}
