import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'gc-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnChanges {
  @Input() closable = true;
  @Input() skeleton = false;
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() icon?: string;
  @Input() actionPrimaryText?: string;
  @Input() actionSecondaryText?: string;
  @Input() actionPrimaryIcon?: string;
  @Input() actionSecondaryIcon?: string;
  @Input() primaryColor = 'primary-500';
  @Input() borderColor = 'primary-200';
  @Input() backgroundColor = 'primary-50';

  @Output() primaryActionClicked = new EventEmitter<void>();
  @Output() secondaryActionClicked = new EventEmitter<void>();

  buttonsClass?: string;
  borderClass?: string;
  backgroundClass?: string;

  ngOnInit(): void {
    this.setValues();
  }

  ngOnChanges(): void {
    this.setValues();
  }

  setValues() {
    this.buttonsClass = `buttons-container__button--${this.primaryColor}`;
    this.borderClass = `gc-alert-container--${this.borderColor}`;
    this.backgroundClass = `background-wrapper--${this.backgroundColor}`;
  }

  onPrimaryButtonClicked() {
    this.primaryActionClicked.emit();
  }

  onSecondaryButtonClicked() {
    this.secondaryActionClicked.emit();
  }
}
