<div class="tfi-not-found">
  <div class="tfi-not-found-wrapper">
    <div class="tfi-not-found-wrapper__title">Error</div>
    <div class="tfi-not-found-wrapper__subtitle">
      No se ha encontrado la página que buscas
    </div>
  </div>
  <div class="tfi-not-found-image-container">
    <img
      class="tfi-not-found-image-container__image"
      src="/assets/images/not-found-ilustration.svg"
      alt="not-found-image"
    />
  </div>
</div>
