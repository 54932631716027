<div
  class="switch-card"
  [ngClass]="{ 'switch-card--disabled': disabled }"
  (click)="switch()"
>
  <div class="switch-card-icon" *ngIf="icon">
    <gc-icon [icon]="icon" [dimension]="16"></gc-icon>
  </div>
  <div class="switch-card-content">
    <span class="switch-card-content__title">{{ title }}</span>
    <span class="switch-card-content__description">{{ description }}</span>
  </div>
  <label
    class="switch-card-switch"
    [ngClass]="{
  'switch-card-switch--enabled': !disabled,
}"
  >
    <input
      [id]="id"
      class="switch-card-switch__input"
      type="checkbox"
      [checked]="checked"
      [disabled]="disabled"
      (click)="switch()"
    />
    <div
      class="switch-card-switch__slider"
      [ngClass]="{
    'switch-card-switch__slider--disabled': disabled,
  }"
    ></div>
  </label>
</div>
