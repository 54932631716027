import { MatDialog } from '@angular/material/dialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IMenuOptions, ITableColumn, ITableItem } from '@gc-tech/components';
import {
  CrudActionsEnum,
  IBranch,
  Modaldata,
  TableMenuActionsEnum,
  UserPermissionsEnum,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Subject, debounceTime, take, takeUntil } from 'rxjs';
import { BranchViewService } from '../../services/branch/view/branch-view.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { BranchEditDialogComponent } from './branch-edit-dialog/branch-edit-dialog.component';
import { BranchViewDetailsDialogComponent } from './branch-view-details-dialog/branch-view-details-dialog.component';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';

@Component({
  selector: 'tfi-feat-bm-branch-management',
  templateUrl: './branch-management.component.html',
  styleUrls: ['./branch-management.component.scss'],
  animations: [
    trigger('slideInFromRight', [
      state('void', style({ transform: 'translateX(100%)' })),
      state('*', style({ transform: 'translateX(0)' })),
      transition('void => *', animate('300ms ease-out')),
    ]),
  ],
})
export class BranchManagementComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  branches: IBranch[] = [];
  isSearching = false;
  userPermissionsEnum = UserPermissionsEnum;
  tableOptions: IMenuOptions[] = [
    {
      id: TableMenuActionsEnum.VIEWDETAILS,
      label: 'Ver',
      icon: TableMenuActionsEnum.TABLET,
    },
    {
      id: TableMenuActionsEnum.EDIT,
      label: 'Editar',
      icon: TableMenuActionsEnum.EDIT,
    },
  ];

  columns: ITableColumn[] = [
    {
      field: 'branchCode',
      header: 'Código',
      sort: true,
    },
    {
      field: 'branchName',
      header: 'Nombre',
      sort: true,
    },
    {
      field: 'branchType',
      header: 'Tipo de Tienda',
      sort: true,
    },
    {
      field: 'phoneNumber',
      header: 'Teléfono',
      sort: false,
    },
    {
      field: 'email',
      header: 'Correo Electrónico',
      sort: false,
    },
    {
      field: '',
      header: '',
      sort: false,
    },
  ];

  constructor(
    private dialog: MatDialog,
    private view: BranchViewService,
    private auth: AuthenticationViewService
  ) {}

  ngOnInit(): void {
    this.isSearching = true;
    this.view.branches$.pipe(takeUntil(this.destroy$)).subscribe((branches) => {
      this.branches = branches;
      this.isSearching = false;
    });
    this.view.getBranches();
    if (
      !this.auth.hasPermissionStrict([this.userPermissionsEnum.USR_BRN_EDT])
    ) {
      this.tableOptions.splice(-1);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onCreateBranch() {
    this.openEditBranchDialog({
      crudAction: CrudActionsEnum.CREATE,
      modalData: {},
    });
  }

  openEditBranchDialog(modalData: Modaldata) {
    const dialogRef = this.dialog.open(BranchEditDialogComponent, {
      data: modalData,
      minWidth: '360px',
      width: '85%',
      maxWidth: '1200px',
      maxHeight: '90vh',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.view.getBranches();
    });
  }

  openViewDetailsBranchDialog(modalData: Modaldata) {
    this.dialog.open(BranchViewDetailsDialogComponent, {
      data: modalData,
      minWidth: '360px',
      width: '85%',
      maxWidth: '1400px',
      maxHeight: '90vh',
    });
  }

  tableMenuSelected(itemData: ITableItem): void {
    if (itemData.action === TableMenuActionsEnum.EDIT) {
      this.openEditBranchDialog({
        crudAction: CrudActionsEnum.UPDATE,
        modalData: itemData.data,
      });
      return;
    }
    if (itemData.action === TableMenuActionsEnum.VIEWDETAILS) {
      this.openViewDetailsBranchDialog({
        crudAction: CrudActionsEnum.DETAIL,
        modalData: itemData.data,
      });
      return;
    }
  }

  search(search: string) {
    this.isSearching = true;
    const searchTerm = search.toLowerCase();

    this.view.branches$
      .pipe(debounceTime(700), take(1))
      .subscribe((branches) => {
        this.branches = branches.filter(
          (branch) =>
            branch.branchCode.toLowerCase().includes(searchTerm) ||
            branch.phoneNumber.toLowerCase().includes(searchTerm) ||
            branch.email.toLowerCase().includes(searchTerm)
        );
        this.isSearching = false;
      });
  }
}
