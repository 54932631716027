import { IPermission } from '@tfi-workspace-web/tfi-shared-utils';
import { ClaimsEnum } from '../enums/claims.enum';
import { IBaseEntity } from './base-entity.interface';
import { ICompany } from './company.interface';

export interface IUser extends IBaseEntity {
  name: string;
  email: string;
  phoneNumber: string;
  country: string;
  password: string;
  confirmPassword: string;
  claims: ClaimsEnum[];
  companyId?: string;
  isUserVerified: boolean;
  verifiedDate?: Date;
  acceptTermsAndConditions: boolean;
  acceptTermsAndConditionsDate?: Date;
  requirePasswordUpdate: boolean;
  branch?: any;
  userRole?: IUserRole;
}

export interface IUserRole extends IBaseEntity {
  company: ICompany;
  name: string;
  description: string;
  permissions: IPermission[];
  isDefaultRole: boolean;
}

export type UpdatedUser = Partial<IUser>;

export interface IUsersResponse {
  status: string;
  results: number;
  data: {
    users: IUser[];
  };
}

export interface IUserResponse {
  status: string;
  results: number;
  data: {
    user: IUser;
  };
}

export interface IUserAssignRoleResponse {
  status: string;
  data: {
    user: IUser;
  };
}
