import { BehaviorSubject, take } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  IShipping,
  IShippingCompany,
  IShippingSend,
  ShippingStatusEnum,
} from '@tfi-workspace-web/tfi-shared-utils';
import { ShippingDataService } from '../data/shipping-data.service';
import { ShippingListFilters } from '../../../constants/shipping-filter';

@Injectable({
  providedIn: 'root',
})
export class ShippingViewService {
  private shippingsSubject = new BehaviorSubject<IShipping[]>([]);
  private shippingsCompanySubject = new BehaviorSubject<IShippingCompany[]>([]);
  private _totalElements = 0;

  public set shippings(data: IShipping[]) {
    this.shippingsSubject.next(data);
  }
  public set shippingsCompany(data: IShippingCompany[]) {
    this.shippingsCompanySubject.next(data);
  }

  shippings$ = this.shippingsSubject.asObservable();
  shippingsCompany$ = this.shippingsCompanySubject.asObservable();

  get totalElements(): number {
    return this._totalElements;
  }

  constructor(private data: ShippingDataService) {}

  getShippings(filterData: ShippingListFilters) {
    return this.data
      .getShippings(filterData)
      .pipe(take(1))
      .subscribe((response) => {
        this._totalElements = response.total;
        this.shippingsSubject.next(response.data.shippings);
      });
  }

  createShipping(shipping: IShippingSend) {
    return this.data.createShipping(shipping);
  }

  getShippingById(id: string) {
    return this.data.getShippingById(id);
  }

  updateShipping(shippingId: string, shipping: IShippingSend) {
    return this.data.updateShipping(shippingId, shipping);
  }

  patchShipping(shippingId: string, data: any) {
    return this.data.patchShipping(shippingId, data);
  }

  updateShippingStatusBulk(
    shippingIds: string[],
    newStatus?: ShippingStatusEnum | null,
    packingUser?: string | null
  ) {
    let payload: any = {
      shippings: shippingIds,
    };
    if (newStatus) {
      payload = {
        ...payload,
        shippingStatus: newStatus,
      };
    }
    if (packingUser) {
      payload = { ...payload, packingUser };
    }
    return this.data.updateShippingStatusBulk(payload);
  }

  getShippingCompany() {
    return this.data.getShippingCompany();
  }

  getShippingCompanySubscription() {
    return this.data
      .getShippingCompany()
      .pipe(take(1))
      .subscribe((responce) => {
        this.shippingsCompanySubject.next(responce.data.shippingCompanies);
      });
  }

  createShippingCompany(shippingCompany: IShippingCompany) {
    return this.data.createShippingCompany(shippingCompany);
  }

  downloadPdf(filterData: ShippingListFilters) {
    return this.data.downloadPdf(filterData);
  }

  printShippingSelected(shippingsSelectedIds: string[], layout: string) {
    return this.data.printShippingSelected(shippingsSelectedIds, layout);
  }

  deleteShipping(shippingId: string, payload: any) {
    return this.data.softDeleteShipping(shippingId, payload);
  }
}
