import { contributorSizeEnum } from '../constants/customer-document.contant';
import { CustomerDocumentType } from '../enums/customer-document-types.enum';
import { IBaseAddress } from './base-address.interface';
import { IBaseEntity } from './base-entity.interface';
import { DocumentTypeEnum } from './ticket.interface';

export interface ICustomer extends IBaseEntity {
  name: string;
  email: string;
  phoneNumber: string;
  address?: IBaseAddress;
  documentType?: CustomerDocumentType;
  documentNumber?: string;
  documentTypeDescription?: string;
  creditLimit?: string;
  dueBalance?: string;
  dateOfBirth?: string;
  nrc?: string;
  contributorSize?: string;
  contributorType?: string;
  economicActivity?: any;
}

export interface ICustomerResponse {
  status: string;
  data: {
    customer: ICustomer;
  };
}

export interface ICustomersResponse {
  status: string;
  results: number;
  data: {
    customers: ICustomer[];
  };
}

export interface IEconomicActiviesResponse {
  status: string;
  results: number;
  data: {
    economicActivities: IEconomicActivity[];
  };
}

export interface IEconomicActivity {
  category: string;
  subCategory?: string;
  activityCode: string;
  activityDescription: string;
  _id: string;
}
