import { IBaseEntity } from './base-entity.interface';

export interface IInvoiceResponse {
  status: string;
  results: number;
  data: {
    invoices: IAdminInvoice[];
  };
}

export interface IAdminInvoice extends IBaseEntity {
  invoiceNumber: string;
  company?: string;
  issueDateTime: Date;
  dueDate: string;
  invoiceType: string;
  status: InvoiceStatusEnum;
  paymentMethodId?: PaymentMethodEnum;
  issueCompanyInfo: {
    name: string;
    address: string;
    nit: string;
    nrc: string;
    phoneNumber: string;
  };
  customerInfo: {
    name: string;
    address: string;
    nit: string;
    nrc: string;
    phoneNumber: string;
  };
  invoiceDetail: IInvoiceDetail[];
  totalBeforeTax: number;
  ivaAmount: number;
  total: number;
}

export interface IInvoiceDetail {
  seq: number;
  quantity: number;
  description: string;
  unitPrice: number;
  discount?: number;
  subTotal: number;
}

export interface InvoiceMethods {
  create: () => Promise<any>;
  update: () => Promise<any>;
  delete: () => Promise<any>;
}

export enum InvoiceStatusEnum {
  ISSUED = 'ISSUED',
  PENDING = 'PENDING',
  PAID = 'PAID',
  OVER_DUE = 'OVER_DUE',
}

export enum PaymentMethodEnum {
  CREDIT_CARD = 'CREDIT_CARD',
  TRANSFER = 'TRANSFER',
  WOMPI = 'WOMPI',
}
