<div
  class="gc-alert-container"
  [ngClass]="[borderClass, skeleton ? 'skeleton--no-animation' : '']"
>
  <div
    class="background-wrapper"
    [ngClass]="[backgroundClass, skeleton ? 'skeleton--no-animation' : '']"
  >
    <gc-icon
      *ngIf="icon"
      [icon]="icon"
      class="background-wrapper__icon"
      [skeleton]="skeleton"
      [dimension]="20"
    ></gc-icon>
    <div class="content-container">
      <span
        class="content-container__title"
        [ngClass]="{ skeleton: skeleton }"
        >{{ title }}</span
      >
      <span
        class="content-container__subtitle"
        [ngClass]="{ skeleton: skeleton }"
        >{{ subtitle }}</span
      >
      <div
        class="buttons-container"
        *ngIf="actionPrimaryText || actionSecondaryText"
      >
        <button
          class="buttons-container__button"
          [ngClass]="[buttonsClass, skeleton ? 'skeleton' : '']"
          (click)="onPrimaryButtonClicked()"
        >
          <gc-icon
            *ngIf="actionPrimaryIcon"
            [icon]="actionPrimaryIcon"
            [dimension]="16"
            [skeleton]="skeleton"
          ></gc-icon>
          {{ actionPrimaryText }}
        </button>
        <button
          class="buttons-container__button"
          [ngClass]="{ buttonsClass,skeleton: skeleton }"
          (click)="onSecondaryButtonClicked()"
        >
          <gc-icon
            *ngIf="actionSecondaryIcon"
            [icon]="actionSecondaryIcon"
            [dimension]="16"
            [skeleton]="skeleton"
          ></gc-icon>
          {{ actionSecondaryText }}
        </button>
      </div>
    </div>
    <gc-icon
      *ngIf="closable"
      class="background-wrapper__close_icon"
      icon="x"
      [dimension]="20"
      [skeleton]="skeleton"
    ></gc-icon>
  </div>
</div>
