import { Injectable } from '@angular/core';
import {
  IBranch,
  IBranchesResponse,
} from '@tfi-workspace-web/tfi-shared-utils';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { BranchDataService } from '../data/branch-data.service';

@Injectable({
  providedIn: 'root',
})
export class BranchViewService {
  private branchesSubject = new BehaviorSubject<IBranch[]>([]);

  branches$ = this.branchesSubject.asObservable();

  constructor(private data: BranchDataService) {}

  getBranches() {
    return this.data
      .getBranches()
      .pipe(take(1))
      .subscribe((response) => {
        this.branchesSubject.next(response.data.branches);
      });
  }

  getBranchesRaw(): Observable<IBranchesResponse> {
    return this.data.getBranches();
  }

  createBranch(branch: IBranch) {
    return this.data.createBranch(branch);
  }

  updateBranch(branchId: string, branch: IBranch) {
    return this.data.updateBranch(branchId, branch);
  }
}
