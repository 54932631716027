import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'gc-download-upload-dialog',
  templateUrl: './download-upload-dialog.component.html',
  styleUrls: ['./download-upload-dialog.component.scss'],
})
export class DownloadUploadDialogComponent {
  @Input() isDownloading = false;
  @Output() fileLoadedEmitter = new EventEmitter<File>();
  @Output() fileDeletedEmitter = new EventEmitter<void>();
  @Output() primaryButtonClicked = new EventEmitter<void>();
  @Output() secondaryButtonClicked = new EventEmitter<void>();
  @Output() thirdButtonClicked = new EventEmitter<void>();
  isPrimaryButtonEnable = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {}

  fileLoaded(file: File) {
    this.isPrimaryButtonEnable = true;
    this.fileLoadedEmitter.emit(file);
  }
  fileDeleted() {
    this.isPrimaryButtonEnable = false;
    this.fileDeletedEmitter.emit();
  }

  onPrimaryButtonClicked() {
    this.primaryButtonClicked.emit();
  }
  onSecondaryButtonClicked() {
    this.secondaryButtonClicked.emit();
  }
  onThirdButtonClicked() {
    this.thirdButtonClicked.emit();
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
