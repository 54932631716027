import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Swiper, SwiperOptions } from 'swiper/types';

@Component({
  selector: 'gc-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit {
  @Input() mainImg = '';
  @Input() imgSize: 'sm' | 'md' | 'lg' = 'sm';
  @Input() gallery: string[] = [];
  @ViewChild('swiperRef', { static: true }) _swiperRef: ElementRef | undefined;
  swiper?: Swiper;
  selectedImg = '';
  emptyImage = '/assets/images/no-image.jpg';

  ngOnInit(): void {
    if (this.mainImg !== '') {
      this.gallery.unshift(this.mainImg);
    }
    if (this.gallery.length > 0) {
      this.selectedImg = this.gallery[0];
      if (this._swiperRef) {
        this.configSwiperCarousel();
      }
    }
  }

  configSwiperCarousel() {
    const options: SwiperOptions = {
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        350: {
          slidesPerView: 5,
        },
      },
    };

    const swiperEl = this._swiperRef?.nativeElement;
    Object.assign(swiperEl, options);

    swiperEl.initialize();

    if (this.swiper) this.swiper.currentBreakpoint = false;
    this.swiper = this._swiperRef?.nativeElement.swiper;
  }
}
