import { IBaseEntity } from './base-entity.interface';
import { IBranch } from './branch.interface';
import { IProduct } from './product.interface';

export interface IInventory extends IBaseEntity {
  companyId?: string;
  branch?: IBranch;
  product?: IProduct;
  stock: number;
  unitsInTransit?: number;
  minStock: number;
  maxStock: number;
  selectedQuantity?: number;
}

export interface IInventoriesResponse {
  status: string;
  results: number;
  page: number;
  result: number;
  total: number;
  totalPages: number;
  data: {
    inventories: IInventory[];
  };
}

export interface IInventoryResponse {
  status: string;
  results: number;
  data: {
    inventory: IInventory[];
  };
}
