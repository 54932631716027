import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IInvoiceResponse,
  environment,
  invoiceMethods,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingDataService {
  private readonly invoiceUrl = environment.api_host + invoiceMethods.invoice;
  constructor(private http: HttpClient) {}

  getInvoices(
    startDate: string,
    endDate: string
  ): Observable<IInvoiceResponse> {
    let params = new HttpParams();
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    return this.http.get<IInvoiceResponse>(this.invoiceUrl, { params });
  }
}
