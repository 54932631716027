<div class="brand-details-container">
  <div class="title-container">
    <h2 class="title-container__title">Detalles de la marca</h2>
    <gc-icon
      class="title-container__icon"
      icon="x"
      [dimension]="22"
      color="grey-800"
      mat-dialog-close
    ></gc-icon>
  </div>
  <div class="brands-content">
    <span class="brands-content__key">Nombre de la marca:</span>
    <span class="brands-content__value">{{
      data.modalData.brandName || '-' | titlecase
    }}</span>
    <span class="brands-content__key">Descripcion:</span>
    <span class="brands-content__value">{{
      data.modalData.description || '-'
    }}</span>
    <div class="vendors-container" *ngIf="data.modalData.vendors.length > 0">
      <span class="brands-content__key">Código de Proveedores:</span>
      <ul class="vendor-list" *ngFor="let id of data.modalData.vendors">
        <li class="vendor-list__item">{{ id }}</li>
      </ul>
    </div>
  </div>
  <div class="button-container">
    <gc-button
      class="button-container__button"
      text="Regresar"
      size="sm"
      icon="chevron-left"
      (click)="cancel()"
    ></gc-button>
  </div>
</div>
