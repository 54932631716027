import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  claimOptions,
  IUser,
  Modaldata,
} from '@tfi-workspace-web/tfi-shared-utils';

@Component({
  selector: 'tfi-feat-bm-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  user: IUser;
  claimsOpt = claimOptions;
  constructor(
    public dialogRef: MatDialogRef<UserDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modaldata
  ) {}
  ngOnInit(): void {
    this.user = this.data.modalData as IUser;
  }

  cancel() {
    this.dialogRef.close();
  }
}
