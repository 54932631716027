<div class="main-container">
  <div class="main-sub-container">
    <div class="title-container">
      <div class="title-container-icon-container" (click)="cancel()">
        <gc-icon icon="chevron-left" [dimension]="20"></gc-icon>
      </div>
      <h2 class="title-container__title">{{ formTitle || '' }}</h2>
    </div>
    <div class="form-container" [formGroup]="form">
      <div class="tfi-sub-form-container">
        <div class="tfi-sections-container">
          <div class="product-section-container">
            <div class="product-section-container__title">
              Información del producto
            </div>
            <div class="input-container">
              <gc-label
                label="Nombre del producto"
                [control]="productNameFormControl"
              >
                <gc-input
                  #myInput
                  id="productName"
                  class="input-container__input"
                  type="text"
                  formControlName="productName"
                  placeHolder="Nombre del producto"
                ></gc-input>
              </gc-label>
            </div>

            <div class="input-container">
              <gc-label
                label="Descripción (Opcional)"
                [control]="descriptionFormControl"
              >
                <gc-input
                  id="descriptionProduct"
                  class="input-container__input"
                  type="text"
                  formControlName="description"
                  placeHolder="Descripción"
                ></gc-input>
              </gc-label>
            </div>
            <div class="input-container">
              <gc-label label="Precio" [control]="priceFormControl">
                <gc-input
                  id="priceProduct"
                  class="input-container__input"
                  icon="dollar-sign"
                  type="number"
                  formControlName="price"
                  placeHolder="Precio"
                  (input)="onPriceChange()"
                ></gc-input>
              </gc-label>
            </div>
            <div class="input-container">
              <gc-label label="Costo" [control]="costFormControl">
                <gc-input
                  id="costProduct"
                  class="input-container__input"
                  icon="dollar-sign"
                  type="number"
                  formControlName="cost"
                  placeHolder="Costo"
                  (input)="onCostChange()"
                ></gc-input>
              </gc-label>
            </div>
            <div class="input-container">
              <gc-label
                label="Costo Variable (Opcional)"
                [control]="unitVariableCostFormControl"
              >
                <gc-input
                  id="unitVariableCost"
                  class="input-container__input"
                  icon="dollar-sign"
                  type="number"
                  formControlName="unitVariableCost"
                  placeHolder="Costo Variable"
                  (input)="onCostChange()"
                ></gc-input>
              </gc-label>
            </div>
            <div class="input-container">
              Costo total: ${{ totalCost | number:'1.2-2'}}
            </div>
            <div class="input-container">
              <gc-label
                label="Margen porcentual"
                [control]="marginPercFormControl"
              >
                <gc-input
                  id="productMarginPerc"
                  class="input-container__input"
                  icon="percent"
                  type="number"
                  formControlName="marginPerc"
                  placeHolder="Margen porcentual"
                  (input)="onMarginPercChange()"
                ></gc-input>
              </gc-label>
            </div>

            <div class="input-container">
              <gc-label label="Margen monetarrio" [control]="marginFormControl">
                <gc-input
                  id="productMargin"
                  class="input-container__input"
                  icon="dollar-sign"
                  type="number"
                  formControlName="margin"
                  placeHolder="Margen monetarrio"
                  (input)="onMarginChange()"
                ></gc-input>
              </gc-label>
            </div>
            <div class="input-sub-container">
              <div class="input-container">
                <gc-label label="Vender sin existencias">
                  <gc-switch
                    size="md"
                    formControlName="sellWithoutStock"
                  ></gc-switch>
                </gc-label>
              </div>
              <div class="input-container">
                <gc-label label="Mostrar en catálogo">
                  <gc-switch
                    size="md"
                    formControlName="showInCatalog"
                  ></gc-switch>
                </gc-label>
              </div>
            </div>
          </div>
          <div class="tfi-rigth-section">
            <div class="product-section-container">
              <div
                class="product-section-container__title product-section-container__title--space-lef"
              >
                Detalles del producto
              </div>
              <div class="border-left">
                <div class="product-section-container-input-wrapper">
                  <div class="input-container">
                    <gc-label label="Categoría" [control]="categoryFormControl">
                      <gc-select
                        id="categoriesProducts"
                        formControlName="category"
                        [data]="categoriesInputOptions"
                        placeholder="Categoría"
                      ></gc-select>
                    </gc-label>
                  </div>
                  <gc-button
                    *PermissionControl="[userPermissionsEnum.USR_CAT_CRT]"
                    class="product-section-container-input-wrapper__button"
                    icon="file-plus"
                    mode="outline"
                    pTooltip="Crear nueva categoría"
                    tooltipPosition="top"
                    (click)="openCategoryDialog()"
                  ></gc-button>
                </div>
                <div class="product-section-container-input-wrapper">
                  <div class="input-container">
                    <gc-label label="Marca" [control]="brandFormControl">
                      <gc-select
                        id="brandProduct"
                        formControlName="brand"
                        [data]="brandsInputOptions"
                        placeholder="Marca"
                      ></gc-select>
                    </gc-label>
                  </div>
                  <gc-button
                    *PermissionControl="[userPermissionsEnum.USR_BRD_CRT]"
                    class="product-section-container-input-wrapper__button"
                    icon="file-plus"
                    mode="outline"
                    pTooltip="Crear nueva marca"
                    tooltipPosition="top"
                    (click)="openBrandDialog()"
                  ></gc-button>
                </div>
                <div class="input-container">
                  <gc-label
                    label="Tipo de producto"
                    [control]="productTypeFormControl"
                  >
                    <gc-select
                      id="productTypeProduct"
                      formControlName="productType"
                      [data]="productTypeOptions"
                      placeholder="Tipo de producto"
                    ></gc-select>
                  </gc-label>
                </div>
                <div class="input-container">
                  <gc-label
                    label="Tipo de impuesto"
                    [control]="saleTypeFormControl"
                  >
                    <gc-select
                      id="saleType"
                      formControlName="saleType"
                      [data]="saleTypeOptions"
                      placeholder="Tipo de impuesto"
                    ></gc-select>
                  </gc-label>
                </div>
                <div class="input-container" *ngIf="prodId !== 'new'">
                  <gc-label
                    label="Código de producto"
                    [control]="productCodeFormControl"
                  >
                    <gc-input
                      id="productCode"
                      class="input-container__input"
                      type="text"
                      formControlName="productCode"
                      placeHolder="Código de producto"
                      [readonly]="true"
                    ></gc-input>
                  </gc-label>
                </div>
                <div class="input-container" *ngIf="prodId !== 'new'">
                  <gc-label
                    label="SKU interno (Opcional)"
                    [control]="internalSKUFormControl"
                  >
                    <gc-input
                      id="internalSKU"
                      class="input-container__input"
                      type="text"
                      formControlName="internalSKU"
                      placeHolder="SKU interno"
                      [readonly]="true"
                    ></gc-input>
                  </gc-label>
                </div>
                <div class="input-container">
                  <gc-label
                    label="SKU del proveedor (Opcional)"
                    [control]="vendorSKUFormControl"
                  >
                    <gc-input
                      id="vendorSKU"
                      class="input-container__input"
                      type="text"
                      formControlName="vendorSKU"
                      placeHolder="SKU del proveedor"
                    ></gc-input>
                  </gc-label>
                </div>
                <div class="input-container">
                  <gc-label
                    [label]="productIsEnabled.value ? 'Habilitado':'Deshabilitado'"
                  >
                    <gc-switch
                      size="md"
                      formControlName="isEnabled"
                    ></gc-switch>
                  </gc-label>
                </div>
              </div>
            </div>
            <div class="product-section-container border-left">
              <div
                class="product-section-container__title product-section-container__title--mod-bottom"
              >
                Atributos e imagenes
              </div>
              <button
                class="add-attribute-button add-attribute-button--space"
                (click)="addProductAttribute()"
              >
                Agregar atributo +
              </button>
              <div
                class="attributes-container"
                formArrayName="attributes"
                *ngFor="let attribute of attributesFormControl.controls; let i = index"
              >
                <div class="attribute-item" [formGroupName]="i">
                  <div class="divider"></div>
                  <div class="attribute-inputs-container">
                    <div class="attribute-name-container">
                      <button
                        class="attribute-name-container__button"
                        (click)="deleteProductAttribute(i)"
                      >
                        <i-feather
                          class="attribute-name-container__button--icon"
                          name="trash"
                        ></i-feather>
                      </button>
                      <div class="input-container input-container--mod">
                        <gc-label
                          label="Nombre del atributo"
                          [control]="getControl(attribute, 'attributeName')"
                        >
                          <gc-input
                            id="attributeNameProduct"
                            type="text"
                            formControlName="attributeName"
                            placeHolder="Nombre del atributo"
                          ></gc-input>
                        </gc-label>
                      </div>
                    </div>
                    <button
                      class="attribute-inputs-container__add-option-button-mobile"
                      (click)="addAttribueOption(i)"
                    >
                      Agregar opcion +
                    </button>
                    <div formArrayName="attributeOptions">
                      <div
                        *ngFor="
                          let option of getAttributeOptions(attribute);
                          let j = index
                        "
                      >
                        <div
                          class="options-array-container"
                          [formGroupName]="j"
                        >
                          <div class="input-container">
                            <gc-label
                              label="Opción"
                              [control]="getControl(option, 'optionName')"
                            >
                              <gc-input
                                id="optionNameProduct"
                                type="text"
                                formControlName="optionName"
                                placeHolder="Nombre de la opción"
                              ></gc-input>
                            </gc-label>
                          </div>
                          <div class="input-container">
                            <gc-label label="Modificador (Opcional)">
                              <gc-input
                                id="priceModifierProduct"
                                type="text"
                                formControlName="priceModifier"
                                placeHolder="modificador de precio"
                              ></gc-input>
                            </gc-label>
                          </div>
                          <button
                            class="options-array-container__delete-button"
                            (click)="deleteOption(i, j)"
                            [disabled]="j === 0"
                          >
                            <i-feather
                              class="options-array-container__delete-button--icon"
                              name="trash"
                            ></i-feather>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!--button
                      class="attribute-inputs-container__add-option-button"
                      (click)="addAttribueOption(i)"
                    >
                      Agregar opcion +
                    </button-->
                  </div>
                </div>
              </div>
              <button class="add-attribute-button" (click)="selectFile.click()">
                Agregar imagen +
              </button>
              <input
                id="imageInputProduct"
                type="file"
                class="add-attribute-button--hidden"
                value="select"
                #selectFile
                accept=".jpg, .jpeg, .png"
                (change)="onImageSelected($event)"
              />
              <div class="images-section-container" [@menuAnimation]>
                <div class="images-section-container__section">
                  <div class="image-gallery">
                    <div
                      class="image-card"
                      *ngFor="let img of productImages; let i = index"
                      [@menuAnimation]
                    >
                      <div class="image-container">
                        <img
                          class="image-container__image"
                          [src]="img.url"
                          alt="image-selected"
                        />
                      </div>
                      <div class="image-card-buttons">
                        <gc-radio-button
                          class="image-card-buttons__radio"
                          label="Principal"
                          (input)="onRadioImageSelected(i)"
                          [checked]="img.isSelected"
                        ></gc-radio-button>
                        <button
                          class="image-card-buttons__delete-image"
                          (click)="removeImage(i)"
                        >
                          <i-feather
                            class="image-card-buttons__delete-image--icon"
                            name="trash"
                          ></i-feather>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="error-alert-container">
          <gc-alert
            *ngIf="errorMsg"
            icon="alert-triangle"
            [title]="errorMsg"
            primaryColor="red-500"
            borderColor="red-600"
            backgroundColor="red-50"
            [closable]="false"
          ></gc-alert>
        </div>
        <div class="buttons-wrapper">
          <div class="button-container">
            <gc-button
              [size]="screenWidth > 500 ? 'md' : 'sm'"
              class="button-container__button"
              text="Cancelar"
              (click)="cancel()"
              type="full"
              color="secondary"
              mode="ghost"
            ></gc-button>
          </div>
          <div class="button-container">
            <gc-button
              [size]="screenWidth > 500 ? 'md' : 'sm'"
              class="button-container__button"
              text="Aceptar"
              (click)="submit()"
              type="full"
              [isLoading]="isLoading"
            ></gc-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
