<div class="tfi-actions">
  <div
    class="tfi-actions-wrapper"
    *ngIf="permittedActions.length <= 3; else longRef"
  >
    <gc-icon
      *ngFor="let action of permittedActions"
      class="tfi-actions-wrapper__icon"
      tooltipPosition="top"
      color="primary-500"
      [dimension]="19"
      [icon]="action.icon"
      [pTooltip]="action.tooltip"
      (click)="selectedAction(action.menuAction)"
    >
    </gc-icon>
  </div>
  <ng-template #longRef>
    <div class="tfi-actions-wrapper">
      <gc-icon
        *ngFor="let action of permittedActions | slice : 0 : 2"
        class="tfi-actions-wrapper__icon"
        tooltipPosition="top"
        color="primary-500"
        [dimension]="19"
        [icon]="action.icon"
        [pTooltip]="action.tooltip"
        (click)="selectedAction(action.menuAction)"
      >
      </gc-icon>
      <div class="tfi-actions-wrapper-menu">
        <gc-icon
          class="tfi-actions-wrapper__icon"
          tooltipPosition="top"
          color="primary-500"
          [dimension]="19"
          icon="more-vertical"
          pTooltip="Más opciones"
          (click)="toggleMenu($event)"
        >
        </gc-icon>
        <ul
          class="tfi-actions-wrapper-menu-panel"
          *ngIf="isPanelOptionVisible"
          #menuElement
          [@menuAnimation]
        >
          <li
            class="tfi-actions-wrapper-menu-panel-action"
            *ngFor="let action of permittedActions | slice : 2"
            (click)="selectedAction(action.menuAction)"
          >
            <gc-icon [icon]="action.icon" [dimension]="16"></gc-icon>
            <span>{{ action.label | titlecase }}</span>
          </li>
        </ul>
      </div>
    </div>
  </ng-template>
</div>
