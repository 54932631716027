import { OperationTimeEnum } from '../enums/operation-time.enum';

export const OperationTimeOptions = [
  /*{ label: '05:00', value: OperationTimeEnum.Hora0500 },
  { label: '05:30', value: OperationTimeEnum.Hora0530 },
  { label: '06:00', value: OperationTimeEnum.Hora0600 },
  { label: '06:30', value: OperationTimeEnum.Hora0630 },*/
  { label: '07:00 AM', value: OperationTimeEnum.Hora0700 },
  { label: '07:30 AM', value: OperationTimeEnum.Hora0730 },
  { label: '08:00 AM', value: OperationTimeEnum.Hora0800 },
  { label: '08:30 AM', value: OperationTimeEnum.Hora0830 },
  { label: '09:00 AM', value: OperationTimeEnum.Hora0900 },
  { label: '09:30 AM', value: OperationTimeEnum.Hora0930 },
  { label: '10:00 AM', value: OperationTimeEnum.Hora1000 },
  { label: '10:30 AM', value: OperationTimeEnum.Hora1030 },
  { label: '11:00 AM', value: OperationTimeEnum.Hora1100 },
  { label: '11:30 AM', value: OperationTimeEnum.Hora1130 },
  { label: '12:00 PM', value: OperationTimeEnum.Hora1200 },
  { label: '12:30 PM', value: OperationTimeEnum.Hora1230 },
  { label: '01:00 PM', value: OperationTimeEnum.Hora1300 },
  { label: '01:30 PM', value: OperationTimeEnum.Hora1330 },
  { label: '02:00 PM', value: OperationTimeEnum.Hora1400 },
  { label: '02:30 PM', value: OperationTimeEnum.Hora1430 },
  { label: '03:00 PM', value: OperationTimeEnum.Hora1500 },
  { label: '03:30 PM', value: OperationTimeEnum.Hora1530 },
  { label: '04:00 PM', value: OperationTimeEnum.Hora1600 },
  { label: '04:30 PM', value: OperationTimeEnum.Hora1630 },
  { label: '05:00 PM', value: OperationTimeEnum.Hora1700 },
  { label: '05:30 PM', value: OperationTimeEnum.Hora1730 },
  { label: '06:00 PM', value: OperationTimeEnum.Hora1800 },
  { label: '06:30 PM', value: OperationTimeEnum.Hora1830 },
  { label: '07:00 PM', value: OperationTimeEnum.Hora1900 },
  /*{ label: '19:30', value: OperationTimeEnum.Hora1930 },
  { label: '20:00', value: OperationTimeEnum.Hora2000 },
  { label: '20:30', value: OperationTimeEnum.Hora2030 },
  { label: '21:00', value: OperationTimeEnum.Hora2100 },
  { label: '21:30', value: OperationTimeEnum.Hora2130 },
  { label: '22:00', value: OperationTimeEnum.Hora2200 },
  { label: '22:30', value: OperationTimeEnum.Hora2230 },
  { label: '23:00', value: OperationTimeEnum.Hora2300 },
  { label: '23:30', value: OperationTimeEnum.Hora2330 },
  { label: '24:00', value: OperationTimeEnum.Hora2400 },*/
];
