export enum UserPermissionsEnum {
  USR_APT_LST = 'USR_APT_LST',
  USR_APT_CRT = 'USR_APT_CRT',
  USR_APT_EDT = 'USR_APT_EDT',
  USR_APT_DLT = 'USR_APT_DLT',
  USR_INV_LST = 'USR_INV_LST',
  USR_INV_CRT = 'USR_INV_CRT',
  USR_INV_EDT = 'USR_INV_EDT',
  USR_INV_DLT = 'USR_INV_DLT',
  USR_BRN_LST = 'USR_BRN_LST',
  USR_BRN_CRT = 'USR_BRN_CRT',
  USR_BRN_EDT = 'USR_BRN_EDT',
  USR_BRN_DLT = 'USR_BRN_DLT',
  USR_BRD_LST = 'USR_BRD_LST',
  USR_BRD_CRT = 'USR_BRD_CRT',
  USR_BRD_EDT = 'USR_BRD_EDT',
  USR_BRD_DLT = 'USR_BRD_DLT',
  USR_CAT_LST = 'USR_CAT_LST',
  USR_CAT_CRT = 'USR_CAT_CRT',
  USR_CAT_EDT = 'USR_CAT_EDT',
  USR_CAT_DLT = 'USR_CAT_DLT',
  USR_CMP_LST = 'USR_CMP_LST',
  USR_CMP_CRT = 'USR_CMP_CRT',
  USR_CMP_EDT = 'USR_CMP_EDT',
  USR_CMP_DLT = 'USR_CMP_DLT',
  USR_CST_LST = 'USR_CST_LST',
  USR_CST_CRT = 'USR_CST_CRT',
  USR_CST_EDT = 'USR_CST_EDT',
  USR_CST_DLT = 'USR_CST_DLT',
  USR_INVNT_LST = 'USR_INVNT_LST',
  USR_INVNT_CRT = 'USR_INVNT_CRT',
  USR_INVNT_EDT = 'USR_INVNT_EDT',
  USR_INVNT_DLT = 'USR_INVNT_DLT',
  USR_KOD_LST = 'USR_KOD_LST',
  USR_KOD_CRT = 'USR_KOD_CRT',
  USR_KOD_EDT = 'USR_KOD_EDT',
  USR_KOD_DLT = 'USR_KOD_DLT',
  USR_PRD_LST = 'USR_PRD_LST',
  USR_PRD_CRT = 'USR_PRD_CRT',
  USR_PRD_EDT = 'USR_PRD_EDT',
  USR_PRD_DLT = 'USR_PRD_DLT',
  USR_SHP_LST = 'USR_SHP_LST',
  USR_SHP_CRT = 'USR_SHP_CRT',
  USR_SHP_EDT = 'USR_SHP_EDT',
  USR_SHP_DLT = 'USR_SHP_DLT',
  USR_SHC_LST = 'USR_SHC_LST',
  USR_SHC_CRT = 'USR_SHC_CRT',
  USR_SHC_EDT = 'USR_SHC_EDT',
  USR_SHC_DLT = 'USR_SHC_DLT',
  USR_TKT_LST = 'USR_TKT_LST',
  USR_TKT_CRT = 'USR_TKT_CRT',
  USR_TKT_EDT = 'USR_TKT_EDT',
  USR_TKT_DLT = 'USR_TKT_DLT',
  USR_USR_LST = 'USR_USR_LST',
  USR_USR_CRT = 'USR_USR_CRT',
  USR_USR_EDT = 'USR_USR_EDT',
  USR_USR_DLT = 'USR_USR_DLT',
  USR_VND_LST = 'USR_VND_LST',
  USR_VND_CRT = 'USR_VND_CRT',
  USR_VND_EDT = 'USR_VND_EDT',
  USR_VND_DLT = 'USR_VND_DLT',
  USR_ROL_LST = 'USR_ROL_LST',
  USR_ROL_CRT = 'USR_ROL_CRT',
  USR_ROL_EDT = 'USR_ROL_EDT',
  USR_ROL_DLT = 'USR_ROL_DLT',
}
