import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DomainService {
  private routeDomain = new BehaviorSubject<string>('');
  companiDomain$ = this.routeDomain.asObservable();

  setCompanyDomain(domain: string) {
    this.routeDomain.next(domain);
  }
}
