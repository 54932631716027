import { IBaseEntity } from './base-entity.interface';

export interface ICompanyWebCatalog extends IBaseEntity {
  company: string;
  domain: string;
}

export interface ICompanyWebCatalogResponse {
  status: string;
  data: {
    companyWebCatalog: ICompanyWebCatalog;
  };
}
