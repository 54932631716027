import { Injectable } from '@angular/core';
import { ICompanyWebCatalog } from '@tfi-workspace-web/tfi-shared-utils';
import { BehaviorSubject, take } from 'rxjs';
import { WebCatalogDataService } from '../data/web-catalog-data.service';
@Injectable({
  providedIn: 'root',
})
export class WebCatalogViewService {
  private webCatalogSubject = new BehaviorSubject<ICompanyWebCatalog>({
    company: '',
    domain: '',
  });

  companyWebCatalog$ = this.webCatalogSubject.asObservable();

  constructor(private data: WebCatalogDataService) {}

  getCompanyWebCatalog() {
    return this.data
      .getCompanyWebCatalog()
      .pipe(take(1))
      .subscribe((response) => {
        this.webCatalogSubject.next(response.data.companyWebCatalog);
      });
  }

  updateCompanyWebCatalog(webCatalog: Partial<ICompanyWebCatalog>) {
    return this.data.updateCompanyWebCatalog(webCatalog);
  }
}
