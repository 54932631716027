import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ICompany,
  ICompanyResponse,
  IFeSettingResponse,
  companyMethods,
  environment,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyDataService {
  private readonly companyUrl = environment.api_host + companyMethods.company;
  constructor(private http: HttpClient) {}

  getCompany(): Observable<ICompanyResponse> {
    return this.http.get<ICompanyResponse>(this.companyUrl);
  }

  updateCompany(company: Partial<ICompany>): Observable<ICompanyResponse> {
    return this.http.patch<ICompanyResponse>(this.companyUrl, company);
  }

  getFeSetting(): Observable<IFeSettingResponse> {
    return this.http.get<IFeSettingResponse>(`${this.companyUrl}/fe-settings`);
  }
}
