import {
  TableColumnTypeEnum,
  TableEntityEnum,
} from '../components/table/table.component.enum';

export interface ITableColumn {
  field: string;
  header: string;
  sort: boolean;
  fieldType?: TableColumnTypeEnum;
  entity?: TableEntityEnum;
}

export interface ITableItem {
  data: any;
  index: number;
  action: string;
}
