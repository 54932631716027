import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Modaldata } from '@tfi-workspace-web/tfi-shared-utils';

@Component({
  selector: 'tfi-feat-product-brands-details-dialog',
  templateUrl: './brands-details-dialog.component.html',
  styleUrls: ['./brands-details-dialog.component.scss'],
})
export class BrandsDetailsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<BrandsDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modaldata
  ) {}

  cancel() {
    this.dialogRef.close();
  }
}
