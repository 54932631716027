export enum TableColumnTypeEnum {
  CURRENCY = 'CURRENCY',
  NUMBER = 'NUMBER',
  SHORT_DATE = 'SHORT_DATE',
  FULL_DATE = 'FULL_DATE',
  TAG = 'TAG',
  WEEK_DAY = 'WEEK_DAY',
}

export enum TableEntityEnum {
  SHIPPING = 'SHIPPING',
  PAYMENT = 'PAYMENT',
  TRANSACTION = 'TRANSACTION',
}
