export interface IFeSettingResponse {
  status: string;
  data: {
    response: {
      enableFE: boolean;
      settings: {
        documents: fiscalDocument[];
      };
    };
  };
}

export interface fiscalDocument {
  documentType: string;
  description: string;
  _id: string;
}

export enum TypeEnum {
  FC = 'FC',
  CCF = 'CCF',
  NA = 'N/A',
}
