import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ICategoriesResponse,
  ICategory,
  ICategoryResponse,
  categoryMethods,
  environment,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoryDataService {
  private host = environment.api_host;
  constructor(private http: HttpClient) {}

  getCategories(): Observable<ICategoriesResponse> {
    return this.http.get<ICategoriesResponse>(
      this.host + categoryMethods.category
    );
  }

  createCategory(category: ICategory): Observable<ICategoryResponse> {
    return this.http.post<ICategoryResponse>(
      this.host + categoryMethods.category,
      category
    );
  }

  updateCategory(
    categoryId: string,
    category: ICategory
  ): Observable<ICategoryResponse> {
    return this.http.put<ICategoryResponse>(
      this.host + categoryMethods.category + '/' + categoryId,
      category
    );
  }
}
