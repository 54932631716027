import { Injectable } from '@angular/core';
import {
  IRole,
  IRolesPostResponse,
  IRolesResponse,
} from '@tfi-workspace-web/tfi-shared-utils';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { RoleDataService } from '../data/role-data.service';
import { Observable, take } from 'rxjs';
import { RoleFilters } from '../../../constants/role-filters';

@Injectable({
  providedIn: 'root',
})
export class RoleViewService {
  private rolesSubject = new BehaviorSubject<IRole[]>([]);
  roles$ = this.rolesSubject.asObservable();
  private _totalElements = 0;

  get totalElements(): number {
    return this._totalElements;
  }

  constructor(private data: RoleDataService) {}

  getRoles(filterData: RoleFilters) {
    return this.data
      .getRoles(filterData)
      .pipe(take(1))
      .subscribe((response) => {
        this._totalElements = response.total;
        this.rolesSubject.next(response.data.roles);
      });
  }

  getRolesRaw(filterData: RoleFilters): Observable<IRolesResponse> {
    return this.data.getRoles(filterData);
  }

  createRole(payload: any): Observable<IRolesPostResponse> {
    return this.data.postRole(payload);
  }

  updateRole(payload: any, id: string): Observable<IRolesPostResponse> {
    return this.data.updateRole(payload, id);
  }

  updateRoleStatus(payload: any, id: string): Observable<IRolesPostResponse>{
    return this.data.changeRoleStatus(payload,id);
  }
}
