import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  ISelectOption,
  IShippingSend,
  OperationTimeOptions,
  TOAST_MESSAGES,
} from '@tfi-workspace-web/tfi-shared-utils';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ShippingViewService } from 'libs/tfi-feat-shipping-management/src/lib/services/shipping/view/shipping-view.service';
import { ToastrService } from 'ngx-toastr';
import { take, catchError, EMPTY } from 'rxjs';
import { CreateDriverDialogComponent } from '../create-driver-dialog/create-driver-dialog.component';

@Component({
  selector: 'gc-sidebar-shipping-basic-form',
  templateUrl: './sidebar-shipping-basic-form.component.html',
  styleUrls: ['./sidebar-shipping-basic-form.component.scss'],
})
export class SidebarShippingBasicFormComponent implements OnInit {
  @Output() hideSidebar = new EventEmitter<void>();
  @Output() updateDrivers = new EventEmitter<void>();
  @Input() isShippingSidebarVisible = false;
  @Input() selectedShipping?: any;
  @Input() driverNameInputOptions: ISelectOption[] = [];
  operationTime = OperationTimeOptions;
  minDate = new Date();
  customerName?: string;
  isLoading = false;

  form = new FormGroup({
    deliveryDay: new FormControl('', [Validators.required]),
    deliveryHour: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    shippingPrice: new FormControl(null, [
      Validators.required,
      Validators.min(0.1),
    ]),
    state: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    driverName: new FormControl('', [Validators.required]),
  });

  get deliveryDayFormControl() {
    return this.form.get('deliveryDay') as FormControl;
  }
  get deliveryHourFormControl() {
    return this.form.get('deliveryHour') as FormControl;
  }
  get addressFormControl() {
    return this.form.get('address') as FormControl;
  }
  get shippingPriceFormControl() {
    return this.form.get('shippingPrice') as FormControl;
  }
  get stateFormControl() {
    return this.form.get('state') as FormControl;
  }
  get cityFormControl() {
    return this.form.get('city') as FormControl;
  }
  get driverNameFormControl() {
    return this.form.get('driverName') as FormControl;
  }

  constructor(
    private router: Router,
    private shippingViewService: ShippingViewService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.patchForm();
  }

  patchForm() {
    this.customerName = this.selectedShipping.customer.name;
    this.deliveryDayFormControl.patchValue(
      new Date(this.selectedShipping?.deliveryDay as Date)
    );
    this.deliveryHourFormControl.patchValue(
      this.selectedShipping?.deliveryHour ?? ''
    );
    this.deliveryHourFormControl.patchValue(
      this.selectedShipping?.deliveryHour ?? ''
    );
    this.addressFormControl.patchValue(
      this.selectedShipping?.shippingAddress?.addressLine ?? ''
    );
    this.shippingPriceFormControl.patchValue(
      this.selectedShipping?.shippingPrice
    );
    this.stateFormControl.setValue(
      this.selectedShipping?.shippingAddress?.state ?? ''
    );
    this.cityFormControl.setValue(
      this.selectedShipping?.shippingAddress?.city ?? ''
    );
    this.driverNameFormControl.setValue(
      this.selectedShipping?.driverName ?? ''
    );
  }

  navigateToEditShipping() {
    this.router.navigate([
      `/admin/shipping-management/shipping/edit/${this.selectedShipping?._id}`,
    ]);
  }

  navigateToTicketTakePayment() {
    this.router.navigate([
      `admin/ticket-management/${this.selectedShipping?.ticketId}/take-payment`,
    ]);
  }

  onHide() {
    this.hideSidebar.emit();
  }

  onSubmitForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    if (this.isLoading) return;
    this.isLoading = true;

    const payload = {
      ...this.selectedShipping,
      ...this.form.value,
      shippingAddress: {
        addressLine: this.addressFormControl.value,
        state: this.stateFormControl.value,
        city: this.cityFormControl.value,
      },
      customer: this.selectedShipping.customer._id,
    } as IShippingSend;

    if (this.selectedShipping?._id && payload)
      this.shippingViewService
        .updateShipping(this.selectedShipping?._id, payload)
        .pipe(
          take(1),
          catchError((err) => {
            console.log(err);
            this.isLoading = false;
            this.toastr.error(TOAST_MESSAGES.ERROR);
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.isLoading = false;
          this.toastr.success(TOAST_MESSAGES.SUCCESS);
          this.onHide();
        });
  }

  onCreateNewDriver() {
    const dialogRef = this.dialog.open(CreateDriverDialogComponent, {
      minWidth: '360px',
      width: '85%',
      maxWidth: '575px',
      maxHeight: '90vh',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.driverNameFormControl.setValue(response);
        this.cdr.detectChanges();
        this.updateDrivers.emit();
      }
    });
  }
}
