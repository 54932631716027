<div class="component-cont">
  <div
    class="selected-img-container"
    [ngClass]="{
      'selected-img-container--large': imgSize === 'lg',
      'selected-img-container--med': imgSize === 'md'
    }"
  >
    <img
      [src]="selectedImg !== '' ? selectedImg : emptyImage"
      alt="selected-img"
    />
  </div>

  <div
    class="carousel-main-container"
    [ngClass]="{ 'carousel-main-container-hidden': gallery.length === 0 }"
  >
    <div
      class="carousel-main-container__previous-button swiper-button-prev"
    ></div>
    <swiper-container
      class="carousel-main-container-swiper"
      #swiperRef
      init="false"
    >
      <swiper-slide
        class="carousel-main-container-swiper-slide-cont"
        *ngFor="let prodImg of gallery; let i = index"
      >
        <img
          class="carousel-main-container-swiper-card"
          [ngClass]="{
            'carousel-main-container-swiper-card--isSelected':
              selectedImg === prodImg
          }"
          [src]="prodImg"
          alt="'image-'{{ i }}"
          (click)="selectedImg = prodImg"
        />
      </swiper-slide>
    </swiper-container>
    <div class="carousel-main-container__next-button swiper-button-next"></div>
  </div>
</div>
