import { Injectable } from '@angular/core';
import { PaymentDataService } from '../data/payment-data.service';
import { IPaymentPayload } from '../../../interfaces/payment-payload.interface';

@Injectable({
  providedIn: 'root',
})
export class PaymentViewService {
  constructor(private data: PaymentDataService) {}

  submitPayment(payload: IPaymentPayload) {
    return this.data.submitPayment(payload);
  }
}
