import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';

@Injectable({
  providedIn: 'root',
})
export class UserPermissonsGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthenticationViewService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const routePermissions: string[] = route.data['permissions'];

    const hasAccess = this.auth.hasPermissionLoose(routePermissions);

    if (hasAccess) {
      return true;
    } else {
      this.router.navigate(['/not-found']);
      return false;
    }
  }
}
