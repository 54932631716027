<p-table
  #dt
  [value]="data"
  [columns]="columns"
  responsiveLayout="scroll"
  [rows]="rows"
  [paginator]="showPaginator && !skeleton"
  [rowsPerPageOptions]="rowsPerPageOptions"
  [showCurrentPageReport]="showCurrentPageReport"
  [resizableColumns]="true"
  [autoLayout]="true"
  [selectionMode]="selectionMode"
  [rowHover]="rowHover && !skeleton"
  [(selection)]="selectedEntities"
  currentPageReportTemplate="Mostrando {first} hasta {last} de {totalRecords} registros"
  paginatorDropdownAppendTo="body"
  [scrollable]="true"
  scrollHeight="calc(60vh - 10vh)"
  (onRowSelect)="updateSelection()"
  (onRowUnselect)="updateSelection()"
  (onHeaderCheckboxToggle)="updateSelection()"
>
  <ng-template pTemplate="header">
    <tr>
      <th *ngIf="enableCheckBox" style="width: 3rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <ng-container *ngFor="let item of columns">
        <th
          [pSortableColumn]="item.field"
          [pSortableColumnDisabled]="!item.sort"
          class="gc-table-hader"
        >
          {{ item.header }}
          <p-sortIcon *ngIf="item.sort" [field]="item.field"></p-sortIcon>
        </th>
      </ng-container>
      <th *ngIf="options"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-dataItem let-rowIndex="rowIndex">
    <tr>
      <td *ngIf="enableCheckBox">
        <div *ngIf="!skeleton">
          <p-tableCheckbox [value]="dataItem"></p-tableCheckbox>
        </div>
        <div *ngIf="skeleton" [ngClass]="{ skeleton: skeleton }">XXXX</div>
      </td>
      <ng-container *ngFor="let field of columns">
        <td [ngSwitch]="field.fieldType">
          <ng-container *ngSwitchCase="columnType.CURRENCY">
            <span [ngClass]="{ skeleton: skeleton }">
              {{ dataItem[field.field] | currency : 'USD' }}
            </span>
          </ng-container>
          <ng-container *ngSwitchCase="columnType.SHORT_DATE">
            <span [ngClass]="{ skeleton: skeleton }">
              {{ dataItem[field.field] | date : 'YYYY-MM-dd' : '' : 'es-SV' }}
            </span>
          </ng-container>
          <ng-container *ngSwitchCase="columnType.FULL_DATE">
            <span [ngClass]="{ skeleton: skeleton }">
              {{ dataItem[field.field] | date }}
            </span>
          </ng-container>
          <ng-container *ngSwitchCase="columnType.WEEK_DAY">
            <span [ngClass]="{ skeleton: skeleton }">
              {{ dataItem[field.field] | date : 'EEEE' | titlecase }}
            </span>
          </ng-container>
          <ng-container *ngSwitchCase="columnType.TAG">
            <ng-contain [ngSwitch]="field.entity">
              <ng-container *ngSwitchCase="entityType.SHIPPING">
                <ng-contain [ngSwitch]="dataItem[field.field]">
                  <gc-badge
                    [skeleton]="skeleton"
                    *ngSwitchCase="'CREATED'"
                    text="Creado"
                    size="lg"
                    mode="outline"
                    backgroundColor="grey-500"
                    textColor="base-black"
                  ></gc-badge>
                  <gc-badge
                    [skeleton]="skeleton"
                    *ngSwitchCase="'IN_TRANSIT'"
                    text="Envio"
                    size="lg"
                    backgroundColor="primary-500"
                    textColor="base-white"
                  ></gc-badge>
                  <gc-badge
                    [skeleton]="skeleton"
                    *ngSwitchCase="'DELIVERED'"
                    text="Entregado"
                    size="lg"
                    backgroundColor="green-500"
                    textColor="base-white"
                  ></gc-badge>
                  <gc-badge
                    [skeleton]="skeleton"
                    *ngSwitchCase="'NO_DELIVERED'"
                    text="No retirado"
                    size="lg"
                    backgroundColor="red-500"
                    textColor="base-white"
                  ></gc-badge>
                  <gc-badge
                    [skeleton]="skeleton"
                    *ngSwitchCase="'PAID'"
                    text="Deposito"
                    size="lg"
                    backgroundColor="base-white"
                    textColor="base-black"
                  ></gc-badge>
                  <gc-badge
                    [skeleton]="skeleton"
                    *ngSwitchCase="'RE_DELIVERED'"
                    text="Re-envio"
                    size="lg"
                    backgroundColor="green-500"
                    textColor="base-white"
                  ></gc-badge>
                  <gc-badge
                    [skeleton]="skeleton"
                    *ngSwitchDefault
                    [text]="dataItem[field.field]"
                    size="lg"
                    mode="outline"
                    backgroundColor="grey-500"
                    textColor="base-black"
                  ></gc-badge>
                </ng-contain>
              </ng-container>
              <ng-container *ngSwitchCase="entityType.PAYMENT">
                <ng-contain [ngSwitch]="dataItem[field.field]">
                  <gc-badge
                    [skeleton]="skeleton"
                    *ngSwitchCase="'ISSUED'"
                    text="Creado"
                    size="lg"
                    mode="outline"
                    backgroundColor="grey-500"
                    textColor="base-black"
                  ></gc-badge>
                  <gc-badge
                    [skeleton]="skeleton"
                    *ngSwitchCase="'PENDING'"
                    text="Pendiente"
                    size="lg"
                    backgroundColor="primary-500"
                    textColor="base-white"
                  ></gc-badge>
                  <gc-badge
                    [skeleton]="skeleton"
                    *ngSwitchCase="'PAID'"
                    text="Pagado"
                    size="lg"
                    backgroundColor="green-500"
                    textColor="base-white"
                  ></gc-badge>
                  <gc-badge
                    [skeleton]="skeleton"
                    *ngSwitchCase="'OVER_DUE'"
                    text="Vencido"
                    size="lg"
                    backgroundColor="red-500"
                    textColor="base-white"
                  ></gc-badge>
                  <gc-badge
                    [skeleton]="skeleton"
                    *ngSwitchDefault
                    [text]="dataItem[field.field]"
                    size="lg"
                    mode="outline"
                    backgroundColor="grey-500"
                    textColor="base-black"
                  ></gc-badge>
                </ng-contain>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <gc-badge
                  [skeleton]="skeleton"
                  [text]="dataItem[field.field]"
                  size="lg"
                  mode="outline"
                  backgroundColor="grey-500"
                  textColor="base-black"
                ></gc-badge>
              </ng-container>
            </ng-contain>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <span [ngClass]="{ skeleton: skeleton }">
              {{ dataItem[field.field] }}</span
            >
          </ng-container>
        </td>
      </ng-container>
      <td *ngIf="options">
        <div [ngClass]="{ skeleton: skeleton }">
          <gc-menu
            [options]="options"
            position="top"
            (selectedOption)="setAction($event, dataItem, rowIndex)"
          ></gc-menu>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
