export interface IOptions {
  key: string;
  value: string;
}

export interface ISelectOption {
  label: string;
  value: any;
  stateDescription?: string;
  stateCode?: string;
  cityDescription?: string;
  cityCode?: string;
  districtDescription?: string;
  districtCode?: string;
}
