import { entityCodeEnum, entityLabelEnum } from '../enums/roles-entities.enum';

export const EntitiesDescription = {
  [entityCodeEnum.APT]: entityLabelEnum.transactions,
  [entityCodeEnum.INVOICE]: entityLabelEnum.billing,
  [entityCodeEnum.BRANCH]: entityLabelEnum.branches,
  [entityCodeEnum.BRAND]: entityLabelEnum.brands,
  [entityCodeEnum.CATEGORY]: entityLabelEnum.categories,
  [entityCodeEnum.COMPANY]: entityLabelEnum.company,
  [entityCodeEnum.CUSTOMER]: entityLabelEnum.customers,
  [entityCodeEnum.INVENTORY]: entityLabelEnum.inventory,
  [entityCodeEnum.KITCHEN_ORDER]: entityLabelEnum.KDS,
  [entityCodeEnum.PRODUCT]: entityLabelEnum.products,
  [entityCodeEnum.SHIPPING]: entityLabelEnum.shippings,
  [entityCodeEnum.SHIPPING_COMPANY]: entityLabelEnum.shippings_company,
  [entityCodeEnum.TICKET]: entityLabelEnum.ticket,
  [entityCodeEnum.USER]: entityLabelEnum.users,
  [entityCodeEnum.VENDOR]: entityLabelEnum.vendors,
  [entityCodeEnum.ROLE]: entityLabelEnum.roles,
};
