import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessManagementWrapperComponent } from './business-management-wrapper.component';
import { GCComponentsModule } from '@gc-tech/components';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { UserDetailComponent } from './components/user-management/user-detail/user-detail.component';
import { UserEditComponent } from './components/user-management/user-edit/user-edit.component';
import { BranchManagementComponent } from './components/branch-management/branch-management.component';
import { BranchEditDialogComponent } from './components/branch-management/branch-edit-dialog/branch-edit-dialog.component';
import { BranchViewDetailsDialogComponent } from './components/branch-management/branch-view-details-dialog/branch-view-details-dialog.component';
import { CompanyManagementComponent } from './components/company-management/company-management.component';
import { TransactionManagementComponent } from './components/transaction-management/transaction-management.component';
import { BillingManagementComponent } from './components/billing-management/billing-management.component';
import { BillingPaymentSelectionDialogComponent } from './components/billing-management/billing-payment-selection-dialog/billing-payment-selection-dialog.component';
import { WirePaymentDialogComponent } from './components/billing-management/wire-payment-dialog/wire-payment-dialog.component';
import { CreditCardPaymentDialogComponent } from './components/billing-management/credit-card-payment-dialog/credit-card-payment-dialog.component';
import { TransactionTableComponent } from './components/transaction-management/transaction-table/transaction-table.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RoleManagementComponent } from './components/role-management/role-management.component';
import { RoleTableComponent } from './components/role-management/components/role-table/role-table.component';
import { RoleFormComponent } from './components/role-management/components/role-form/role-form.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CatalogConfigComponent } from './components/company-management/catalog-config/catalog-config.component';
import { PaymentGatewaysConfigComponent } from './components/company-management/payment-gateways-config/payment-gateways-config.component';
@NgModule({
  imports: [
    CommonModule,
    GCComponentsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    ScrollingModule,
  ],
  declarations: [
    BusinessManagementWrapperComponent,
    UserManagementComponent,
    UserDetailComponent,
    UserEditComponent,
    BranchManagementComponent,
    BranchEditDialogComponent,
    BranchViewDetailsDialogComponent,
    CompanyManagementComponent,
    TransactionManagementComponent,
    BillingManagementComponent,
    BillingPaymentSelectionDialogComponent,
    WirePaymentDialogComponent,
    CreditCardPaymentDialogComponent,
    TransactionTableComponent,
    RoleManagementComponent,
    RoleTableComponent,
    RoleFormComponent,
    CatalogConfigComponent,
    PaymentGatewaysConfigComponent,
  ],
  exports: [
    BusinessManagementWrapperComponent,
    UserManagementComponent,
    RoleManagementComponent,
  ],
})
export class TfiFeatBusinessManagementModule {}
