import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CompanyDataService } from '@tfi-workspace-web/tfi-feat-business-management';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';
import {
  ClaimsEnum,
  CompanyIndustryEnum,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KdsAccessGuard implements CanActivate {
  constructor(
    private router: Router,
    private companyDataService: CompanyDataService,
    private authViewService: AuthenticationViewService
  ) {}

  canActivate(): Observable<boolean> {
    return this.companyDataService.getCompany().pipe(
      map(({ data }) => {
        const userRoles = this.authViewService.userProfile.claims;
        if (
          data.company.industry === CompanyIndustryEnum.FOOD &&
          data.company.businessParams?.enableKDS.value &&
          userRoles.includes(ClaimsEnum.KITCHEN_DISPLAY)
        ) {
          return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
      }),
      catchError(() => {
        this.router.navigate(['/']);
        return of(false);
      })
    );
  }
}
