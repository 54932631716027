<div
  class="product-detail-main-container"
  *ngIf="product && !isLoading; else notFound"
>
  <div class="gallery-container">
    <gc-image-gallery [mainImg]="mainImg" [gallery]="images"></gc-image-gallery>
  </div>
  <div class="product-detail-container">
    <span class="product-detail-container__internal-sku">{{
      product.internalSKU
    }}</span>
    <h2 class="product-detail-container__name">{{ product.productName }}</h2>
    <span class="product-detail-container__price">{{
      product.price | currency
    }}</span>
    <span class="product-detail-container__desc">{{
      product.description
    }}</span>
    <div class="contact-us-container">
      <div class="contact-us-container__element">
        <img
          class="icon-container"
          src="/assets/icons/whatsapp-grey.svg"
          alt="whatsapp"
        />
        <span
          >Para ordenar este producto contactenos
          <gc-link text="Whatsapp" (click)="openWhatsapp()"></gc-link
        ></span>
      </div>
    </div>
  </div>
</div>

<ng-template #notFound>
  <div *ngIf="!isLoading" class="not-found-container">
    Producto no encontrado
  </div>
</ng-template>
