import {
  PaymentTypeEnum,
  discountTypeEnum,
  IShipping,
} from '@tfi-workspace-web/tfi-shared-utils';
import { CurrencyEnum } from '../enums/currency.enum';
import { IBaseEntity } from './base-entity.interface';

export interface ITicket extends IBaseEntity {
  ticketNumber: number;
  tableNumber?: string | null;
  company: string;
  customer: any;
  location: string;
  documentType: DocumentTypeEnum;
  user: string;
  taxId: string;
  operationType: 1 | -1;
  paymentDetail: IPaymentDetail[];
  status: TicketStatusEnum;
  deliverOption: DeliverOptionEnum;
  deliverAddress?: string;
  totalTaxAmount: number;
  totalCostAmount: number;
  totalAmountBeforeTax: number;
  totalAmount: number;
  totalDiscount: number;
  currency: CurrencyEnum;
  taxDetail: ITicketTaxDetail[];
  ticketDetail: any[];
  dueAmount: number;
  shippingInfo?: IShippingInfo;
  attachments?: {
    paymentReceipt?: string;
  };
  shippingDetail?: IShipping;
  hasDTE?: boolean;
}

export interface IShippingInfo {
  date: Date;
  time: string;
  requestPayment: boolean;
  contactInfo: {
    name: string;
    phoneNumber: string;
    email?: string;
  };
  address: {
    addressLine: string;
    state: string;
    stateCode: string;
    city: string;
    cityCode: string;
    district: string;
    districtCode: string;
    country: string;
  };
}

export enum DocumentTypeEnum {
  TICKET = 'TICKET',
  CCF = 'CCF',
  FAC = 'FAC',
  FAE = 'FAE',
  NC = 'NC',
  ND = 'ND',
}

export enum TicketStatusEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
  REFUNDED = 'REFUNDED',
}

export const ticketStatusLabels: any = {
  OPEN: 'Abierto',
  CLOSED: 'Cerrado',
  CANCELLED: 'Cancelado',
  REFUNDED: 'Reintegrado',
};

export enum DeliverOptionEnum {
  TO_GO = 'TO_GO',
  IN_PLACE = 'IN_PLACE',
  DELIVERY = 'DELIVERY',
}

export const deliveryOptionLabels: any = {
  TO_GO: 'Para llevar',
  IN_PLACE: 'En sitio',
  DELIVERY: 'A domicilio',
};

export type PatchTicket = Partial<ITicket>;

export interface ITicketsResponse {
  status: string;
  results: number;
  page: number;
  result: number;
  total: number;
  totalPages: number;
  data: {
    tickets: ITicket[];
  };
}

export interface ITicketResponse {
  status: string;
  results: number;
  page: number;
  result: number;
  total: number;
  totalPages: number;
  data: {
    ticket: ITicket;
  };
}

export interface ITicketSaleResponse {
  status: string;
  data: {
    ticket: ITicket;
    shipping: IShipping;
  };
}

export interface IPaymentDetail extends IBaseEntity {
  paymentType: PaymentTypeEnum;
  paymentAmount: number;
  paymentChange: number;
  checkNumber?: string;
  creditCardDetail?: {
    creditCardToken: string;
    creditCardBrand: string;
    lastFourDigits: string;
    dueMonth: string;
    dueYear: string;
  };
}

export interface ITicketDetail extends IBaseEntity {
  product: string;
  quantity: number;
  hasDiscount: boolean;
  discountType: discountTypeEnum;
  discountValue: number;
  discountPercentage: number;
  notes?: string;
}

export interface ITicketTaxDetail {
  taxType: string;
  taxPercentage: number;
  taxAmount: number;
}

export interface ITicketRetResponse {
  status: string;
  data: {
    ticket: ITicket;
  };
}
