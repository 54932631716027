import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CrudActionsEnum,
  IRole,
  ISelectOption,
  IUser,
  Modaldata,
  TOAST_MESSAGES,
} from '@tfi-workspace-web/tfi-shared-utils';
import { ToastrService } from 'ngx-toastr';
import {
  catchError,
  EMPTY,
  map,
  mergeMap,
  of,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { BusinessManagementViewService } from '../../../services/business-management-view.service';
import { BranchViewService } from '../../../services/branch/view/branch-view.service';
import { RoleViewService } from '../../../services/role/view/role-view.service';
import { RoleFilters } from '../../../constants/role-filters';

@Component({
  selector: 'tfi-feat-bm-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit {
  isLoading = false;
  formTitle?: string;
  errorMsg?: string;
  showPassControls = false;
  actions = CrudActionsEnum;
  filters = new RoleFilters();
  roles: IRole[] = [];
  form: FormGroup;
  user: IUser;
  destroy$: Subject<boolean> = new Subject<boolean>();
  branchesInputOptions: ISelectOption[] = [];
  roleInputOptions: ISelectOption[] = [];

  constructor(
    public dialogRef: MatDialogRef<UserEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modaldata,
    private view: BusinessManagementViewService,
    private toastr: ToastrService,
    private branchesViewService: BranchViewService,
    private roleViewService: RoleViewService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getRoles();
    this.getBranches();
    if (this.data.crudAction === CrudActionsEnum.UPDATE) {
      this.user = this.data.modalData as IUser;
      this.setFormData(this.user);
      this.handlePasswordForm();
      this.formTitle = 'Actualiza un usuario existente';
    } else if (this.data.crudAction === CrudActionsEnum.CREATE) {
      this.addPasswordControls();
      this.showPassControls = true;
      this.formTitle = 'Crea un nuevo usuario';
    }
  }

  initForm() {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl('', [Validators.required]),
      branch: new FormControl('', [Validators.required]),
      showPasswordControls: new FormControl(false),
      country: new FormControl('El Salvador'),
      userRole: new FormControl(null, [Validators.required]),
    });
  }

  get nameControl() {
    return this.form.get('name') as FormControl;
  }

  get emailControl() {
    return this.form.get('email') as FormControl;
  }
  get phoneNumberControl() {
    return this.form.get('phoneNumber') as FormControl;
  }
  get branchControl() {
    return this.form.get('branch') as FormControl;
  }

  get passwordControl() {
    return this.form.get('password') as FormControl;
  }

  get confirmPasswordControl() {
    return this.form.get('confirmPassword') as FormControl;
  }

  get userRoleControl() {
    return this.form.get('userRole') as FormControl;
  }

  setFormData(user: IUser) {
    this.form.patchValue({
      name: user.name,
      email: user.email,
      phoneNumber: user.phoneNumber,
    });
    this.form.markAsPristine();
  }

  getRoles(): void {
    this.filters.limit = 100;
    this.roleViewService.getRoles(this.filters);
    this.roleViewService.roles$
      .pipe(
        takeUntil(this.destroy$),
        map((roles) =>
          roles.map((rol) => {
            return {
              label: rol.name,
              value: rol._id,
            } as ISelectOption;
          })
        )
      )
      .subscribe((roleData) => {
        if (roleData && roleData?.length > 0) {
          if (this.data.crudAction === CrudActionsEnum.UPDATE) {
            setTimeout(() => {
              this.userRoleControl.setValue(this.user?.userRole?._id);
            }, 500);
          }
          this.roleInputOptions = roleData;
        }
      });
  }

  getBranches() {
    this.branchesViewService.getBranches();
    this.branchesViewService.branches$
      .pipe(
        takeUntil(this.destroy$),
        map((branches) =>
          branches.map((c) => {
            return {
              label: `${c.branchCode} - ${c.branchName}`,
              value: c._id,
            } as ISelectOption;
          })
        )
      )
      .subscribe((branches) => {
        this.branchesInputOptions = branches;
        setTimeout(() => {
          this.branchControl.setValue(this.user?.branch?._id || '');
        }, 500);
      });
  }

  addPasswordControls() {
    this.form.addControl(
      'password',
      new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d).+$/),
      ])
    );
    this.form.addControl(
      'confirmPassword',
      new FormControl('', Validators.required)
    );
    this.form.addValidators(
      this.isPasswordEqual('password', 'confirmPassword')
    );
  }

  removePasswordControls() {
    this.form.removeControl('password');
    this.form.removeControl('confirmPassword');
  }

  saveUser() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    if (this.isLoading) return;
    if (this.data.crudAction === CrudActionsEnum.UPDATE) {
      this.updateUser();
    } else if (this.data.crudAction === CrudActionsEnum.CREATE) {
      this.createUser();
    }
  }

  updateUser() {
    this.isLoading = true;
    const id = this.user._id ?? '';
    this.view
      .patchUser(id, this.form.value)
      .pipe(
        take(1),
        catchError(() => {
          this.isLoading = false;
          this.toastr.error(TOAST_MESSAGES.ERROR);
          return EMPTY;
        }),
        mergeMap((res) => {
          if (res?.status === 'success') {
            return this.view.updateUserRole(
              { roleId: this.userRoleControl.value },
              res.data.user._id ?? ''
            );
          } else {
            return of(res);
          }
        })
      )
      .subscribe(() => this.dialogRef.close(true));
  }

  createUser() {
    this.isLoading = true;
    this.view
      .createUser(this.form.value)
      .pipe(
        take(1),
        catchError(() => {
          this.isLoading = false;
          this.toastr.error(TOAST_MESSAGES.ERROR);
          return EMPTY;
        }),
        mergeMap((res) => {
          if (res?.status === 'success') {
            return this.view.updateUserRole(
              { roleId: this.userRoleControl.value },
              res.data.user._id ?? ''
            );
          } else {
            return of(res);
          }
        })
      )
      .subscribe(() => this.dialogRef.close(true));
  }

  isPasswordEqual(field1: string, field2: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const fieldValue1 = formGroup.get(field1)?.value;
      const fieldValue2 = formGroup.get(field2)?.value;
      if (fieldValue1 !== fieldValue2) {
        formGroup.get(field2)?.setErrors({ notEqual: true });
        return { notEqual: true };
      }
      formGroup.get(field2)?.setErrors(null);
      return null;
    };
  }

  handlePasswordForm() {
    this.form.controls['showPasswordControls'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        res ? this.addPasswordControls() : this.removePasswordControls();
        this.showPassControls = res;
      });
  }
  closeModal(): void {
    this.dialogRef.close();
  }
}
