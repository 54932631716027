import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IBrand,
  IBrandResponse,
  IBrandsResponse,
  brandsMethods,
  environment,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrandsDataService {
  private host = environment.api_host;
  constructor(private http: HttpClient) {}

  getBrands(): Observable<IBrandsResponse> {
    return this.http.get<IBrandsResponse>(this.host + brandsMethods.brand);
  }

  createBrand(brand: IBrand): Observable<IBrandResponse> {
    return this.http.post<IBrandResponse>(
      this.host + brandsMethods.brand,
      brand
    );
  }

  updateBrand(brandId: string, brand: IBrand): Observable<IBrandResponse> {
    return this.http.put<IBrandResponse>(
      this.host + brandsMethods.brand + '/' + brandId,
      brand
    );
  }
}
