import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ISelectOption, ITableItem } from '@gc-tech/components';
import {
  DownloadPdfService,
  IBrand,
  ICategory,
  IProduct,
  TOAST_MESSAGES,
  TableMenuActionsEnum,
  UserPermissionsEnum,
  productStatusOptions,
} from '@tfi-workspace-web/tfi-shared-utils';
import { EMPTY, Subject, catchError, switchMap, take, takeUntil } from 'rxjs';
import { ProductViewService } from '../../services/view/product-view/product-view.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductFilters } from '../../constants/product.filter';
import { ZebraPrintingService } from '@gc-tech/devices-utils';
import { MatDialog } from '@angular/material/dialog';
import { ProductUpdateStatus } from '@tfi-workspace-web/tfi-feat-product-management';
import { ToastrService } from 'ngx-toastr';
import { DownloadUploadDialogComponent } from 'libs/gc-components-lib/src/lib/components/download-upload-dialog/download-upload-dialog.component';
import { Sidebar } from 'primeng/sidebar';
import { BrandsViewService } from '../../services/view/brands-view/brands-view.service';
import { CategoryViewService } from '../../services/view/category-view/category-view.service';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';

@Component({
  selector: 'tfi-feat-product-product-wrapper',
  templateUrl: './product-wrapper.component.html',
  styleUrls: ['./product-wrapper.component.scss'],
  animations: [
    trigger('slideInFromRight', [
      state('void', style({ transform: 'translateX(100%)' })),
      state('*', style({ transform: 'translateX(0)' })),
      transition('void => *', animate('300ms ease-out')),
    ]),
  ],
})
export class ProductWrapperComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('statusModal') statusModal!: TemplateRef<any>;
  @ViewChild('confirmationModal') confirmationModal!: TemplateRef<any>;
  @ViewChild('mobileCardsContainer') mobileCardsContainer!: ElementRef;
  showFilters = false;
  isSearching = false;
  isUpdatingStatus = false;
  productStatOpt = productStatusOptions;
  categoryInputOptions: ISelectOption[] = [];
  brandInputOptions: ISelectOption[] = [];
  products: IProduct[] = [];
  batchUpdateProducts: IProduct[] = [];
  filters: ProductFilters;
  prodStatusEnum = ProductUpdateStatus;
  statusModalForm = new FormGroup({
    modalSwCard: new FormControl(null, [Validators.required]),
  });
  uploadFile?: File;
  batchResultMessage = '';
  editSidebarVisibility = false;
  widthBreakpoint = 1000;
  screenWidth?: number;
  mobileProductSelected?: IProduct;
  isEditing = false;
  showCatalogModal = false;
  showStatusModal = false;
  showCatalogModalForm = new FormGroup({
    showCatalog: new FormControl(null, [Validators.required]),
  });
  userPermissionsEnum = UserPermissionsEnum;
  @ViewChildren('ediProductSidebar') ediProductSidebar?: Sidebar;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenWidth = window.innerWidth;
    this.validateScreenWidthForSideBar();
  }
  totalTableElements?: number;

  form = new FormGroup({
    productStateFilter: new FormControl(false),
    productBrandFilter: new FormControl(''),
    productCategoryFilter: new FormControl(''),
  });

  formSearch = new FormGroup({ searchFilter: new FormControl('') });

  constructor(
    private downloadPdfService: DownloadPdfService,
    private productViewService: ProductViewService,
    private categoryViewService: CategoryViewService,
    private brandViewService: BrandsViewService,
    private router: Router,
    private zbPrintingService: ZebraPrintingService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private auth: AuthenticationViewService
  ) {
    this.filters = new ProductFilters();
  }

  ngOnInit(): void {
    this.getFiltersFromStorage();
    this.getCategories();
    this.getBrands();
    this.isSearching = true;
    this.productViewService.getProducts(this.filters);
    this.productViewService.products$
      .pipe(takeUntil(this.destroy$))
      .subscribe((products) => {
        const productsMapped = this.mapProducts(products);
        this.products = productsMapped;
        this.totalTableElements = this.productViewService.totalElements;
        this.isSearching = false;
        this.scrollToTop();
      });
  }

  getCategories() {
    this.categoryViewService.categories$
      .pipe(takeUntil(this.destroy$))
      .subscribe((categories) => {
        this.updateCategoriesInputOptions(categories);
      });
    this.categoryViewService.getCategories();
  }

  updateCategoriesInputOptions(categories: ICategory[]) {
    categories.forEach((category) => {
      if (!this.categoryInputOptions.some((c) => c.value === category._id)) {
        this.categoryInputOptions = [
          ...this.categoryInputOptions,
          {
            label: category.categoryName,
            value: category.categoryName,
          },
        ];
      }
    });
  }

  getBrands() {
    this.brandViewService.brands$
      .pipe(takeUntil(this.destroy$))
      .subscribe((brands) => {
        this.updateBrandsInputOptions(brands);
      });
    this.brandViewService.getBrands();
  }

  updateBrandsInputOptions(brands: IBrand[]) {
    brands.forEach((brand) => {
      if (!this.brandInputOptions.some((c) => c.value === brand._id)) {
        this.brandInputOptions = [
          ...this.brandInputOptions,
          {
            label: brand.brandName,
            value: brand.brandName,
          },
        ];
      }
    });
  }

  getMargin(prod: IProduct): string {
    const prCost = prod.cost || 0;
    return (((prod.price - prCost) / prod.price) * 100).toFixed(2);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.saveFiltersInStorage(this.filters);
  }

  createNewProduct() {
    this.router.navigate(['/admin/product-management/product/new']);
  }

  async tableMenuSelected(itemData: ITableItem) {
    if (itemData.action === TableMenuActionsEnum.EDIT) {
      this.router.navigate([
        `/admin/product-management/product/${itemData.data._id}`,
      ]);
    } else {
      if (itemData.data.serialCode)
        await this.zbPrintingService.printCodeBar(itemData.data.serialCode);
    }
  }

  search(search: string) {
    this.isSearching = true;
    this.filters.productName = search;
    this.filters.page = 1;
    this.productViewService.getProducts(this.filters);
    this.saveFiltersInStorage(this.filters);
  }

  toogleFilters(): void {
    this.showFilters = !this.showFilters;
  }

  onFilterByStatus(event: ISelectOption) {
    this.filters.isEnabled = event.value ? 'true' : 'false';
    this.filters.page = 1;
    this.productViewService.getProducts(this.filters);
    this.saveFiltersInStorage(this.filters);
  }

  onFilterByBrand(event: ISelectOption) {
    this.filters.brandName = event.value;
    this.filters.page = 1;
    this.productViewService.getProducts(this.filters);
    this.saveFiltersInStorage(this.filters);
  }

  onFilterByCateogry(event: ISelectOption) {
    this.filters.categoryName = event.value;
    this.filters.page = 1;
    this.productViewService.getProducts(this.filters);
    this.saveFiltersInStorage(this.filters);
  }

  clearFilters() {
    this.form.controls.productStateFilter.setValue(null);
    this.form.controls.productBrandFilter.setValue(null);
    this.form.controls.productCategoryFilter.setValue(null);
    this.formSearch.controls.searchFilter.setValue(null);
    Object.keys(this.filters).forEach((field) => {
      if (field === 'page' || field === 'limit') return;
      if (this.filters[field]) this.filters[field] = '';
    });
    this.productViewService.getProducts(this.filters);
    this.saveFiltersInStorage(this.filters);
  }

  mapProducts(products: IProduct[]): IProduct[] {
    return products.map((product: IProduct) => {
      return {
        ...product,
        catName: product.category.categoryName,
        braName: product.brand.brandName,
        margin: this.getMargin(product),
      };
    });
  }

  openUpdateProductModal(): void {
    const dialogRef = this.dialog.open(this.statusModal, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'tfi-status-modal-container',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.statusModalForm.controls.modalSwCard.patchValue(null);
      });
  }

  executeBatchUpdate(): void {
    this.isUpdatingStatus = true;
    const prodIdArr = this.batchUpdateProducts.map(
      (prod: IProduct) => prod._id
    ) as string[];
    const payload = {
      products: prodIdArr,
      action: this.statusModalForm.controls.modalSwCard.value,
    };
    this.productViewService
      .updateProdStatus(payload)
      .pipe(
        take(1),
        catchError(() => {
          this.isUpdatingStatus = false;
          this.toastr.error(TOAST_MESSAGES.ERROR);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.toastr.success(TOAST_MESSAGES.SUCCESS);
        this.closeAllModals();
        this.isUpdatingStatus = false;
        this.statusModalForm.controls.modalSwCard.patchValue(null);
        this.batchUpdateProducts = [];
        this.productViewService.getProducts(this.filters);
      });
  }

  openUploadModal() {
    const dialogRef = this.dialog.open(DownloadUploadDialogComponent, {
      width: '90%',
      maxWidth: '700px',
      data: {
        title: 'Importar productos desde archivo de Excel',
        subtitle:
          'Actualiza los registros de productos utilizando un archivo de Excel',
        primaryButtonLabel: 'Importar',
        secondaryButtonLabel: 'Cancelar',
        thirdButtonLabel: 'Descargar',
      },
    });

    dialogRef.componentInstance.fileLoadedEmitter.subscribe((file) => {
      this.uploadFile = file;
    });
    dialogRef.componentInstance.primaryButtonClicked.subscribe(() => {
      if (this.uploadFile) {
        this.dialog.closeAll();
        this.isUpdatingStatus = true;
        this.dialog.open(this.confirmationModal, {
          disableClose: true,
        });
        this.productViewService
          .bulkCreation(this.uploadFile)
          .pipe(
            take(1),
            catchError(() => {
              this.isUpdatingStatus = false;
              this.productViewService.getProducts(this.filters);
              this.batchResultMessage =
                'Ha ocurrido un error en la importacion de productos';
              return EMPTY;
            })
          )
          .subscribe((res) => {
            if (res.file) {
              this.downloadPdfService.downloadFileExcel(res.file, `Errores`);
            }
            this.batchResultMessage = res.message;
            this.isUpdatingStatus = false;
            this.productViewService.getProducts(this.filters);
          });
      }
    });
    dialogRef.componentInstance.secondaryButtonClicked.subscribe(() => {
      this.dialog.closeAll();
    });
    dialogRef.componentInstance.thirdButtonClicked.subscribe(() => {
      dialogRef.componentInstance.isDownloading = true;
      this.productViewService
        .downloadTemplateProd()
        .pipe(
          take(1),
          catchError(() => {
            this.toastr.error(TOAST_MESSAGES.ERROR);
            this.isSearching = false;
            dialogRef.componentInstance.isDownloading = false;
            return EMPTY;
          })
        )
        .subscribe((blob: Blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'product_template.xlsx';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          dialogRef.componentInstance.isDownloading = false;
        });
    });
  }

  closeAllModals(): void {
    this.dialog.closeAll();
    this.showCatalogModal = false;
    this.showStatusModal = false;
    this.showCatalogModalForm.controls.showCatalog.patchValue(null);
  }

  updateImageProduct(product: IProduct) {
    if (this.isSearching) return;
    this.isSearching = true;
    if (this.isSearching && product._id) {
      this.productViewService
        .updateProduct(product._id, product)
        .pipe(
          take(1),
          switchMap(async () => {
            this.productViewService.getProducts(this.filters);
            this.isSearching = false;
            this.toastr.success(TOAST_MESSAGES.SUCCESS);
            this.dialog.closeAll();
          }),
          catchError(() => {
            this.isSearching = false;
            this.dialog.closeAll();
            this.toastr.error(TOAST_MESSAGES.ERROR);
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  validateScreenWidthForSideBar() {
    if (this.screenWidth && this.screenWidth > this.widthBreakpoint) {
      this.editSidebarVisibility = false;
    }
  }

  pageChanged(page: number) {
    this.filters.page = page;
    this.productViewService.getProducts(this.filters);
    this.saveFiltersInStorage(this.filters);
  }

  limitPerPageChange(limitPerPage: number) {
    this.filters.limit = limitPerPage;
    this.filters.page = 1;
    this.productViewService.getProducts(this.filters);
    this.saveFiltersInStorage(this.filters);
  }

  saveFiltersInStorage(filters: ProductFilters) {
    localStorage.setItem('productFilters', JSON.stringify(filters));
  }

  getFiltersFromStorage() {
    const filtersExist = localStorage?.getItem('productFilters');
    if (filtersExist) {
      this.filters = JSON.parse(filtersExist);
      let isFilterApplied = false;
      this.filters = JSON.parse(filtersExist);
      Object.entries(this.filters).forEach(([key, value]) => {
        if (
          !isFilterApplied &&
          key !== 'page' &&
          key !== 'limit' &&
          key !== 'productName'
        ) {
          isFilterApplied = value !== '';
        }
      });
      this.showFilters = isFilterApplied;
      switch (this.filters.isEnabled) {
        case 'true':
          this.form.controls.productStateFilter.setValue(true);
          break;
        case 'false':
          this.form.controls.productStateFilter.setValue(false);
          break;
        default:
          this.form.controls.productStateFilter.setValue(null);
          break;
      }
      if (this.filters.brandName)
        this.form.controls.productBrandFilter.setValue(this.filters.brandName);
      if (this.filters.categoryName)
        this.form.controls.productCategoryFilter.setValue(
          this.filters.categoryName
        );
      if (this.filters.productName)
        this.formSearch.controls.searchFilter.setValue(
          this.filters.productName
        );
    }
  }

  openEditMobileSidebar(product: IProduct) {
    if (this.auth.hasPermissionStrict([this.userPermissionsEnum.USR_PRD_EDT])) {
      this.mobileProductSelected = product;
      this.editSidebarVisibility = true;
    }
  }

  onHideProductSidebar() {
    this.editSidebarVisibility = false;
    this.mobileProductSelected = undefined;
    this.productViewService.getProducts(this.filters);
  }

  onBulkShowCatalog() {
    this.isUpdatingStatus = true;
    const prodIdArr = this.batchUpdateProducts.map(
      (prod: IProduct) => prod._id
    ) as string[];
    const payload = {
      productsId: prodIdArr,
      showInCatalog:
        this.showCatalogModalForm.controls.showCatalog.value ===
        this.prodStatusEnum.ACTIVATE
          ? true
          : false,
    };
    this.productViewService
      .bulkShowInCatalog(payload)
      .pipe(
        take(1),
        catchError(() => {
          this.isUpdatingStatus = false;
          this.toastr.error(TOAST_MESSAGES.ERROR);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.toastr.success(TOAST_MESSAGES.SUCCESS);
        this.closeAllModals();
        this.isUpdatingStatus = false;
        this.batchUpdateProducts = [];
        this.productViewService.getProducts(this.filters);
      });
  }

  scrollToTop(): void {
    this.mobileCardsContainer.nativeElement.scrollTop = 0;
  }
}
