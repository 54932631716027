import { Component, ElementRef, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

@Component({
  selector: 'gc-radio-card',
  templateUrl: './radio-card.component.html',
  styleUrls: ['./radio-card.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioCardComponent),
      multi: true,
    },
  ],
})
export class RadioCardComponent {
  @Input() id?: string;
  @Input() title?: string;
  @Input() description?: string;
  @Input() icon?: string;
  @Input() disabled = false;
  @Input() checked = false;
  @Input() value = '';
  @Input() size?: 'sm' | 'normal';

  constructor(private el: ElementRef) {}

  onChange: (value: any) => void = noop;
  onTouch: () => void = noop;
  writeValue: (value: string) => void = noop;

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  select() {
    if (this.disabled) return;
    this.onTouch();
    this.onChange(this.value);
  }

  onContainerSelected() {
    if (this.disabled) return;
    const radioInput =
      this.el.nativeElement.querySelector('.radio-card__input');
    radioInput.click();
  }
}
