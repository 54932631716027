<div class="tfi-modal">
  <div class="tfi-modal-header">
    <span class="tfi-modal-header__title">Seleccione el método de pago</span>
    <div class="tfi-modal-header__close-button">
      <gc-icon
        icon="x"
        color="grey-700"
        [dimension]="18"
        (click)="cancel()"
      ></gc-icon>
    </div>
  </div>
  <div class="tfi-modal-body">
    <form [formGroup]="form" class="tfi-modal-body-form">
      <div class="tfi-modal-body-form-radio-container">
        <gc-radio-card
          title="Transferencia"
          description="La verificación de esta transacción requerirá un período de 24 a 48 horas."
          icon="server"
          size="sm"
          [value]="paymentTypeValues.WIRE"
          [checked]="paymentTypeFormControl.value === paymentTypeValues.WIRE"
          formControlName="paymentType"
        ></gc-radio-card>
        <gc-radio-card
          title="Tarjeta de Crédito"
          description="Esta transacción se realizará de manera inmediata."
          icon="credit-card"
          size="sm"
          [value]="paymentTypeValues.CREDIT_CARD"
          [checked]="
            paymentTypeFormControl.value === paymentTypeValues.CREDIT_CARD
          "
          formControlName="paymentType"
        ></gc-radio-card>
      </div>
      <div class="tfi-modal-body-form-alert">
        <gc-alert
          *ngIf="errorMsg"
          icon="alert-triangle"
          [title]="errorMsg"
          primaryColor="red-500"
          borderColor="red-600"
          backgroundColor="red-50"
          [closable]="false"
        ></gc-alert>
      </div>
      <div class="tfi-modal-body-form-buttons">
        <div class="tfi-modal-body-form-buttons__button">
          <gc-button
            text="Cancelar"
            type="full"
            mode="outline"
            color="secondary"
            (click)="cancel()"
          ></gc-button>
        </div>
        <div class="tfi-modal-body-form-buttons__button">
          <gc-button text="Continuar" type="full" (click)="submit()"></gc-button>
        </div>
      </div>
    </form>
  </div>
</div>
