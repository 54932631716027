import { Injectable } from '@angular/core';
import { IPermission } from '@tfi-workspace-web/tfi-shared-utils';
import { BehaviorSubject, take } from 'rxjs';
import { PermissionDataService } from '../data/permission-data.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionViewService {
  private permissionSubject = new BehaviorSubject<IPermission[]>([]);
  permissions$ = this.permissionSubject.asObservable();
  constructor(private data: PermissionDataService) {}

  getPermissions() {
    return this.data
      .getPermissions()
      .pipe(take(1))
      .subscribe((response) => {
        this.permissionSubject.next(response.data.permissions);
      });
  }
}
