<p-table
  dataKey="_id"
  [value]="tableData"
  [rows]="rows"
  responsiveLayout="scroll"
  [rows]="rows"
  [paginator]="showPaginator && !skeleton"
  [rowsPerPageOptions]="rowsPerPageOptions"
  [showCurrentPageReport]="showCurrentPageReport"
  [resizableColumns]="true"
  [autoLayout]="true"
  [scrollable]="true"
  scrollHeight="calc(65vh - 10vh)"
  paginatorDropdownAppendTo="body"
  currentPageReportTemplate="Mostrando {first} hasta {last} de {totalRecords} registros"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 15%">Referencia de pago</th>
      <th style="width: 20%">Cantidad</th>
      <th style="width: 20%">Método de pago</th>
      <th style="width: 20%">Estado</th>
      <th style="width: 20%">Fecha de emisión</th>
      <th style="width: 5%"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-transaction let-rowIndex="rowIndex">
    <tr>
      <td>
        <span [ngClass]="{ skeleton: skeleton }">
          {{ transaction.paymentReferenceId }}</span
        >
      </td>
      <td>
        <span [ngClass]="{ skeleton: skeleton }">
          ${{ transaction.amount | number : '1.2-2' }}
        </span>
      </td>
      <td>
        <div [ngSwitch]="transaction.paymentMethod">
          <span [ngClass]="{ skeleton: skeleton }" *ngSwitchCase="'WOMPI'">
            Tarjeta de crédito
          </span>
          <span [ngClass]="{ skeleton: skeleton }" *ngSwitchCase="'TRANSFER'">
            Transferencia
          </span>
        </div>
      </td>
      <td>
        <div [ngSwitch]="transaction.status">
          <gc-badge
            *ngSwitchCase="'SUCCEEDED'"
            size="lg"
            text="Aprobado"
            backgroundColor="green-500"
            textColor="base-white"
            [skeleton]="skeleton"
          ></gc-badge>
          <gc-badge
            *ngSwitchCase="'PENDING'"
            size="lg"
            text="Pendiente"
            backgroundColor="primary-500"
            textColor="base-white"
            [skeleton]="skeleton"
          ></gc-badge>
          <gc-badge
            *ngSwitchCase="'FAILED'"
            size="lg"
            text="Error"
            backgroundColor="red-500"
            textColor="base-white"
            [skeleton]="skeleton"
          ></gc-badge>
          <gc-badge
            *ngSwitchCase="'CANCELLED'"
            size="lg"
            text="Cancelado"
            backgroundColor="red-500"
            textColor="base-white"
            [skeleton]="skeleton"
          ></gc-badge>
        </div>
      </td>
      <td>
        <span [ngClass]="{ skeleton: skeleton }">{{
          transaction.transactionDate | date
        }}</span>
      </td>
      <td>
        <div
          [ngClass]="{ skeleton: skeleton }"
          *ngIf="
            transaction.paymentMethod === 'Transferencia' &&
            transaction.status === 'FAILED'
          "
        >
          <gc-menu
            [options]="options"
            position="top"
            (selectedOption)="setAction($event, transaction, rowIndex)"
          ></gc-menu>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
