import { IBaseEntity } from './base-entity.interface';

export interface IPermission extends IBaseEntity {
  entity: string;
  code: string;
  description?: string;
}

export interface IPermissionResponse {
  status: string;
  results: number;
  data: {
    permissions: IPermission[];
  };
}

export interface IPermissionEntity {
  entity: string;
  permissions: IPermisionHandler[];
}

export interface IPermisionHandler {
  code: string;
  id: string;
  isSelected: boolean;
  label: string;
}
