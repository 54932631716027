export interface ICustomerPropTranslation {
  key: string;
  translation: string;
}

export const requirePropFC = [{ key: 'name', translation: 'Nombre completo' }];

export const requirePropCCF = [
  { key: 'name', translation: 'Nombre completo' },
  { key: 'email', translation: 'Correo electrónico' },
  { key: 'documentType', translation: 'Tipo de documento' },
  { key: 'documentNumber', translation: 'Número de documento' },
  { key: 'nrc', translation: 'NRC' },
  { key: 'economicActivity', translation: 'Actividad económica' },
  { key: 'address.addressLine', translation: 'Dirección' },
  { key: 'address.state', translation: 'Departamento' },
  { key: 'address.city', translation: 'Municipio' },
];
