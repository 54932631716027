import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { GCComponentsModule } from '@gc-tech/components';
import { IMenuItem } from '@tfi-workspace-web/tfi-shared-utils';
import { Router, RouterModule } from '@angular/router';
import { CatalogViewService } from '../../services/catalog/view/catalog-view.service';
import { DomainService } from '../../services/catalog/domain/domain.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'e-tfi-ecom-header',
  standalone: true,
  imports: [CommonModule, GCComponentsModule, RouterModule],
  templateUrl: './ecom-header.component.html',
  styleUrls: ['./ecom-header.component.scss'],
})
export class EcomHeaderComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter();
  @Input() menuItems: IMenuItem[] = [];
  @Input() logoImage?: string;
  screenWidth?: number;
  withBreakpoint = 1000;
  isProfileMenuVisible = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private el: ElementRef,
    private router: Router,
    private catalogViewService: CatalogViewService,
    private domainService: DomainService
  ) {}

  ngOnInit(): void {
    this.domainService.companiDomain$
      .pipe(takeUntil(this.destroy$))
      .subscribe((domain) => {
        if (domain) {
          this.getCompany(domain);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getCompany(domain: string): void {
    this.catalogViewService.getCompany(domain);
    this.catalogViewService.company$.subscribe((company) => {
      this.logoImage = company.mainLogo;
    });
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: Event): void {
    const isClickedOutsiseDesktopMenu = !this.el.nativeElement
      .querySelector('.desktop-menu-container')
      ?.contains(event.target);
    const isClickedOutsideProfileMenu = !this.el.nativeElement
      .querySelector('.desktop-profile-button')
      ?.contains(event.target);
    if (isClickedOutsiseDesktopMenu) {
      this.hideMenu();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenWidth = window.innerWidth;
    this.validateScreenWidthForMenu();
  }

  validateScreenWidthForMenu() {
    if (this.screenWidth && this.screenWidth < this.withBreakpoint) {
      this.hideMenu();
    }
  }

  hideMenu() {
    this.menuItems.forEach((menuOption) => (menuOption.isSelected = false));
  }

  showMenu(item: IMenuItem) {
    if (item.isSelected) {
      item.isSelected = false;
      return;
    }
    this.hideMenu();
    item.isSelected = true;
  }

  onMobileMenuClicked() {
    this.toggleSidebar.emit();
  }

  toggleProfileMenu() {
    this.isProfileMenuVisible = !this.isProfileMenuVisible;
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }
}
