import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Modaldata } from '@tfi-workspace-web/tfi-shared-utils';

@Component({
  selector: 'tfi-feat-product-category-details-dialog',
  templateUrl: './category-details-dialog.component.html',
  styleUrls: ['./category-details-dialog.component.scss'],
})
export class CategoryDetailsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CategoryDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modaldata
  ) {}

  cancel() {
    this.dialogRef.close();
  }
}
