import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PaymentTypeEnum } from '@tfi-workspace-web/tfi-shared-utils';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'tfi-feat-bm-billing-payment-selection-dialog',
  templateUrl: './billing-payment-selection-dialog.component.html',
  styleUrls: ['./billing-payment-selection-dialog.component.scss'],
})
export class BillingPaymentSelectionDialogComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  paymentTypeValues = PaymentTypeEnum;
  errorMsg = '';

  form = new FormGroup({
    paymentType: new FormControl('', [Validators.required]),
  });

  get paymentTypeFormControl() {
    return this.form.get('paymentType') as FormControl;
  }

  constructor(
    public dialogRef: MatDialogRef<BillingPaymentSelectionDialogComponent>
  ) {}

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.errorMsg = ''));
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    this.errorMsg = '';
    if (this.form.invalid) {
      this.errorMsg = 'Debe seleccionar una opción';
      return;
    }

    this.dialogRef.close(this.paymentTypeFormControl.value);
  }
}
