import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Modaldata } from '@tfi-workspace-web/tfi-shared-utils';
import { BrandsViewService } from '../../view/brands-view/brands-view.service';
import { ManageBrandsDialogComponent } from '../../../components/brands/manage-brands-dialog/manage-brands-dialog.component';
import { BrandsDetailsDialogComponent } from '../../../components/brands/brands-details-dialog/brands-details-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class BrandsDialogService {
  constructor(
    private dialog: MatDialog,
    private brandsViewService: BrandsViewService
  ) {}

  openManageBrandsDialog(modalData: Modaldata) {
    const dialogRef = this.dialog.open(ManageBrandsDialogComponent, {
      data: modalData,
      minWidth: '360px',
      width: '85%',
      maxWidth: '800px',
      maxHeight: '90vh',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.brandsViewService.getBrands();
    });
  }

  openDetailBrandDialog(modalData: Modaldata) {
    const dialogRef = this.dialog.open(BrandsDetailsDialogComponent, {
      data: modalData,
      minWidth: '360px',
    });
  }
}
