import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  ILogin,
  ILoginResponse,
  IOnboardingRegister,
  IPasswordChange,
  IPasswordResponse,
} from '../../interfaces/authentication.interface';
import {
  environment,
  authMethods,
  businessRules,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Observable } from 'rxjs';
import { IAdminInvoicingPlansSuccessResponse } from '../../interfaces/onboarding-post.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationDataService {
  private host = environment.api_host;
  constructor(private http: HttpClient) {}

  postLogin(data: ILogin) {
    return this.http.post<ILoginResponse>(this.host + authMethods.signIn, data);
  }

  postRegister(data: IOnboardingRegister) {
    return this.http.post<ILoginResponse>(
      this.host + authMethods.register,
      data
    );
  }

  postResetPassword(email: string): Observable<IPasswordResponse> {
    return this.http.post<IPasswordResponse>(
      this.host + authMethods.resetPassword,
      { email }
    );
  }

  verifyUser(token: string) {
    return this.http.get(`${this.host}${authMethods.verifyUser}/${token}`);
  }

  postChangePassword(
    passUpdate: IPasswordChange
  ): Observable<IPasswordResponse> {
    return this.http.post<IPasswordResponse>(
      this.host + authMethods.changePassword,
      passUpdate
    );
  }

  getAdminInvPlans(): Observable<IAdminInvoicingPlansSuccessResponse> {
    return this.http.get<IAdminInvoicingPlansSuccessResponse>(
      this.host + businessRules.plans
    );
  }
}
