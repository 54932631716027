import { IBaseAddress } from './base-address.interface';
import { IBaseEntity } from './base-entity.interface';

export interface IBranch extends IBaseEntity {
  branchCode: string;
  branchName: string;
  branchType: BranchTypeEnum;
  address?: IBaseAddress;
  phoneNumber: string;
  email: string;
  schedules: ISchedule[];
}

export enum BranchTypeEnum {
  WAREHOUSE = 'WAREHOUSE',
  STORE = 'STORE',
}

export interface ISchedule {
  day: DayOfWeek;
  description: string;
  startTime: string;
  endTime: string;
}

export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface IBranchesResponse {
  status: string;
  results: number;
  data: {
    branches: IBranch[];
  };
}

export interface IBranchResponse {
  status: string;
  results: number;
  data: {
    branch: IBranch;
  };
}
