<tfi-header
  id="header"
  [menuItems]="menuItems"
  (toggleSidebar)="toggleSidebar()"
></tfi-header>
<main
  class="main custom-scroll"
  [ngClass]="{ 'main--full-height': !isFooterVisible }"
>
  <div class="router-container">
    <router-outlet></router-outlet>
  </div>
</main>
<tfi-footer id="footer" *ngIf="isFooterVisible"></tfi-footer>
<section id="sidebar">
  <p-sidebar [(visible)]="sidebarVisible">
    <tfi-mobile-sidebar
      [menuItems]="menuItems"
      (hideMobileSidebarMenu)="sidebarVisible = false"
    ></tfi-mobile-sidebar>
    <ul class="mobile-business-menu-container">
      <li
        class="mobile-business-menu-container__item"
        (click)="mobileBussAdminRoute()"
      >
        Administración de negocio
      </li>
    </ul>
    <ng-template pTemplate="footer">
      <ul class="mobile-profile-menu-container">
        <li class="mobile-profile-menu-container__item" (click)="logout()">
          Cerrar sesión
        </li>
      </ul>
    </ng-template>
  </p-sidebar>
</section>
