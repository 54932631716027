<div>
  <p-sidebar
    class="tfi-sidebar"
    [(visible)]="isProductSidebarVisible"
    appendTo="body"
    position="right"
    (onHide)="onHide()"
  >
    <ng-template pTemplate="header">
      <span class="tfi-sidebar__title">Edita el producto</span>
    </ng-template>
    <div class="tfi-sidebar-body">
      <div class="tfi-sidebar-body-form" [formGroup]="form">
        <gc-button
          *PermissionControl="[
            userPermissionsEnum.USR_PRD_EDT,
            userPermissionsEnum.USR_BRD_LST,
            userPermissionsEnum.USR_CAT_LST
          ]"
          class="tfi-sidebar-body-form__link"
          mode="ghost"
          size="sm"
          text="Ver más"
          (click)="navigateToEditProduct()"
        ></gc-button>
        <div class="tfi-sidebar-body-form-image-container">
          <img
            class="tfi-sidebar-body-form-image-container__image"
            [src]="
              selectedProduct?.mainImageUrl || '/assets/images/no-image.jpg'
            "
            alt="product-image"
          />
        </div>
        <gc-button
          mode="ghost"
          size="sm"
          text="Cambiar imagen"
          [isLoading]="isLoading"
          (click)="inputFileReference.click()"
        ></gc-button>
        <input
          id="imageInputProduct"
          type="file"
          class="tfi-sidebar-body-form__input-file"
          value="select"
          #inputFileReference
          accept=".jpg, .jpeg, .png"
          (change)="onNewImageSelected($event)"
        />
        <div class="tfi-sidebar-body-form-sku">
          <span class="tfi-sidebar-body-form-sku__label"
            >SKU Interno: {{ selectedProduct?.internalSKU ?? '-' }}</span
          >
        </div>
        <gc-label
          class="tfi-sidebar-body-form__input"
          label="Nombre del producto"
          [control]="productNameFormControl"
        >
          <gc-input
            id="productName"
            type="text"
            formControlName="productName"
            placeHolder="Nombre del producto"
          ></gc-input>
        </gc-label>
        <gc-label
          label="Costo"
          [control]="costFormControl"
          class="tfi-sidebar-body-form__input"
        >
          <gc-input
            id="costProduct"
            icon="dollar-sign"
            type="number"
            formControlName="cost"
            placeHolder="Costo"
          ></gc-input>
        </gc-label>
        <gc-label
          label="Precio"
          [control]="priceFormControl"
          class="tfi-sidebar-body-form__input"
        >
          <gc-input
            id="priceProduct"
            icon="dollar-sign"
            type="number"
            formControlName="price"
            placeHolder="Precio"
          ></gc-input>
        </gc-label>
        <gc-label
          class="tfi-sidebar-body-form__input"
          [label]="statusFormControl.value ? 'Habilitado' : 'Deshabilitado'"
        >
          <gc-switch size="md" formControlName="isEnabled"></gc-switch>
        </gc-label>
        <gc-button
          class="tfi-sidebar-body-form__button"
          type="full"
          mode="outline"
          text="Cancelar"
          (click)="onHide()"
        ></gc-button>
        <gc-button
          *PermissionControl="[userPermissionsEnum.USR_PRD_EDT]"
          class="tfi-sidebar-body-form__input"
          type="full"
          text="Editar el producto"
          [isLoading]="isLoading"
          (click)="onEditFormSubmitted()"
        ></gc-button>
      </div>
    </div>
  </p-sidebar>
</div>
