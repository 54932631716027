import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

@Component({
  selector: 'gc-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements ControlValueAccessor {
  @Input() id = '';
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() disabled = false;
  @Input() checked = false;
  @Output() toggleChanged = new EventEmitter<boolean>();
  @ViewChild('switchElement', { static: false }) switchElement!: ElementRef;
  focused = false;

  onChange: (value: boolean) => void = noop;
  onTouch: () => void = noop;

  writeValue(value: boolean) {
    if (value) {
      this.checked = value;
    }
  }
  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  switch() {
    if (!this.disabled) {
      this.onTouch();
      this.onChange(!this.checked);
      this.checked = !this.checked;

      this.toggleChanged.emit(this.checked);
    }
  }

  focusInput() {
    if (this.switchElement) {
      console.log('paso');
      this.switchElement.nativeElement.focus();
    }
  }

  onFocus() {
    this.focused = true;
  }

  onBlur() {
    this.focused = false;
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.disabled) {
      this.switch();
    }
  }
}
