<div class="tfi-modal">
  <span class="tfi-modal__title">
    Total a pagar: ${{ totalPayment | number : '1.2-2' }}
  </span>
  <span class="tfi-modal__label">Información de la cuenta de banco:</span>

  <div class="tfi-modal-body">
    <div class="tfi-modal-body-row">
      <div class="tfi-modal-body-row-wrapper">
        <span class="tfi-modal-body-row-wrapper__key">Banco:</span>
        <span class="tfi-modal-body-row-wrapper__value">Banco Agrícola</span>
      </div>
      <gc-icon
        class="tfi-modal-body-row__icon"
        icon="copy"
        [dimension]="16"
        (click)="copyOnClipboard('Banco Agrícola')"
      ></gc-icon>
    </div>

    <div class="tfi-modal-body-row">
      <div class="tfi-modal-body-row-wrapper">
        <span class="tfi-modal-body-row-wrapper__key">Número de cuenta:</span>
        <span class="tfi-modal-body-row-wrapper__value">123456789-1</span>
      </div>
      <gc-icon
        class="tfi-modal-body-row__icon"
        icon="copy"
        [dimension]="16"
        (click)="copyOnClipboard('123456789-1')"
      ></gc-icon>
    </div>

    <div class="tfi-modal-body-row">
      <div class="tfi-modal-body-row-wrapper">
        <span class="tfi-modal-body-row-wrapper__key">Tipo de cuenta:</span>
        <span class="tfi-modal-body-row-wrapper__value"
          >Cuenta empresarial</span
        >
      </div>
      <gc-icon
        class="tfi-modal-body-row__icon"
        icon="copy"
        [dimension]="16"
        (click)="copyOnClipboard('Cuenta empresarial')"
      ></gc-icon>
    </div>
  </div>
  <div class="tfi-modal-upload-payment-proof">
    <span class="tfi-modal-upload-payment-proof__label"
      >Adjunta el comprobante de pago:</span
    >
    <gc-upload-file
      (fileLoaded)="fileLoaded($event)"
      (fileDeleted)="fileDeleted()"
    ></gc-upload-file>
  </div>
  <div class="tfi-modal-error-alert">
    <gc-alert
      *ngIf="errorMsg"
      icon="alert-triangle"
      [title]="errorMsg"
      primaryColor="red-500"
      borderColor="red-600"
      backgroundColor="red-50"
      [closable]="false"
    ></gc-alert>
  </div>
  <span class="tfi-modal__disclaimer"
    >Le informamos que su transacción está sujeta a verificación y será
    confirmada dentro de un plazo de 24 a 48 horas después de haber enviado el
    comprobante de pago correspondiente.
  </span>
  <div class="tfi-modal-button-container">
    <div class="tfi-modal-button-container__button">
      <gc-button
        text="Cancelar"
        type="full"
        mode="outline"
        color="secondary"
        (click)="cancel()"
      ></gc-button>
    </div>
    <div class="tfi-modal-button-container__button">
      <gc-button
        text="Finalizar"
        type="full"
        [isLoading]="isLoading"
        (click)="submit()"
      ></gc-button>
    </div>
  </div>
</div>
