import { TableMenuActionsEnum } from '../enums/table-menu-actions.enum';
import { UserPermissionsEnum } from '../enums/user-permissions.enum';

export interface ITableAction {
  label: string;
  icon: string;
  tooltip?: string;
  permissions: UserPermissionsEnum[];
  menuAction: TableMenuActionsEnum;
  showAction?(value: any): boolean;
}
