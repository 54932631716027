import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITableColumn } from '@gc-tech/components';
import {
  BranchTypeEnum,
  BranchTypeOptions,
  DaysOfWeekConstant,
  ISchedule,
  Modaldata,
} from '@tfi-workspace-web/tfi-shared-utils';
import { DayOfWeekEnum } from 'libs/tfi-shared-utils/src/lib/enums/day-of-week.enum';
import { ScheduleViewDetails } from '../../../interfaces/schedule-view-details.interface';

@Component({
  selector: 'tfi-feat-bm-branch-view-details-dialog',
  templateUrl: './branch-view-details-dialog.component.html',
  styleUrls: ['./branch-view-details-dialog.component.scss'],
})
export class BranchViewDetailsDialogComponent {
  branchTypeLabel?: string;
  dataMain: ScheduleViewDetails[] = [];

  columns: ITableColumn[] = [
    {
      field: 'day',
      header: 'Día',
      sort: false,
    },
    {
      field: 'startTime',
      header: 'Apertura',
      sort: false,
    },
    {
      field: 'endTime',
      header: 'Cierre',
      sort: false,
    },
    {
      field: 'description',
      header: 'Descripción',
      sort: false,
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<BranchViewDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modaldata
  ) {
    this.setBranchType();
    this.setDayWeek();
  }

  cancel() {
    this.dialogRef.close();
  }

  setBranchType() {
    if (this.data.modalData.branchType === BranchTypeEnum.STORE) {
      this.branchTypeLabel = BranchTypeOptions[0].label;
    } else if (this.data.modalData.branchType === BranchTypeEnum.WAREHOUSE) {
      this.branchTypeLabel = BranchTypeOptions[1].label;
    }
  }

  setDayWeek() {
    this.data.modalData.schedules.forEach((item: ISchedule) => {
      this.dataMain.push(item);
    });

    this.dataMain = this.data.modalData.schedules.map((item: ISchedule) => ({
      ...item,
    }));

    this.dataMain.forEach((element) => {
      if (element.day === DayOfWeekEnum.MONDAY) {
        element.day = DaysOfWeekConstant[0].label;
      } else if (element.day === DayOfWeekEnum.TUESDAY) {
        element.day = DaysOfWeekConstant[1].label;
      } else if (element.day === DayOfWeekEnum.WEDNESDAY) {
        element.day = DaysOfWeekConstant[2].label;
      } else if (element.day === DayOfWeekEnum.THURSDAY) {
        element.day = DaysOfWeekConstant[3].label;
      } else if (element.day === DayOfWeekEnum.FRIDAY) {
        element.day = DaysOfWeekConstant[4].label;
      } else if (element.day === DayOfWeekEnum.SATURDAY) {
        element.day = DaysOfWeekConstant[5].label;
      } else if (element.day === DayOfWeekEnum.SUNDAY) {
        element.day = DaysOfWeekConstant[6].label;
      }
    });
  }
}
