import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  environment,
  IRolesPostResponse,
  IRolesResponse,
  roleMethods,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Observable } from 'rxjs';
import { RoleFilters } from '../../../constants/role-filters';

@Injectable({
  providedIn: 'root',
})
export class RoleDataService {
  private readonly roleUrl = environment.api_host + roleMethods.roles;

  constructor(private http: HttpClient) {}

  getRoles(filterData: RoleFilters): Observable<IRolesResponse> {
    let params = new HttpParams();
    Object.keys(filterData).forEach((field) => {
      if (filterData[field]) params = params.set(field, filterData[field]);
    });
    return this.http.get<IRolesResponse>(this.roleUrl, { params });
  }

  postRole(payload: any): Observable<IRolesPostResponse> {
    return this.http.post<IRolesPostResponse>(this.roleUrl, payload);
  }

  updateRole(payload: any, id: string): Observable<IRolesPostResponse> {
    return this.http.put<IRolesPostResponse>(`${this.roleUrl}/${id}`, payload);
  }

  changeRoleStatus(payload: any, id: string): Observable<IRolesPostResponse> {
    return this.http.patch<IRolesPostResponse>(`${this.roleUrl}/change-status/${id}`, payload);
  }
}
