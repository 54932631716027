import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMenuOptions } from '@gc-tech/components';
import { TableMenuActionsEnum } from '@tfi-workspace-web/tfi-shared-utils';

@Component({
  selector: 'tfi-feat-bm-transaction-table',
  templateUrl: './transaction-table.component.html',
  styleUrls: ['./transaction-table.component.scss'],
})
export class TransactionTableComponent {
  @Input() tableData: any[] = [];
  @Input() rows = 10;
  @Input() showPaginator = true;
  @Input() rowsPerPageOptions: number[] = [10, 20, 30];
  @Input() showCurrentPageReport = true;
  @Input() skeleton = false;
  @Output() transactionSelected = new EventEmitter();

  options: IMenuOptions[] = [
    {
      id: 'editar',
      label: 'Agregar comprobante',
      icon: TableMenuActionsEnum.EDIT,
    },
  ];

  setAction(action: string, data: any, index: number) {
    const detail = {
      data,
      index,
      action,
    };
    this.transactionSelected.emit(detail);
  }
}
