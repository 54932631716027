<div class="branch-detail-container">
  <div class="title-container">
    <h2 class="title-container__title">Detalles de sucursal</h2>
    <gc-icon
      class="title-container__icon"
      icon="x"
      [dimension]="22"
      color="grey-800"
      mat-dialog-close
    ></gc-icon>
  </div>
  <div class="details-container">
    <div class="flex-container">
      <div class="flex-item">
        <span class="flex-item__key">Codigo de la sucursal:</span>
        <span class="flex-item__value">{{
          data.modalData.branchCode || '-' | titlecase
        }}</span>
      </div>
      <div class="flex-item">
        <span class="flex-item__key">Nombre de la sucursal:</span>
        <span class="flex-item__value">{{
          data.modalData.branchName || '-' | titlecase
        }}</span>
      </div>
    </div>
    <div class="flex-container">
      <div class="flex-item">
        <span class="flex-item__key">Teléfono:</span>
        <span class="flex-item__value">{{
          data.modalData.phoneNumber || '-'
        }}</span>
      </div>
      <div class="flex-item">
        <span class="flex-item__key">Correo Electrónico:</span>
        <span class="flex-item__value">{{ data.modalData.email ?? '-' }}</span>
      </div>
    </div>
    <div class="flex-container">
      <div class="flex-item">
        <span class="flex-item__key">Tipo de sucursal</span>
        <span class="flex-item__value">{{ branchTypeLabel || '-' }}</span>
      </div>
    </div>
    <div class="flex-container">
      <div class="flex-item">
        <span class="flex-item__key">Direccón:</span>
        <span class="flex-item__value">{{
          data.modalData.address?.addressLine || '-'
        }}</span>
      </div>
      <div class="flex-item">
        <span class="flex-item__key">Departamento:</span>
        <span class="flex-item__value">{{
          data.modalData.address?.state || '-'
        }}</span>
      </div>
    </div>

    <div class="flex-container">
      <div class="flex-item">
        <span class="flex-item__key">Municipio:</span>
        <span class="flex-item__value">{{
          data.modalData.address?.city || '-'
        }}</span>
      </div>
      <div class="flex-item">
        <span class="flex-item__key">Distrito: (Opcional)</span>
        <span class="flex-item__value">{{
          data.modalData.address?.district || '-'
        }}</span>
      </div>
    </div>
    <div class="schedule-container">
      <h2 class="schedule-container__title">Horarios</h2>
      <div class="table-container">
        <gc-table
          [showPaginator]="false"
          [rowHover]="false"
          [columns]="columns"
          [data]="dataMain"
        ></gc-table>
      </div>
    </div>
  </div>
  <div class="button-container">
    <gc-button
      class="button-container__button"
      text="Regresar"
      size="sm"
      icon="chevron-left"
      type="full"
      (click)="cancel()"
    ></gc-button>
  </div>
</div>
