<div
  class="tfi-card"
  [ngClass]="{
    'tfi-card--selected': isSelected,
    'tfi-card--skeleton': skeleton,
    'tfi-card--no-image': !cardImage,
  }"
  (click)="onCardSelected()"
>
  <div
    class="tfi-card-image-container"
    *ngIf="cardImage"
    [ngClass]="{ skeleton: skeleton }"
  >
    <img
      [ngStyle]="{ opacity: skeleton ? 0 : 1 }"
      class="tfi-card-image-container__image"
      [src]="cardImage"
      alt="ilustration"
    />
  </div>
  <div class="tfi-card-content">
    <span class="tfi-card-content__title" [ngClass]="{ skeleton: skeleton }">{{
      title | slice : 0 : 31
    }}</span>
    <span
      class="tfi-card-content__description"
      [ngClass]="{ skeleton: skeleton }"
      *ngIf="description"
      >{{ description }}</span
    >
  </div>
</div>
