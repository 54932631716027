import { Injectable } from '@angular/core';
import { CatalogDataService } from '../data/catalog-data.service';
import { ProductFilters } from '@tfi-workspace-web/tfi-feat-product-management';
import { ICompany } from '@tfi-workspace-web/tfi-shared-utils';
import { BehaviorSubject, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CatalogViewService {
  private fetchedCompany = new BehaviorSubject<ICompany>({} as ICompany);
  company$ = this.fetchedCompany.asObservable();

  private currentCategogy = new BehaviorSubject<string | null>(null);
  currentCat$ = this.currentCategogy.asObservable();

  constructor(private data: CatalogDataService) {}

  getCategories() {
    return this.data.getCategories();
  }

  getBrands() {
    return this.data.getBrands();
  }

  getProducts(filterData: ProductFilters) {
    return this.data.getProducts(filterData);
  }

  getProductDetail(prodName: string) {
    return this.data.getProduct(prodName);
  }

  getCompany() {
    return this.data
      .getCompany()
      .pipe(take(1))
      .subscribe((responce) => {
        this.fetchedCompany.next(responce.data.company);
      });
  }

  updateCat(category: string) {
    this.currentCategogy.next(category);
  }
}
