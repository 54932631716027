import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, Subject, catchError, take, takeUntil } from 'rxjs';
import { CompanyViewService } from '../../services/company/view/company-view.service';
import {
  CompanyIndustryEnum,
  CompanyIndustryOptions,
  FormErrorMessageEnum,
  IBusinessParams,
  ICompany,
  ICompanyWebCatalog,
  TOAST_MESSAGES,
  UserPermissionsEnum,
  defaultBusinessParams,
} from '@tfi-workspace-web/tfi-shared-utils';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IFormFieldMessages } from '@gc-tech/components';
import { ITaxDetail } from 'libs/tfi-shared-utils/src/lib/interfaces/tax.interface';
import { ToastrService } from 'ngx-toastr';
import { BusinesParamsEnum } from '../../enums/company-management.enums';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';
import { WebCatalogViewService } from '../../services/web-catalog/view/web-catalog-view.service';

@Component({
  selector: 'tfi-feat-bm-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss'],
  animations: [
    trigger('slideInFromRight', [
      state('void', style({ transform: 'translateX(100%)' })),
      state('*', style({ transform: 'translateX(0)' })),
      transition('void => *', animate('300ms ease-out')),
    ]),
  ],
})
export class CompanyManagementComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  company?: ICompany;
  companyWebCatalog?: ICompanyWebCatalog;
  contactEditMode = false;
  taxEditMode = false;
  isLoading = false;
  canNotEditCompany = true;
  percentagePattern = /^[0-9.]*$/;
  companyIndustry = CompanyIndustryOptions;
  companyIndustryEnum = CompanyIndustryEnum;
  contactForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    phoneNumber: new FormControl('', [Validators.required]),
  });
  taxDetailForm = new FormGroup({
    taxDetail: new FormArray([]),
  });
  errorMsgContact = '';
  errorMsgTax = '';
  messagePatter: IFormFieldMessages = {
    pattern: 'Porcentaje invalido',
  };
  businessParams!: IBusinessParams;
  isSavingParams = false;
  userPermissionsEnum = UserPermissionsEnum;

  constructor(
    private view: CompanyViewService,
    private webCatalogViewService: WebCatalogViewService,
    private toastr: ToastrService,
    private auth: AuthenticationViewService
  ) {}

  get emailCodeFormControl() {
    return this.contactForm.get('email') as FormControl;
  }
  get phoneNumberhCodeFormControl() {
    return this.contactForm.get('phoneNumber') as FormControl;
  }

  get taxDetailFormControl() {
    return this.taxDetailForm.get('taxDetail') as FormArray;
  }

  ngOnInit(): void {
    this.canNotEditCompany = !this.auth.hasPermissionStrict([
      this.userPermissionsEnum.USR_CMP_EDT,
    ]);
    this.view.company$.pipe(takeUntil(this.destroy$)).subscribe((company) => {
      this.company = company;
      this.businessParams = this.company?.businessParams as IBusinessParams;
      this.startFormTaxDetail();
      if (
        Object.keys(defaultBusinessParams).length !==
          Object.keys(this.businessParams || {}).length - 1 &&
        this.company.displayName !== ''
      ) {
        this.udpateMissingParams();
      }
    });
    this.view.getCompany();

    this.webCatalogViewService.companyWebCatalog$
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyWebCatalog) => {
        this.companyWebCatalog = companyWebCatalog;
      });
    if (this.companyWebCatalog) {
      this.webCatalogViewService.getCompanyWebCatalog();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.taxDetailFormControl.clear();
  }

  editContactInfo(mode: boolean): void {
    if (!mode) {
      this.contactForm.patchValue({
        email: this.company?.email,
        phoneNumber: this.company?.phoneNumber,
      });
      this.contactEditMode = !mode;
    } else {
      this.isLoading = true;
      this.view
        .updateCompany(this.contactForm.getRawValue() as Partial<ICompany>)
        .pipe(
          take(1),
          catchError((err: any) => {
            if (err.status === 400) {
              this.errorMsgContact =
                err.error.message || err.error.error.message;
            } else {
              this.errorMsgContact =
                FormErrorMessageEnum.OCURRIO_UN_ERROR_INTENTE_MAS_TARDE;
            }
            this.isLoading = false;
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.errorMsgContact = '';
          this.isLoading = false;
          this.contactEditMode = !mode;
          this.view.getCompany();
        });
    }
    this.contactForm.markAsPristine();
  }

  cancelContactEdit(): void {
    this.errorMsgContact = '';
    this.contactEditMode = false;
    this.contactForm.markAsPristine();
  }

  startFormTaxDetail(): void {
    if (
      (this.company?.taxDetail?.length || 0 > 0) &&
      this.taxDetailFormControl.length === 0
    ) {
      this.fillTaxArrayForm();
    }
  }

  fillTaxArrayForm(): void {
    this.company?.taxDetail?.forEach((tax) => {
      const taxDetailControl = new FormGroup({
        taxType: new FormControl(tax.taxType, [Validators.required]),
        taxPercentage: new FormControl((tax.taxPercentage * 100).toFixed(2), [
          Validators.required,
          Validators.min(1),
          Validators.max(100),
          Validators.pattern(this.percentagePattern),
        ]),
        description: new FormControl(tax.description),
      });
      this.taxDetailFormControl.push(taxDetailControl);
    });
  }

  addTax() {
    const taxDetailControl = new FormGroup({
      taxType: new FormControl('', [Validators.required]),
      taxPercentage: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(this.percentagePattern),
      ]),
      description: new FormControl(''),
    });
    this.taxDetailFormControl.push(taxDetailControl);
  }

  getControl(control: AbstractControl, controlName: string): FormControl {
    return control.get(controlName) as FormControl;
  }

  deleteTax(index: number) {
    const scheduele = this.taxDetailFormControl;
    scheduele.removeAt(index);
  }

  editTaxInfo(mode: boolean): void {
    this.errorMsgTax = '';
    if (!mode) {
      this.taxEditMode = !mode;
    } else {
      this.isLoading = true;
      this.view
        .updateCompany({
          taxDetail: this.convertValues(
            this.taxDetailFormControl.getRawValue()
          ),
        } as Partial<ICompany>)
        .pipe(
          take(1),
          catchError((err: any) => {
            if (err.status === 400) {
              this.errorMsgTax = err.error.message || err.error.error.message;
            } else {
              this.errorMsgTax =
                FormErrorMessageEnum.OCURRIO_UN_ERROR_INTENTE_MAS_TARDE;
            }
            this.isLoading = false;
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.errorMsgTax = '';
          this.isLoading = false;
          this.taxEditMode = !mode;
          this.taxDetailFormControl.clear();
          this.view.getCompany();
        });
    }
  }

  cancelTaxEdit(): void {
    this.errorMsgTax = '';
    this.taxEditMode = false;
    this.taxDetailFormControl.clear();
    this.fillTaxArrayForm();
    this.taxDetailForm.markAsPristine();
  }

  convertValues(taxDetailArr: ITaxDetail[]): ITaxDetail[] {
    return taxDetailArr.map((detail: ITaxDetail) => {
      return {
        ...detail,
        taxPercentage: parseFloat((detail.taxPercentage / 100).toFixed(4)),
      };
    });
  }

  toggleKitchenParam(): void {
    if (this.isSavingParams) return;
    this.isSavingParams = true;
    this.updateBusinessParams(BusinesParamsEnum.ENABLEKDS);
  }

  toggleZebraPrinter(): void {
    if (this.isSavingParams) return;
    this.isSavingParams = true;
    this.canNotEditCompany = true;
    this.updateBusinessParams(BusinesParamsEnum.ENABLE_ZEBRA_PRINTER);
  }

  toggleHideStockZero(): void {
    if (this.isSavingParams) return;
    this.isSavingParams = true;
    this.canNotEditCompany = true;
    this.updateBusinessParams(BusinesParamsEnum.ENABLE_HIDE_STOCK_ZERO);
  }

  updateBusinessParams(attrName: BusinesParamsEnum): void {
    this.businessParams[attrName].value = !this.businessParams[attrName].value;
    this.view
      .updateCompany({ businessParams: this.businessParams })
      .pipe(
        take(1),
        catchError((err: any) => {
          this.businessParams[attrName].value =
            !this.businessParams[attrName].value;
          if (err.status === 400) {
            this.toastr.error(err.error.message || err.error.error.message);
          } else {
            this.toastr.error(
              FormErrorMessageEnum.OCURRIO_UN_ERROR_INTENTE_MAS_TARDE
            );
          }
          this.isSavingParams = false;
          this.canNotEditCompany = false;
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.isSavingParams = false;
        this.canNotEditCompany = false;
        this.toastr.success(TOAST_MESSAGES.SUCCESS);
        this.view.getCompany();
      });
  }

  udpateMissingParams(): void {
    const dftBussParam = { ...defaultBusinessParams };
    const paramsToUpdate = Object.assign(
      dftBussParam,
      this.businessParams || {}
    ) as IBusinessParams;
    this.view
      .updateCompany({ businessParams: paramsToUpdate })
      .pipe(
        take(1),
        catchError((err: any) => {
          if (err.status === 400) {
            this.toastr.error(err.error.message || err.error.error.message);
          } else {
            this.toastr.error(
              FormErrorMessageEnum.OCURRIO_UN_ERROR_INTENTE_MAS_TARDE
            );
          }
          this.isSavingParams = false;
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.view.getCompany();
      });
  }

  updateCompanyData() {
    this.view.getCompany();
  }
}
