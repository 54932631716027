<div>
  <p-sidebar
    class="tfi-sidebar"
    [(visible)]="isShippingSidebarVisible"
    appendTo="body"
    position="right"
    (onHide)="onHide()"
  >
    <ng-template pTemplate="header">
      <span class="tfi-sidebar__title">Detalles del envío</span>
    </ng-template>
    <div class="tfi-sidebar-body">
      <div class="tfi-sidebar-body-content" [formGroup]="form">
        <div
          class="tfi-sidebar-body-content-row tfi-sidebar-body-content-row--disp"
        >
          <div class="tfi-sidebar-body-content-row">
            <span class="tfi-sidebar-body-content-row__key"
              >Número de Ticket:</span
            >
            <span class="tfi-sidebar-body-content-row__value">{{
              selectedTicket?.ticketNumber ?? '-'
            }}</span>
          </div>
          <div class="tfi-sidebar-body-content-row">
            <span class="tfi-sidebar-body-content-row__key"
              >Número de envío:</span
            >
            <span
              class="tfi-sidebar-body-content-row__link-value"
              (click)="navigateToEditShipping()"
              >{{ selectedTicket?.shippingDetail?.shippingNumber ?? '-' }}</span
            >
          </div>
        </div>
        <div class="tfi-sidebar-body-content-row">
          <span class="tfi-sidebar-body-content-row__key">Cliente:</span>
          <span class="tfi-sidebar-body-content-row__value">{{
            selectedTicket?.customer?.name ?? '-'
          }}</span>
        </div>

        <div
          class="tfi-sidebar-body-content-row tfi-sidebar-body-content-row--disp"
          *ngIf="!isEditing.deliveryDay; else shippingDateRef"
        >
          <div class="tfi-sidebar-body-content-row">
            <span class="tfi-sidebar-body-content-row__key"
              >Fecha de entrega:</span
            >
            <span class="tfi-sidebar-body-content-row__value">{{
              selectedTicket?.shippingDetail?.deliveryDay ?? '-'
                | date : "EEEE dd 'de' MMMM ',' y"
                | titlecase
            }}</span>
          </div>
          <div class="tfi-sidebar-body-content-row">
            <span class="tfi-sidebar-body-content-row__key"
              >Hora de entrega:</span
            >
            <span class="tfi-sidebar-body-content-row__value">{{
              selectedTicket?.shippingDetail?.deliveryHour ?? '-'
            }}</span>
          </div>

          <gc-icon
            class="tfi-sidebar-body-content-row-wrapper__edit-icon tfi-sidebar-body-content-row-wrapper__edit-icon--date"
            icon="edit"
            color="primary-500"
            [dimension]="22"
            (click)="initShippingDeliveryDateEdit()"
          ></gc-icon>
        </div>

        <ng-template #shippingDateRef>
          <div
            class="tfi-sidebar-body-content-row tfi-sidebar-body-content-row--disp"
          >
            <div class="tfi-sidebar-body-content-row-wrapper">
              <div class="tfi-sidebar-body-content-row">
                <span class="tfi-sidebar-body-content-row__key"
                  >Fecha de entrega:</span
                >
                <gc-calendar
                  [readOnly]="true"
                  [minDate]="minDate"
                  formControlName="deliveryDay"
                ></gc-calendar>
              </div>
              <div class="tfi-sidebar-body-content-row">
                <span class="tfi-sidebar-body-content-row__key"
                  >Hora de entrega:</span
                >
                <gc-select
                  id="shippingDeliveryHour"
                  formControlName="deliveryHour"
                  [data]="operationTime"
                  [enableSearch]="true"
                ></gc-select>
              </div>

              <div
                class="tfi-sidebar-body-content-row-wrapper-icon-container"
                *ngIf="!isLoading.deliveryDay; else isLoadingDeliveryRef"
              >
                <gc-icon
                  icon="save"
                  color="primary-500"
                  [dimension]="22"
                  (click)="onSaveShippingDelDate()"
                ></gc-icon>
                <gc-icon
                  icon="x-circle"
                  color="error"
                  [dimension]="22"
                  (click)="isEditing.deliveryDay = false"
                ></gc-icon>
              </div>
              <ng-template #isLoadingDeliveryRef>
                <div
                  class="tfi-sidebar-body-content-row-wrapper-icon-container"
                >
                  <div
                    class="tfi-sidebar-body-content-row-wrapper-icon-container--spinner"
                  ></div>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-template>

        <div class="tfi-sidebar-body-content-row">
          <span class="tfi-sidebar-body-content-row__key"
            >Direccion de envío:</span
          >
          <span class="tfi-sidebar-body-content-row__value"
            >{{ selectedTicket?.shippingDetail?.shippingAddress?.addressLine }},
            {{ selectedTicket?.shippingDetail?.shippingAddress?.city }}
            {{ selectedTicket?.shippingDetail?.shippingAddress?.state }}</span
          >
        </div>
        <div class="tfi-sidebar-body-content-row">
          <span class="tfi-sidebar-body-content-row__key">Precio:</span>
          <span class="tfi-sidebar-body-content-row__value"
            >${{
              selectedTicket?.shippingDetail?.price ?? '-' | number : '1.2-2'
            }}</span
          >
        </div>
        <div class="tfi-sidebar-body-content-row">
          <span class="tfi-sidebar-body-content-row__key">Costo de envio:</span>
          <div
            class="tfi-sidebar-body-content-row-wrapper"
            *ngIf="!isEditing.shippingPrice; else shippingCostRef"
          >
            <span class="tfi-sidebar-body-content-row-wrapper__value"
              >${{
                selectedTicket?.shippingDetail?.shippingPrice ?? '-'
                  | number : '1.2-2'
              }}</span
            >
            <gc-icon
              class="tfi-sidebar-body-content-row-wrapper__edit-icon"
              icon="edit"
              color="primary-500"
              [dimension]="22"
              (click)="initShippingCostEdit()"
            ></gc-icon>
          </div>
          <ng-template #shippingCostRef>
            <div class="tfi-sidebar-body-content-row-wrapper">
              <gc-input
                #shippingCost
                class="tfi-sidebar-body-content-row-wrapper__input"
                icon="dollar-sign"
                type="number"
                formControlName="shippingPrice"
                placeHolder="Costo de envío"
              ></gc-input>
              <div
                class="tfi-sidebar-body-content-row-wrapper-icon-container"
                *ngIf="!isLoading.shippingPrice; else isLoadingCostRef"
              >
                <gc-icon
                  icon="save"
                  color="primary-500"
                  [dimension]="22"
                  (click)="onSaveShippingCost()"
                ></gc-icon>
                <gc-icon
                  icon="x-circle"
                  color="error"
                  [dimension]="22"
                  (click)="isEditing.shippingPrice = false"
                ></gc-icon>
              </div>
              <ng-template #isLoadingCostRef>
                <div
                  class="tfi-sidebar-body-content-row-wrapper-icon-container"
                >
                  <div
                    class="tfi-sidebar-body-content-row-wrapper-icon-container--spinner"
                  ></div>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </div>
        <div class="tfi-sidebar-body-content-row">
          <span class="tfi-sidebar-body-content-row__key"
            >Estado del envío:</span
          >
          <div
            class="tfi-sidebar-body-content-row-wrapper"
            *ngIf="!isEditing.shippingStatus; else shippingStatusRef"
          >
            <ng-contain
              class="tfi-sidebar-body-content-row-wrapper__badge"
              [ngSwitch]="selectedTicket?.shippingDetail?.shippingStatus"
            >
              <gc-badge
                *ngSwitchCase="'CREATED'"
                text="Creado"
                size="lg"
                mode="outline"
                backgroundColor="grey-500"
                textColor="base-black"
              ></gc-badge>
              <gc-badge
                *ngSwitchCase="'IN_TRANSIT'"
                text="Envio"
                size="lg"
                backgroundColor="grey-600"
                textColor="base-white"
              ></gc-badge>
              <gc-badge
                *ngSwitchCase="'DELIVERED'"
                text="Entregado"
                size="lg"
                backgroundColor="green-500"
                textColor="base-white"
              ></gc-badge>
              <gc-badge
                *ngSwitchCase="'NO_DELIVERED'"
                text="No retirado"
                size="lg"
                backgroundColor="red-500"
                textColor="base-white"
              ></gc-badge>
              <gc-badge
                *ngSwitchCase="'IN_PROCESS'"
                text="En Proceso"
                size="lg"
                backgroundColor="info"
                textColor="base-white"
              ></gc-badge>
              <gc-badge
                *ngSwitchCase="'PAID'"
                text="Deposito"
                size="lg"
                backgroundColor="primary-500"
                textColor="base-white"
              ></gc-badge>
              <gc-badge
                *ngSwitchCase="'RE_DELIVERED'"
                text="Re-envio"
                size="lg"
                backgroundColor="green-500"
                textColor="base-white"
              ></gc-badge>
              <gc-badge
                *ngSwitchDefault
                [text]="selectedTicket?.shippingDetail?.shippingStatus"
                size="lg"
                mode="outline"
                backgroundColor="grey-500"
                textColor="base-black"
              ></gc-badge>
            </ng-contain>
            <gc-icon
              class="tfi-sidebar-body-content-row-wrapper__edit-icon"
              icon="edit"
              color="primary-500"
              [dimension]="22"
              (click)="initShippingStatusEdit()"
            ></gc-icon>
          </div>
          <ng-template #shippingStatusRef>
            <div class="tfi-sidebar-body-content-row-wrapper">
              <gc-select
                class="tfi-sidebar-body-content-row-wrapper__input"
                formControlName="shippingStatus"
                placeholder="Estado del envío"
                [data]="shippingStatusOptions"
                [enableEmptyState]="false"
                (optionSelectedEmitter)="onStatusSelected($event)"
              ></gc-select>
              <div
                class="tfi-sidebar-body-content-row-wrapper-icon-container"
                *ngIf="!isLoading.shippingStatus; else isLoadingStatusRef"
              >
                <gc-icon
                  icon="save"
                  color="primary-500"
                  [dimension]="22"
                  (click)="onSaveShippingStatus()"
                ></gc-icon>
                <gc-icon
                  icon="x-circle"
                  color="error"
                  [dimension]="22"
                  (click)="isEditing.shippingStatus = false"
                ></gc-icon>
              </div>
              <ng-template #isLoadingStatusRef>
                <div
                  class="tfi-sidebar-body-content-row-wrapper-icon-container"
                >
                  <div
                    class="tfi-sidebar-body-content-row-wrapper-icon-container--spinner"
                  ></div>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </div>
        <div class="tfi-sidebar-body-content-row">
          <span class="tfi-sidebar-body-content-row__key">Encomendista:</span>
          <div
            class="tfi-sidebar-body-content-row-wrapper"
            *ngIf="!isEditing.driverName; else shippingDriverRef"
          >
            <span class="tfi-sidebar-body-content-row-wrapper__value">{{
              selectedTicket?.shippingDetail?.driverName || '-'
            }}</span>
            <gc-icon
              class="tfi-sidebar-body-content-row-wrapper__edit-icon"
              icon="edit"
              color="primary-500"
              [dimension]="22"
              (click)="initShippingDriverEdit()"
            ></gc-icon>
          </div>
          <ng-template #shippingDriverRef>
            <div class="tfi-sidebar-body-content-row-wrapper">
              <div class="tfi-sidebar-body-content-row-wrapper-driver">
                <gc-select
                  class="tfi-sidebar-body-content-row-wrapper-driver__input"
                  id="shippingDriverName"
                  placeholder="Encomendista"
                  formControlName="driverName"
                  [data]="driverNameInputOptions"
                  [enableEmptyState]="true"
                ></gc-select>
                <gc-button
                  icon="user-plus"
                  mode="outline"
                  pTooltip="Crear nuevo encomendista"
                  tooltipPosition="top"
                  (click)="onCreateNewDriver()"
                ></gc-button>
              </div>
              <div
                class="tfi-sidebar-body-content-row-wrapper-icon-container"
                *ngIf="!isLoading.driverName; else isLoadingDriverRef"
              >
                <gc-icon
                  icon="save"
                  color="primary-500"
                  [dimension]="22"
                  (click)="onSaveShippingDriver()"
                ></gc-icon>
                <gc-icon
                  icon="x-circle"
                  color="error"
                  [dimension]="22"
                  (click)="isEditing.driverName = false"
                ></gc-icon>
              </div>
              <ng-template #isLoadingDriverRef>
                <div
                  class="tfi-sidebar-body-content-row-wrapper-icon-container"
                >
                  <div
                    class="tfi-sidebar-body-content-row-wrapper-icon-container--spinner"
                  ></div>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </div>

        <div class="tfi-sidebar-body-content-row">
          <span class="tfi-sidebar-body-content-row__key">Notas:</span>
          <div
            class="tfi-sidebar-body-content-row-wrapper"
            *ngIf="!isEditing.notes; else shippingNotesRef"
          >
            <ng-contain class="tfi-sidebar-body-content-row-wrapper__value">
              <span class="tfi-sidebar-body-content-row-wrapper__value">{{
                selectedTicket?.shippingDetail?.notes ?? '-'
              }}</span>
            </ng-contain>
            <gc-icon
              class="tfi-sidebar-body-content-row-wrapper__edit-icon"
              icon="edit"
              color="primary-500"
              [dimension]="22"
              (click)="initShippingNotesEdit()"
            ></gc-icon>
          </div>
          <ng-template #shippingNotesRef>
            <div class="tfi-sidebar-body-content-row-wrapper">
              <textarea
                id="float-input"
                rows="8"
                cols="52"
                formControlName="notes"
                pInputTextarea
              ></textarea>
              <div
                class="tfi-sidebar-body-content-row-wrapper-icon-container"
                *ngIf="!isLoading.notes; else isLoadingStatusRef"
              >
                <gc-icon
                  icon="save"
                  color="primary-500"
                  [dimension]="22"
                  (click)="onSaveShippingNotes()"
                ></gc-icon>
                <gc-icon
                  icon="x-circle"
                  color="error"
                  [dimension]="22"
                  (click)="isEditing.notes = false"
                ></gc-icon>
              </div>
              <ng-template #isLoadingStatusRef>
                <div
                  class="tfi-sidebar-body-content-row-wrapper-icon-container"
                >
                  <div
                    class="tfi-sidebar-body-content-row-wrapper-icon-container--spinner"
                  ></div>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </div>

        <div class="tfi-sidebar-body-content-row">
          <gc-button
            class="tfi-sidebar-body-content-row__button"
            type="full"
            mode="outline"
            text="Regresar"
            (click)="onHide()"
          ></gc-button>
        </div>
      </div>
    </div>
  </p-sidebar>
</div>
