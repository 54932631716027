import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';

const modules = [TableModule, TooltipModule, CalendarModule, TabViewModule];

@NgModule({
  declarations: [],
  imports: [...modules],
  exports: [...modules],
})
export class SharedPrimengModule {}
