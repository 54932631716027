<div>
  <p-sidebar
    class="tfi-sidebar"
    [(visible)]="isShippingSidebarVisible"
    appendTo="body"
    position="right"
    (onHide)="onHide()"
  >
    <ng-template pTemplate="header">
      <span class="tfi-sidebar__title">Edita el envío</span>
    </ng-template>
    <div class="tfi-sidebar-body">
      <div class="tfi-sidebar-body-form" [formGroup]="form">
        <gc-button
          class="tfi-sidebar-body-form__link"
          mode="ghost"
          size="sm"
          text="Ver más"
          (click)="navigateToEditShipping()"
        ></gc-button>
        <gc-button
          class="tfi-sidebar-body-form__link"
          mode="ghost"
          size="sm"
          text="Ver detalle de el ticket"
          (click)="navigateToTicketTakePayment()"
        ></gc-button>
        <div class="tfi-sidebar-body-form__information">
          <span>{{ selectedShipping?.customer?.name }}</span>
        </div>
        <div class="tfi-sidebar-body-form-driver">
          <gc-label
            class="tfi-sidebar-body-form-driver__input"
            label="Encomendista"
            [control]="driverNameFormControl"
          >
            <gc-select
              id="shippingDriverName"
              placeHolder="Encomendista"
              [data]="driverNameInputOptions"
              formControlName="driverName"
            >
            </gc-select>
          </gc-label>
          <gc-button
            class="tfi-sidebar-body-form-driver__button"
            icon="user-plus"
            mode="outline"
            pTooltip="Crear nuevo encomendista"
            tooltipPosition="top"
            (click)="onCreateNewDriver()"
          ></gc-button>
        </div>
        <gc-label
          class="tfi-sidebar-body-form__input"
          label="Fecha de entrega (encomendista)"
          [control]="deliveryDayFormControl"
        >
          <gc-calendar
            [readOnly]="true"
            [minDate]="minDate"
            placeholder="Seleccione una fecha"
            formControlName="deliveryDay"
          ></gc-calendar>
        </gc-label>
        <gc-label
          class="tfi-sidebar-body-form__input"
          label="Hora retiro"
          [control]="deliveryHourFormControl"
        >
          <gc-select
            id="shippingDeliveryHour"
            formControlName="deliveryHour"
            [data]="operationTime"
            placeholder="Hora de entrega"
            [enableSearch]="true"
          ></gc-select>
        </gc-label>
        <gc-label
          class="tfi-sidebar-body-form__input"
          label="Precio de envío"
          [control]="shippingPriceFormControl"
        >
          <gc-input
            id="shippingPrice"
            icon="dollar-sign"
            type="number"
            formControlName="shippingPrice"
            placeHolder="Precio de envío"
          ></gc-input>
        </gc-label>
        <gc-label
          class="tfi-sidebar-body-form__input"
          label="Destino"
          [control]="addressFormControl"
        >
          <gc-input
            id="shippingLocation"
            type="text"
            formControlName="address"
            placeHolder="Destino"
          ></gc-input>
        </gc-label>
        <gc-label
          class="tfi-sidebar-body-form__input"
          label="Departamento"
          [control]="stateFormControl"
        >
          <gc-input
            id="shippingState"
            type="text"
            formControlName="state"
            placeHolder="Departamento"
          ></gc-input>
        </gc-label>
        <gc-label
          class="tfi-sidebar-body-form__input"
          label="Municipio"
          [control]="cityFormControl"
        >
          <gc-input
            id="shippingCity"
            type="text"
            formControlName="city"
            placeHolder="Municipio"
          ></gc-input>
        </gc-label>
        <gc-button
          class="tfi-sidebar-body-form__button"
          type="full"
          mode="outline"
          text="Cancelar"
          (click)="onHide()"
        ></gc-button>
        <gc-button
          class="tfi-sidebar-body-form__input"
          type="full"
          text="Editar el envío"
          [isLoading]="isLoading"
          (click)="onSubmitForm()"
        ></gc-button>
      </div>
    </div>
  </p-sidebar>
</div>
