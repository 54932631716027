<div class="gc-input-container" [ngSwitch]="type">
  <input
    *ngSwitchCase="'number'"
    #inputElement
    NumbersOnlyDirective
    SetDecimalLimit
    [decimalPlaces]="decimalLimit"
    [id]="id"
    [placeholder]="placeHolder"
    [min]="min"
    [max]="max"
    [maxLength]="maxLength"
    [(ngModel)]="value"
    (input)="onChange(value)"
    (blur)="onTouch()"
    [disabled]="disabled"
    class="gc-input-container__input"
    [ngClass]="{
      'gc-input-container__input--icon-left': icon && iconPosition === 'left',
      'gc-input-container--disabled': disabled
    }"
  />
  <input
    *ngSwitchCase="'cardNumber'"
    #inputElement
    creditCardDirective
    NumbersOnlyDirective
    [id]="id"
    [placeholder]="placeHolder"
    [(ngModel)]="value"
    (input)="onChange(value)"
    (blur)="onTouch()"
    [disabled]="disabled"
    class="gc-input-container__input"
    [ngClass]="{
      'gc-input-container--disabled': disabled
    }"
  />
  <input
    *ngSwitchCase="'expirationCard'"
    #inputElement
    cardExpirationDirective
    NumbersOnlyDirective
    [id]="id"
    [placeholder]="placeHolder"
    [(ngModel)]="value"
    (input)="onChange(value)"
    (blur)="onTouch()"
    [disabled]="disabled"
    class="gc-input-container__input"
    [ngClass]="{
      'gc-input-container--disabled': disabled
    }"
  />
  <input
    *ngSwitchCase="'percent'"
    #inputElement
    NumbersOnlyDirective
    SetDecimalLimit
    [decimalPlaces]="2"
    [id]="id"
    [placeholder]="placeHolder"
    [min]="min"
    [max]="max"
    [maxLength]="maxLength"
    [(ngModel)]="value"
    (input)="onChange(value)"
    (blur)="onTouch()"
    [disabled]="disabled"
    class="gc-input-container__input-percent"
    [readonly]="readonly"
  />
  <input
    *ngSwitchDefault
    #inputElement
    [id]="id"
    [type]="type"
    [placeholder]="placeHolder"
    [min]="min"
    [max]="max"
    [maxLength]="maxLength"
    [(ngModel)]="value"
    (input)="onChange(value)"
    (blur)="onTouch()"
    [disabled]="disabled"
    class="gc-input-container__input"
    [mask]="mask"
    [readonly]="readonly"
    [ngClass]="{
      'gc-input-container__input--icon-left': icon && iconPosition === 'left',
      'gc-input-container--disabled': disabled
    }"
  />

  <gc-icon
    *ngIf="icon"
    [dimension]="18"
    [icon]="icon"
    color="grey-400"
    class="gc-input-container__icon"
    [ngClass]="{
    'gc-input-container__icon--left': icon && iconPosition === 'left',
    'gc-input-container__icon--right': icon && iconPosition === 'right',
  }"
  ></gc-icon>
  <gc-icon
    *ngIf="type === 'percent'"
    [dimension]="18"
    icon="percent"
    color="grey-400"
    class="gc-input-container__icon"
    [ngClass]="{
    'gc-input-container__icon--percent':type === 'percent',
  }"
  ></gc-icon>
  <gc-icon
    *ngIf="iconPassword"
    [dimension]="20"
    [icon]="iconPassword"
    class="gc-input-container__icon-password"
    (click)="onIconPasswordClick()"
  ></gc-icon>
</div>
