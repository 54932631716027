export enum entityCodeEnum {
  APT = 'ADMIN_PAYMENT_TRANSACTION',
  INVOICE = 'INVOICE',
  BRANCH = 'BRANCH',
  BRAND = 'BRAND',
  CATEGORY = 'CATEGORY',
  COMPANY = 'COMPANY',
  CUSTOMER = 'CUSTOMER',
  INVENTORY = 'INVENTORY',
  KITCHEN_ORDER = 'KITCHEN_ORDER',
  PRODUCT = 'PRODUCT',
  SHIPPING = 'SHIPPING',
  SHIPPING_COMPANY = 'SHIPPING_COMPANY',
  TICKET = 'TICKET',
  USER = 'USER',
  VENDOR = 'VENDOR',
  ROLE = 'ROLE',
}

export enum entityLabelEnum {
  transactions = 'Transacciones',
  billing = 'Facturación',
  branches = 'Sucursales',
  brands = 'Marcas',
  categories = 'Categorías',
  company = 'Compañia',
  customers = 'Clientes',
  inventory = 'Inventario',
  KDS = 'Ordenes de cocina (KDS)',
  products = 'Productos',
  shippings = 'Envíos',
  shippings_company = 'Envíos de compañia',
  ticket = 'POS',
  users = 'Usuarios',
  vendors = 'Proveedores',
  roles = 'Roles',
}
