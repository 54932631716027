import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IMultimediaResponse,
  IProduct,
  IProductBatchUpdate,
  IProductBatchUpdateResponce,
  IProductBulkResponse,
  IProductResponse,
  IProductsResponse,
  IShowInCatalogPayload,
  ISkuVerificationResponse,
  environment,
  multimediaMethods,
  productMethods,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Observable } from 'rxjs';
import { ProductFilters } from '../../../constants/product.filter';

@Injectable({
  providedIn: 'root',
})
export class ProductDataService {
  private host = environment.api_host;

  private readonly productUrl = environment.api_host + productMethods.product;

  constructor(private http: HttpClient) {}

  getProducts(filterData: ProductFilters): Observable<IProductsResponse> {
    let params = new HttpParams();

    Object.keys(filterData).forEach((field) => {
      if (filterData[field]) {
        params = params.set(field, filterData[field]);
      }
    });

    return this.http.get<IProductsResponse>(this.productUrl, { params });
  }

  getProduct(productId: string): Observable<IProductResponse> {
    return this.http.get<IProductResponse>(`${this.productUrl}/${productId}`);
  }

  createProduct(product: IProduct): Observable<IProductResponse> {
    return this.http.post<IProductResponse>(this.productUrl, product);
  }

  updateProduct(
    productId: string,
    product: IProduct
  ): Observable<IProductResponse> {
    return this.http.put<IProductResponse>(
      `${this.productUrl}/${productId}`,
      product
    );
  }

  uploadImage(imageUrl: FormData): Observable<IMultimediaResponse> {
    return this.http.post<IMultimediaResponse>(
      this.host + multimediaMethods.image,
      imageUrl
    );
  }

  validateSKU(
    type: string,
    value: string
  ): Observable<ISkuVerificationResponse> {
    return this.http.get<ISkuVerificationResponse>(
      `${this.productUrl}/verify-sku?type=${type}&code=${value}`
    );
  }

  batchUpdateProdStatus(
    prodUpdate: IProductBatchUpdate
  ): Observable<IProductBatchUpdateResponce> {
    return this.http.put<IProductBatchUpdateResponce>(
      `${this.productUrl}/change-status`,
      prodUpdate
    );
  }

  bulkCreation(file: File): Observable<IProductBulkResponse> {
    const body = new FormData();
    body.append('excelFile', file);
    return this.http.post<IProductBulkResponse>(
      `${this.productUrl}/bulk-create`,
      body
    );
  }

  downloadTemplateProduct() {
    return this.http.get(`${this.productUrl}/download-bulk-template`, {
      responseType: 'blob',
    });
  }

  bulkShowInCatalog(payload: IShowInCatalogPayload) {
    return this.http.put(`${this.productUrl}/bulk-show-catalog`, payload);
  }
}
