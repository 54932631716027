import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { catchError, EMPTY, Subject, take, takeUntil } from 'rxjs';
import { CatalogViewService } from '../../services/catalog/view/catalog-view.service';
import {
  ICatalogProduct,
  ICategory,
  ICompany,
} from '@tfi-workspace-web/tfi-shared-utils';
import { GCComponentsModule } from '@gc-tech/components';
import { Swiper, SwiperOptions } from 'swiper/types';
import { register } from 'swiper/element/bundle';
import { ProductFilters } from '@tfi-workspace-web/tfi-feat-product-management';
import { Router, RouterModule } from '@angular/router';
import { EcomProductListComponent } from '../../components/ecom-product-list/ecom-product-list.component';

register();

@Component({
  selector: 'e-tfi-ecom-main-page',
  standalone: true,
  imports: [
    CommonModule,
    GCComponentsModule,
    RouterModule,
    EcomProductListComponent,
  ],
  templateUrl: './ecommerce-page.component.html',
  styleUrls: ['./ecommerce-page.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EcommercePageComponent implements OnInit, OnDestroy {
  @ViewChild('swiperRef', { static: true }) _swiperRef: ElementRef | undefined;
  swiper?: Swiper;
  categories: ICategory[] = [];
  products: ICatalogProduct[] = [];
  filters: ProductFilters;
  company!: ICompany;
  totalTableElements?: number;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private catalogViewService: CatalogViewService,
    private router: Router
  ) {
    this.filters = new ProductFilters();
  }

  ngOnInit(): void {
    this.configSwiperCarousel();
    this.getFiltersFromStorage();
    this.getCategories();
    this.getCompany();
    this.checkProducts();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  checkProducts(): void {
    this.router.navigateByUrl(`/catalog/${this.filters.categoryName ?? ''}`);
  }

  listenToCatChanges(): void {
    this.catalogViewService.currentCat$
      .pipe(takeUntil(this.destroy$))
      .subscribe((cat) => {
        if (this.categories.length > 0) {
          this.categories.map((category) => {
            category.isSelected = false;
          });
          const ind = this.categories.findIndex(
            (category) => category.categoryName === cat
          );
          this.categories[ind > 0 ? ind : 0].isSelected = true;
        }
      });
  }

  getCompany(): void {
    this.catalogViewService.company$.subscribe((company) => {
      this.company = company;
    });
  }

  getCategories() {
    this.catalogViewService
      .getCategories()
      .pipe(
        take(1),
        catchError(() => {
          return EMPTY;
        })
      )
      .subscribe((categories) => {
        this.categories = categories.data.categories;
        if (
          this.categories.length > 0 &&
          this.categories[0].categoryName !== 'Todos los articulos'
        )
          this.categories.unshift({
            categoryName: 'Todos los articulos',
            isSelected: false,
          });
        this.applyCategoryFilter();
        this.listenToCatChanges();
      });
  }

  configSwiperCarousel() {
    const options: SwiperOptions = {
      spaceBetween: 15,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        300: {
          slidesPerView: 2,
        },
        450: {
          slidesPerView: 3,
        },
        524: {
          slidesPerView: 4,
        },
        750: {
          slidesPerView: 5,
        },
        850: {
          slidesPerView: 6,
        },
        1000: {
          slidesPerView: 6,
        },
        1200: {
          slidesPerView: 6,
        },
        1400: {
          slidesPerView: 7,
        },
        1600: {
          slidesPerView: 8,
        },
        1800: {
          slidesPerView: 9,
        },
        2100: {
          slidesPerView: 10,
        },
      },
    };

    const swiperEl = this._swiperRef?.nativeElement;
    Object.assign(swiperEl, options);

    swiperEl.initialize();

    if (this.swiper) this.swiper.currentBreakpoint = false;
    this.swiper = this._swiperRef?.nativeElement.swiper;
  }

  filterProductsByCategory(category: ICategory) {
    this.filters.productName = '';
    this.saveFiltersInStorage();
    this.categories.map((category) => {
      category.isSelected = false;
    });
    category.isSelected = true;
    if (category.categoryName === 'Todos los articulos') {
      this.filters.categoryName = '';
      this.saveFiltersInStorage();
      this.router.navigateByUrl(`/catalog`);
      return;
    }
    this.filters.categoryName = category.categoryName;
    this.saveFiltersInStorage();
    this.router.navigateByUrl(`/catalog/${this.filters.categoryName}`);
  }

  saveFiltersInStorage() {
    localStorage.setItem('catalogFilters', JSON.stringify(this.filters));
  }

  getFiltersFromStorage() {
    const filtersExist = localStorage?.getItem('catalogFilters');
    if (filtersExist) {
      this.filters = JSON.parse(filtersExist);
    }
  }

  applyCategoryFilter() {
    this.categories.forEach((category) => {
      if (category.categoryName === this.filters.categoryName) {
        category.isSelected = true;
      } else if (this.filters.categoryName === '') {
        this.categories[0].isSelected = true;
      }
    });
  }

  openWhatsapp(phoneNumber?: string) {
    if (phoneNumber) {
      const message = encodeURIComponent(
        'Quiero mas información de sus productos.'
      );
      const url = `https://wa.me/${phoneNumber}?text=${message}`;
      window.open(url, '_blank');
    }
  }

  makeCall(phoneNumber?: string) {
    if (phoneNumber) {
      const url = `tel:${phoneNumber}`;
      window.open(url);
    }
  }
}
