import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IMenuOptions } from '../../interfaces/menu-options.interface';
import { ITableColumn, ITableItem } from '../../interfaces/table.interface';
import { TableColumnTypeEnum, TableEntityEnum } from './table.component.enum';

@Component({
  selector: 'gc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges {
  @Input() data: any = [];
  @Input() columns: ITableColumn[] = [];
  @Input() rows = 10;
  @Input() showPaginator = true;
  @Input() skeleton = false;
  @Input() rowsPerPageOptions: number[] = [10, 20, 30];
  @Input() showCurrentPageReport = true;
  @Input() selectionMode: 'single' | 'multiple' = 'multiple';
  @Input() rowHover = true;
  @Input() dataKey = '';
  @Input() enableCheckBox = false;
  @Input() options?: IMenuOptions[];
  @Output() selectedAction = new EventEmitter<ITableItem>();
  @Output() selectedRows = new EventEmitter<any[]>();
  selectedEntities: any[] = [];
  columnType = TableColumnTypeEnum;
  entityType = TableEntityEnum;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.selectedEntities = [];
      this.selectedRows.emit([]);
    }
  }

  setAction(action: string, data: any, index: number) {
    const detail = {
      data,
      index,
      action,
    };
    this.selectedAction.emit(detail);
  }
  updateSelection() {
    this.selectedRows.emit(this.selectedEntities);
  }
}
