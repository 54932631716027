import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { ProductDataService } from '../../data/product-data/product-data.service';
import {
  IProduct,
  IProductBatchUpdate,
  IShowInCatalogPayload,
} from '@tfi-workspace-web/tfi-shared-utils';
import { ProductFilters } from '../../../constants/product.filter';

@Injectable({
  providedIn: 'root',
})
export class ProductViewService {
  private productsSubject = new BehaviorSubject<IProduct[]>([]);
  private _totalElements = 0;

  products$ = this.productsSubject.asObservable();

  get totalElements(): number {
    return this._totalElements;
  }

  constructor(private data: ProductDataService) {}

  getProducts(filterData: ProductFilters) {
    return this.data
      .getProducts(filterData)
      .pipe(take(1))
      .subscribe((response) => {
        this._totalElements = response.total;
        this.productsSubject.next(response.data.products);
      });
  }

  getProduct(productId: string) {
    return this.data.getProduct(productId);
  }

  createProduct(product: IProduct) {
    return this.data.createProduct(product);
  }

  updateProduct(productId: string, product: IProduct) {
    return this.data.updateProduct(productId, product);
  }

  uploadImage(imageUrl: FormData) {
    return this.data.uploadImage(imageUrl);
  }

  validateSKU(type: string, value: string) {
    return this.data.validateSKU(type, value);
  }

  updateProdStatus(prodUpdate: IProductBatchUpdate) {
    return this.data.batchUpdateProdStatus(prodUpdate);
  }

  bulkCreation(file: File) {
    return this.data.bulkCreation(file);
  }

  downloadTemplateProd(): Observable<Blob> {
    return this.data.downloadTemplateProduct();
  }

  bulkShowInCatalog(payload: IShowInCatalogPayload) {
    return this.data.bulkShowInCatalog(payload);
  }
}
