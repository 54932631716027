import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IShippingResponse,
  IShippingSend,
  IShippingsResponse,
  environment,
  shippingMethods,
  IShippingCompanyResponse,
  IShippingCompaniesResponse,
  IShippingCompany,
} from '@tfi-workspace-web/tfi-shared-utils';
import { ShippingListFilters } from '../../../constants/shipping-filter';

@Injectable({
  providedIn: 'root',
})
export class ShippingDataService {
  private readonly shippingUrl =
    environment.api_host + shippingMethods.shipping;

  constructor(private http: HttpClient) {}

  getShippings(
    filterData: ShippingListFilters
  ): Observable<IShippingsResponse> {
    let params = new HttpParams();
    Object.keys(filterData).forEach((field) => {
      if (filterData[field]) params = params.set(field, filterData[field]);
    });
    return this.http.get<IShippingsResponse>(this.shippingUrl, {
      params,
    });
  }

  createShipping(shipping: IShippingSend): Observable<IShippingResponse> {
    return this.http.post<IShippingResponse>(this.shippingUrl, shipping);
  }

  getShippingById(id: string): Observable<IShippingResponse> {
    return this.http.get<IShippingResponse>(`${this.shippingUrl}/${id}`);
  }

  updateShipping(
    shippingId: string,
    shipping: IShippingSend
  ): Observable<IShippingResponse> {
    return this.http.put<IShippingResponse>(
      `${this.shippingUrl}/${shippingId}`,
      shipping
    );
  }

  patchShipping(shippingId: string, data: any): Observable<IShippingResponse> {
    return this.http.patch<IShippingResponse>(
      `${this.shippingUrl}/${shippingId}`,
      data
    );
  }

  updateShippingStatusBulk(payload: any) {
    return this.http.put(`${this.shippingUrl}/status-update/bulk`, payload);
  }

  getShippingCompany(): Observable<IShippingCompaniesResponse> {
    return this.http.get<IShippingCompaniesResponse>(
      `${this.shippingUrl}company`
    );
  }

  createShippingCompany(
    shippingCompany: IShippingCompany
  ): Observable<IShippingCompanyResponse> {
    return this.http.post<IShippingCompanyResponse>(
      `${this.shippingUrl}company`,
      shippingCompany
    );
  }

  downloadPdf(filterData: ShippingListFilters) {
    let params = new HttpParams();
    Object.keys(filterData).forEach((field) => {
      if (filterData[field]) params = params.set(field, filterData[field]);
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
      Accept: 'application/pdf',
    });
    return this.http.get(`${this.shippingUrl}/report/pdf`, {
      params,
      headers,
      responseType: 'arraybuffer',
    });
  }

  printShippingSelected(shippingsSelectedIds: string[], layout: string) {
    const body = {
      shippings: shippingsSelectedIds,
      layout: layout,
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    });

    return this.http.post(`${this.shippingUrl}/print-label`, body, {
      headers: headers,
      responseType: 'blob' as 'json',
    });
  }

  softDeleteShipping(shippingId: string, payload: any) {
    return this.http.delete(`${this.shippingUrl}/${shippingId}`, payload);
  }
}
