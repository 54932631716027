import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { noop } from 'rxjs';

@Component({
  selector: 'gc-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CalendarComponent),
    },
  ],
})
export class CalendarComponent {
  @Output() dateSelected = new EventEmitter<any>();
  @Input() id?: string;
  @Input() type: 'single' | 'range' = 'single';
  @Input() placeholder?: string;
  @Input() disabled = false;
  @Input() readOnly = false;
  @Input() minDate: any;
  @Input() maxDate: any;
  @Input() textAlign: 'left' | 'center' | 'right' = 'left';

  formControl: FormControl = new FormControl<string>('');
  value = '';

  onChange: (value: string) => void = noop;
  onTouch: () => void = noop;

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    this.formControl.setValue(value, { emitEvent: false });
    this.value = value;
    this.onSelect();
  }

  onInput() {
    this.onChange(this.value);
  }

  onSelect() {
    this.onChange(this.value);
    this.dateSelected.emit(this.value);
  }

  onBlur() {
    this.onTouch();
  }
}
