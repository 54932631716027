<ul
  class="mobile-menu-container"
  *ngFor="let menuOption of menuItems; let i = index"
>
  <ng-container *ngIf="i === 0 || menuOption.hasAccess">
    <li
      class="menu-item"
      (click)="onHideMobileSidebarMenu()"
      [routerLink]="menuOption.path"
    >
      <div class="menu-label-container">
        <gc-icon
          *ngIf="menuOption.children.length > 0"
          class="menu-label-container__icon"
          icon="chevron-down"
          [dimension]="16"
        ></gc-icon>
        <span class="menu-label-container__label">{{ menuOption.label }}</span>
      </div>
      <ul class="inner-options" *ngIf="menuOption.isSelected" [@menuAnimation]>
        <li
          class="inner-options__item"
          *ngFor="let innerOption of menuOption.children"
        >
          <span>{{ innerOption.label }}</span>
        </li>
      </ul>
    </li>
  </ng-container>
</ul>
