<div class="tfi-upload">
  <ng-template #empty>
    <div
      class="tfi-upload-drag-drop-container"
      (dragover)="onDragOver($event)"
      (drop)="onDrop($event)"
      (click)="fileInput.click()"
    >
      <div class="tfi-upload-drag-drop-container-icon-container">
        <gc-icon icon="upload" color="grey-500"></gc-icon>
      </div>
      <div class="tfi-upload-drag-drop-container-content">
        <a class="tfi-upload-drag-drop-container-content__label">
          Arrastre y suelte un archivo aquí o haz clic para seleccionar un
          archivo</a
        >
        <p
          *ngIf="showMaxSizeLabel"
          class="tfi-upload-drag-drop-container-content__size"
        >
          Tamaño maximo: {{ maxSizeInBytes | number }} Bytes
        </p>
      </div>
      <input
        type="file"
        class="tfi-upload-drag-drop-container__input"
        #fileInput
        (change)="onFileSelected($event)"
      />
    </div>
  </ng-template>

  <div class="tfi-upload-uploaded-container" *ngIf="file; else empty">
    <div class="tfi-upload-uploaded-container-icon-container">
      <gc-icon icon="check-circle" color="green-500"></gc-icon>
    </div>
    <div class="tfi-upload-uploaded-container-content">
      <span class="tfi-upload-file-uploaded-container-content__name">{{
        file.name
      }}</span>
      <span class="tfi-upload-uploaded-container-content__size"
        >{{ (file.size / 1024).toFixed(2) }} KB</span
      >
      <span class="tfi-upload-uploaded-container-content__state">Cargado</span>
    </div>
    <div class="tfi-upload-uploaded-container__delete-button">
      <gc-icon icon="trash-2" (click)="removeFile()"></gc-icon>
    </div>
  </div>
</div>
