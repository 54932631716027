import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';
import { ClaimsEnum } from '@tfi-workspace-web/tfi-shared-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(
    private authViewService: AuthenticationViewService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      this.authViewService.userProfile.requirePasswordUpdate &&
      this.authViewService.isAuthenticated
    ) {
      this.authViewService.logout();
    }
    if (!this.authViewService.isAuthenticated) {
      this.router.navigate(['authentication/login']);
    }
    if (route.data['role']) {
      const userRoles = this.authViewService.userProfile.claims;
      const index = route.data['role'].findIndex((claim: string) =>
        userRoles.includes(claim as ClaimsEnum)
      );

      if (index === -1) {
        this.router.navigate([this.router.url]);
      }
    }

    return true;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(next, state);
  }
}
