export enum StatesEnum {
  SAN_SALVADOR = 'San Salvador',
  LA_LIBERTAD = 'La Libertad',
}

export enum CitiesEnum {
  SAN_SALVADOR_NORTE = 'San Salvador Norte',
  SAN_SALVADOR_OESTE = 'San Salvador Oeste',
  SAN_SALVADOR_ESTE = 'San Salvador Este',
  SAN_SALVADOR_CENTRO = 'San Salvador Centro',
  SAN_SALVADOR_SUR = 'San Salvador Sur',
  LA_LIBERTAD_NORTE = 'La Libertad Norte',
  LA_LIBERTAD_CENTRO = 'La Libertad Centro',
  LA_LIBERTAD_OESTE = 'La Libertad Oeste',
  LA_LIBERTAD_ESTE = 'La Libertad Este',
  LA_LIBERTAD_COSTA = 'La Libertad Costa',
  LA_LIBERTAD_SUR = 'La Libertad Sur',
}

export enum DistricEnum {
  AGUILARES = 'Aguilares',
  EL_PAISNAL = 'El Paisnal',
  GUAZAPA = 'Guazapa',
  APOPA = 'Apopa',
  NEJAPA = 'Nejapa',
  ILOPANGO = 'Ilopango',
  SAN_MARTIN = 'San Martin',
  SOYAPANGO = 'Soyapango',
  TONACATEPEQUE = 'Tonacatepeque',
  AYUTUXTEPEQUE = 'Ayutuxtepeque',
  MEJICANOS = 'Mejicanos',
  CUSCATANCINGO = 'Cuscatancingo',
  CIUDAD_DELGADO = 'Ciudad Delgado',
  SAN_SALVADOR = 'San Salvador',
  SAN_MARCOS = 'San Marcos',
  SANTO_TOMAS = 'Santo Tomas',
  SANTIAGO_TEXACUANGOS = 'Santiago Texacuangos',
  PANCHIMALCO = 'Panchimalco',
  ROSARIO_DE_MORA = 'Rosario De Mora',
  QUEZALTEPEQUE = 'Quazaltepeque',
  SAN_MATIAS = 'San Matias',
  SAN_PABLO_TACACHICO = 'San Pablo Tocachico',
  SAN_JUAN_OPICO = 'San Juan Opico',
  CIUDAD_ARCE = 'Ciudad Arce',
  COLON = 'Colon',
  JAYAQUE = 'Jayaque',
  SACACOYO = 'Sacacoyo',
  TEPECOYO = 'Tepecoyo',
  TALNIQUE = 'Talnique',
  ANTIGUO_CUSCATLAN = 'Antiguo Cuscatlán',
  HUIZUCAR = 'Huizucar',
  NUEVO_CUSCATLAN = 'Nuevo Cuscatlán',
  SAN_JOSE_VILLANUEVA = 'San José Villanueva',
  ZARAGOZA = 'Zaragoza',
  CHILTIUPAN = 'Chiltuipan',
  JICALAPA = 'Jicalapa',
  LA_LIBERTAD = 'La LIbertad',
  TAMANIQUE = 'Tamanique',
  TEOTEPEQUE = 'Teotepeque',
  SANTA_TECLA = 'Santa Tecla',
  COMASAGUA = 'Comasagua',
}
