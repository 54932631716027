<div class="roles-container" [@slideInFromRight]>
  <div class="controls-container">
    <div class="search-container">
      <div class="search-container__input">
        <form [formGroup]="formSearch">
          <gc-input
            id="searchProduct"
            #searchTerm
            type="text"
            formControlName="searchFilter"
            icon="search"
            placeHolder="Buscar por nombre de rol"
          ></gc-input>
        </form>
      </div>
    </div>
    <gc-button
      *PermissionControl="[userPermissionsEnum.USR_ROL_CRT]"
      class="product-add-btn"
      text="Crear role"
      icon="plus"
      type="full"
      (click)="createNewRole()"
    ></gc-button>
  </div>
  <div class="table-container">
    <tfi-feat-bm-role-table
      [data]="roles"
      [skeleton]="isSearching"
      [totalTableElements]="totalTableElements"
      (roleSelected)="manageAction($event)"
    ></tfi-feat-bm-role-table>
  </div>
  <div *ngIf="totalTableElements && filters.page && filters.limit">
    <gc-paginator
      [totalElements]="totalTableElements"
      [currentPage]="filters.page"
      [limitPerPage]="filters.limit"
      (pageChanged)="pageChanged($event)"
      (limitPerPageChanged)="limitPerPageChange($event)"
    ></gc-paginator>
  </div>
</div>
