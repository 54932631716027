import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[creditCardDirective]',
})
export class CreditCardDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: any) {
    const inputValue = event.target.value.replace(/\D/g, '').substring(0, 16); // Eliminar caracteres no numéricos y limitar a 16 dígitos
    const formattedValue = this.formatCreditCardNumber(inputValue);
    this.el.nativeElement.value = formattedValue;
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const pastedInput = event
      .clipboardData!.getData('text/plain')
      .replace(/\D/g, '')
      .substring(0, 16);
    const formattedValue = this.formatCreditCardNumber(pastedInput);
    this.el.nativeElement.value = formattedValue;
    event.preventDefault();
  }

  private formatCreditCardNumber(value: string): string {
    const formattedValue = value.replace(/(\d{4})/g, '$1-');
    return formattedValue.endsWith('-')
      ? formattedValue.slice(0, -1)
      : formattedValue;
  }
}
