import { Route } from '@angular/router';
import { MainLayoutComponent } from './modules/layout/pages/main-layout/main-layout.component';
import { AuthenticationGuard } from './guards/authentication.guard';
import { UserPermissionsEnum } from '@tfi-workspace-web/tfi-shared-utils';
import { KdsAccessGuard } from './guards/kds-access.guard';
import { UserPermissonsGuard } from './guards/user-permissions.guard';
import { NotFoundComponent } from '@tfi-workspace-web/libs/tfi-ecommerce-lib';

export const appRoutes: Route[] = [
  { path: '', redirectTo: 'admin', pathMatch: 'full' },
  {
    path: 'admin',
    component: MainLayoutComponent,
    canActivate: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    children: [
      { path: '', redirectTo: 'dashboard-management', pathMatch: 'full' },
      {
        path: 'product-management',
        loadChildren: () =>
          import('./modules/product-management/product-management.module').then(
            (m) => m.ProductManagementModule
          ),
      },
      {
        path: 'customer-admin',
        loadChildren: () =>
          import('./modules/customer/customer.module').then(
            (m) => m.CustomerModule
          ),
        data: { permissions: [UserPermissionsEnum.USR_CST_LST] },
        canActivate: [UserPermissonsGuard],
      },
      {
        path: 'business-management',
        loadChildren: () =>
          import(
            './modules/business-management/business-management.module'
          ).then((m) => m.BusinessManagementModule),
      },
      {
        path: 'shipping-management',
        loadChildren: () =>
          import(
            './modules/shipping-management/shipping-management.module'
          ).then((m) => m.ShippingManagementModule),
        data: { permissions: [UserPermissionsEnum.USR_SHP_LST] },
        canActivate: [UserPermissonsGuard],
      },
      {
        path: 'inventory-management',
        loadChildren: () =>
          import(
            './modules/inventory-management/inventory-management.module'
          ).then((m) => m.InventoryManagementModule),
        data: { permissions: [UserPermissionsEnum.USR_INVNT_LST] },
        canActivate: [UserPermissonsGuard],
      },
      {
        path: 'ticket-management',
        loadChildren: () =>
          import('./modules/ticket-management/ticket-management.module').then(
            (m) => m.TicketManagementModule
          ),
        data: { permissions: [UserPermissionsEnum.USR_TKT_LST] },
        canActivate: [UserPermissonsGuard],
      },
      {
        path: 'kds-management',
        canActivate: [KdsAccessGuard, UserPermissonsGuard],
        loadChildren: () =>
          import('./modules/kitchen-management/kitchen-management.module').then(
            (m) => m.KitchenManagementModule
          ),
        data: { permissions: [UserPermissionsEnum.USR_KOD_LST] },
      },
      {
        path: 'scheduler-management',
        loadChildren: () =>
          import(
            './modules/scheduler-management/scheduler-management.module'
          ).then((m) => m.SchedulerManagementModule),
        data: { permissions: [UserPermissionsEnum.USR_SHC_LST] },
        canActivate: [UserPermissonsGuard],
      },
      {
        path: 'dashboard-management',
        loadChildren: () =>
          import(
            './modules/dashboard-management/dashboard-management.module'
          ).then((m) => m.DashboardManagementModule),
      },
    ],
  },
  {
    path: 'authentication',
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
];
