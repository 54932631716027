import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

@Component({
  selector: 'gc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() id = '';
  @Input() label?: string;
  @Input() checked = false;
  @Input() disabled = false;

  onChange: (value: boolean) => void = noop;
  onTouch: () => void = noop;

  writeValue(value: boolean) {
    this.checked = value;
  }
  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  switch() {
    if (!this.disabled) {
      this.onTouch();
      this.onChange(!this.checked);
      this.checked = !this.checked;
    }
  }
}
