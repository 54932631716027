<div class="gc-label-main">
  <div class="gc-label-container">
    <label class="gc-label-container__label">{{ label }}</label>
    <gc-icon
      *ngIf="showTooltip"
      icon="alert-circle"
      class="gc-label-container__icon"
      [pTooltip]="tooltip"
      [dimension]="18"
      tooltipPosition="top"
    ></gc-icon>
  </div>
  <div class="gc-content-element">
    <ng-content></ng-content>
  </div>
  <div
    class="gc-label-messages-container"
    *ngIf="currentMessage || touchedOnly"
  >
    <gc-icon
      color="error"
      icon="alert-circle"
      class="gc-label-messages-container__icon"
      [dimension]="16"
    ></gc-icon>
    <span class="gc-label-messages-container__message">{{
      currentMessage ?? defaultMessage
    }}</span>
  </div>
</div>
