import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import { IMenuItem } from '@tfi-workspace-web/tfi-shared-utils';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { CMenuBreakpoint } from '../../constants/layout-breakpoints';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';

@Component({
  selector: 'tfi-mobile-sidebar',
  templateUrl: './mobile-sidebar.component.html',
  styleUrls: ['./mobile-sidebar.component.scss'],
  animations: [
    trigger('menuAnimation', [
      state('void', style({ height: '0', opacity: '0' })),
      state('*', style({ height: '*', opacity: '1' })),
      transition('void <=> *', animate('200ms ease')),
    ]),
  ],
})
export class MobileSidebarComponent {
  @Input() menuItems: IMenuItem[] = [];
  @Output() hideMobileSidebarMenu = new EventEmitter();
  screenWidth?: number;
  withBreakpoint = CMenuBreakpoint;

  constructor(public authViewService: AuthenticationViewService) {}

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenWidth = window.innerWidth;
    this.validateScreenWidthForMenu();
  }

  validateScreenWidthForMenu() {
    if (this.screenWidth && this.screenWidth > this.withBreakpoint) {
      this.hideMMobileMenu();
    }
  }

  hideMMobileMenu() {
    this.menuItems.forEach((menuOption) => (menuOption.isSelected = false));
  }

  onHideMobileSidebarMenu() {
    this.hideMobileSidebarMenu.emit();
  }
}
