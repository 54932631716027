import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductFilters } from '@tfi-workspace-web/tfi-feat-product-management';
import {
  catalogMethods,
  environment,
  IBrandsResponse,
  ICategoriesResponse,
  ICompanyResponse,
  IProductCatalogResponse,
  IProductResponse,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CatalogDataService {
  private readonly catalogUrl = environment.api_host + catalogMethods.catalog;

  constructor(private http: HttpClient) {}

  getCategories(domain: string): Observable<ICategoriesResponse> {
    return this.http.get<ICategoriesResponse>(
      `${this.catalogUrl}/${domain}/category`
    );
  }

  getBrands(domain: string): Observable<IBrandsResponse> {
    return this.http.get<IBrandsResponse>(`${this.catalogUrl}/${domain}/brand`);
  }

  getCompany(domain: string): Observable<ICompanyResponse> {
    return this.http.get<ICompanyResponse>(
      `${this.catalogUrl}/${domain}/config`
    );
  }

  getProduct(prodName: string, domain: string): Observable<IProductResponse> {
    let params = new HttpParams();
    params = params.set('productName', prodName);
    return this.http.get<IProductResponse>(
      `${this.catalogUrl}/${domain}/product/product-detail`,
      { params }
    );
  }

  getProducts(
    filterData: ProductFilters,
    domain: string
  ): Observable<IProductCatalogResponse> {
    let params = new HttpParams();

    Object.keys(filterData).forEach((field) => {
      if (filterData[field]) {
        params = params.set(field, filterData[field]);
      }
    });

    return this.http.get<IProductCatalogResponse>(
      `${this.catalogUrl}/${domain}/product`,
      {
        params,
      }
    );
  }
}
