export interface ISaleSummaryResponse {
  status: string;
  data: {
    salesSummary: ISalesSummary;
  };
}

export interface ISalesSummary {
  totalSales: number;
  totalCost: number;
  totalMargin: number;
  percMargin: number;
  avgTicket: number;
  totalOperations: number;
}

//Monthly Sale

export interface IMonthlySaleResponse {
  status: string;
  data: {
    monthlySales: IMonthlySale[];
  };
}

export interface IMonthlySale {
  year: string;
  month: string;
  revenue: number;
  cost: number;
  margin: number;
  marginPerc: number;
}

export interface IMonthlySaleChart {
  name: string;
  value: number;
}

//Top Customer

export interface ITopCustomerResponse {
  status: string;
  data: {
    topCustomers: ITopCustomer[];
  };
}

export interface ITopCustomer {
  year: string;
  month: string;
  topCustomers: ITopCustomerDetail[];
}

export interface ITopCustomerDetail {
  customer: string;
  revenue: number;
  cost: number;
  margin: number;
  marginPerc: number;
}

//Top brands

export interface ITopBrandsResponse {
  status: string;
  data: {
    topBrands: ITopBrand[];
  };
}

export interface ITopBrand {
  year: string;
  month: string;
  topBrands: ITopBrandDetail[];
}

export interface ITopBrandDetail {
  brand: string;
  revenue: number;
  cost: number;
  margin: number;
  marginPerc: number;
}

//Top Categories

export interface ITopCategoriesResponse {
  status: string;
  data: {
    topCategories: ITopCategory[];
  };
}

export interface ITopCategory {
  year: string;
  month: string;
  topCategories: ITopCategoryDetail[];
}

export interface ITopCategoryDetail {
  category: string;
  revenue: number;
  cost: number;
  margin: number;
  marginPerc: number;
}

//others
export interface IPeriodDates {
  startDate: string;
  endDate: string;
}
