import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ButtonComponent } from './components/button/button.component';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { IconComponent } from './components/icon/icon.component';
import { InputComponent } from './components/input/input.component';
import { CommonModule } from '@angular/common';
import { BadgeComponent } from './components/badge/badge.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LabelComponent } from './components/label/label.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TableComponent } from './components/table/table.component';
import { MenuComponent } from './components/menu/menu.component';
import { SharedPrimengModule } from './modules/primeng/shared-primeng.module';
import { SelectComponent } from './components/select/select.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { LinkComponent } from './components/link/link.component';
import { AlertComponent } from './components/alert/alert.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { CardComponent } from './components/card/card.component';
import { NumbersOnlyDirective } from './directives/numbers-only/numbers-only.directive';
import { SetDecimalLimitDirective } from './directives/set-decimal-limit/set-decimal-limit.directive';
import { AccessControlDirective } from './directives/access-control/access-control.directive';
import { SearchSelectComponent } from './components/search-select/search-select.component';
import { SwitchCardComponent } from './components/switch-card/switch-card.component';
import { RadioCardComponent } from './components/radio-card/radio-card.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { DownloadUploadDialogComponent } from './components/download-upload-dialog/download-upload-dialog.component';
import { CreditCardDirective } from './directives/credit-card/credit-card.directive';
import { CardExpirationDateDirective } from './directives/card-expiration-date/card-expiration-date.directive';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { SmallInfoCardComponent } from './components/small-info-card/small-info-card.component';
import { SidebarProductBasicFormComponent } from './components/sidebar-product-basic-form/sidebar-product-basic-form.component';
import { SidebarModule } from 'primeng/sidebar';
import { SidebarShippingBasicFormComponent } from './components/sidebar-shipping-basic-form/sidebar-shipping-basic-form.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { register } from 'swiper/element/bundle';
import { PermissionControlDirective } from './directives/permission-control/permission-control.directive';
import { SidebarShippingDetailComponent } from './components/sidebar-shipping-detail/sidebar-shipping-detail.component';
import { CreateDriverDialogComponent } from './components/create-driver-dialog/create-driver-dialog.component';
import { TableActionsComponent } from './components/table-actions/table-actions.component';

register();

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

const components = [
  PermissionControlDirective,
  ImageGalleryComponent,
  AccessControlDirective,
  ButtonComponent,
  IconComponent,
  InputComponent,
  LabelComponent,
  RadioButtonComponent,
  CheckboxComponent,
  BadgeComponent,
  SwitchComponent,
  TableComponent,
  MenuComponent,
  SelectComponent,
  TextAreaComponent,
  LinkComponent,
  AlertComponent,
  CalendarComponent,
  ConfirmDialogComponent,
  EmptyStateComponent,
  CardComponent,
  NumbersOnlyDirective,
  SetDecimalLimitDirective,
  SearchSelectComponent,
  SwitchCardComponent,
  RadioCardComponent,
  UploadFileComponent,
  DownloadUploadDialogComponent,
  CreditCardDirective,
  CardExpirationDateDirective,
  PaginatorComponent,
  SmallInfoCardComponent,
  SidebarShippingBasicFormComponent,
  SidebarProductBasicFormComponent,
  SidebarShippingDetailComponent,
  CreateDriverDialogComponent,
  TableActionsComponent,
];

@NgModule({
  imports: [
    FeatherModule.pick(allIcons),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPrimengModule,
    NgxMaskModule.forRoot(),
    SidebarModule,
  ],
  declarations: [...components],
  exports: [...components, SharedPrimengModule, FeatherModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GCComponentsModule {}
