import { Component, Input } from '@angular/core';

@Component({
  selector: 'gc-small-info-card',
  templateUrl: './small-info-card.component.html',
  styleUrls: ['./small-info-card.component.scss'],
})
export class SmallInfoCardComponent {
  @Input() imageUrl?: string;
  @Input() primaryLabel?: string;
  @Input() secondaryLabel?: string;
  @Input() price?: number;
  @Input() inventory?: number;
  @Input() showInventory = false;
}
