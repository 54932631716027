import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum ConfirmDialogEnum {
  CONFIRM = 'CONFIRM',
  CANCEL = 'CANCEL',
  CLOSE = 'CLOSE',
}

export interface IConfirmDialogCloseData {
  action: ConfirmDialogEnum;
}

export interface IConfirmDialogData {
  title: string;
  subTitle: string;
  primaryButtonText: string;
  secondaryButtonText: string;
}
@Component({
  selector: 'gc-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  actionOptions = ConfirmDialogEnum;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData
  ) {}

  setAction(action: ConfirmDialogEnum) {
    this.dialogRef.close({ action } as IConfirmDialogCloseData);
  }
}
