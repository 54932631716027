import { IBaseEntity } from './base-entity.interface';
export interface IMultimediaResponse {
  status: string;
  data: {
    file: IFileMultimediaResponse;
  };
}

export interface IFileMultimediaResponse extends IBaseEntity {
  bucket: string;
  key: string;
  originalName: string;
  url: string;
  contentType: string;
  eTag: string;
  __v: string;
}
