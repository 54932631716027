import { Injectable } from '@angular/core';
import { TransactionDataService } from '../data/transaction-data.service';
import { catchError, EMPTY, Subscription, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransactionViewService {
  constructor(private data: TransactionDataService) {}

  getTransactions(startDate: string, endDate: string) {
    return this.data.getTransactions(startDate, endDate);
  }

  refreshPendingWompiTransactions(): Subscription {
    return (
      this.data
        .getRefreshWompiTransactions()
        .pipe(
          take(1),
          catchError((err) => {
            console.error(err);
            return EMPTY;
          })
        )
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .subscribe(() => {})
    );
  }
}
