import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[SetDecimalLimit]',
})
export class SetDecimalLimitDirective {
  @Input() decimalPlaces = 2; // Número de decimales permitidos

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private ngModel: NgModel
  ) {}

  @HostListener('input', ['$event']) onInput(event: Event) {
    const inputElement: HTMLInputElement = this.el.nativeElement;

    const value = inputElement.value;

    const regex = new RegExp(`^-?\\d*\\.?\\d{0,${this.decimalPlaces}}$`);
    if (!regex.test(value)) {
      this.renderer.setProperty(inputElement, 'value', value.slice(0, -1));
    }

    this.ngModel.update.emit(inputElement.value);
  }
}
