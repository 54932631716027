<div class="tfi-modal">
  <div class="tfi-modal-header">
    <div class="tfi-modal-header-icon-container">
      <gc-icon icon="file" [dimension]="20"></gc-icon>
    </div>
    <gc-icon
      class="tfi-modal-header__icon"
      icon="x"
      color="grey-700"
      [dimension]="18"
      (click)="closeDialog()"
    ></gc-icon>
  </div>
  <div class="tfi-modal-body">
    <div class="tfi-modal-body-title-container">
      <div class="tfi-modal-body-title-container-section">
        <span class="tfi-modal-body-title-container-section__title">{{
          data.title
        }}</span>
        <span class="tfi-modal-body-title-container-section__subtitle">{{
          data.subtitle
        }}</span>
      </div>
      <gc-button
        *ngIf="!data.hideDownloadButton"
        icon="download"
        mode="outline"
        color="secondary"
        [isLoading]="isDownloading"
        [disabled]="isDownloading"
        [text]="data.thirdButtonLabel"
        (click)="onThirdButtonClicked()"
      ></gc-button>
    </div>
    <gc-upload-file
      (fileLoaded)="fileLoaded($event)"
      (fileDeleted)="fileDeleted()"
    ></gc-upload-file>
    <div class="tfi-modal-body-buttons">
      <div class="tfi-modal-body-buttons__button">
        <gc-button
          mode="outline"
          type="full"
          [text]="data.secondaryButtonLabel"
          (click)="onSecondaryButtonClicked()"
        ></gc-button>
      </div>
      <div class="tfi-modal-body-buttons__button">
        <gc-button
          type="full"
          [text]="data.primaryButtonLabel"
          [disabled]="!isPrimaryButtonEnable"
          (click)="onPrimaryButtonClicked()"
        ></gc-button>
      </div>
    </div>
  </div>
</div>
