import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMenuOptions } from '@gc-tech/components';
import {
  IRole,
  TableMenuActionsEnum,
  UserPermissionsEnum,
} from '@tfi-workspace-web/tfi-shared-utils';
import { RoleActionsEnum } from 'libs/tfi-feat-business-management/src/lib/enums/role-management-action.enum';

@Component({
  selector: 'tfi-feat-bm-role-table',
  templateUrl: './role-table.component.html',
  styleUrls: ['./role-table.component.scss'],
})
export class RoleTableComponent {
  @Input() data: IRole[] = [];
  @Input() rows = 10;
  @Input() skeleton = false;
  @Input() first = 0;
  @Input() totalTableElements?: number;
  @Output() roleSelected = new EventEmitter();
  userPermissionsEnum = UserPermissionsEnum;

  get rolesArr() {
    return this.data ? this.data : [];
  }

  options: IMenuOptions[] = [
    {
      id: TableMenuActionsEnum.EDIT,
      label: 'Editar',
      icon: TableMenuActionsEnum.EDIT,
    },
    {
      id: RoleActionsEnum.ENABLE_DISABLE,
      label: 'Activar/Desactivar',
      icon: 'key',
    },
  ];

  setAction(action: string, data: any, index: number) {
    const detail = {
      data,
      index,
      action,
    };
    this.roleSelected.emit(detail);
  }
}
