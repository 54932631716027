import { ProductTypeEnum } from '../enums/product-type.enum';
import { SaleTypeEnum } from '../interfaces/product.interface';

export const ProductTypeOptions = [
  { label: 'Producto', value: ProductTypeEnum.PRODUCT },
  { label: 'Servicio', value: ProductTypeEnum.SERVICE },
];

export const productStatusOptions = [
  { label: 'Activo', value: true },
  { label: 'Inactivo', value: false },
];

export const SaleTypeOptions = [
  { label: 'No sujeta', value: SaleTypeEnum.NON_SUBJECT },
  { label: 'Gravada', value: SaleTypeEnum.TAXED },
  { label: 'Exenta', value: SaleTypeEnum.EXEMPT },
];
