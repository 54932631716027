<div class="gc-select-container">
  <ul
    class="panel-container-top"
    *ngIf="filteredOptions.length > 0 && topPosition"
  >
    <li
      class="panel-container__item"
      *ngFor="let option of filteredOptions"
      (click)="selectOption(option)"
    >
      {{ option.label }}
    </li>
  </ul>
  <div class="input-container">
    <gc-icon
      *ngIf="enableSearch"
      icon="search"
      [dimension]="16"
      color="grey-600"
      class="input-container__search-icon"
    ></gc-icon>
    <input
      class="input-container__input"
      autocomplete="off"
      [readonly]="!enableSearch"
      [id]="id"
      [type]="type"
      [placeholder]="placeholder"
      [(ngModel)]="searchTerm"
      [disabled]="disabled"
      [maxlength]="maxLength"
      [ngClass]="{ 'input-container__input--disabled': disabled, 'input-container__input--no-icon': !enableSearch, }"
      (keyup)="filterOptions($event)"
      (input)="onChange(searchTerm)"
      (focus)="onInputFocus()"
      (blur)="onTouch()"
      (focusout)="onFocusOut()"
      (keydown)="onKeydown($event)"
      (click)="onInputFocus()"
    />
    <div class="input-container__x-icon">
      <gc-icon
        *ngIf="enableEmptyState"
        [ngClass]="{
          'input-container__x-icon--disabled': disabled,
        }"
        icon="x"
        color="grey-200"
        [dimension]="18"
        (click)="emptySelection()"
      ></gc-icon>
    </div>
    <gc-icon
      [ngClass]="{
        'input-container__chevron-down--hide': filteredOptions.length > 0,
        'input-container__chevron-down--disabled': disabled,
      }"
      icon="chevron-down"
      color="grey-300"
      class="input-container__chevron-down"
      (click)="showPanel()"
    ></gc-icon>
    <gc-icon
      icon="chevron-up"
      class="input-container__chevron-up"
      *ngIf="filteredOptions.length > 0"
      color="grey-300"
      (click)="hidePanel()"
    ></gc-icon>
  </div>
  <ul
    class="panel-container"
    *ngIf="filteredOptions.length > 0 && !topPosition"
    #optionList
  >
    <li
      class="panel-container__item"
      *ngFor="let option of filteredOptions; let i = index"
      (click)="selectOption(option)"
      [ngClass]="{ 'panel-container__item--hover': i === currentHoverIndex }"
    >
      {{ option.label }}
    </li>
  </ul>
</div>
