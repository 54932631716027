<div class="tfi-modal">
  <div class="tfi-modal__informative">
    <div class="tfi-modal__content">
      <div class="tfi-modal__header">
        <div class="tfi-modal__action">
          <gc-icon
            icon="alert-octagon"
            [dimension]="22"
            color="grey-800"
            mat-dialog-close
          ></gc-icon>
          <gc-icon
            icon="x"
            [dimension]="22"
            color="grey-800"
            (click)="setAction(actionOptions.CLOSE)"
          ></gc-icon>
        </div>
      </div>

      <div class="tfi-modal__body">
        <p class="tfi-modal__title">{{ data.title }}</p>
        <p class="tfi-modal__description">
          {{ data.subTitle }}
        </p>
        <div class="tfi-modal-buttons">
          <div class="tfi-modal-buttons__actions">
            <gc-button
              mode="outline"
              color="primary"
              type="full"
              size="md"
              [text]="data.secondaryButtonText"
              (click)="setAction(actionOptions.CANCEL)"
            ></gc-button>
          </div>
          <div class="tfi-modal-buttons__actions">
            <gc-button
              mode="solid"
              color="primary"
              type="full"
              size="md"
              (click)="setAction(actionOptions.CONFIRM)"
              [text]="data.primaryButtonText"
            ></gc-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
