<div class="tfi-modal">
  <div class="tfi-modal-wrapper">
    <div class="tfi-modal-wrapper-header">
      <span class="tfi-modal-wrapper-header__title">
        {{ formTitle || '' }}
      </span>
      <div class="tfi-modal-wrapper-header__close-button">
        <gc-icon
          mat-dialog-close
          icon="x"
          color="grey-700"
          [dimension]="18"
        ></gc-icon>
      </div>
    </div>
    <div class="tfi-modal-wrapper-body">
      <form class="tfi-modal-wrapper-body-form" [formGroup]="form">
        <gc-label label="Nombre" [control]="nameControl">
          <gc-input
            id="userName"
            type="text"
            formControlName="name"
            placeHolder="Nombre"
          ></gc-input>
        </gc-label>

        <gc-label label="Email" [control]="emailControl">
          <gc-input
            id="userEmail"
            type="text"
            formControlName="email"
            placeHolder="Email"
          ></gc-input>
        </gc-label>

        <gc-label label="Telefono" [control]="phoneNumberControl">
          <gc-input
            id="userPHone"
            type="text"
            formControlName="phoneNumber"
            placeHolder="Telefono"
          ></gc-input>
        </gc-label>

        <gc-label label="Sucursal" [control]="branchControl">
          <gc-select
            id="userBranch"
            formControlName="branch"
            [data]="branchesInputOptions"
            placeholder="Sucursal"
          ></gc-select>
        </gc-label>

        <gc-label label="Rol" [control]="userRoleControl">
          <gc-select
            id="userRole"
            formControlName="userRole"
            [data]="roleInputOptions"
            placeholder="Rol"
          ></gc-select>
        </gc-label>

        <gc-label
          *ngIf="data.crudAction === actions.UPDATE"
          label="Reestablecer Contraseña?"
        >
          <gc-switch
            size="md"
            formControlName="showPasswordControls"
          ></gc-switch>
        </gc-label>

        <gc-label
          *ngIf="showPassControls"
          label="Contraseña"
          [control]="passwordControl"
        >
          <gc-input
            id="password"
            type="password"
            formControlName="password"
            placeHolder="Contraseña"
          ></gc-input>
        </gc-label>
        <gc-label
          *ngIf="showPassControls"
          label="Contraseña"
          [control]="confirmPasswordControl"
        >
          <gc-input
            id="confirm-password"
            type="password"
            formControlName="confirmPassword"
            placeHolder="Confirmar contraseña"
          ></gc-input>
        </gc-label>
      </form>
    </div>
  </div>
  <div class="tfi-modal-footer">
    <div class="tfi-modal-footer-buttons">
      <div class="tfi-modal-footer-buttons__button">
        <gc-button
          text="Cancelar"
          type="full"
          mode="outline"
          color="secondary"
          (click)="closeModal()"
        ></gc-button>
      </div>
      <div class="tfi-modal-footer-buttons__button">
        <gc-button
          text="Guardar"
          type="full"
          [isLoading]="isLoading"
          (click)="saveUser()"
        ></gc-button>
      </div>
    </div>
  </div>
</div>
