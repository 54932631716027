export interface IProductCatalogResponse {
  status: string;
  results: number;
  total: number;
  totalPages: number;
  page: number;
  data: {
    products: ICatalogProduct[];
  };
}

export interface ICatalogProduct {
  productName: string;
  description?: string;
  price: number;
  mainImageUrl?: string;
  images?: string[];
  categoryName: string;
  brandName: string;
}
