import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDialogComponent,
  ConfirmDialogEnum,
  IConfirmDialogCloseData,
  IConfirmDialogData,
  IMenuOptions,
  ITableColumn,
  ITableItem,
} from '@gc-tech/components';
import {
  ClaimsEnum,
  CrudActionsEnum,
  IUser,
  Modaldata,
  TableMenuActionsEnum,
  TOAST_MESSAGES,
  UserPermissionsEnum,
} from '@tfi-workspace-web/tfi-shared-utils';
import {
  catchError,
  debounceTime,
  EMPTY,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { UserActionsEnum } from '../../enums/user-management-action.enum';
import { BusinessManagementViewService } from '../../services/business-management-view.service';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserEditComponent } from '../user-management/user-edit/user-edit.component';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';

@Component({
  selector: 'tfi-feat-bm-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  animations: [
    trigger('slideInFromRight', [
      state('void', style({ transform: 'translateX(100%)' })),
      state('*', style({ transform: 'translateX(0)' })),
      transition('void => *', animate('300ms ease-out')),
    ]),
  ],
})
export class UserManagementComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  users: IUser[] = [];
  isSearching = false;
  userPermissionsEnum = UserPermissionsEnum;
  tableOptions: IMenuOptions[] = [
    {
      id: TableMenuActionsEnum.VIEWDETAILS,
      label: 'Ver',
      icon: TableMenuActionsEnum.TABLET,
    },
    {
      id: TableMenuActionsEnum.EDIT,
      label: 'Editar',
      icon: TableMenuActionsEnum.EDIT,
    },
    {
      id: UserActionsEnum.ENABLE_DISABLE,
      label: 'Activar/Desactivar',
      icon: 'key',
    },
  ];

  columns: ITableColumn[] = [
    {
      field: 'name',
      header: 'Nombre',
      sort: true,
    },
    {
      field: 'email',
      header: 'Email',
      sort: false,
    },
    {
      field: 'phoneNumber',
      header: 'Telefono',
      sort: false,
    },
    {
      field: 'isEnabled',
      header: 'Estado',
      sort: false,
    },
    {
      field: 'isUserVerified',
      header: 'Verificado',
      sort: false,
    },
  ];

  constructor(
    private dialog: MatDialog,
    private view: BusinessManagementViewService,
    private toastr: ToastrService,
    private auth: AuthenticationViewService
  ) {}

  ngOnInit(): void {
    this.isSearching = true;
    this.view.users$.pipe(takeUntil(this.destroy$)).subscribe((users) => {
      if (localStorage.getItem('userProfile')) {
        const currUser = JSON.parse(localStorage.getItem('userProfile')!);
        this.users = users.filter((usr) => usr._id !== currUser.id);
      } else {
        this.users = users;
      }
      this.isSearching = false;
    });
    if (
      !this.auth.hasPermissionStrict([
        this.userPermissionsEnum.USR_USR_EDT,
        this.userPermissionsEnum.USR_BRN_LST,
        this.userPermissionsEnum.USR_ROL_LST,
      ])
    ) {
      this.tableOptions.splice(-2);
    }
    this.view.getUsers();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  tableMenuSelected(itemData: ITableItem): void {
    switch (itemData.action) {
      case TableMenuActionsEnum.VIEWDETAILS: {
        this.openUserDetailDialog({
          crudAction: CrudActionsEnum.DETAIL,
          modalData: itemData.data,
        });
        break;
      }
      case TableMenuActionsEnum.EDIT: {
        if (!itemData.data.claims.includes(ClaimsEnum.OWNER)) {
          this.openUserEditlDialog({
            crudAction: CrudActionsEnum.UPDATE,
            modalData: itemData.data,
          });
        } else {
          this.toastr.error(
            'Los usuarios de tipo Propietario no se pueden actualizar'
          );
        }
        break;
      }
      case UserActionsEnum.ENABLE_DISABLE: {
        if (!itemData.data.claims.includes(ClaimsEnum.OWNER)) {
          this.openConfirmEnableDisableUser(itemData.data);
        } else {
          this.toastr.error(
            'Los usuarios de tipo Propietario no se pueden actualizar'
          );
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  search(search: string) {
    this.isSearching = true;
    const searchTerm = search.toLowerCase();

    this.view.users$.pipe(debounceTime(700), take(1)).subscribe((customer) => {
      this.users = customer.filter(
        (customer) =>
          customer.name.toLowerCase().includes(searchTerm) ||
          customer.phoneNumber.toLowerCase().includes(searchTerm) ||
          customer.email.toLowerCase().includes(searchTerm)
      );
      this.isSearching = false;
    });
  }

  createUser() {
    this.openUserEditlDialog({
      crudAction: CrudActionsEnum.CREATE,
      modalData: null,
    });
  }

  openUserDetailDialog(modalData: Modaldata) {
    this.dialog.open(UserDetailComponent, {
      data: modalData,
      minWidth: '360px',
      width: '85%',
      maxWidth: '800px',
      maxHeight: '90vh',
    });
  }

  openUserEditlDialog(modalData: Modaldata) {
    const ref = this.dialog.open(UserEditComponent, {
      data: modalData,
      width: '90%',
      maxWidth: '500px',
      disableClose: true,
    });
    ref
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.toastr.success(TOAST_MESSAGES.SUCCESS);
          this.view.getUsers();
        }
      });
  }

  openConfirmEnableDisableUser(data: IUser) {
    const action = data.isEnabled ? 'desactivar' : 'activar';
    const description = data.isEnabled
      ? `Al ${action} la cuenta, el usuario no podra iniciar sesion ni realizar otras operaciones.`
      : `Al ${action} la cuenta, el usuario podra iniciar sesion nuevamente y relaizar operaciones.`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Seguro que deseas ${action} el usuario ${data.name}?`,
        subTitle: description,
        primaryButtonText: data.isEnabled ? 'Desactivar' : 'Activar',
        secondaryButtonText: 'Cancelar',
      } as IConfirmDialogData,
      width: '540px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        catchError(() => {
          this.toastr.error(TOAST_MESSAGES.ERROR);
          return EMPTY;
        })
      )
      .subscribe((modal: IConfirmDialogCloseData) => {
        if (modal.action === ConfirmDialogEnum.CONFIRM) {
          this.view
            .handleUserState(data._id, !data.isEnabled)
            .pipe(
              take(1),
              tap(() => this.view.getUsers()),
              catchError(() => {
                this.toastr.error(TOAST_MESSAGES.ERROR);
                return EMPTY;
              })
            )
            .subscribe(() => this.toastr.success(TOAST_MESSAGES.SUCCESS));
        }
      });
  }
}
