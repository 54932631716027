import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import {
  IMenuItem,
  UserPermissionsEnum,
} from '@tfi-workspace-web/tfi-shared-utils';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { CMenuBreakpoint } from '../../constants/layout-breakpoints';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';
import { Router } from '@angular/router';
import { ClaimsEnum } from '@tfi-workspace-web/tfi-shared-utils';

@Component({
  selector: 'tfi-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('menuAnimation', [
      state('void', style({ height: '0', opacity: '0' })),
      state('*', style({ height: '*', opacity: '1' })),
      transition('void <=> *', animate('200ms ease')),
    ]),
  ],
})
export class HeaderComponent {
  @Output() toggleSidebar = new EventEmitter();
  @Input() menuItems: IMenuItem[] = [];
  screenWidth?: number;
  withBreakpoint = CMenuBreakpoint;
  isProfileMenuVisible = false;
  claimsEnum = ClaimsEnum;
  showBussAdmin = false;
  userPermissionsEnum = UserPermissionsEnum;

  constructor(
    private el: ElementRef,
    public authViewService: AuthenticationViewService,
    private router: Router
  ) {
    this.showBussAdmin = this.authViewService.hasPermissionLoose([
      this.userPermissionsEnum.USR_CMP_LST,
      this.userPermissionsEnum.USR_USR_LST,
      this.userPermissionsEnum.USR_ROL_LST,
      this.userPermissionsEnum.USR_BRN_LST,
      this.userPermissionsEnum.USR_INV_LST,
      this.userPermissionsEnum.USR_APT_LST,
    ]);
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: Event): void {
    const isClickedOutsiseDesktopMenu = !this.el.nativeElement
      .querySelector('.desktop-menu-container')
      .contains(event.target);
    const isClickedOutsideProfileMenu = !this.el.nativeElement
      .querySelector('.desktop-profile-button')
      .contains(event.target);
    if (isClickedOutsiseDesktopMenu) {
      this.hideMenu();
    }
    if (isClickedOutsideProfileMenu) {
      this.isProfileMenuVisible = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenWidth = window.innerWidth;
    this.validateScreenWidthForMenu();
  }

  validateScreenWidthForMenu() {
    if (this.screenWidth && this.screenWidth < this.withBreakpoint) {
      this.hideMenu();
    }
  }

  hideMenu() {
    this.menuItems.forEach((menuOption) => (menuOption.isSelected = false));
  }

  showMenu(item: IMenuItem) {
    if (item.isSelected) {
      item.isSelected = false;
      return;
    }
    this.hideMenu();
    item.isSelected = true;
  }

  onMobileMenuClicked() {
    this.toggleSidebar.emit();
  }

  toggleProfileMenu() {
    this.isProfileMenuVisible = !this.isProfileMenuVisible;
  }

  logout() {
    this.authViewService.logout();
    this.isProfileMenuVisible = false;
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  bussAdminRoute(): void {
    let routeToNav = '';
    if (
      this.authViewService.hasPermissionStrict([
        this.userPermissionsEnum.USR_CMP_LST,
      ])
    ) {
      routeToNav = 'companies';
    } else if (
      this.authViewService.hasPermissionStrict([
        this.userPermissionsEnum.USR_USR_LST,
      ])
    ) {
      routeToNav = 'users';
    } else if (
      this.authViewService.hasPermissionStrict([
        this.userPermissionsEnum.USR_ROL_LST,
      ])
    ) {
      routeToNav = 'roles';
    } else if (
      this.authViewService.hasPermissionStrict([
        this.userPermissionsEnum.USR_BRN_LST,
      ])
    ) {
      routeToNav = 'branches';
    } else if (
      this.authViewService.hasPermissionStrict([
        this.userPermissionsEnum.USR_INV_LST,
      ])
    ) {
      routeToNav = 'billing';
    } else if (
      this.authViewService.hasPermissionStrict([
        this.userPermissionsEnum.USR_APT_LST,
      ])
    ) {
      routeToNav = 'transactions';
    }
    this.router.navigate([`/admin/business-management/${routeToNav}`]);
  }
}
