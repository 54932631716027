import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IRefreshTransactionsResponce,
  ITransactionsResponse,
  environment,
  paymentTransactionMethods,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransactionDataService {
  private readonly paymentTransactionUrl =
    environment.api_host + paymentTransactionMethods.transaction;
  constructor(private http: HttpClient) {}

  getTransactions(
    startDate: string,
    endDate: string
  ): Observable<ITransactionsResponse> {
    let params = new HttpParams();
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    return this.http.get<ITransactionsResponse>(this.paymentTransactionUrl, {
      params,
    });
  }

  getRefreshWompiTransactions(): Observable<IRefreshTransactionsResponce> {
    return this.http.get<IRefreshTransactionsResponce>(
      `${this.paymentTransactionUrl}/refresh-pending-transactions`
    );
  }
}
