<div class="user-detail-container">
  <div class="title-container">
    <h2 class="title-container__title">Detalles del usuario</h2>
    <gc-icon
      class="title-container__icon"
      icon="x"
      [dimension]="22"
      color="grey-800"
      mat-dialog-close
    ></gc-icon>
  </div>
  <div class="details-container">
    <div class="flex-container">
      <div class="flex-item">
        <span class="flex-item__key">Nombre Completo:</span>
        <span class="flex-item__value">{{ user.name || '-' | titlecase }}</span>
      </div>
      <div class="flex-item">
        <span class="flex-item__key">Correo Electrónico:</span>
        <span class="flex-item__value">{{ user.email || '-' }}</span>
      </div>
    </div>
    <div class="flex-container">
      <div class="flex-item">
        <span class="flex-item__key">Telefono:</span>
        <span class="flex-item__value">{{
          user.phoneNumber || '-' | titlecase
        }}</span>
      </div>
      <div class="flex-item">
        <span class="flex-item__key">País:</span>
        <span class="flex-item__value">{{ user.country || '-' }}</span>
      </div>
    </div>
    <div class="flex-container">
      <div class="flex-item">
        <span class="flex-item__key">Fecha Creación:</span>
        <span class="flex-item__value">{{
          user.createdDate | date : 'dd/MM/yyyy' || '-'
        }}</span>
      </div>
      <div class="flex-item">
        <span class="flex-item__key">Fecha Verificación:</span>
        <span class="flex-item__value">{{
          user.verifiedDate | date : 'dd/MM/yyyy' || '-'
        }}</span>
      </div>
    </div>
    <div class="flex-container">
      <div class="flex-item">
        <span class="flex-item__key">Rol:</span>
        <span class="flex-item__value">{{ user.userRole?.name || '' }}</span>
      </div>
      <div class="flex-item">
        <span class="flex-item__key">Sucursal:</span>
        <span class="flex-item__value"
          >{{ user.branch.branchCode || '' }} -
          {{ user.branch.branchName || '' }}</span
        >
      </div>
    </div>
    <div class="button-container">
      <gc-button
        class="button-container__button"
        text="Regresar"
        size="sm"
        icon="chevron-left"
        (click)="cancel()"
      ></gc-button>
    </div>
  </div>
</div>
