import { ClaimsEnum } from '../enums/claims.enum';

export interface IMenuItem {
  icon?: string;
  label: string;
  isSelected?: boolean;
  path?: string;
  children: IMenuItem[];
  claims?: ClaimsEnum[];
  hasAccess?: boolean;
}
