<div class="gc-checkbox-container">
  <input
    [id]="id"
    class="gc-checkbox-container__input"
    [ngClass]="{ 'gc-checkbox-container__input--enabled': !disabled }"
    type="checkbox"
    [checked]="checked"
    [disabled]="disabled"
    (click)="switch()"
  />
  <span
    class="gc-checkbox-container__label"
    [ngClass]="{ 'gc-checkbox-container__label--disabled': disabled }"
    >{{ label }}</span
  >
</div>
