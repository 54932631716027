import { Injectable } from '@angular/core';
import { ICategory } from '@tfi-workspace-web/tfi-shared-utils';
import { BehaviorSubject, take } from 'rxjs';
import { CategoryDataService } from '../../data/category-data/category-data.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryViewService {
  private categoriesSubject = new BehaviorSubject<ICategory[]>([]);

  categories$ = this.categoriesSubject.asObservable();

  constructor(private data: CategoryDataService) {}

  getCategories() {
    return this.data
      .getCategories()
      .pipe(take(1))
      .subscribe((response) => {
        this.categoriesSubject.next(response.data.categories);
      });
  }

  createCategory(category: ICategory) {
    return this.data.createCategory(category);
  }

  updateCategory(categoryId: string, category: ICategory) {
    return this.data.updateCategory(categoryId, category);
  }
}
