import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';
import {
  ITableAction,
  TableMenuActionsEnum,
} from '@tfi-workspace-web/tfi-shared-utils';

@Component({
  selector: 'gc-table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.scss'],
  animations: [
    trigger('menuAnimation', [
      state('void', style({ height: '0', opacity: '0' })),
      state('*', style({ height: '*', opacity: '1' })),
      transition('void <=> *', animate('200ms ease')),
    ]),
  ],
})
export class TableActionsComponent implements OnInit {
  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.hidePanel();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.hidePanel();
  }

  @ViewChild('menuElement') menuElement: ElementRef | undefined;
  @Input() tableActions: ITableAction[] = [];
  @Input() entity: any;
  @Output() actionClicked = new EventEmitter<TableMenuActionsEnum>();
  permittedActions: ITableAction[] = [];
  isPanelOptionVisible?: boolean;

  constructor(
    private authenticationView: AuthenticationViewService,
    private elRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.hidePanel();
      }
    });
  }

  ngOnInit(): void {
    this.filterActionsByPermissions();
  }

  filterActionsByPermissions(): void {
    this.permittedActions = this.tableActions.filter(
      (action) =>
        this.authenticationView.hasPermissionStrict(action.permissions) &&
        (action.showAction ? action.showAction(this.entity) : true)
    );
  }

  selectedAction(action: TableMenuActionsEnum) {
    this.hidePanel();
    this.actionClicked.emit(action);
  }

  toggleMenu(event: MouseEvent): void {
    this.isPanelOptionVisible = !this.isPanelOptionVisible;

    if (this.isPanelOptionVisible) {
      this.cdRef.detectChanges();
      if (this.menuElement) {
        const menu = this.menuElement.nativeElement;
        this.renderer.appendChild(document.body, menu);
        this.positionMenu(event);
      }
    }
  }

  positionMenu(event: any): void {
    if (this.menuElement) {
      const menu = this.menuElement.nativeElement;
      const rect = event.target.getBoundingClientRect();
      this.renderer.setStyle(menu, 'position', 'absolute');
      this.renderer.setStyle(menu, 'top', `${rect.bottom + 10}px`);
      this.renderer.setStyle(menu, 'left', `${rect.left - 170}px`);
    }
  }

  hidePanel() {
    this.isPanelOptionVisible = false;
    this.cdRef.detectChanges();
  }
}
