<div class="branch-container" [@slideInFromRight]>
  <div class="controls-container">
    <div class="input-container">
      <gc-input
        id="branchSearch"
        #searchTerm
        class="input-container__input"
        type="text"
        icon="search"
        (input)="search(searchTerm.value)"
        placeHolder="Buscar sucursal"
      ></gc-input>
    </div>
    <div class="button-container">
      <gc-button
        *PermissionControl="[userPermissionsEnum.USR_BRN_CRT]"
        text="Agregar sucursal"
        icon="plus"
        type="full"
        (click)="onCreateBranch()"
      ></gc-button>
    </div>
  </div>
  <div class="table-container">
    <gc-table
      (selectedAction)="tableMenuSelected($event)"
      [columns]="columns"
      [data]="branches"
      [options]="tableOptions"
      [skeleton]="isSearching"
    ></gc-table>
  </div>
</div>
