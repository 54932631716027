import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gc-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
  @Input() text?: string;
  @Input() skeleton = false;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() backgroundColor?: string = 'primary-100';
  @Input() textColor?: string = 'primary-600';
  @Input() mode: 'solid' | 'outline' = 'solid';
  @Input() icon?: string;
  @Input() iconLeft?: string;
  @Input() iconRight?: string;

  sizeClass?: string;
  backgrounfColorClass?: string;
  modeBackground?: string;
  textColorClass?: string;

  ngOnInit(): void {
    this.sizeClass = `[ size-wrapper--${this.size} ]`;
    this.textColorClass = `size-wrapper__label--${this.textColor}`;
    this.backgrounfColorClass = `gc-badge-container--${this.backgroundColor}`;
    if (this.mode === 'outline') {
      this.modeBackground = 'transparent';
    }
  }
}
