import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrandsWrapperComponent } from './components/brands/brands-wrapper.component';
import { GCComponentsModule } from '@gc-tech/components';
import { ReactiveFormsModule } from '@angular/forms';
import { ManageBrandsDialogComponent } from './components/brands/manage-brands-dialog/manage-brands-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrandsDetailsDialogComponent } from './components/brands/brands-details-dialog/brands-details-dialog.component';
import { CategoryWrapperComponent } from './components/category/category-wrapper.component';
import { BrandsDialogService } from './services/dialog/brands-dialog/brands-dialog.service';
import { ManageCategoryDialogComponent } from './components/category/manage-category-dialog/manage-category-dialog.component';
import { CategoryDialogService } from './services/dialog/category-dialog/category-dialog.service';
import { CategoryDetailsDialogComponent } from './components/category/category-details-dialog/category-details-dialog.component';
import { ProductWrapperComponent } from './components/products/product-wrapper.component';
import { ProductManagementFormComponent } from './components/products/product-management-form/product-management-form';
import { DragDirective } from './directive/drag/drag.directive';
import { ProductsTableComponent } from './components/products/components/products-table/products-table.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SidebarModule } from 'primeng/sidebar';
@NgModule({
  imports: [
    CommonModule,
    GCComponentsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    SidebarModule,
  ],
  declarations: [
    BrandsWrapperComponent,
    ManageBrandsDialogComponent,
    BrandsDetailsDialogComponent,
    CategoryWrapperComponent,
    ManageCategoryDialogComponent,
    CategoryDetailsDialogComponent,
    ProductWrapperComponent,
    ProductManagementFormComponent,
    DragDirective,
    ProductsTableComponent,
  ],
  providers: [BrandsDialogService, CategoryDialogService],
  exports: [
    BrandsWrapperComponent,
    CategoryWrapperComponent,
    ProductWrapperComponent,
  ],
})
export class TfiFeatProductManagementModule {}
