import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[cardExpirationDirective]',
})
export class CardExpirationDateDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: any) {
    const inputValue = event.target.value.replace(/\D/g, '').substring(0, 4); // Eliminar caracteres no numéricos y limitar a 4 dígitos
    const formattedValue = this.formatExpirationDate(inputValue);
    this.el.nativeElement.value = formattedValue;
  }

  private formatExpirationDate(value: string): string {
    const formattedValue = value.replace(/(\d{2})/g, '$1 / ');
    return formattedValue.endsWith(' / ')
      ? formattedValue.slice(0, -3)
      : formattedValue;
  }
}
