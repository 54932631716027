export enum FormErrorMessageEnum {
  ESPERE_POR_FAVOR = 'Espere por favor...',
  LOS_DATOS_SON_INVALIDOS_INTENTE_DE_NUEVO = 'Los datos son invalidos, verifique cada uno de los campos.',
  LOS_DATOS_SON_INALIDOS = 'Los datos son invalidos.',
  EL_REGISTRO_YA_EXISTE = 'El registro ya existe.',
  NO_SE_PUDO_COMPLETAR_EL_REGISTRO = 'No se pudo completar el registro.',
  OCURRIO_UN_ERROR_INTENTE_MAS_TARDE = 'Ocurrio un error, intente mas tarde.',
  USUARIO_NO_ENCONTRADO = 'Usuario no encontrado',
  USUARIO_NO_VERIFICADO = 'Usuario no verificado',
}
