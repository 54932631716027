<div *ngIf="rolesArr.length > 0; else emptyTable">
  <p-table
    dataKey="_id"
    [value]="data"
    [rows]="rows"
    [resizableColumns]="true"
    [autoLayout]="true"
    [scrollable]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 20%">Nombre</th>
        <th style="width: 20%">Descripcion</th>
        <th style="width: 12%">Estado</th>
        <th
          *PermissionControl="[userPermissionsEnum.USR_ROL_EDT]"
          style="width: 5%"
          alignFrozen="right"
          pFrozenColumn
        ></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-role let-rowIndex="rowIndex">
      <tr>
        <td>
          <span [ngClass]="{ skeleton: skeleton }">
            {{ role.name ?? '-' }}
          </span>
        </td>
        <td>
          <span [ngClass]="{ skeleton: skeleton }">
            {{ role.description ?? '-' }}
          </span>
        </td>
        <td>
          <gc-badge
            [skeleton]="skeleton"
            [text]="role.isEnabled ? 'Activo' : 'Inactivo'"
            size="lg"
            [backgroundColor]="role.isEnabled ? 'green-500' : 'red-500'"
            textColor="base-white"
          ></gc-badge>
        </td>
        <td
          *PermissionControl="[userPermissionsEnum.USR_ROL_EDT]"
          alignFrozen="right"
          pFrozenColumn
        >
          <div [ngClass]="{ skeleton: skeleton }" class="option">
            <gc-menu
              *ngIf="!role.isDefaultRole"
              [options]="options"
              position="top"
              (selectedOption)="setAction($event, role, rowIndex)"
            ></gc-menu>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #emptyTable>
  <h2 class="tfi-empty-label">No se encontraron registros.</h2>
</ng-template>
