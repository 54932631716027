<div class="gc-textarea-container">
  <textarea
    class="gc-textarea-container__element"
    [cols]="cols"
    [rows]="rows"
    [maxLength]="maxLength"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [ngClass]="{
      'gc-textarea-container__element--resize': !resize,
      'gc-textarea-container__element--disabled': disabled,
    }"
  ></textarea>
</div>
