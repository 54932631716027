import { Component, OnDestroy, OnInit } from '@angular/core';
import { BrandsViewService } from '../../services/view/brands-view/brands-view.service';
import { Subject, debounceTime, take, takeUntil } from 'rxjs';
import {
  CrudActionsEnum,
  IBrand,
  UserPermissionsEnum,
} from '@tfi-workspace-web/tfi-shared-utils';
import { BrandsDialogService } from '../../services/dialog/brands-dialog/brands-dialog.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';

@Component({
  selector: 'tfi-feat-product-brands-wrapper',
  templateUrl: './brands-wrapper.component.html',
  styleUrls: ['./brands-wrapper.component.scss'],
  animations: [
    trigger('slideInFromRight', [
      state('void', style({ transform: 'translateX(100%)' })),
      state('*', style({ transform: 'translateX(0)' })),
      transition('void => *', animate('300ms ease-out')),
    ]),
  ],
})
export class BrandsWrapperComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  brands: IBrand[] = [];
  userPermissionsEnum = UserPermissionsEnum;
  constructor(
    private dialogService: BrandsDialogService,
    private brandsViewService: BrandsViewService,
    private auth: AuthenticationViewService
  ) {}

  ngOnInit(): void {
    this.brandsViewService.brands$
      .pipe(takeUntil(this.destroy$))
      .subscribe((brands) => {
        this.brands = brands;
      });
    this.brandsViewService.getBrands();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  openCreateBrandsDialog() {
    this.dialogService.openManageBrandsDialog({
      crudAction: CrudActionsEnum.CREATE,
      modalData: {},
    });
  }

  onCardSelected(index: number) {
    if (this.auth.hasPermissionStrict([UserPermissionsEnum.USR_BRD_EDT])) {
      this.dialogService.openManageBrandsDialog({
        crudAction: CrudActionsEnum.UPDATE,
        modalData: this.brands[index],
      });
    }
  }

  search(search: string) {
    const searchTerm = search.toLowerCase();

    this.brandsViewService.brands$
      .pipe(debounceTime(700), take(1))
      .subscribe((brand) => {
        this.brands = brand.filter((brand) =>
          brand.brandName.toLowerCase().includes(searchTerm)
        );
      });
  }
}
