import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { AuthenticationDataService } from '../data/authentication-data.service';

import { Router } from '@angular/router';
import {
  ILogin,
  IOnboardingRegister,
  IPasswordChange,
  IUserProfile,
} from '../../interfaces/authentication.interface';
import { IAdminInvoicingPlansSuccessResponse } from '../../interfaces/onboarding-post.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationViewService {
  private _userLoggedIn = new BehaviorSubject<boolean>(false);

  public setUserLoogedIn(userLoggedIn: boolean) {
    this._userLoggedIn.next(userLoggedIn);
  }

  public getUserLoogedIn(): Observable<boolean> {
    return this._userLoggedIn.asObservable();
  }
  set isAuthenticated(val: any) {
    localStorage.setItem('isAuthenticated', val);
  }
  get isAuthenticated() {
    return localStorage.getItem('isAuthenticated') == 'true';
  }

  set token(val: any) {
    localStorage.setItem('token', val);
  }
  get token() {
    return localStorage.getItem('token');
  }
  set userProfile(val: IUserProfile) {
    localStorage.setItem('userProfile', JSON.stringify(val));
  }
  get userProfile() {
    return JSON.parse(
      localStorage.getItem('userProfile') ?? '{}'
    ) as IUserProfile;
  }

  constructor(
    private data: AuthenticationDataService,
    private router: Router
  ) {}

  login(data: ILogin) {
    return this.data.postLogin(data).pipe(
      tap((res: any) => {
        localStorage.clear();
        this.isAuthenticated = true;
        this.setUserLoogedIn(true);
        this.token = res.data.accessToken;
        this.userProfile = res.data.user;
      })
    );
  }

  logout() {
    localStorage.clear();
    this.setUserLoogedIn(false);
    this.router.navigate(['authentication/login']);
  }

  register(data: IOnboardingRegister) {
    return this.data.postRegister(data);
  }

  resetPassword(email: string) {
    return this.data.postResetPassword(email);
  }

  changePassword(updatePassword: IPasswordChange) {
    return this.data.postChangePassword(updatePassword);
  }

  verUser(token: string) {
    return this.data.verifyUser(token);
  }

  adminInvoicePlansOpt(): Observable<IAdminInvoicingPlansSuccessResponse> {
    return this.data.getAdminInvPlans();
  }

  hasPermissionStrict(permissions: string[]): boolean {
    return permissions.every((permission) =>
      this.userProfile.permissions.includes(permission)
    );
  }

  hasPermissionLoose(permissions: string[]): boolean {
    return permissions.some((permission) =>
      this.userProfile.permissions.includes(permission)
    );
  }
}
