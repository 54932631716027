export interface IDTEReponse {
  status: string;
  data: {
    id: string;
    internalId: null;
    generationCode: string;
    companyId: string;
    environment: string;
    type: string;
    version: number;
    status: string;
    controlNumber: string;
    mhResponse: MhResponse;
    dte: DTE;
    mhDteUrl: string;
    generatedAt: Date;
    createdAt: Date;
    updatedAt: Date;
  };
}

export interface DTE {
  emisor: Emisor;
  resumen: Resumen;
  apendice: Apendice[];
  receptor: Receptor;
  extension: null;
  ventaTercero: null;
  identificacion: Identificacion;
  cuerpoDocumento: CuerpoDocumento[];
  otrosDocumentos: null;
  documentoRelacionado: null;
  respuestaHacienda: RespuestaHacienda;
  firmaElectronica: string;
  selloRecibido: string;
}

export interface Apendice {
  campo: string;
  valor: string;
  etiqueta: string;
}

export interface CuerpoDocumento {
  psv: number;
  codigo: string;
  ivaItem: number;
  numItem: number;
  cantidad: number;
  tipoItem: number;
  tributos: null;
  noGravado: number;
  precioUni: number;
  uniMedida: number;
  codTributo: null;
  montoDescu: number;
  ventaNoSuj: number;
  descripcion: string;
  ventaExenta: number;
  ventaGravada: number;
  numeroDocumento: null;
}

export interface Emisor {
  nit: string;
  nrc: string;
  correo: string;
  nombre: string;
  telefono: string;
  direccion: Direccion;
  codEstable: string;
  codActividad: string;
  codEstableMH: string;
  codPuntoVenta: string;
  descActividad: string;
  codPuntoVentaMH: string;
  nombreComercial: string;
  tipoEstablecimiento: string;
}

export interface Direccion {
  municipio: string;
  complemento: string;
  departamento: string;
}

export interface Identificacion {
  fecEmi: Date;
  horEmi: string;
  tipoDte: string;
  version: number;
  ambiente: string;
  tipoModelo: number;
  tipoMoneda: string;
  motivoContin: null;
  numeroControl: string;
  tipoOperacion: number;
  codigoGeneracion: string;
  tipoContingencia: null;
}

export interface Receptor {
  nrc: string;
  correo: string;
  nombre: string;
  telefono: string;
  direccion: Direccion;
  codActividad: string;
  numDocumento: string;
  descActividad: string;
  tipoDocumento: string;
}

export interface RespuestaHacienda {
  estado: string;
  version: number;
  ambiente: string;
  codigoMsg: string;
  versionApp: number;
  clasificaMsg: string;
  observaciones: any[];
  selloRecibido: string;
  descripcionMsg: string;
  fhProcesamiento: string;
  codigoGeneracion: string;
}

export interface Resumen {
  pagos: null;
  ivaRete1: number;
  subTotal: number;
  totalIva: number;
  tributos: null;
  reteRenta: number;
  descuNoSuj: number;
  saldoFavor: number;
  totalDescu: number;
  totalNoSuj: number;
  totalPagar: number;
  descuExenta: number;
  totalExenta: number;
  totalLetras: string;
  descuGravada: number;
  totalGravada: number;
  subTotalVentas: number;
  totalNoGravado: number;
  condicionOperacion: number;
  numPagoElectronico: null;
  montoTotalOperacion: number;
  porcentajeDescuento: number;
}

export interface MhResponse {
  data: RespuestaHacienda;
  status: number;
  statusText: string;
}

export interface IDtePayload {
  ticketId: string;
}

export interface tktRetPayLoad {
  reason: string;
  ticketId: string;
  comment?: string;
}
