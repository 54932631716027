import { Injectable } from '@angular/core';
import { CatalogDataService } from '../data/catalog-data.service';
import { ProductFilters } from '@tfi-workspace-web/tfi-feat-product-management';
import { ICompany } from '@tfi-workspace-web/tfi-shared-utils';
import { BehaviorSubject, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CatalogViewService {
  private fetchedCompany = new BehaviorSubject<ICompany>({} as ICompany);
  company$ = this.fetchedCompany.asObservable();

  private currentCategogy = new BehaviorSubject<string | null>(null);
  currentCat$ = this.currentCategogy.asObservable();

  constructor(private data: CatalogDataService) {}

  getCategories(domain: string) {
    return this.data.getCategories(domain);
  }

  getBrands(domain: string) {
    return this.data.getBrands(domain);
  }

  getProducts(filterData: ProductFilters, domain: string) {
    return this.data.getProducts(filterData, domain);
  }

  getProductDetail(prodName: string, domain: string) {
    return this.data.getProduct(prodName, domain);
  }

  getCompany(domain: string) {
    return this.data
      .getCompany(domain)
      .pipe(take(1))
      .subscribe((responce) => {
        this.fetchedCompany.next(responce.data.company);
      });
  }

  updateCat(category: string) {
    this.currentCategogy.next(category);
  }
}
