import { IBaseEntity } from './base-entity.interface';

export interface IBrand extends IBaseEntity {
  brandName: string;
  description?: string;
  vendor?: string[];
  brandImg?: string;
  isSelected?: boolean;
}

export interface IBrandsResponse {
  status: string;
  results: number;
  data: {
    brands: IBrand[];
  };
}

export interface IBrandResponse {
  status: string;
  data: {
    brand: IBrand;
  };
}
