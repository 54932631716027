<div class="tfi-info-card">
  <div class="tfi-info-card__inventory-label" *ngIf="showInventory">
    Disp: {{ inventory | number : '1.0-0' }}
  </div>
  <div class="tfi-info-card-image-container">
    <img class="tfi-info-card-image-container__image" [src]="imageUrl" />
  </div>
  <div class="tfi-info-card-content">
    <span class="tfi-info-card-content__primary-label">{{ primaryLabel }}</span>
    <span class="tfi-info-card-content__secondary-label">{{
      secondaryLabel
    }}</span>
    <span *ngIf="price" class="tfi-info-card-content__price"
      >${{ price | number : '1.2-2' }}</span
    >
  </div>
</div>
