import { Injectable } from '@angular/core';
import { IUser, IUserAssignRoleResponse, UpdatedUser } from '@tfi-workspace-web/tfi-shared-utils';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { BusinessManagementDataService } from './business-management-data.service';

@Injectable({
  providedIn: 'root',
})
export class BusinessManagementViewService {
  private usersSubject = new BehaviorSubject<IUser[]>([]);

  users$ = this.usersSubject.asObservable();

  constructor(private data: BusinessManagementDataService) {}

  getUsers() {
    return this.data
      .getUsers()
      .pipe(take(1))
      .subscribe((response) => {
        this.usersSubject.next(response.data.users);
      });
  }

  createUser(user: IUser) {
    return this.data.createUser(user);
  }

  updateUser(userId: string, user: IUser) {
    return this.data.updateUser(userId, user);
  }

  patchUser(userId: string, user: UpdatedUser) {
    return this.data.patchUser(userId, user);
  }

  handleUserState(userId: string | undefined, state: boolean) {
    const id = userId ?? '';
    return this.data.patchUser(id, {
      isEnabled: state,
    });
  }

  updateUserRole(payload: any, id: string): Observable<IUserAssignRoleResponse>{
    return this.data.assignUserRole(payload,id);
  }
}
