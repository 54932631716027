import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileHandle } from '@tfi-workspace-web/tfi-shared-utils';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appDrag]',
})
export class DragDirective {
  @Output() files = new EventEmitter<FileHandle>();

  @HostBinding('style.background') private background = '#eee';

  constructor(private sanitazer: DomSanitizer) {}

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#999';
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#eee';
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#eee';

    let fileHandle: FileHandle;

    if (event.dataTransfer?.files[0]) {
      const file = event.dataTransfer?.files[0];
      const url = this.sanitazer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(file)
      );
      fileHandle = { file, url };
      this.files.emit(fileHandle);
    }
  }
}
