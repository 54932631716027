export enum TableMenuActionsEnum {
  VIEWDETAILS = 'viewDetails',
  EDIT = 'edit',
  DELETE = 'delete',
  CREATE = 'create',
  TABLET = 'table',
  PRINT = 'printer',
  ASSIGN_SHIPPING = 'assing-shipping',
  PROCESS_ORDEN = 'process-order',
}
