import { ClaimsEnum } from '../enums/claims.enum';
import { ISelectOption } from '../interfaces/input-select.interface';

export const ClaimsData = [
  {
    label: 'Administrator',
    value: ClaimsEnum.ADMIN,
  },
  {
    label: 'Operator',
    value: ClaimsEnum.OPERATOR,
  },
  {
    label: 'Pantalla Cocina',
    value: ClaimsEnum.KITCHEN_DISPLAY,
  }
] as ISelectOption[];

export const claimOptions = {
  [ClaimsEnum.ADMIN as ClaimsEnum]: 'Administrator',
  [ClaimsEnum.KITCHEN_DISPLAY as ClaimsEnum]: 'Pantalla Cocina',
  [ClaimsEnum.OPERATOR as ClaimsEnum]: 'Operator',
  [ClaimsEnum.OWNER as ClaimsEnum]: 'Propietario',
};
