import { IBaseEntity } from './base-entity.interface';

export interface IShippingCompany extends IBaseEntity {
  company?: string;
  name: string;
}

export interface IShippingCompaniesResponse {
  status: string;
  results: number;
  data: {
    shippingCompanies: IShippingCompany[];
  };
}

export interface IShippingCompanyResponse {
  status: string;
  results: number;
  data: {
    shippingCompany: IShippingCompany[];
  };
}
