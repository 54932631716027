<div class="tfi-billing">
  <div
    *PermissionControl="[userPermissionsEnum.USR_APT_CRT]"
    class="tfi-billing-button-container"
  >
    <gc-button
      text="Realizar Pago"
      [disabled]="this.selectedInvoices.length === 0"
      (click)="openPaymentDialog()"
    ></gc-button>
  </div>
  <div class="tfi-billing-table-container">
    <gc-table
      [columns]="columns"
      [data]="invoices"
      [enableCheckBox]="canExecutePayment"
      (selectedRows)="setSelectedInvoices($event)"
    ></gc-table>
  </div>
</div>

<ng-template #statusModal>
  <div class="tfi-modal-message-container">
    <ng-container *ngIf="isLoading">
      <div class="tfi-sub-container">
        <span class="tfi-sub-container__message"
          >Estamos generando el link de pago</span
        >
        <mat-spinner [diameter]="75"></mat-spinner>
        <span class="tfi-sub-container__message">Espere un momento...</span>
      </div>
    </ng-container>
  </div>
</ng-template>
