<section class="tfi-catalog">
  <h2 class="tfi-catalog__title">Información de catálogo</h2>
  <div class="tfi-catalog-view" *ngIf="!isEditMode; else editRef">
    <div class="tfi-catalog-view-row">
      <div class="tfi-catalog-view-row-item">
        <span class="tfi-catalog-view-row-item__key">Logo:</span>
        <img
          class="tfi-catalog-view-row-item__logo"
          [src]="company.mainLogo || '/assets/images/no-image.jpg'"
          alt="logo-image"
        />
      </div>
    </div>

    <div class="tfi-catalog-view-row">
      <div class="tfi-catalog-view-row-item">
        <span class="tfi-catalog-view-row-item__key">Slogan:</span>
        <span class="tfi-catalog-view-row-item__value">{{
          company.slogan ?? '- '
        }}</span>
      </div>
      <div class="tfi-catalog-view-row-item">
        <span class="tfi-catalog-view-row-item__key"
          >Teléfono de contacto:</span
        >
        <span class="tfi-catalog-view-row-item__value">{{
          company.socialMedia?.phoneNumber ?? '-'
        }}</span>
      </div>
    </div>

    <div class="tfi-catalog-view-row">
      <div class="tfi-catalog-view-row-item">
        <span class="tfi-catalog-view-row-item__key">WhatsApp:</span>
        <span class="tfi-catalog-view-row-item__value">{{
          company.socialMedia?.whatsapp ?? '-'
        }}</span>
      </div>
      <div class="tfi-catalog-view-row-item">
        <span class="tfi-catalog-view-row-item__key">Enlace a Instagram:</span>
        <span class="tfi-catalog-view-row-item__value">{{
          company.socialMedia?.instagram ?? '-'
        }}</span>
      </div>
    </div>

    <div class="tfi-catalog-view-row">
      <div class="tfi-catalog-view-row-item">
        <span class="tfi-catalog-view-row-item__key"> Enlace a Facebook:</span>
        <span class="tfi-catalog-view-row-item__value">{{
          company.socialMedia?.facebook ?? '-'
        }}</span>
      </div>
      <div class="tfi-catalog-view-row-item">
        <span class="tfi-catalog-view-row-item__key">Enlace de Tik-tok:</span>
        <span class="tfi-catalog-view-row-item__value">{{
          company.socialMedia?.tiktok ?? '-'
        }}</span>
      </div>
    </div>

    <div class="tfi-catalog-view-row">
      <div class="tfi-catalog-view-row-item">
        <span class="tfi-catalog-view-row-item__key">Enlace a X-Twitter:</span>
        <span class="tfi-catalog-view-row-item__value">{{
          company.socialMedia?.twitter ?? '-'
        }}</span>
      </div>
      <div class="tfi-catalog-view-row-item">
        <span class="tfi-catalog-view-row-item__key">Enlace a Youtube:</span>
        <span class="tfi-catalog-view-row-item__value">{{
          company.socialMedia?.youtube ?? '-'
        }}</span>
      </div>
    </div>
    <div
      *PermissionControl="[userPermissionsEnum.USR_CMP_EDT]"
      class="tfi-catalog-view-button"
    >
      <gc-link size="lg" text="Editar" (click)="onEditCatalog()"></gc-link>
    </div>
  </div>
  <ng-template #editRef>
    <div class="tfi-catalog-edit">
      <div class="tfi-catalog-edit-form" [formGroup]="form">
        <div class="tfi-catalog-edit-form-row">
          <div class="tfi-catalog-edit-form-row-item">
            <span class="tfi-catalog-edit-form-row-item__key">Logo:</span>
            <div class="tfi-catalog-edit-form-row-item-wrapper">
              <img
                class="tfi-catalog-edit-form-row-item-wrapper__logo"
                [src]="currentLogoImage || '/assets/images/no-image.jpg'"
                alt="logo-image"
                (click)="onImageClicked()"
              />
              <gc-icon
                class="tfi-catalog-edit-form-row-item-wrapper__delete-icon"
                *ngIf="currentLogoImage"
                icon="trash"
                [dimension]="16"
                (click)="deleteLogo()"
              ></gc-icon>
            </div>
          </div>
        </div>
        <div class="tfi-catalog-edit-form-row">
          <div class="tfi-catalog-edit-form-row-item">
            <gc-label label="Slogan (Opcional)" [control]="sloganFormControl">
              <gc-input
                id="sloganCatalog"
                type="text"
                formControlName="slogan"
                placeHolder="Slogan"
              ></gc-input>
            </gc-label>
          </div>
          <div class="tfi-catalog-edit-form-row-item">
            <gc-label
              label="Número de teléfono (Opcional)"
              [control]="phoneNumberFormControl"
            >
              <gc-input
                id="instagramCatalog"
                type="text"
                [mask]="'0000-0000'"
                icon="phone"
                formControlName="phoneNumber"
                placeHolder="Número de teléfono"
              ></gc-input>
            </gc-label>
          </div>
        </div>
        <div class="tfi-catalog-edit-form-row">
          <div class="tfi-catalog-edit-form-row-item">
            <gc-label
              label="Número de WhatsApp (Opcional)"
              [control]="whatsappFormControl"
            >
              <gc-input
                id="whatsappCatalog"
                type="text"
                [mask]="'0000-0000'"
                icon="phone"
                formControlName="whatsapp"
                placeHolder="WhatsApp"
              ></gc-input>
            </gc-label>
          </div>
          <div class="tfi-catalog-edit-form-row-item">
            <gc-label
              label="Instagram (Opcional)"
              [control]="instagramFormControl"
            >
              <gc-input
                id="instagramCatalog"
                type="text"
                formControlName="instagram"
                icon="instagram"
                placeHolder="Enlace a Instagram"
              ></gc-input>
            </gc-label>
          </div>
        </div>
        <div class="tfi-catalog-edit-form-row">
          <div class="tfi-catalog-edit-form-row-item">
            <gc-label
              label="Facebook (Opcional)"
              [control]="facebookFormControl"
            >
              <gc-input
                id="facebookCatalog"
                type="text"
                formControlName="facebook"
                icon="facebook"
                placeHolder="Enlace a Facebook"
              ></gc-input>
            </gc-label>
          </div>
          <div class="tfi-catalog-edit-form-row-item">
            <gc-label label="Tik-tok (Opcional)" [control]="tiktokFormControl">
              <gc-input
                id="tiktokCatalog"
                type="text"
                formControlName="tiktok"
                placeHolder="Enlace a Tik-tok"
              ></gc-input>
            </gc-label>
          </div>
        </div>
        <div class="tfi-catalog-edit-form-row">
          <div class="tfi-catalog-edit-form-row-item">
            <gc-label
              label="X-Twitter (Opcional)"
              [control]="twitterFormControl"
            >
              <gc-input
                id="twitterCatalog"
                type="text"
                formControlName="twitter"
                icon="twitter"
                placeHolder="Enlace a X-Twitter"
              ></gc-input>
            </gc-label>
          </div>
          <div class="tfi-catalog-edit-form-row-item">
            <gc-label label="Youtube (Opcional)" [control]="youtubeFormControl">
              <gc-input
                id="youtubeCatalog"
                type="text"
                formControlName="youtube"
                icon="youtube"
                placeHolder="Enlace a Youtube"
              ></gc-input>
            </gc-label>
          </div>
        </div>
        <div class="tfi-catalog-edit-form-buttons">
          <gc-button
            class="tfi-catalog-edit-form-buttons__btn"
            text="Cancelar"
            type="full"
            (click)="isEditMode = false"
            color="secondary"
            mode="ghost"
            [disabled]="isLoading"
          ></gc-button>
          <gc-button
            class="tfi-catalog-edit-form-buttons__btn"
            text="Guardar"
            type="full"
            [isLoading]="isLoading"
            [disabled]="isLoading"
            (click)="submitCatalogForm()"
          ></gc-button>
        </div>
      </div>
    </div>
  </ng-template>
</section>
