<div class="category-details-container">
  <div class="title-container">
    <h2 class="title-container__title">Detalles de la categoría</h2>
    <gc-icon
      class="title-container__icon"
      icon="x"
      [dimension]="22"
      color="grey-800"
      mat-dialog-close
    ></gc-icon>
  </div>
  <div class="categories-content">
    <span class="categories-content__key">Nombre de la marca:</span>
    <span class="categories-content__value">{{
      data.modalData.categoryName || '-' | titlecase
    }}</span>
    <span class="categories-content__key">Descripcion: (Opcional)</span>
    <span class="categories-content__value">{{
      data.modalData.description || '-'
    }}</span>
  </div>
  <div class="button-container">
    <gc-button
      class="button-container__button"
      text="Regresar"
      size="sm"
      icon="chevron-left"
      (click)="cancel()"
    ></gc-button>
  </div>
</div>
