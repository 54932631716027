import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ITableItem } from '@gc-tech/components';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DownloadUploadDialogComponent } from 'libs/gc-components-lib/src/lib/components/download-upload-dialog/download-upload-dialog.component';
import * as moment from 'moment';
import { TransactionViewService } from '../../services/transaction/view/transaction-view.service';
import { EMPTY, catchError, take } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { IAdminPaymentTransaction } from '@tfi-workspace-web/tfi-shared-utils';

@Component({
  selector: 'tfi-feat-bm-transaction-management',
  templateUrl: './transaction-management.component.html',
  styleUrls: ['./transaction-management.component.scss'],
})
export class TransactionManagementComponent implements OnInit {
  transactions: IAdminPaymentTransaction[] = [];
  isLoading = false;
  uploadFile?: File;
  startDateFilter?: string;
  endDateFilter?: string;

  constructor(
    public dialog: MatDialog,
    private transactionViewService: TransactionViewService,
    private toastr: ToastrService
  ) {
    this.inicializeDatesFilters();
  }

  ngOnInit(): void {
    this.getTransactions(this.startDateFilter || '', this.endDateFilter || '');
  }

  inicializeDatesFilters() {
    const currentYear = moment().year();
    this.startDateFilter = moment()
      .year(currentYear)
      .startOf('year')
      .format('YYYY-MM-DD');
    this.endDateFilter = moment()
      .year(currentYear)
      .endOf('year')
      .format('YYYY-MM-DD');
  }

  getTransactions(startDate: string, endDate: string) {
    this.transactionViewService
      .getTransactions(startDate, endDate)
      .pipe(
        take(1),
        catchError(() => {
          this.toastr.error(
            'No se pudo completar la solicitud de las transacciones.'
          );
          return EMPTY;
        })
      )
      .subscribe((transactionResponse) => {
        this.transactions = transactionResponse.data.adminPaymentTransactions;
      });
  }

  tableMenuSelected(itemData: ITableItem) {
    const dialogRef = this.dialog.open(DownloadUploadDialogComponent, {
      width: '90%',
      maxWidth: '700px',
      data: {
        title: 'Actualizar comprobante de pago',
        subtitle:
          'Actualiza tu comprobante de pago utilizando una imagen de la transferencia bancaria',
        primaryButtonLabel: 'Actualizar',
        secondaryButtonLabel: 'Cancelar',
        hideDownloadButton: true,
      },
    });

    dialogRef.componentInstance.fileLoadedEmitter.subscribe((file) => {
      if (file) {
        this.uploadFile = file;
      }
    });
    dialogRef.componentInstance.primaryButtonClicked.subscribe(() => {
      if (this.uploadFile) {
        this.dialog.closeAll();
        this.patchAttachment();
      }
    });

    dialogRef.componentInstance.secondaryButtonClicked.subscribe(() => {
      this.dialog.closeAll();
    });
  }

  patchAttachment() {
    if (this.isLoading) return;
    this.isLoading = true;
    //call endpoint
  }
}
