<div class="brands-dialog-container">
  <div class="title-container">
    <h2 class="title-container__title">{{ formTitle || '' }}</h2>
    <gc-icon
      class="title-container__icon"
      icon="x"
      [dimension]="22"
      color="grey-800"
      mat-dialog-close
    ></gc-icon>
  </div>
  <div class="form-container" [formGroup]="form">
    <div class="input-container">
      <gc-label label="Nombre de la marca" [control]="brandNameFormControl">
        <gc-input
          id="brandName"
          class="input-container__input"
          type="text"
          formControlName="brandName"
          placeHolder="Nombre de la marca"
        ></gc-input>
      </gc-label>
    </div>

    <div class="input-container">
      <gc-label
        label="Descripción (Opcional)"
        [control]="descriptionFormControl"
      >
        <gc-input
          id="brandDescription"
          class="input-container__input"
          type="text"
          formControlName="description"
          placeHolder="Descripción"
        ></gc-input>
      </gc-label>
    </div>
    <div class="image-section">
      <gc-button
        class="image-section__add-button"
        text="Agregar imagen"
        (click)="selectFile.click()"
        mode="ghost"
        color="primary"
        icon="plus-circle"
        iconPosition="right"
        [isLoading]="isImageLoading"
      ></gc-button>
      <input
        class="image-section__input"
        id="imageInputCAtegory"
        type="file"
        value="select"
        #selectFile
        accept=".jpg, .jpeg, .png"
        (change)="onImageSelected($event)"
      />
      <div class="image-section-wrapper" *ngIf="imageItem">
        <img
          class="image-section-wrapper__image"
          [src]="imageItem"
          alt="image-selected"
        />
        <button
          class="image-section-wrapper__delete-image"
          (click)="removeImage()"
        >
          <i-feather
            class="image-section-wrapper__delete-image--icon"
            name="trash"
          ></i-feather>
        </button>
      </div>
    </div>
    <span class="error-message">{{ errorMsg }}</span>
    <div class="buttons-wrapper">
      <div class="button-container">
        <gc-button
          class="button-container__button"
          text="Cancelar"
          (click)="cancel()"
          type="full"
          color="secondary"
          mode="ghost"
        ></gc-button>
      </div>
      <div class="button-container">
        <gc-button
          class="button-container__button"
          text="Aceptar"
          (click)="saveAndContinue()"
          type="full"
          [isLoading]="isLoading"
        ></gc-button>
      </div>
    </div>
  </div>
</div>
