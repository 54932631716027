import { Component, HostListener, OnInit } from '@angular/core';
import { CMenuBreakpoint } from '../../constants/layout-breakpoints';
import { AuthenticationViewService } from '@tfi-workspace-web/tfi-feat-onboarding';
import { Router, Scroll } from '@angular/router';
import {
  ClaimsEnum,
  CompanyIndustryEnum,
  IMenuItem,
  UserPermissionsEnum,
} from '@tfi-workspace-web/tfi-shared-utils';
import { CompanyViewService } from '@tfi-workspace-web/tfi-feat-business-management';
import { EMPTY, Subject, catchError, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'tfi-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  userPermissionsEnum = UserPermissionsEnum;
  sidebarVisible = false;
  menuItems: IMenuItem[] = [];
  withBreakpoint = CMenuBreakpoint;
  screenWidth?: number;
  isFooterVisible = true;
  userPermissions = UserPermissionsEnum;
  mainMenuOptions = [
    {
      label: 'Home',
      isSelected: false,
      path: '/admin/dashboard-management',
      children: [],
    },
    {
      label: 'Clientes',
      isSelected: false,
      path: '/admin/customer-admin',
      children: [],
      hasAccess: this.authViewService.hasPermissionLoose([
        this.userPermissions.USR_CST_LST,
      ]),
    },
    {
      label: 'Productos',
      isSelected: false,
      path: `/admin/product-management${this.getProdRoute()}`,
      children: [],
      hasAccess: this.authViewService.hasPermissionLoose([
        this.userPermissions.USR_PRD_LST,
        this.userPermissions.USR_CAT_LST,
        this.userPermissions.USR_BRD_LST,
      ]),
    },
    {
      label: 'Inventario',
      isSelected: false,
      path: '/admin/inventory-management',
      children: [],
      hasAccess: this.authViewService.hasPermissionStrict([
        this.userPermissions.USR_INVNT_LST,
        this.userPermissions.USR_CAT_LST,
        this.userPermissions.USR_BRD_LST,
      ]),
    },
    {
      label: 'POS',
      isSelected: false,
      path: '/admin/ticket-management',
      children: [],
      hasAccess: this.authViewService.hasPermissionStrict([
        this.userPermissions.USR_TKT_LST,
        this.userPermissions.USR_BRN_LST,
        this.userPermissions.USR_CMP_LST,
      ]),
    },
    {
      label: 'Envíos',
      isSelected: false,
      path: '/admin/shipping-management',
      children: [],
      hasAccess: this.authViewService.hasPermissionLoose([
        this.userPermissions.USR_SHP_LST,
      ]),
    },
    /*{
      label: 'Citas',
      isSelected: false,
      path: '/admin/scheduler-management',
      children: [],
    },*/
  ];

  kitchenMenuOption = {
    label: 'Kitchen Display',
    isSelected: false,
    path: '/admin/kds-management',
    children: [],
    hasAccess: this.authViewService.hasPermissionLoose([
      this.userPermissions.USR_KOD_LST,
    ]),
  };

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenWidth = window.innerWidth;
    this.validateScreenWidthForMenu();
  }

  constructor(
    private authViewService: AuthenticationViewService,
    private router: Router,
    private companyViewService: CompanyViewService
  ) {}

  ngOnInit(): void {
    this.validateScreenWidthForMenu();
    this.validateFooterVisivibility();
    this.validateKitchenOptionVisibility();
  }

  validateKitchenOptionVisibility() {
    this.companyViewService.company$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => (this.menuItems = this.mainMenuOptions)),
        catchError(() => {
          return EMPTY;
        })
      )
      .subscribe((res) => {
        if (
          (res.industry === CompanyIndustryEnum.FOOD &&
            res.businessParams?.enableKDS.value) ||
          this.authViewService.userProfile.claims.includes(
            ClaimsEnum.KITCHEN_DISPLAY
          )
        ) {
          this.menuItems = [...this.menuItems, this.kitchenMenuOption];
        }
      });
  }

  validateScreenWidthForMenu() {
    if (this.screenWidth && this.screenWidth > this.withBreakpoint) {
      this.sidebarVisible = false;
    }
  }

  validateFooterVisivibility() {
    this.router.events.subscribe((event) => {
      if (event instanceof Scroll) {
        this.isFooterVisible = !this.router.url.includes(
          'ticket-management/ticket'
        );
      }
    });
  }

  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
  }

  hideSideBar() {
    this.sidebarVisible = false;
  }

  logout() {
    this.authViewService.logout();
    this.sidebarVisible = false;
  }

  mobileBussAdminRoute(): void {
    let routeToNav = '';
    if (
      this.authViewService.hasPermissionStrict([
        this.userPermissionsEnum.USR_CMP_LST,
      ])
    ) {
      routeToNav = 'companies';
    } else if (
      this.authViewService.hasPermissionStrict([
        this.userPermissionsEnum.USR_USR_LST,
      ])
    ) {
      routeToNav = 'users';
    } else if (
      this.authViewService.hasPermissionStrict([
        this.userPermissionsEnum.USR_ROL_LST,
      ])
    ) {
      routeToNav = 'roles';
    } else if (
      this.authViewService.hasPermissionStrict([
        this.userPermissionsEnum.USR_BRN_LST,
      ])
    ) {
      routeToNav = 'branches';
    } else if (
      this.authViewService.hasPermissionStrict([
        this.userPermissionsEnum.USR_INV_LST,
      ])
    ) {
      routeToNav = 'billing';
    } else if (
      this.authViewService.hasPermissionStrict([
        this.userPermissionsEnum.USR_APT_LST,
      ])
    ) {
      routeToNav = 'transactions';
    }
    this.hideSideBar();
    this.router.navigate([`/admin/business-management/${routeToNav}`]);
  }

  getProdRoute(): string {
    if (
      this.authViewService.hasPermissionStrict([
        this.userPermissionsEnum.USR_PRD_LST,
        this.userPermissionsEnum.USR_CAT_LST,
        this.userPermissionsEnum.USR_BRD_LST,
      ])
    ) {
      return '/product';
    } else if (
      this.authViewService.hasPermissionStrict([
        this.userPermissionsEnum.USR_CAT_LST,
      ])
    ) {
      return '/category';
    } else if (
      this.authViewService.hasPermissionStrict([
        this.userPermissionsEnum.USR_BRD_LST,
      ])
    ) {
      return '/brands';
    }
    return '';
  }
}
