<div class="gc-search-select">
  <div class="gc-search-select-container" (click)="openPanel($event)">
    <gc-icon
      class="gc-search-select-container__search-icon"
      icon="search"
      color="grey-600"
      [dimension]="16"
    ></gc-icon>
    <input
      class="gc-search-select-container__input"
      [ngClass]="{ 'gc-search-select-container__input--disabled': disabled }"
      autocomplete="off"
      [id]="id"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [(ngModel)]="searchTerm"
      (input)="onInput()"
    />
    <div
      *ngIf="!isInputEmpty"
      class="gc-search-select-container__x-icon"
      [ngClass]="{
      'gc-search-select-container__x-icon--disabled': disabled,
    }"
    >
      <gc-icon
        icon="x"
        color="grey-200"
        [dimension]="18"
        (click)="emptySelection($event)"
      ></gc-icon>
    </div>
    <gc-icon
      *ngIf="!isPanelOptionsVisible; else showPanel"
      class="gc-search-select-container__chevron"
      icon="chevron-down"
      color="grey-300"
      (click)="openPanel($event)"
    ></gc-icon>
    <ng-template #showPanel>
      <gc-icon
        icon="chevron-up"
        class="gc-search-select-container__chevron"
        color="grey-300"
        (click)="closePanel($event)"
      ></gc-icon>
    </ng-template>
  </div>
  <div class="gc-search-select-panel" *ngIf="isPanelOptionsVisible">
    <li
      class="gc-search-select-panel__item"
      *ngFor="let option of listOptions"
      (click)="optionSelected(option)"
    >
      {{ option.label }}
    </li>
  </div>
  <div
    class="gc-search-select-panel"
    *ngIf="listOptions.length === 0 && isPanelOptionsVisible"
  >
    <li class="gc-search-select-panel__item">No se encontró ningún registro</li>
  </div>
</div>
