import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ShippingViewService } from 'libs/tfi-feat-shipping-management/src/lib/services/shipping/view/shipping-view.service';
import { ToastrService } from 'ngx-toastr';
import { InputComponent } from '../input/input.component';
import {
  ISelectOption,
  ITicket,
  OperationTimeOptions,
  ShippingStatusOptions,
  TOAST_MESSAGES,
} from '@tfi-workspace-web/tfi-shared-utils';
import { catchError, EMPTY, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CreateDriverDialogComponent } from '../create-driver-dialog/create-driver-dialog.component';

@Component({
  selector: 'gc-sidebar-shipping-detail',
  templateUrl: './sidebar-shipping-detail.component.html',
  styleUrls: ['./sidebar-shipping-detail.component.scss'],
})
export class SidebarShippingDetailComponent implements OnInit {
  @ViewChild('shippingCost') shippingCost!: InputComponent;
  @Output() hideSidebar = new EventEmitter<void>();
  @Output() updateTable = new EventEmitter<void>();
  @Input() isShippingSidebarVisible = false;
  @Input() selectedTicket?: ITicket;
  shippingStatusOptions = ShippingStatusOptions;
  driverNameInputOptions: ISelectOption[] = [];
  operationTime = OperationTimeOptions;
  minDate = new Date();

  isEditing = {
    shippingPrice: false,
    shippingStatus: false,
    driverName: false,
    notes: false,
    deliveryDay: false,
  };

  isLoading = {
    shippingPrice: false,
    shippingStatus: false,
    driverName: false,
    notes: false,
    deliveryDay: false,
  };

  form = new FormGroup({
    shippingPrice: new FormControl(null, [Validators.min(0)]),
    shippingStatus: new FormControl(null),
    driverName: new FormControl(null),
    notes: new FormControl(null),
    deliveryDay: new FormControl(null),
    deliveryHour: new FormControl(null),
  });

  get shippingPriceFormControl() {
    return this.form.get('shippingPrice') as FormControl;
  }
  get shippingStatusFormControl() {
    return this.form.get('shippingStatus') as FormControl;
  }
  get driverNameFormControl() {
    return this.form.get('driverName') as FormControl;
  }
  get notesFormControl() {
    return this.form.get('notes') as FormControl;
  }
  get deliveryDayFormControl() {
    return this.form.get('deliveryDay') as FormControl;
  }
  get deliveryHourFormControl() {
    return this.form.get('deliveryHour') as FormControl;
  }

  constructor(
    private router: Router,
    private shippingViewService: ShippingViewService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getDrivers();
    this.patchForm();
  }

  patchForm() {
    this.shippingPriceFormControl.patchValue(
      this.selectedTicket?.shippingDetail?.shippingPrice
    );
    this.shippingStatusFormControl.patchValue(
      this.selectedTicket?.shippingDetail?.shippingStatus
    );
    this.driverNameFormControl.patchValue(
      this.selectedTicket?.shippingDetail?.driverName
    );
    this.notesFormControl.patchValue(
      this.selectedTicket?.shippingDetail?.notes
    );
    if (this.selectedTicket?.shippingDetail?.deliveryDay) {
      this.deliveryDayFormControl.patchValue(
        new Date(this.selectedTicket?.shippingDetail?.deliveryDay)
      );
    }
    this.deliveryHourFormControl.patchValue(
      this.selectedTicket?.shippingDetail?.deliveryHour
    );
  }

  getDrivers() {
    this.shippingViewService
      .getShippingCompany()
      .subscribe((shippingCompanyReponse) => {
        const companies = shippingCompanyReponse.data.shippingCompanies;
        companies.forEach((deliveryCompany) => {
          if (
            !this.driverNameInputOptions.some(
              (c) => c.value === deliveryCompany.name
            )
          ) {
            this.driverNameInputOptions = [
              ...this.driverNameInputOptions,
              { label: deliveryCompany.name, value: deliveryCompany.name },
            ];
          }
        });
      });
  }

  onHide() {
    this.hideSidebar.emit();
  }

  navigateToEditShipping() {
    this.router.navigate([
      `/admin/shipping-management/shipping/edit/${this.selectedTicket?.shippingDetail?._id}`,
    ]);
  }

  onStatusSelected(status: ISelectOption) {
    this.shippingStatusFormControl.setValue(status.value);
  }

  initShippingCostEdit() {
    this.stopEditing();
    this.patchForm();
    this.isEditing.shippingPrice = true;
    this.cdr.detectChanges();
    this.shippingCost.focusInput();
  }

  initShippingStatusEdit() {
    this.stopEditing();
    this.patchForm();
    this.isEditing.shippingStatus = true;
  }

  initShippingNotesEdit() {
    this.stopEditing();
    this.patchForm();
    this.isEditing.notes = true;
  }

  initShippingDeliveryDateEdit() {
    this.stopEditing();
    this.patchForm();
    this.isEditing.deliveryDay = true;
  }

  initShippingDriverEdit() {
    this.stopEditing();
    this.patchForm();
    this.isEditing.driverName = true;
  }

  onSaveShippingCost() {
    if (this.shippingPriceFormControl.value === '') {
      this.shippingPriceFormControl.setValue(0);
    }
    this.isLoading.shippingPrice = true;
    this.submit();
  }

  onSaveShippingStatus() {
    this.isLoading.shippingStatus = true;
    this.submit();
  }

  onSaveShippingNotes() {
    this.isLoading.notes = true;
    this.submit();
  }

  onSaveShippingDelDate() {
    this.isLoading.deliveryDay = true;
    this.submit();
  }

  onSaveShippingDriver() {
    this.isLoading.driverName = true;
    this.submit();
  }

  submit() {
    this.shippingViewService
      .patchShipping(
        this.selectedTicket?.shippingDetail?._id || '',
        this.form.value
      )
      .pipe(
        take(1),
        catchError(() => {
          this.stopLoadings();
          this.toastr.error(TOAST_MESSAGES.ERROR);
          return EMPTY;
        })
      )
      .subscribe((response) => {
        this.stopLoadings();
        this.stopEditing();
        this.toastr.success(TOAST_MESSAGES.SUCCESS);
        if (this.selectedTicket)
          this.selectedTicket.shippingDetail = response.data.shipping;
        this.updateTable.emit();
      });
  }

  stopLoadings() {
    this.isLoading.shippingPrice = false;
    this.isLoading.shippingStatus = false;
    this.isLoading.driverName = false;
    this.isLoading.notes = false;
    this.isLoading.deliveryDay = false;
  }

  stopEditing() {
    this.isEditing.shippingPrice = false;
    this.isEditing.shippingStatus = false;
    this.isEditing.driverName = false;
    this.isEditing.notes = false;
    this.isEditing.deliveryDay = false;
  }

  onCreateNewDriver() {
    const dialogRef = this.dialog.open(CreateDriverDialogComponent, {
      minWidth: '360px',
      width: '85%',
      maxWidth: '575px',
      maxHeight: '90vh',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.driverNameFormControl.setValue(response);
        this.cdr.detectChanges();
        this.getDrivers();
      }
    });
  }
}
