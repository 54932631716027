import { Component } from '@angular/core';

@Component({
  selector: 'tfi-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  get currentYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }
}
