export const authMethods = {
  signIn: 'api/v1/auth/sign-in',
  register: 'api/v1/auth/signup',
  resetPassword: 'api/v1/auth/reset-password',
  changePassword: 'api/v1/auth/change-password',
  verifyUser: 'api/v1/auth//verify-user',
};

export const customerMethods = {
  customer: 'api/v1/customer',
};

export const brandsMethods = {
  brand: 'api/v1/brand',
};

export const categoryMethods = {
  category: 'api/v1/category',
};

export const productMethods = {
  product: 'api/v1/product',
};

export const orderMethods = {
  order: 'api/order',
};

export const userMethods = {
  user: 'api/v1/user',
};

export const branchMethods = {
  branch: 'api/v1/branch',
};

export const shippingMethods = {
  shipping: 'api/v1/shipping',
};

export const companyMethods = {
  company: 'api/v1/company',
};

export const multimediaMethods = {
  image: 'api/v1/multimedia/image',
};

export const inventoryMethods = {
  inventory: 'api/v1/inventory',
};

export const ticketMethods = {
  ticket: 'api/v1/ticket',
};

export const kitchenMethods = {
  order: 'api/v1/kitchen-order',
};

export const reportingMethods = {
  reporting: 'api/v1/reporting/business-measures',
};

export const invoiceMethods = {
  invoice: 'api/v1/invoice',
};

export const paymentMethods = {
  payment: 'api/v1/payment',
};

export const paymentTransactionMethods = {
  transaction: 'api/v1/payment-transaction',
};

export const businessRules = {
  plans: 'api/v1/business-rules',
};

export const roleMethods = {
  roles: 'api/v1/user-role',
};

export const permissionMethods = {
  roles: 'api/v1/permission',
};

export const catalogMethods = {
  catalog: 'api/v1/catalog',
};

export const addressMethods = {
  address: 'api/v1/address',
};

export const economicActivitiesMethods = {
  economicActivities: 'api/v1/economic-activities',
};

export const paymentGatewaysMethods = {
  paymentGateways: 'api/v1/client/payment',
};

export const dteMethods = {
  dte: 'api/v1/dte',
};

export const companyWebCatalogMethods = {
  webCatalog: 'api/v1/web-catalog',
};
