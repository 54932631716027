<header class="header-container">
  <div class="header-wrapper">
    <div class="logo-container">
      <img
        src="../../../../../assets/images/tefi-logo.svg"
        alt="tefi-logo"
        style="width: 180px; height: 50px"
      />
    </div>
    <ul class="desktop-menu-container">
      <li
        *ngFor="let menuOption of menuItems; let i = index"
        class="menu-item"
        (click)="showMenu(menuOption)"
        [routerLink]="menuOption.path"
      >
        <ng-container *ngIf="i === 0 || menuOption.hasAccess">
          <a
            class="menu-item__link"
            [routerLink]="menuOption.path"
            routerLinkActive="active-link"
          >
            <div class="menu-label-container">
              <gc-icon
                icon="chevron-down"
                [dimension]="16"
                *ngIf="menuOption.children.length > 0"
              ></gc-icon>
              <span class="menu-label-container__label">{{
                menuOption.label
              }}</span>
            </div>
          </a>
          <ul
            class="inner-options"
            *ngIf="menuOption.isSelected && menuOption.children.length > 0"
            [@menuAnimation]
          >
            <li
              class="inner-options__item"
              *ngFor="let item of menuOption.children"
            >
              <a [routerLink]="item.path">{{ item.label }}</a>
            </li>
          </ul>
        </ng-container>
      </li>
    </ul>
    <button class="desktop-profile-button" (click)="toggleProfileMenu()">
      <gc-icon
        icon="menu"
        [dimension]="18"
        color="grey-900"
        class="desktop-profile-button__icon"
      ></gc-icon>
      <img
        class="desktop-profile-button__avatar"
        src="/assets/layout/images/user-avatar.png"
        alt="user-avatar"
      />
      <ul
        class="profile-menu-container"
        *ngIf="isProfileMenuVisible"
        [@menuAnimation]
      >
        <li
          *ngIf="showBussAdmin"
          class="profile-menu-item"
          (click)="bussAdminRoute()"
        >
          <gc-icon
            class="profile-menu-item__icon"
            icon="airplay"
            [dimension]="20"
            color="grey-700"
          ></gc-icon>
          <span class="profile-menu-item__label"
            >Administración de Negocio</span
          >
        </li>
        <li class="profile-menu-item" (click)="logout()">
          <gc-icon
            class="profile-menu-item__icon"
            icon="log-out"
            [dimension]="20"
            color="grey-700"
          ></gc-icon>
          <span class="profile-menu-item__label">Cerrar Sesión</span>
        </li>
      </ul>
    </button>
    <gc-icon
      class="header-wrapper__mobile-menu-button"
      icon="menu"
      (click)="onMobileMenuClicked()"
      color="grey-500"
      [dimension]="22"
    ></gc-icon>
  </div>
</header>
