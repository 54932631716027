import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  environment,
  paymentMethods,
} from '@tfi-workspace-web/tfi-shared-utils';
import { Observable } from 'rxjs';
import { IPaymentPayload } from '../../../interfaces/payment-payload.interface';

@Injectable({
  providedIn: 'root',
})
export class PaymentDataService {
  private readonly paymentUrl = environment.api_host + paymentMethods.payment;
  constructor(private http: HttpClient) {}

  submitPayment(payload: IPaymentPayload): Observable<any> {
    return this.http.post<any>(this.paymentUrl, payload);
  }
}
