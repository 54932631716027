import { IBaseEntity } from './base-entity.interface';

export interface ICategory extends IBaseEntity {
  categoryName: string;
  description?: string;
  categoryImg?: string;
  isSelected?: boolean;
}

export interface ICategoriesResponse {
  status: string;
  results: number;
  data: {
    categories: ICategory[];
  };
}

export interface ICategoryResponse {
  status: string;
  data: {
    category: ICategory;
  };
}
