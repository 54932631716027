import { EntityEnum } from '../enums/entity.enum';
import { PeriodicityEnum } from '../enums/periodicity.enum';

export const PeriodicityOptions = {
  [PeriodicityEnum.MONTHLY as any]: 'Mensual',
};

export const EntityOptions = {
  [EntityEnum.BRANCH as any]: 'Sucursales',
  [EntityEnum.USER as any]: 'Usuarios',
};
