<div class="gc-menu">
  <i-feather
    class="gc-menu__icon"
    name="more-vertical"
    (click)="openOptions($event)"
  ></i-feather>
  <ul
    *ngIf="showOptions"
    class="gc-menu-options"
    [ngClass]="{
  'gc-menu-options--left': position === 'left',
  'gc-menu-options--top': position === 'top',
  }"
  >
    <li
      class="gc-menu-options-item"
      *ngFor="let option of options"
      (click)="selectOption(option.id)"
    >
      <gc-icon [icon]="option.icon" [dimension]="16"></gc-icon>
      <span class="gc-menu-options-item__label">{{ option.label }}</span>
    </li>
  </ul>
</div>
