import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'gc-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  @Output() fileLoaded = new EventEmitter<File>();
  @Output() fileDeleted = new EventEmitter<void>();
  @Input() maxSizeInBytes = 10 * 1024 * 1024;
  @Input() showMaxSizeLabel = true;
  file: File | null = null;

  constructor(private toastr: ToastrService) {}

  @HostListener('document:drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const fileList: FileList = event.dataTransfer!.files;
    const selectedFile = fileList[0];
    if (selectedFile.size <= this.maxSizeInBytes) {
      this.file = selectedFile;
      this.fileLoaded.emit(selectedFile);
    } else {
      this.toastr.error(
        'El tamaño del archivo seleccionado excede lo permitido'
      );
      return;
    }
  }

  onFileSelected(event: any) {
    const fileList: FileList = event.target.files;
    const selectedFile = fileList[0];
    if (selectedFile.size <= this.maxSizeInBytes) {
      this.file = selectedFile;
      this.fileLoaded.emit(selectedFile);
    } else {
      this.toastr.error(
        'El tamaño del archivo seleccionado excede lo permitido'
      );
      return;
    }
  }

  removeFile() {
    this.file = null;
    this.fileDeleted.emit();
  }

  @HostListener('document:dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
