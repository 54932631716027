import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CrudActionsEnum,
  FormErrorMessageEnum,
  IPermission,
  IRole,
  IRolesResponse,
  Modaldata,
  TableMenuActionsEnum,
  TOAST_MESSAGES,
  UserPermissionsEnum,
} from '@tfi-workspace-web/tfi-shared-utils';
import { RoleViewService } from '../../services/role/view/role-view.service';
import { catchError, EMPTY, Subject, switchMap, take, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup } from '@angular/forms';
import { RoleFilters } from '../../constants/role-filters';
import { MatDialog } from '@angular/material/dialog';
import { RoleFormComponent } from './components/role-form/role-form.component';
import { PermissionViewService } from '../../services/permission/view/permission-view.service';
import { RoleActionsEnum } from '../../enums/role-management-action.enum';
import {
  ConfirmDialogComponent,
  ConfirmDialogEnum,
  IConfirmDialogCloseData,
  IConfirmDialogData,
} from '@gc-tech/components';

@Component({
  selector: 'tfi-feat-bm-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss'],
  animations: [
    trigger('slideInFromRight', [
      state('void', style({ transform: 'translateX(100%)' })),
      state('*', style({ transform: 'translateX(0)' })),
      transition('void => *', animate('300ms ease-out')),
    ]),
  ],
})
export class RoleManagementComponent implements OnInit, OnDestroy {
  isSearching = false;
  roles: IRole[] = [];
  permissions: IPermission[] = [];
  totalTableElements?: number;
  destroy$: Subject<boolean> = new Subject<boolean>();
  formSearch = new FormGroup({ searchFilter: new FormControl('') });
  filters = new RoleFilters();
  errorMsg?: string;
  userPermissionsEnum = UserPermissionsEnum;
  constructor(
    private roleViewService: RoleViewService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private permissionViewService: PermissionViewService
  ) {}

  ngOnInit(): void {
    this.isSearching = true;

    this.roleViewService.roles$
      .pipe(takeUntil(this.destroy$))
      .subscribe((roles) => {
        this.totalTableElements = this.roleViewService.totalElements;
        this.roles = roles;
        this.isSearching = false;
      });
    this.permissionViewService.permissions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((permissions) => {
        this.permissions = permissions;
      });
    this.roleViewService.getRoles(this.filters);
    this.permissionViewService.getPermissions();
    this.addRoleFilter();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addRoleFilter(): void {
    this.formSearch.controls.searchFilter.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        switchMap((search) => {
          this.isSearching = true;
          this.filters.roleName = search || '';
          this.filters.page = 1;
          return this.roleViewService.getRolesRaw(this.filters);
        }),
        catchError(() => {
          this.isSearching = false;
          this.errorMsg =
            FormErrorMessageEnum.OCURRIO_UN_ERROR_INTENTE_MAS_TARDE;
          this.toastr.error(TOAST_MESSAGES.ERROR);
          return EMPTY;
        })
      )
      .subscribe((roles: IRolesResponse) => {
        if (roles?.data?.roles) {
          this.roles = roles?.data?.roles;
          this.totalTableElements = roles.total;
          this.isSearching = false;
        }
      });
  }

  pageChanged(page: number) {
    this.filters.page = page;
    this.roleViewService.getRoles(this.filters);
  }

  limitPerPageChange(limitPerPage: number) {
    this.filters.limit = limitPerPage;
    this.filters.page = 1;
    this.roleViewService.getRoles(this.filters);
  }

  manageAction(event: any): void {
    if (event.action === TableMenuActionsEnum.EDIT) {
      this.openRoleFormDialog({
        crudAction: CrudActionsEnum.UPDATE,
        modalData: {
          permissions: this.permissions,
          selectedRol: event.data,
        },
      });
    }

    if (event.action === RoleActionsEnum.ENABLE_DISABLE) {
      this.openChangeActionModal(event.data);
    }
  }

  createNewRole(): void {
    this.openRoleFormDialog({
      crudAction: CrudActionsEnum.CREATE,
      modalData: {
        permissions: this.permissions,
      },
    });
  }

  openChangeActionModal(data: IRole) {
    const message = data.isEnabled
      ? `Para desactivar debes asegurarte que el rol no este asignado a ningun usuario`
      : ``;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Seguro que deseas ${
          data.isEnabled ? 'desactivar' : 'activar'
        } el rol ${data.name}?`,
        subTitle: message,
        primaryButtonText: 'Aceptar',
        secondaryButtonText: 'Cancelar',
      } as IConfirmDialogData,
      width: '540px',
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        catchError(() => {
          this.toastr.error(TOAST_MESSAGES.ERROR);
          return EMPTY;
        })
      )
      .subscribe((modal: IConfirmDialogCloseData) => {
        if (modal.action === ConfirmDialogEnum.CONFIRM) {
          this.roleViewService
            .updateRoleStatus(
              { isEnabled: (!data.isEnabled).toString() },
              data._id as string
            )
            .pipe(
              take(1),
              catchError(() => {
                this.toastr.error(TOAST_MESSAGES.ERROR);
                return EMPTY;
              })
            )
            .subscribe((res) => {
              if (res) {
                this.toastr.success(TOAST_MESSAGES.SUCCESS);
                this.roleViewService.getRoles(this.filters);
              }
            });
        }
      });
  }

  openRoleFormDialog(modalData: Modaldata) {
    const dialogRef = this.dialog.open(RoleFormComponent, {
      data: modalData,
      minWidth: '360px',
      width: '85%',
      maxWidth: '1200px',
      autoFocus: false,
      panelClass: 'custom-dialog-container',
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.roleViewService.getRoles(this.filters);
        }
      });
  }
}
