<div *ngIf="data.length > 0; else emptyTable">
  <p-table
    #table
    dataKey="_id"
    [value]="data"
    [rows]="rows"
    editMode="row"
    responsiveLayout="scroll"
    [rows]="rows"
    [rowsPerPageOptions]="rowsPerPageOptions"
    [resizableColumns]="true"
    [autoLayout]="true"
    [scrollable]="true"
    [(selection)]="selectedRows"
    (onRowSelect)="updateSelection()"
    (onRowUnselect)="updateSelection()"
    (onHeaderCheckboxToggle)="updateSelection()"
    [scrollHeight]="showFilters ? 'calc(58vh - 10vh)' : 'calc(65vh - 10vh)'"
  >
    <ng-template pTemplate="header">
      <tr>
        <th
          *PermissionControl="[userPermissionsEnum.USR_PRD_EDT]"
          style="width: 2%"
        >
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th style="width: 10%">SKU interno</th>
        <th style="width: 15%">Producto</th>
        <th style="width: 14%">Imagen</th>
        <th style="width: 15%">Categoría</th>
        <th style="width: 12%">Marca</th>
        <th style="width: 9%">Precio</th>
        <th style="width: 9%">Costo Total</th>
        <th style="width: 9%">Margen %</th>
        <th style="width: 9%">Estado</th>
        <th
          *PermissionControl="[
            userPermissionsEnum.USR_PRD_EDT,
            userPermissionsEnum.USR_CAT_LST,
            userPermissionsEnum.USR_BRD_LST
          ]"
          style="width: 5%"
          alignFrozen="right"
          pFrozenColumn
        ></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
      <tr>
        <td *PermissionControl="[userPermissionsEnum.USR_PRD_EDT]">
          <div *ngIf="!skeleton">
            <p-tableCheckbox [value]="product"></p-tableCheckbox>
          </div>
          <div *ngIf="skeleton" [ngClass]="{ skeleton: skeleton }">XXXX</div>
        </td>
        <td>
          <span [ngClass]="{ skeleton: skeleton }">
            {{ product?.internalSKU ?? '-' }}
          </span>
        </td>
        <td>
          <span [ngClass]="{ skeleton: skeleton }">
            {{ product?.productName ?? '-' }}</span
          >
        </td>
        <td>
          <img
            class="product-image"
            *ngIf="!skeleton"
            [src]="product.mainImageUrl || '/assets/images/no-image.jpg'"
            alt="inventory.product.productName"
            (click)="onImageClicked(product)"
          />
          <div
            *ngIf="skeleton"
            class="product-image"
            [ngClass]="{ skeleton: skeleton }"
          ></div>
        </td>
        <td>
          <span [ngClass]="{ skeleton: skeleton }">{{
            product?.category?.categoryName ?? '-'
          }}</span>
        </td>
        <td>
          <span [ngClass]="{ skeleton: skeleton }">{{
            product?.brand?.brandName ?? '-'
          }}</span>
        </td>
        <td>
          <span [ngClass]="{ skeleton: skeleton }"
            >${{ product?.price ?? 0 | number : '1.2-2' }}</span
          >
        </td>
        <td>
          <span [ngClass]="{ skeleton: skeleton }"
            >${{
              product?.cost + product?.unitVariableCost ?? 0 | number : '1.2-2'
            }}</span
          >
        </td>
        <td>
          <span [ngClass]="{ skeleton: skeleton }">{{
            product?.marginPerc ?? 0 | percent
          }}</span>
        </td>
        <td>
          <gc-badge
            [skeleton]="skeleton"
            [text]="product.isEnabled ? 'Activo' : 'Inactivo'"
            size="lg"
            [backgroundColor]="product.isEnabled ? 'green-500' : 'red-500'"
            textColor="base-white"
          ></gc-badge>
        </td>
        <td
          *PermissionControl="[
            userPermissionsEnum.USR_PRD_EDT,
            userPermissionsEnum.USR_CAT_LST,
            userPermissionsEnum.USR_BRD_LST
          ]"
          alignFrozen="right"
          pFrozenColumn
        >
          <div [ngClass]="{ skeleton: skeleton }">
            <gc-menu
              [options]="options"
              position="top"
              (selectedOption)="setAction($event, product, rowIndex)"
            ></gc-menu>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div
    *ngIf="totalTableElements && currentPage && limitPerPage && showPaginator"
  >
    <gc-paginator
      [totalElements]="totalTableElements"
      [currentPage]="currentPage"
      [limitPerPage]="limitPerPage"
      (pageChanged)="pageChanged($event)"
      (limitPerPageChanged)="limitPerPageChanged($event)"
    ></gc-paginator>
  </div>
</div>
<ng-template #emptyTable>
  <h2 class="tfi-empty-label">No se encontraron registros.</h2>
</ng-template>
