import { TableMenuActionsEnum } from '../enums/table-menu-actions.enum';
import { UserPermissionsEnum } from '../enums/user-permissions.enum';
import { ITableAction } from '../interfaces/table-action.interface';
import { ITicket } from '../interfaces/ticket.interface';

export const OPEN_TICKET_ACTIONS: ITableAction[] = [
  {
    label: 'Editar',
    icon: 'edit',
    tooltip: 'Editar',
    permissions: [UserPermissionsEnum.USR_TKT_EDT],
    menuAction: TableMenuActionsEnum.EDIT,
  },
  {
    label: 'Procesar Orden',
    icon: 'dollar-sign',
    tooltip: 'Procesar Orden',
    permissions: [
      UserPermissionsEnum.USR_TKT_EDT,
      UserPermissionsEnum.USR_CMP_LST,
    ],
    menuAction: TableMenuActionsEnum.PROCESS_ORDEN,
  },
  {
    label: 'Eliminar',
    icon: 'trash-2',
    tooltip: 'Eliminar',
    permissions: [UserPermissionsEnum.USR_TKT_DLT],
    menuAction: TableMenuActionsEnum.DELETE,
  },
];

export const NOT_OPEN_TICKET_ACTIONS: ITableAction[] = [
  {
    label: 'Ver detalle',
    icon: 'eye',
    tooltip: 'Ver detalle',
    permissions: [UserPermissionsEnum.USR_TKT_LST],
    menuAction: TableMenuActionsEnum.VIEWDETAILS,
  },
  {
    label: 'Imprimir',
    icon: 'printer',
    tooltip: 'Imprimir',
    permissions: [UserPermissionsEnum.USR_TKT_LST],
    menuAction: TableMenuActionsEnum.PRINT,
  },
  {
    label: 'Ver envío',
    icon: 'truck',
    tooltip: 'Ver envío',
    permissions: [UserPermissionsEnum.USR_SHP_EDT],
    menuAction: TableMenuActionsEnum.ASSIGN_SHIPPING,
    showAction: (value: ITicket) => {
      return !!value?.shippingInfo?.date;
    },
  },
];
