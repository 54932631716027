<label
  class="gc-switch-container"
  [ngClass]="{
    'gc-switch-container--enabled': !disabled,
    'gc-switch-container--small': size === 'sm',
    'gc-switch-container--medium': size === 'md',
    'gc-switch-container--large': size === 'lg'
  }"
>
  <input
    #switchElement
    [id]="id"
    class="gc-switch-container__input"
    type="checkbox"
    [checked]="checked"
    [disabled]="disabled"
    (click)="switch()"
    tabindex="0"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (keydown)="onKeydown($event)"
  />
  <div
    class="gc-switch-container__slider"
    [ngClass]="{
      'gc-switch-container__slider--disabled': disabled,
      'gc-switch-container__slider--small': size === 'sm',
      'gc-switch-container__slider--medium': size === 'md',
      'gc-switch-container__slider--large': size === 'lg',
      'gc-switch-container__slider--focused': focused
    }"
  ></div>
</label>
