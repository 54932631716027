import { Injectable } from '@angular/core';
import { IShipping } from '@tfi-workspace-web/tfi-shared-utils';
import * as moment from 'moment';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';

@Injectable({
  providedIn: 'root',
})
export class ZebraPrintingService {
  constructor() {}

  async printCodeBar(serial: string): Promise<boolean> {
    try {
      const browserPrint = new ZebraBrowserPrintWrapper();

      const defaultPrinter = await browserPrint.getDefaultPrinter();

      browserPrint.setPrinter(defaultPrinter);

      const printerStatus = await browserPrint.checkPrinterStatus();

      if (printerStatus.isReadyToPrint) {
        const zpl = `^XA
                    ^BY2,2,100
                    ^FO20,20^BC^FD${serial}^FS
                    ^XZ`;

        browserPrint.print(zpl);

        return true;
      } else {
        console.log('Error/s', printerStatus.errors);
        return false;
      }
    } catch (error) {
      console.log('Error', error);
      return false;
    }
  }

  async printShippingLabel(shipping: IShipping): Promise<boolean> {
    try {
      const browserPrint = new ZebraBrowserPrintWrapper();
      const defaultPrinter = await browserPrint.getDefaultPrinter();
      browserPrint.setPrinter(defaultPrinter);
      const printerStatus = await browserPrint.checkPrinterStatus();

      let prefix = '';
      if (shipping.packingUser) {
        prefix =
          shipping.packingUser?.split(' ')[0]?.charAt(0) ??
          '' + shipping.packingUser?.split(' ')[1]?.charAt(0);
      }

      if (printerStatus.isReadyToPrint) {
        const total = shipping?.price + shipping?.shippingPrice;
        const zpl = `^XA

                    ^FX Top section with logo, name and address.
                    ^CF0,50
                    ^FO300,60^FDBaby Times^FS
                    ^FX Second section with recipient address and permit information.
                    ^CF0,30
                    ^FO50,150^FDCLIENTE: ^FS
                    ^CFA,30
                    ^FO270,150^FD${
                      shipping?.customer?.name
                        ?.normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toUpperCase() ?? ''
                    }^FS
                    ^CF0,30
                    ^FO50,200^FDTELEFONO: ^FS
                    ^CFA,30
                    ^FO270,200^FD${shipping?.customerPhoneNumber ?? ''}^FS
                    ^CF0,30
                    ^FO50,250^FDDESTINO: ^FS
                    ^CFA,30
                    ^FO270,250^FB600,3,,^FD${
                      shipping?.shippingAddress?.addressLine?.toUpperCase() ??
                      ''
                    }^FS
                    ^CF0,30
                    ^FO50,350^FDDIA ENTREGA:^FS
                    ^CFA,30
                    ^FO270,350^FD${
                      shipping?.collectionDate
                        ? moment(shipping.collectionDate)
                            .locale('es')
                            .format('dddd')
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            ?.toUpperCase()
                        : ''
                    }^FS
                    ^CF0,30
                    ^FO50,400^FDHORA: ^FS
                    ^CFA,30
                    ^FO270,400^FD${shipping.deliveryHour}^FS
                    ^CF0,30
                    ^FO50,450^FDENCOMENDISTA: ^FS
                    ^CFA,30
                    ^FO270,450^FB550,3,,^FD${
                      shipping.driverName +
                      '  ' +
                      moment(shipping.deliveryDay).format('DD-MMM-yyyy')
                    }^FS

                    ^FX Total section
                    ^CF0,30
                    ^FO400,570^FDSUB-TOTAL: ^FS
                    ^CFA,30
                    ^FO625,570^FD$${shipping.price.toFixed(2)}^FS
                    ^CF0,30
                    ^FO400,620^FDENVIO:^FS
                    ^CFA,30
                    ^FO625,620^FD$${shipping.shippingPrice.toFixed(2)}^FS
                    ^CF0,30
                    ^FO400,680^FDTOTAL: ^FS
                    ^CFA,30
                    ^FO625,680^FD$${total.toFixed(2)}  ^FS
                    ^CF0,30
                    ^FO60,620^FD#${shipping.shippingNumber}^FS
                    ^CF0,30
                    ^FO60,670^FD${prefix?.toUpperCase() ?? ''}^FS

                    ^XZ`;

        await browserPrint.print(zpl);

        return true;
      } else {
        console.log('Error/s', printerStatus?.errors);
        return false;
      }
    } catch (error) {
      console.log('Error', error);
      return false;
    }
  }
}
