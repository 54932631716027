import { Component, Input, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

@Component({
  selector: 'gc-switch-card',
  templateUrl: './switch-card.component.html',
  styleUrls: ['./switch-card.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchCardComponent),
      multi: true,
    },
  ],
})
export class SwitchCardComponent {
  @Input() id = '';
  @Input() title?: string;
  @Input() description?: string;
  @Input() icon?: string;
  @Input() disabled = false;
  @Input() checked = false;

  formControl: FormControl = new FormControl<boolean>(false);

  onChange: (value: boolean) => void = noop;
  onTouch: () => void = noop;

  writeValue(value: boolean) {
    if (value) {
      this.checked = value;
    }
  }
  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  switch() {
    if (!this.disabled) {
      this.onTouch();
      this.onChange(!this.checked);
      this.checked = !this.checked;
    }
  }
}
