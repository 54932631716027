import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

@Component({
  selector: 'gc-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent implements ControlValueAccessor {
  @Input() id?: string;
  @Input() label?: string;
  @Input() value = '';
  @Input() groupName?: string;
  @Input() disabled = false;
  private _contorlValue?: string;
  @Input() checked?: boolean = true;

  onChange: (value: string) => void = noop;
  onTouch: () => void = noop;

  writeValue(value: string) {
    if (this.value === value) {
      this._contorlValue = value;
    }
    this.checked = this.value === this._contorlValue;
  }
  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  select() {
    if (!this.disabled) {
      this.onTouch();
      this.onChange(this.value);
    }
  }
}
