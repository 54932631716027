<div class="tfi-brand" [@slideInFromRight]>
  <div class="tfi-brand-controls">
    <div class="tfi-brand-controls__input">
      <gc-input
        id="name"
        #searchTerm
        type="text"
        icon="search"
        (input)="search(searchTerm.value)"
        placeHolder="Buscar una marca"
      ></gc-input>
    </div>
    <div class="tfi-brand-controls__button">
      <gc-button
        *PermissionControl="[userPermissionsEnum.USR_BRD_CRT]"
        text="Agregar una marca"
        icon="plus"
        type="full"
        (click)="openCreateBrandsDialog()"
      ></gc-button>
    </div>
  </div>
  <div class="tfi-brand-cards">
    <gc-card
      *ngFor="let brand of brands; let i = index"
      [cardImage]="brand.brandImg"
      title="{{ brand.brandName }}"
      (click)="onCardSelected(i)"
    ></gc-card>
  </div>
</div>
